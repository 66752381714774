import React from 'react'
import PagePadding from 'components/PagePadding'
import MainSegment from 'components/MainSegment'
import Layout from 'components/Layout'
import RichText from 'components/RichText'
import Helmet from 'components/Helmet'
import Link from 'components/Link'
import useGlobalState from 'lib/globalState'
import { Deprecated } from 'components/Deprecated'

const TermsOfUse = () => {
  const [globalState] = useGlobalState()
  const langName = globalState.lang
  return langName === 'ru' ? (
    <Layout perple={true} minimal={true}>
      <Helmet title="Пользовательское соглашение" />
      <PagePadding>
        <MainSegment title="Пользовательское соглашение">
          <RichText>
            <Deprecated />
            <p>
              Пользовательское соглашение по использованию Сервиса и предоставлению прав использования базы данных
              (далее – Соглашение) определяет права и обязанности Администратора и Пользователя, далее при совместном
              упоминании именуемые Стороны, при использовании Сервиса Администратора.
              <br />
              Соглашение адресовано неопределенному кругу физических лиц, достигших 18 лет, юридических лиц и
              индивидуальных предпринимателей и является публичной офертой в соответствии с п. 2 ст. 437 Гражданского
              кодекса Российской Федерации (далее – ГК РФ).
              <br />
              Безусловным акцептом Пользователем Соглашения является выполнение действий, указанных в пп. 2.10-2.12
              Соглашения.
              <br />
              Соглашение действительно в электронном виде и не требует двустороннего подписания.
            </p>
            <h2>1. ОПРЕДЕЛЕНИЯ</h2>
            <ul>
              <li>
                1.1. Пользовательское соглашение (Соглашение) – по использованию Сервиса и предоставлению прав
                использования базы данных.
              </li>
              <li>
                1.2. Администратор - ИП «Дмитриев Сергей Игоревич», юридическое лицо, зарегистрированное и действующее в
                соответствии с законодательством Российской Федерации (ОГРН 317631300071877, ИНН 631110961004, адрес
                места нахождения: г. Самара, ул. Магнитогорская, д. 1, кв. 122, 443013), являющееся стороной Соглашения,
                предоставляющее Пользователю право использовать Сервис и базу данных.
              </li>
              <li>
                1.3. Сервис - автоматизированный программно-технологический комплекс, доступ к которому предоставляется
                в сети Интернет, по адресу: binking.io, оператором которого является Администратор, позволяющий в режиме
                реального времени осуществлять подключение к собственным сервисам Пользователей базы данных.
              </li>
              <li>
                1.4. База данных (БД) – база данных, содержащая информацию, позволяющую по номеру карты получить
                воспроизведение логотипа банка, цвет, номер телефона, и иную информацию о банке. БД может использоваться
                совместно с предоставляемым JS-плагином, позволяющим использовать БД и API.
              </li>
              <li>
                1.5. API - программный интерфейс, при использовании которого Пользователь получает функциональную
                возможность автоматизированным образом получать по номеру карты информацию из базы данных.
              </li>
              <li>
                1.6. Аутентификация – проверка подлинности предъявленного Пользователем идентификатора. Аутентификация
                требуется Пользователю при доступе к Сервису.
              </li>
              <li>
                1.7. Пользователь – физическое лицо, достигшее возраста 18 (восемнадцати) лет, юридическое лицо или
                индивидуальный предприниматель, присоединившийся к Соглашению.
              </li>
              <li>
                1.8. Личный кабинет – персональный интерфейс Сервиса с набором пользовательских инструментов,
                позволяющих получить доступ к функциональным возможностям Сервиса, в том числе: использовать API,
                получать базы данных банков, получать доступа к дополнительному функционалу и осуществлять иные действия
                посредством Сервиса в соответствии с Пользовательским соглашением. Вход в Личный кабинет осуществляется
                Пользователем после Аутентификации.
              </li>
              <li>
                1.9. Простая ЭП - электронная подпись, в соответствии с Федеральным законом «Об электронной подписи» от
                06 апреля 2011 г. № 63-ФЗ, создаваемая Пользователем с использованием Логина и Пароля при авторизации на
                Сайте. Формирование простой ЭП выражается в форме уникального номера, присваиваемого Пользователю при
                регистрации на Сайте и присоединяемого ко всем электронным документам (сообщениям), создаваемым
                Пользователем через Личный кабинет. Добавление уникального номера Пользователя к любому оформляемому
                через Сайт сообщению (документу) производится автоматически, во всех случаях, когда для оформления
                (акцепта) этого сообщения (документа) Пользователю было необходимо указать свой Логин и Пароль.
                Пользователи рассматривают свои простые ЭП в качестве аналогов собственноручных подписей, а документы
                (сообщения) оформленные Пользователями на Сайте под своим Логином и Паролем - как документы (сообщения),
                подписанные простой ЭП, равнозначной (имеющими одинаковую юридическую силу) документам на бумажном
                носителе, подписанным собственноручной подписью. Пользователи обязуются соблюдать конфиденциальность
                своего Логина и Пароля. Определение лица, подписавшего на Сайте сообщение (документ) своей простой ЭП,
                осуществляется и подтверждается Интернет-Площадкой на основании введенных Пользователем при создании
                этого сообщения (документа) Логина и Пароля.
              </li>
              <li>
                1.10. Электронный документ Сервиса (ЭДС) - взаимосвязанный набор электронных записей (log-файлов),
                создаваемый и хранимый с помощью Сервиса, выражающий намерение Пользователя заключить Соглашение
                посредством Сервиса и получить функциональные и иные возможности Сервиса.
              </li>
              <li>
                1.11. Виртуальный лицевой счет – счет, расположенный в Сервисе, отражающий текущий баланс средств
                Пользователя для получения функциональных и иных возможностей Сервиса.
              </li>
              <li>
                1.12. Электронный журнал - взаимосвязанный набор электронных записей (log-файлов), отражающий действия
                Пользователей в Сервисе. Электронный журнал хранится у Администратора Сервиса.
              </li>
              <li>
                1.13. Ключ – известная Пользователю гиперактивная ссылка, полученная на его адрес электронной почты,
                указанным при регистрации, переход по которой подтверждает личность Пользователя и его Аутентификацию.
              </li>
              <li>
                1.14. Политика конфиденциальности - документ, который принят Администратором в соответствии с
                требованиями законодательства Российской Федерации и определяет политику Администратора в отношении
                обработки персональных данных, порядок обработки Администратором персональных данных и меры по
                обеспечению безопасности персональных данных, принятые Администратором. Действующая редакция Политики
                конфиденциальности размещена в открытом доступе в сети Интернет по адресу:
                https://binking.ru/ru/privacy-policy
              </li>
              <li>
                1.15. Тарифы – размер вознаграждения за использование API и базы данных, установленные Администратором и
                доступные для ознакомления в Личном кабинете Пользователя и (или) на странице
                https://binking.io/ru/price
              </li>
              <li>
                1.16. Учетная запись – совокупность логина и пароля Пользователя в Сервисе. Логин и пароль Пользователя
                являются аналогом собственноручной подписи.
              </li>
              <li>
                1.17. Кнопка - элемент интерфейса Сервиса, нажатие на который означает однозначное согласие Пользователя
                с условиями, непосредственно относящимися к нему.
              </li>
              <li>
                1.18. Программное обеспечение – конфигурация (программное обеспечение), представляющая собой
                совокупность данных и команд, представленных в объективной форме, предоставляемая Пользователю в Сервисе
                с целью учета операций, производимых в Сервисе или с целью предоставления отчетности для анализа
                Сервисом. На Программное обеспечение Пользователю предоставляется право использования на условиях
                простой (неисключительной) лицензии на срок действия Соглашения.
              </li>
              <li>1.19. Сайт – сайт, расположенный в сети Интернет по адресу: binking.io.</li>
            </ul>
            <h2>2. ПРЕДМЕТ СОГЛАШЕНИЯ. ОБЩИЕ ТРЕБОВАНИЯ</h2>
            <ul>
              <li>
                2.1. В соответствии с условиями Соглашения Администратор предоставляет Пользователю право использования
                Сервиса на условиях простой (неисключительной) лицензии, а Пользователь обязуется оплатить
                пользовательское вознаграждение в случаях, предусмотренных Тарифами.
              </li>
              <li>
                2.2. Все условия, оговоренные далее, относятся как к Сервису в целом, так и ко всем компонентам в
                отдельности.
              </li>
              <li>2.3. Территория предоставления прав – все страны мира.</li>
              <li>2.4. Срок предоставления прав – ограничен сроком действия Соглашения и равен 100 (ста) годам.</li>
              <li>
                2.5. Администратор имеет право в любой момент изменять Соглашение и все приложения к нему (полностью или
                в части) в одностороннем порядке, без предварительного согласования с Пользователем. Все изменения
                вступают в силу в дату размещения на Сайте, если более длительный срок вступления изменений в силу не
                определен дополнительно при публикации новой редакции Соглашения.
              </li>
              <li>
                2.6. Пользователь обязуется самостоятельно следить за изменениями Соглашения и приложений к нему путем
                периодического ознакомления с актуальной редакцией не менее одного раза в пять календарных дней.
                Пользователь самостоятельно отвечает за любые последствия, наступившие в связи с неознакомлением с
                Соглашением, приложениями к нему и изменениями к ним.
              </li>
              <li>
                2.7. В случае если Пользователь не согласен с каким-либо положением Соглашения и (или) приложений к
                нему, и (или) изменениями к ним, он обязан незамедлительно прекратить использование Сервиса.
              </li>
              <li>2.8. Соглашение состоит из:</li>
              <li>
                2.8.1. Пользовательского соглашения по использованию Сервиса и предоставлению прав использования базы
                данных (публичная оферта).
              </li>
              <li>2.8.2. Тарифов, размещаемых в Личном кабинете и (или) на странице https://binking.io/ru/price</li>
              <li>
                2.9. Перед использованием Сервиса (в том числе при согласии с условиями Соглашения) Пользователь обязан
                ознакомиться:
              </li>
              <li>2.9.1. с содержанием Соглашения и приложений к нему;</li>
              <li>2.9.2. с описанием и функциональностью Сервиса;</li>
              <li>2.9.3. с порядком расчета пользовательского вознаграждения.</li>
              <li>
                2.10. Для начала использования Сервиса и присоединения к Соглашению Пользователь должен совершить
                конклюдентные действия: акцептовать Соглашение путем нажатия кнопок с соответствующей функциональной
                возможностью на Сайте, а также успешно пройти процедуру Аутентификации в порядке, установленным
                Соглашением. Иной порядок акцепта может быть предусмотрен отдельными Приложениями к настоящему
                Соглашению или изменениями к нему.
              </li>
              <li>
                2.11. Пользователь гарантирует, что Учетная запись и предоставленная информация относится к
                Пользователю.
              </li>
              <li>
                2.12. Совершение Пользователем действий, указанных на Сайте в качестве действий, необходимых для
                использования функциональной возможности Сервиса (переход по ссылке на Сайте / нажатие кнопок с
                соответствующей функциональной возможностью / ввод Ключа в предложенную экранную форму и прочее),
                признаются действиями, совершенными лично Пользователем, а также являются действиями, совершенными с
                использованием Простой ЭП и считаются однозначным выражением согласия (акцептом) Пользователя на
                получение соответствующей функциональной возможности на условиях, указанных Администратором на Сайте и в
                Соглашении. Определение Пользователя, использующего Простую ЭП, осуществляется Администратором после
                успешной Аутентификации. ЭДС считается подписанным Простой ЭП при установлении факта совершения
                Пользователем действий, указанных на Сайте в качестве действий, необходимых для использования
                функциональной возможности Сервиса (переход по ссылке на Сайте / нажатие кнопок с соответствующей
                функциональной возможностью / ввод Ключа, совпадающего с отправленным Пользователю Ключом). Акцептом
                Соглашения и согласие с Тарифами на платные функциональные возможности Сервиса является оплата
                Пользователем права использования Базы данных либо зачисление денежных средств на виртуальный счет для
                использования API.
              </li>
              <li>
                2.13. Право использования Сервиса считается предоставленным с момента акцепта Пользователем Соглашения в
                соответствии с 2.12 Соглашения. Стороны установили, что Акт приема-передачи прав не составляется.
                Доказательством предоставления Пользователю права использования Сервиса являются данные Электронного
                журнала Администратора. Стороны установили, что акты и счета- фактуры на использование платного
                функционала Сервиса не составляются. Доказательством предоставления Пользователю права использования
                платного функционала Сервиса являются данные Электронного журнала Администратора.
              </li>
            </ul>
            <h2>3. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
            <ul>
              <li>3.1. Администратор обязан:</li>
              <li>3.1.1. Предоставить Пользователю право использования Сервиса путем открытия доступа к Сервису.</li>
              <li>3.1.2. В разумные сроки устранять сбои в работе Сервиса, возникшие по вине Администратора.</li>
              <li>
                3.1.3. Осуществлять техническую поддержку Пользователей по вопросам работы с Сервисом. Техническая
                поддержка и взаимодействие с Сервисом осуществляется в рабочие дни, рабочее время (по GMT +03:00) по
                контактам, указанным на Сайте. Ответы по вопросам работы с Сервисом направляются Пользователю в течение
                7 (семи) рабочих дней с момента поступления запроса Администратору.
              </li>
              <li>
                3.1.4. Предоставить возможность использования API при положительном балансе на виртуальном счете
                Пользователя.
              </li>
              <li>
                3.1.5. Предоставить Пользователю ссылку для загрузки и использования БД. Срок доступности ссылки для
                загрузки БД составляет 1 (один) год с момента оплаты.
              </li>
              <li>3.2. Пользователь обязан:</li>
              <li>3.2.1. Использовать Сервис в соответствии с Соглашением.</li>
              <li>
                3.2.2. Признавать документы и сообщения, подписанные с использованием Простой ЭП, равнозначными
                документам, подписанным собственноручной подписью на бумажном носителе.
              </li>
              <li>3.2.3. Принять и исполнять соответствующие условия Соглашения.</li>
              <li>3.2.4. Обеспечивать достаточность средств на Виртуальном счете для продолжения использования API.</li>
              <li>3.2.5. Не предоставлять возможность пользования Личного кабинета третьим лицам.</li>
              <li>
                3.2.6. Самостоятельно принимать решения о целесообразности использования Сервиса, нести ответственность
                за принятие таких решений, не предъявлять к Администратору претензии в связи с неисполнением Соглашения
                по независящим от Администратора причинам.
              </li>
              <li>
                3.2.7. Своевременно предоставлять актуальные сведения, необходимые Администратору для осуществления
                деятельности в соответствии с Соглашением.
              </li>
              <li>3.3. Администратор имеет право:</li>
              <li>
                3.3.1. В одностороннем внесудебном порядке расторгнуть Соглашение и (или) блокировать доступ
                Пользователя к Сервису, запретить доступ в Личный кабинет Пользователя в Сервисе либо ограничить
                Пользователю часть функциональных возможностей или поддержку. Администратор не несет ответственности за
                убытки (ущерб), возникшие у Пользователя в связи с такими действиями Администратора. Администратор не
                обязан сообщать Пользователю причины расторжения Соглашения и (или) блокировки (удаления) Личного
                кабинета.
              </li>
              <li>
                3.3.2. Проводить профилактические работы на сервере, для чего временно приостанавливать доступ к
                Сервису, API и БД.
              </li>
              <li>
                3.3.3. Модифицировать или выпускать обновления для Сервиса, добавлять новые свойства или функциональные
                возможности, повышающие его работоспособность или иным способом улучшающие его характеристики, в том
                числе в целях удовлетворения требований законодательства.
              </li>
              <li>3.4. Пользователь имеет право:</li>
              <li>3.4.1. Использовать Сервис в соответствии с условиями Соглашения;</li>
              <li>
                3.4.2. После оплаты вознаграждения в соответствии с Тарифами использовать БД и API, предоставленные
                Администратором;
              </li>
              <li>3.4.3. Использовать дополнительный функционал Сервиса.</li>
              <li>
                3.5. Пользователь не вправе использовать Сервис с целью совершения противоправных действий, в том числе
                с целью:
              </li>
              <li>
                3.5.1. Предоставления неверно указанных данных Пользователя и иных сведений, которые запрашивает Сервис;
              </li>
              <li>
                3.5.2. Легализации доходов, полученных преступным путем, и любых других операций в нарушение
                законодательства;
              </li>
              <li>3.5.3. Нарушения нормальной работы Сайта;</li>
              <li>3.5.4. Использования Сервиса способом, прямо не предусмотренным Соглашением;</li>
              <li>3.5.5. Распространения, копирования, обнародования БД и API;</li>
              <li>3.5.6. Использования Сервиса и/или его частей за пределами срока действия Соглашения;</li>
              <li>3.5.7. Аутентификации в Личном кабинете третьих лиц;</li>
              <li>
                3.5.8. Размещения материалов, содержащих: нецензурные, бранные слова или словосочетания,
                порнографические изображения и тексты или сцены сексуального характера; угрозы, призывы к насилию и
                совершению противоправных действий, нарушению законодательства; элементы насилия, жестокости, расовой,
                межнациональной или межрелигиозной розни, а также ссылки на такие материалы; нацистскую атрибутику или
                символику; пропаганду преступной деятельности, руководства по совершению противоправных действий;
                результаты интеллектуальной деятельности третьих лиц (без согласия указанных лиц).
              </li>
              <li>3.6. Гарантии и заявления.</li>
              <li>
                3.6.1. Пользователь заверяет и гарантирует, что все условия Соглашения ему понятны, и Пользователь
                принимает условия Соглашения без оговорок в полном объеме.
              </li>
              <li>
                3.6.2. Пользователь гарантирует, что не будет использовать Сервис в целях, прямо не указанных в
                Соглашении и на Сайте.
              </li>
              <li>
                3.6.3. Пользователь имеет право запросить возврат денежных средств за использование сервиса в течение 10
                дней с момента оплаты услуг. Заявление о возврате является действительным только при условии, что было
                направлено по адресу manager@binking.io с электронной почты пользователя, привязанной к аккаунту, через
                который были оплачены услуги.
              </li>
            </ul>
            <h2>4. ОБЕСПЕЧЕНИЕ КОНФИДЕНЦИАЛЬНОСТИ</h2>
            <ul>
              <li>
                4.1. Администратор является оператором персональных данных в соответствии с законодательством и несет
                все права, обязанности и ответственность, которые возлагаются законодательством на оператора
                персональных данных. При обработке персональных данных Пользователя Администратор руководствуется
                Федеральным законом от 29.07.2006. № 152-ФЗ «О персональных данных», Политикой конфиденциальности
                Сервиса.
              </li>
              <li>
                4.2. Пользователь осознает и дает согласие, что в результате сбоя, технических неисправностей, действий
                третьих лиц, в том числе (но не ограничиваясь) вирусной или хакерской атаке, данные Пользователя,
                размещенные в Сервисе, могут стать доступны третьим лицам. Пользователь обязуется не предъявлять
                требований к Администратору о возмещении убытков (ущерба), возникших, в связи с этим.
              </li>
              <li>4.3. Администратор обрабатывает только данные, которые необходимы для исполнения Соглашения.</li>
              <li>
                4.4. Стороны договорились сохранять в режиме конфиденциальности любые сведения, полученные одной
                Стороной в отношении другой в ходе исполнения обязательств по Соглашению. К конфиденциальной информации
                не относятся общедоступные сведения и сведения, согласие на распространение которых Пользователь дал
                согласие.
              </li>
              <li>
                4.5. Политика конфиденциальности Сервиса размещена по адресу Сайта:
                https://binking.io/ru/privacy-policy.
              </li>
            </ul>
            <h2>5. ОТВЕТСТВЕННОСТЬ. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h2>
            <ul>
              <li>
                5.1. Сервис предоставляется Пользователю «как есть» (as is), в соответствии с общепринятым в
                международной практике принципом. Это означает, что за проблемы, возникающие в процессе установки,
                обновления, поддержки и эксплуатации Сервиса (в т. ч. проблемы совместимости с другими программными
                продуктами (пакетами, драйверами и др.), несоответствия результатов использования Сервиса ожиданиям
                Пользователя, ошибок в расчетах и т. п.), Администратор ответственности не несет. Пользователь должен
                понимать, что несет полную ответственность за возможные негативные последствия, вызванные
                несовместимостью или конфликтами Сервиса с другими программными продуктами, установленными на компьютере
                или ином устройстве Пользователя. Сервис не предназначен и не может быть использован в информационных
                системах, работающих в опасных средах, либо обслуживающих системы жизнеобеспечения, в которых сбой в
                работе Сервиса может создать угрозу жизни людей или повлечь большие материальные убытки.
              </li>
              <li>
                5.2. Администратор не дает никаких заверений и не дает никаких гарантий того, что предоставляемые услуги
                будут бесперебойными или безошибочными, и не дает никаких гарантий, что веб-сайт или хост-сервер не
                содержат вирусов или других форм вредоносного кода или программ. Пользователь понимает и соглашается с
                тем, что Администратор не гарантирует и не может гарантировать точность базы данных.
              </li>
              <li>
                5.3. Ни при каких обстоятельствах Администратор, его агенты или лица, участвующие в создании, создании,
                доставке или обновлении Сайта и / или базы данных, не несут ответственности за любые прямые, косвенные,
                случайные или косвенные убытки, возникшие в результате использования данного Сайта, БД или API, любых
                продуктов или услуг Администратора, а также продуктов и / или услуг любой другой связанной компании или
                веб-сайта.
              </li>
              <li>
                5.4. Ответственность Администратора в отношении продуктов или услуг, приобретенных непосредственно у
                Администратора, прямо ограничена заменой дефектных товаров / исправлением услуг или возвратом или
                зачислением Покупателю суммы, равной покупной цене товара. Ссылки на другие веб-сайты с веб-сайта
                Администратора не подразумевают одобрение компании, продуктов или услуг, доступных на таких веб-сайтах.
                Ссылки на другие сайты предоставляются Администратором исключительно для удобства пользователей.
              </li>
              <li>
                5.5. Администратор не несет ответственности за невозможность использования Сервиса по причинам,
                зависящим от Пользователя или третьих лиц.
              </li>
              <li>
                5.6. Администратор прилагает все возможные усилия для обеспечения нормальной работоспособности Сервиса,
                однако не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по Соглашению,
                а также за прямые и косвенные убытки Пользователя, включая упущенную выгоду и возможный ущерб, возникший
                в том числе в результате:
              </li>
              <li>5.6.1. сбоев в работе Сервиса;</li>
              <li>
                5.6.2. неправомерных действий пользователей сети Интернет, направленных на нарушение информационной
                безопасности или нормального функционирования Сайта и Сервиса;
              </li>
              <li>
                5.6.3. отсутствия (невозможности установления, прекращения и пр.) Интернет-соединения между сервером
                Пользователя и сервером Администратора;
              </li>
              <li>
                5.6.4. проведения государственными и муниципальными органами, а также иными организациями мероприятий в
                рамках оперативно-розыскных мероприятий;
              </li>
              <li>
                5.6.5. установления государственного регулирования (или регулирования иными организациями) хозяйственной
                деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых
                ограничений, затрудняющих или делающих невозможным исполнение Соглашения;
              </li>
              <li>
                5.6.6. других случаев, связанных с действиями (бездействием) пользователей сети Интернет и/или других
                субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного
                оборудования, существовавшей на момент заключения Соглашения.
              </li>
              <li>
                5.7. Администратор оставляет за собой право приостанавливать работу Сайта и Сервиса для проведения
                профилактических работ.
              </li>
              <li>
                5.8. Пользователь при использовании Сервиса обязуется не нарушать законодательство и интересы третьих
                лиц. Администратор не несет ответственность за действия Пользователя, совершаемые с использованием
                Сервиса, а равно не несет ответственности за невыполнение Пользователем обязательств перед третьими
                лицами.
              </li>
              <li>
                5.9. Пользователь самостоятельно несет ответственность за сохранность своих данных для доступа к Личному
                кабинету и за убытки, которые могут возникнуть по причине несанкционированного его использования.
              </li>
              <li>
                5.10. В случае возникновения между Пользователем и Администратором споров или разногласий, вытекающих из
                Соглашения или связанных с ним, стороны примут все меры к их разрешению путем переговоров между собой. В
                случае если не удается разрешить возникшие между сторонами споры и/или разногласия путем переговоров,
                такие споры разрешаются по месту нахождения Администратора.
              </li>
              <li>
                5.11. Администратор не несет ответственности за недоставку операторами связи отправленных
                Администратором сообщений, ключей, иной информации и документов.
              </li>
              <li>
                5.12. Пользователь несет ответственность за достоверность предоставленной Администратору информации и ее
                актуализацию.
              </li>
              <li>5.13. Администратор не несет ответственности за:</li>
              <li>5.13.1. Нарушение Пользователем условий Соглашения;</li>
              <li>
                5.13.2. Неправомерные действия третьих лиц, в том числе связанных с использованием Личного кабинета
                Пользователя и списанием средств;
              </li>
              <li>
                5.13.3. Какие-либо косвенные/непрямые убытки или упущенную выгоду Пользователя и/или третьих лиц в
                результате использования Сервиса;
              </li>
              <li>
                5.13.4. За неисправности, ошибки данных и сбои в работе программно- аппаратного комплекса,
                обеспечивающего функционирование Сервиса, а также за временное отсутствие у Пользователя доступа к
                Сервису и предоставляемым функциональным возможностям в связи с проведением профилактических и иных
                работ, а также связанные с этим убытки Пользователя.
              </li>
              <li>
                5.14. Стороны не несут ответственности за частичное или полное неисполнение обязательств по Соглашению,
                если это неисполнение явилось следствием обстоятельств непреодолимой силы, возникших после заключения
                Соглашения в результате обстоятельств чрезвычайного характера, которые Стороны не могли предвидеть или
                предотвратить, в том числе: пожары, наводнения, стихийные бедствия, войны, террористические акты,
                изменения законодательства, в результате которых одна из Сторон Соглашения не сможет исполнить принятые
                на себя обязательства. Сторона по Соглашению, затронутая обстоятельствами непреодолимой силы, должна в
                течение 10 (десяти) календарных дней известить другую Сторону о наступлении обстоятельств непреодолимой
                силы.
              </li>
              <li>
                5.15. Пользователь понимает и соглашается с тем, что 100-процентная точность базы данных является целью,
                но не может быть гарантирована. Администрация постоянно работает над поддержанием точных записей и
                актуальной информации, а также регулярно обновляет записи базы данных. Пользователь понимает и
                соглашается, что 100-процентная точность невозможна. Может быть некоторая временная задержка между
                фактическим изменением данных и временем, когда эти данные отражаются в базе данных.
              </li>
              <li>
                5.16. Любое копирование, повторное использование, изменение, распространение, использование или
                публикация Пользователем какой-либо части сайта Администратора строго запрещены, если Администратор
                заранее не предоставил письменное разрешение.
              </li>
            </ul>
            <h2>6. ПОЛЬЗОВАТЕЛЬСКОЕ ВОЗНАГРАЖДЕНИЕ.РАСЧЕТЫ ПО СОГЛАШЕНИЮ</h2>
            <ul>
              <li>
                6.1. Пользователь уплачивает Администратору Пользовательское вознаграждение за использование Сервиса
                (API), Базы данных в размерах, устанавливаемыми Тарифами, размещенными на Сайте.
              </li>
              <li>
                6.2. Вознаграждение перечисляется путем перевода денежных средств на Виртуальный лицевой счет
                Администратора с использованием программного обеспечения CloudPayments, размещенного на Сайте.
              </li>
              <li>
                6.3. Вознаграждение Администратора не облагается НДС в связи с использованием упрощенной системы
                налогообложения.
              </li>
              <li>6.4. Комиссии и сборы, в случае их взимания, оплачивает Пользователь.</li>
              <li>
                6.5. За право использования БД Пользователь оплачивает единовременное Пользовательское вознаграждение
                согласно Тарифам.
              </li>
              <li>
                6.6. Для использования API Пользователь пополняет Виртуальный лицевой счет в Сервисе, который отражает
                текущие финансовые обязательства Администратора перед Пользователем.
              </li>
              <li>
                6.7. Обязательства Администратора перед Пользователем, отраженные на виртуальном лицевом счете
                Пользователя в личном кабинете Сервиса, изменяются при осуществлении Пользователем распоряжения
                денежными средствами, находящимися у Администратора, путем ежеразового использования функционала API
                (денежные средства списываются с Виртуального лицевого счета за каждое обращение к API, не считая
                тестовых «sandbox=1»).
              </li>
              <li>
                6.8. При акцепте Соглашения Пользователь привязывает банковскую карту к Виртуальному лицевому счету,
                после чего в случае израсходования всех денежных средств на Виртуальном лицевом счете происходит его
                автоматическое пополнение с карты Пользователя согласно Тарифам. Пользователь может отвязать банковскую
                карту от Виртуального лицевого счета и отключить автоматическое пополнение в Личном кабинете.
              </li>
              <li>
                6.9. Все остальные условия, не охваченные настоящим Соглашением, указаны на Сайте и (или) в Личном
                кабинете.
              </li>
              <li>
                6.10. Из-за нематериального характера продуктов Администратора, их электронного распространения и
                способности Пользователя протестировать продукты перед их покупкой возврат средств после покупки не
                предусмотрен. Пользователь соглашается с тем, что любые обнаруженные проблемы или проблемы с базой
                данных должны быть немедленно доведены до сведения службы поддержки Администратора. Возврат денег за
                ошибки или пропуски данных в базе данных не предусмотрены. Пользователь понимает, что база данных может
                содержать неточности и некорректную информацию Оплата может быть отменена, если покупка была совершена
                по ошибке. Такие запросы должны быть отправлены по электронной почте и будут обработаны в течение 3
                рабочих дней.
              </li>
            </ul>
            <h2>7. СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ. ПОРЯДОК РАСТОРЖЕНИЯ</h2>
            <ul>
              <li>7.1. Соглашение вступает в силу с даты акцепта и действует в течение 10 лет.</li>
              <li>7.2. Соглашение может быть расторгнуто досрочно:</li>
              <li>7.2.1. по взаимному соглашению Администратора и Пользователя;</li>
              <li>
                7.2.2. по инициативе Администратора в одностороннем внесудебном порядке в случае нарушения Пользователем
                условий Соглашения без возврата последнему каких-либо денежных средств, уплаченных ранее.
              </li>
              <li>
                7.3. Уведомление о расторжении Соглашения направляется Администратором посредством Личного кабинета или
                электронной почты за 5 (пять) календарных дней до даты расторжения.
              </li>
              <li>
                7.4. По окончании срока, указанного в п. 7.3 Соглашения, доступ в Личный кабинет пользователя в Сервисе
                прекращается.
              </li>
            </ul>
            <h2>8. ПРОЧИЕ УСЛОВИЯ</h2>
            <ul>
              <li>
                8.1. Пользователь вправе использовать все последующие обновления/новые версии Сервиса на условиях
                использования исходного Сервиса.
              </li>
              <li>
                8.2. Все вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством
                РФ.
              </li>
              <li>
                8.3. При покупке базы данных Пользователю предоставляется непередаваемая, неисключительная, отзывная
                лицензия для доступа и использования Сайта и / или базы данных в соответствии с настоящим Соглашением.
                Пользователю запрещается передавать Сервис, API, БД в пользование третьим лицам, а также совершать любые
                иные сделки в отношении указанных объектов с третьими лицами. Пользователь не имеет права перепродавать
                или публиковать базу данных в любой форме для любых целей, а также Пользователь не уполномочен создавать
                или предлагать бесплатные службы поиска с использованием базы данных Администратора.
              </li>
              <li>
                8.4. Разрешение споров, связанных с установлением подлинности ЭДС Пользователя, подписанных Простой ЭП,
                осуществляется Рабочей группой, в которую входят представители Сторон. Полномочия членов Рабочей группы
                подтверждаются доверенностью или распорядительным актом стороны, которую они представляют. Заседание
                Рабочей группы по рассмотрению претензии Пользователя проводится по адресу Администратора. Рабочая
                группа использует для анализа данные Электронного журнала и определяет, включая, но не ограничиваясь,
                следующее:
              </li>
              <li>8.4.1. Предмет разногласий на основании претензии Пользователя.</li>
              <li>8.4.2. Операцию, относящуюся к предмету разногласий.</li>
              <li>8.4.3. Факт Аутентификации, предшествующий отправке спорного ЭДС.</li>
              <li>
                8.4.4. Дату и время введения Ключа (при наличии соответствующего запроса от Сервиса) для подтверждения
                факта формирования Простой ЭП.
              </li>
              <li>
                8.5. Подтверждением правильности исполнения Сервисом спорного ЭДС является одновременное выполнение
                следующих условий:
              </li>
              <li>8.5.1. Установлен факт Аутентификации, предшествующий отправке спорного ЭДС.</li>
              <li>
                8.5.2. Установлен факт отправления Ключа на указанный адрес Пользователя (при наличии соответствующего
                запроса от Сервиса).
              </li>
              <li>
                8.5.3. Установлен факт Ключа, совпадающего с отправленным Пользователю Ключом, для подтверждения факта
                формирования ЭДС (при наличии соответствующего запроса от Сервиса).
              </li>
              <li>
                8.6. В случае подтверждения правильности исполнения Администратором спорного ЭДС претензии Пользователя
                к Администратору, связанные с последствиями исполнения указанного ЭДС, признаются необоснованными.
                Невыполнение любого из перечисленных условий означает, что правильность исполнения ЭДС не подтверждена,
                то есть проверяемый ЭДС подтвержден некорректной Простой ЭП либо ЭДС не был правильно исполнен
                Администратором. В этом случае претензии к Администратору, связанные с последствиями исполнения
                указанного ЭДС, признаются обоснованными. По итогам заседания Рабочей группы оформляется акт Рабочей
                группы.
              </li>
              <li>
                8.7. Все споры и разногласия, которые могут возникнуть из Соглашения по вопросам, не нашедшим своего
                разрешения в тексте Соглашения, будут разрешаться путем переговоров. При невозможности урегулирования
                спорных вопросов по Соглашению в процессе переговоров, споры с Пользователем – юридическим лицом или
                индивидуальным предпринимателем подлежат рассмотрению в Арбитражном суде Самарской области, споры с
                участием Пользователей – физических лиц, не являющихся индивидуальными предпринимателями, подлежат
                рассмотрению в Ленинском районном суде города Самары.
              </li>
              <li>
                8.8. Стороны признают используемые ими по Соглашению системы телекоммуникаций, обработки и хранения
                информации достаточными для обеспечения надежной и эффективной работы при приеме, передаче, обработке и
                хранении информации, а систему защиты информации, обеспечивающую разграничение доступа, шифрование,
                достаточной для защиты от несанкционированного доступа, подтверждения авторства и подлинности
                информации, содержащейся в получаемых ЭДС, и разбора спорных ситуаций.
              </li>
              <li>
                8.9. Пользователь соглашается не использовать сайт Администратора или его продукты и / или услуги для
                любых целей, которые являются незаконными или запрещенными в любом месте. Пользователь также соглашается
                не использовать Сайт каким-либо образом, который может отключить, повредить, перегружать или ухудшить
                работу любого сайта Администратора, а также помешать любой другой стороне использовать Сайт и / или
                продукты или услуги Администратора. Покупатель также соглашается не пытаться получить
                несанкционированный доступ к какому- либо материалу Администратора
              </li>
              <li>8.10. Местом заключения настоящего Соглашения признается г. Самара.</li>
              <li>
                8.11. Стороны гарантируют, что в течение срока действия Договора ими будут приниматься все надлежащие
                меры по предупреждению коррупционного поведения со стороны представителей обеих Сторон, а также их
                контрагентов. Стороны подтверждают, что в силу положений ФЗ №273-ФЗ от 25.12.2008 «О противодействии
                коррупции» применяют и соблюдают стандарты и процедуры, направленные на обеспечение добросовестной
                работы организаций и предотвращение коррупционных правонарушений.
              </li>
              <li>
                8.12. Настоящим Стороны согласовали, что вся переписка между Сторонами в процессе исполнения настоящего
                Соглашения, предоставление информации, направление запросов и уведомлений, претензий (а также исковых
                заявлений), осуществляется с использованием Сервиса и/или электронной почты, указанной Пользователем в
                процессе регистрации, и не требует дублирования посредством направления бумажных документов.
              </li>
              <li>
                8.13. Стороны признают, что информация, переданная с использованием Сервиса, считается доставленной
                второй Стороне, с момента отправки в самом Сервисе, либо с момента отправки с электронной почты
                Администратора: manager@binking.io
              </li>
              <li>
                8.14. Сообщение/документация считается доставленным(ой) и в тех случаях, если оно поступило лицу,
                которому оно направлено (адресату), но по обстоятельствам, зависящим от него, адресат не ознакомился с
                ним.
              </li>
            </ul>
            <h2>9. РЕКВИЗИТЫ АДМИНИСТРАТОРА</h2>
            <p>
              Название организации: ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ ДМИТРИЕВ СЕРГЕЙ ИГОРЕВИЧ
              <br />
              Юридический адрес организации: Российская Федерация, 443013, САМАРСКАЯ ОБЛ, Г САМАРА, УЛ МАГНИТОГОРСКАЯ,
              д. 1, кв. 122
              <br />
              ИНН: 631110961004
              <br />
              Расчетный счет: 40802810100000152466
              <br />
              ОГРН: 317631300071877
              <br />
              Банк: АО «Тинькофф Банк»
              <br />
              БИК банка: 044525974
              <br />
              ИНН банка: 7710140679
              <br />
              Корреспондентский счет банка: 30101810145250000974
              <br />
              Юридический адрес банка: 127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26
            </p>
          </RichText>
        </MainSegment>
      </PagePadding>
    </Layout>
  ) : (
    <Layout perple={true} minimal={true}>
      <Helmet title="TERMS OF USE" />
      <PagePadding>
        <MainSegment title="TERMS OF USE">
          <RichText>
            <Deprecated />
            <p>
              This User Agreement for the use of Service and the granting of rights to use the database (hereinafter
              referred to as “Agreement”) defines the rights and obligations of Administrator and the User, hereinafter
              jointly referred to as “Parties”, by the use of Service of Administrator.
            </p>
            <p>
              The Agreement is intended to an indefinite circle of individuals above the age of 18 years, to legal
              entities and individual entrepreneurs and represents a public offer.
            </p>
            <p>
              The unconditional acceptance by the User of this Agreement is the execution of actions, specified in
              clauses 2.10-2.12 of Agreement.
            </p>
            <p>This agreement is valid in electronic form and does not require any bilateral signing.</p>
            <h2>1. DEFINITIONS</h2>
            <ul>
              <li>
                1.1. User Agreement (Agreement) – refers to the use of Service and the granting of rights to use the
                database.
              </li>
              <li>
                1.2. Administrator - IE "Sergei Dmitriev", a legal entity registered and acting in accordance with the
                legislation of the UK (BINBASE LTD, ID 14298599, location address: 41 Florence Avenue, Hove, England,
                BN3 7GX) - is a party to this Agreement, who grants the User the right to use the Service and the
                database.
              </li>
              <li>
                1.3. Service - an automated software and hardware complex, access to which is provided on the Internet,
                at the address: binking.io, the operator of which is the Administrator, which allows a real-time
                connection to the own services of the Users of database.
              </li>
              <li>
                1.4. Database (DB) is a database containing information that allows getting a reproduction of the bank's
                logo, colour, phone number, and other information about the bank by the card number. The database can be
                used in conjunction with the provided JS plugin that allows using the database and API.
              </li>
              <li>
                1.5. API is a software interface, by which deployment the User receives the functional possibility to
                automatically receive information from the database by the card number.
              </li>
              <li>
                1.6. Authentication - verification of the authenticity of the identifier presented by the User.
                Authentication is required from the User when accessing the Service.
              </li>
              <li>
                1.7. User - an individual who has reached the age of 18 (eighteen) years, a legal entity or an
                individual entrepreneur who has accepted the Agreement.
              </li>
              <li>
                1.8. Personal Account - a personal interface of the Service with a set of custom tools that enable an
                access to the functionality of the Service, such as: to use the API, to get databases of banks, to get
                access to additional functionality and perform other actions by the means of Service in accordance with
                the User Agreement. A Login to the Personal Account is performed by the User after Authentication.
              </li>
              <li>
                1.9. Simple ES is an electronic signature in accordance with the Federal Law "On Electronic Signature"
                from April 06, 2011 No. 63-FZ, created by the User using the Login and Password when authorizing on the
                Website. The formation of a simple electronic signature is expressed in the form of a unique number
                assigned to the User when registering on the Website and attached to all electronic documents (messages)
                created by the User within the Personal Account. The addition of a unique User number to any message
                (document) issued through the Website is done automatically, in all cases when, in order to issue
                (accept) this message (document) the User had to specify his Login and Password. The Users consider
                their simple electronic signatures as analogs of handwritten signatures, and the documents (messages)
                executed by Users on the Website under their Login and Password - as the documents (messages) signed by
                a simple electronic signature, equivalent (having the same legal force) to paper documents, signed with
                their own handwritten signature. The Users undertake to respect the confidentiality of their Login and
                Password. The identification of the person who signed the message (document) with his simple electronic
                signature on the Website is performed and confirmed by the Internet Platform on the basis of the Login
                and Password entered by the User by creation of this message (document).
              </li>
              <li>
                1.10. An electronic document of the Service (EDS) is an interconnected set of electronic records
                (log-files) created and stored using the Service, expressing the User's intention to conclude an
                Agreement through the Service and to get the functional and other capabilities of the Service.
              </li>
              <li>
                1.11. Virtual personal account - an account located on the Service, reflecting the current balance of
                the User's funds to obtain the functional and other capabilities of the Service.
              </li>
              <li>
                1.12. An electronic journal is an interconnected set of electronic records (log files) that reflect the
                actions of Users in the Service. The electronic journal is kept by the Service Administrator.
              </li>
              <li>
                1.13. Key - a hyper-active link known to the User, received to his email address, specified by the
                registration. Following this link confirms the identity of the User and his Authentication.
              </li>
              <li>
                1.14. Privacy Policy is a document adopted by the Administrator in accordance with the requirements of
                the legislation of the UK. It defines the Administrator's policy regarding the processing of personal
                data, the procedure for processing of personal data by the Administrator and the measures to ensure the
                security of personal data taken by the Administrator. The current version of the Privacy Policy is
                publicly available on the Internet at:{' '}
                <Link href={'https://binking.io/en/privacy-policy'}>https://binking.io/en/privacy-policy</Link>
              </li>
              <li>
                1.15. Tariffs - the amount of remuneration for using the API and databases established by the
                Administrator and available for review in the User's Personal Account and (or) on the page{' '}
                <Link href={'https://binking.io/price'}>https://binking.io/en/price</Link>
              </li>
              <li>
                1.16. Account - an aggregate of the User's login and password in the Service. The User's login and
                password are analogous to his handwritten signature.
              </li>
              <li>
                1.17. Button is an element of the Service interface, which clicking means the User's unambiguous consent
                to the Terms directly related to him.
              </li>
              <li>
                1.18. Software - a configuration (software), which represents an aggregate of data and commands
                presented in an objective form, provided to the User in the Service in order to record the operations
                performed in the Service or to provide reports for analysis by the Service. The User is granted the
                right to use the Software under the terms of a simple (non-exclusive) license for the term of validity
                of this Agreement.
              </li>
              <li>1.19. Website - a website located on the Internet at the address: binking.io.</li>
            </ul>
            <h2>2. SUBJECT OF AGREEMENT. GENERAL REQUIREMENTS</h2>
            <ul>
              <li>
                2.1. In accordance with the terms of this Agreement Administrator grants the User the right to use the
                Service under the terms of a simple (non-exclusive) license, and the User undertakes to pay the user fee
                in the cases, specified in the Tariffs.
              </li>
              <li>
                2.2. All conditions specified hereunder apply both to the Service as a whole and to all its components
                separately.
              </li>
              <li>2.3. The territory of the grant of rights - all countries of the world.</li>
              <li>
                2.4. The period for granting the rights is limited by the term of this Agreement and is equal to 100
                (one hundred) years.
              </li>
              <li>
                2.5. The Administrator has the right to change the Agreement and all its annexes at any time (in whole
                or in part) unilaterally, without prior consent with the User. All and any modifications come into force
                on the date of their posting on the Website, unless a longer term for their entry into force is
                additionally determined by the publication of the new version of the Agreement.
              </li>
              <li>
                2.6. The user undertakes to independently monitor the changes to the Agreement and its annexes by the
                periodical reviewing the current version at least once in every five calendar days. The user is solely
                responsible for any consequences arising from failure to familiarize himself with the Agreement, its
                annexes and the amendments to them.
              </li>
              <li>
                2.7. In case if the User does not agree with any provision of the Agreement and (or) annexes thereto, as
                well as (or) the amendments to them, he must immediately stop the using of Service.
              </li>
              <li>2.8. The User Agreement consists of the following:</li>
              <li>
                2.8.1. A User agreement on the use of the Service and the granting of rights to use the database (a
                public offer).
              </li>
              <li>
                2.8.2. The Tariffs posted in the Personal Account and (or) on the page{' '}
                <Link href={'https://binking.io/price'}>https://binking.io/en/price</Link>
              </li>
              <li>
                2.9. Before using the Service (including by acceptance of the terms of the Agreement), the User must
                read the following documents:
              </li>
              <li>2.9.1. The contents of the Agreement and its annexes;</li>
              <li>2.9.2. The description and functionality of the Service;</li>
              <li>2.9.3. The procedure of calculation of the user’ reward.</li>
              <li>
                2.10. To start using the Service and access the Agreement, the User must perform implicative actions:
                accept the Agreement by pressing the buttons with the appropriate functionality on the Website, and o
                successfully complete the Authentication procedure in the manner, prescribed by the Agreement. A
                different acceptance procedure may be provided for by separate Appendices to this Agreement or by
                amendments to it.
              </li>
              <li>
                2.11. The User guarantees that the Account and the information provided by him relate to the User.
              </li>
              <li>
                2.12. The performance by the User of the actions, indicated on the Website as the actions, required for
                the use of functionality of the Service (clicking on the link on the Website / pressing the buttons with
                the appropriate functionality / entering the Key into the proposed screen form, etc.) are recognized as
                the actions performed personally by the User, and represent the actions, performed with the use of a
                Simple Electronic Signature and are considered an unambiguous expression of consent (acceptance) of the
                User to receive the appropriate functionality under the conditions, specified by the Administrator on
                the Website and in the Agreement. The definition of the User who implements a Simple Electronic
                Signature is carried out by the Administrator after successful Authentication. EDS is considered as
                signed by Simple Electronic Signature upon establishment of the fact that the User has performed the
                actions indicated on the Website as the actions, required to use the functionality of the Service
                (clicking on the link on the Website / pressing the buttons with the corresponding functionality /
                entering the Key that matches the Key, sent to the User). Acceptance of the Agreement and acceptance of
                the Tariffs for the paid functionality of the Service is the payment by the User for the right to use
                the Database or crediting of money to a virtual account for using the API.
              </li>
              <li>
                2.13. The right to use the Service is considered granted from the moment when the User accepts the
                Agreement in accordance with its clause 2.12. The parties established that the Act of acceptance and
                transfer of rights will not be drawn up. Proof of granting the User the right to use the Service will be
                the data of the Administrator's Electronic Journal. The parties have established that the acts and the
                invoices for the use of the paid functionality of the Service will not be drawn up. Evidence of granting
                the User the right to use the paid functionality of the Service is the data of the Administrator's
                Electronic Journal.
              </li>
            </ul>
            <h2>3. RIGHTS AND OBLIGATIONS OF THE PARTIES</h2>
            <ul>
              <li>3.1. The administrator undertakes to the following:</li>
              <li>3.1.1. Grant the User the right to use the Service by opening an access to the Service.</li>
              <li>3.1.2. Eliminate the service failures caused by the Administrator within a reasonable time frame.</li>
              <li>
                3.1.3. Provide the technical support to Users regarding the operation of the Service. Technical support
                and interaction with the Service are carried out on working days, during working hours (GMT +03: 00)
                using the contacts indicated on the Website. The answers for the questions of working of the Service are
                sent to the User within 7 (seven) business days from the date of receipt of the request by the
                Administrator.
              </li>
              <li>3.1.4. Provide the ability to use the API by a positive balance on the User's virtual account.</li>
              <li>
                3.1.5. Provide to the User a link for the download and the use of a database. The link for the download
                of the database is available within 1 (one) year from the date of payment.
              </li>
              <li>3.2. The user undertakes to the following:</li>
              <li>3.2.1. Use the Service in accordance with the Agreement.</li>
              <li>
                3.2.2. Recognize the documents and the messages, signed by a Simple Electronic Signature, as an
                equivalent to the documents, signed with a handwritten signature on a paper copy.
              </li>
              <li>3.2.3. Accept and comply with the relevant terms of the Agreement.</li>
              <li>3.2.4. Ensure that funds in the Virtual Account are sufficient to continue using the API.</li>
              <li>3.2.5. Do not provide the opportunity to use the Personal Account to third parties.</li>
              <li>
                3.2.6. Independently make the decisions about the expediency of using the Service, bear responsibility
                for making of such decisions, avoid the claims to the Administrator in connection with non-fulfilment of
                the Agreement for the reasons beyond the Administrator's control.
              </li>
              <li>
                3.2.7. Provide in due time an up-to-date information, required for the Administrator to perform
                activities in accordance with the Agreement.
              </li>
              <li>3.3. Administrator is entitled to the following:</li>
              <li>
                3.3.1. Unilaterally and extrajudicially terminate the Agreement and (or) block the User's access to the
                Service, deny access to the User's Personal Account in the Service, or limit the User's functionality or
                the user’ support. Administrator is not responsible for losses (damage) incurred by the User in
                connection with such actions of the Administrator. Administrator must not inform the User of the reasons
                for termination of Agreement and (or) for blocking (deletion) of his Personal Account.
              </li>
              <li>
                3.3.2. Perform the preventive maintenance on the server and temporarily suspend an access to the
                Service, API and database for such reason.
              </li>
              <li>
                3.3.3. Modify or release updates for the Service, add new features or functionality that increase its
                performance or otherwise improve its characteristics, including in order to meet the requirements of
                current legislation.
              </li>
              <li>3.4. The User is entitled to the following:</li>
              <li>3.4.1. Use the Service in accordance with the terms of the Agreement;</li>
              <li>
                3.4.2. Use the database and API provided by the Administrator upon payment of the fee in accordance with
                the Tariffs;
              </li>
              <li>3.4.3. Use additional functionality of the Service.</li>
              <li>3.5. The user may not use the Service for perpetration of illegal actions, such as:</li>
              <li>
                3.5.1. Provision of incorrectly specified User’ data and other information, requested by the Service;
              </li>
              <li>
                3.5.2. Legalization of money, obtained through criminal activity, and any other operations in violation
                of the current legislation;
              </li>
              <li>3.5.3. Disrupt to the normal operation of the Website;</li>
              <li>3.5.4. Use the Service in a manner, not expressly provided for in the Agreement;</li>
              <li>3.5.5. Distribute, copy and disclose the databases and APIs;</li>
              <li>3.5.6. Use the Service and / or its parts beyond the term of validity of Agreement;</li>
              <li>3.5.7. Authenticate third parties in the Personal Account;</li>
              <li>
                3.5.8. Post materials that contain obscene or abusive words and phrases, pornographic images and texts
                or scenes of a sexual nature; threats, calls for violence and unlawful acts or violation of the law;
                elements of violence, cruelty, racial, ethnic or religious hatred, as well as the links to Nazi
                attributes or symbols; propaganda of criminal activity, guides for perpetration of criminal activities;
                the results of intellectual activity of third parties (without their consent)
              </li>
              <li>3.6. Warranties and representations.</li>
              <li>
                3.6.1. The User assures and guarantees that he understands all provisions of Agreement and accepts its
                terms without reservations in full.
              </li>
              <li>
                3.6.2. The user guarantees that he will never use the Service for purposes, not directly specified in
                the Agreement and on the Website.
              </li>
              <li>
                3.6.3. The user has the right to request a refund for using the service within 10 days from the date of
                payment for services. A return statement is only valid if it has been sent to manager@binking.io from
                the user's email associated with the account via who were paid services.
              </li>
            </ul>
            <h2>4. GUARANTEE OF CONFIDENTIALITY</h2>
            <ul>
              <li>
                4.1. Administrator is the operator of personal data in accordance with the law and bears all rights,
                duties and responsibilities, assigned to the operator of personal data by the current legislation. By
                processing of the User's personal data Administrator is guided by the Federal Law of July 29, 2006. No.
                152-FZ "On Personal Data" and the Privacy Policy of a Service.
              </li>
              <li>
                4.2. The User understands and agrees that as a result of a failure, technical malfunctions, actions of
                third parties, including (but not limited to) a virus or a hacker attack, the User's data posted on the
                Service may become available to third parties. The User undertakes not to make claims to the
                Administrator for compensation for losses (damage) arising in this regard.
              </li>
              <li>4.3. Administrator processes only the data, required for execution of this Agreement.</li>
              <li>
                4.4. The Parties have agreed to keep confidential any information, received by one Party in relation to
                the other in the course of fulfilling its obligations under this Agreement. Confidential information
                does not include publicly available information and information, which distribution had been agreed by
                the User.
              </li>
              <li>
                4.5. The Privacy Policy of the Service is posted by the address: https://binking.io/en/privacy-policy .
              </li>
            </ul>
            <h2>5. LIABILITY. SETTLEMENT OF DISPUTES</h2>
            <ul>
              <li>
                5.1. The Service is provided to the User "as is" in accordance with the generally accepted principle in
                international practice. This means that Administrator is not responsible for problems arising during the
                installation, updating, support and operation of the Service (including compatibility problems with
                other software products (packages, drivers, etc.), inconsistency between the results of using the
                Service and the User's expectations, errors in calculations and etc. The User must understand that he is
                fully responsible for possible negative consequences caused by incompatibility or conflicts of the
                Service with other software products installed on the computer or other device of the User. The Service
                is not intended and cannot be used in information systems operating in hazardous environments, or in
                those serving the life support systems, in which a failure in the operation of the Service can threaten
                the life of people or entail large material losses.
              </li>
              <li>
                5.2. The administrator makes no representations or guarantees that the provided services will be
                uninterrupted or error-free, and does not make any guarantees that the website or host server is free of
                viruses or other forms of malicious code or programs. The User understands and agrees that the
                Administrator does not guarantee and cannot guarantee the accuracy of the database.
              </li>
              <li>
                5.3. Under no circumstances the Administrator, his agents or persons involved in the creation, delivery
                or updating of the Website and / or the database will be liable for any direct, indirect, incidental or
                consequential damages arising from the use of this Website, DB or API, any products or services of the
                Administrator, as well as products and / or services of any other related company or website.
              </li>
              <li>
                5.4. The Administrator's liability in relation to products or services purchased directly from the
                Administrator is expressly limited to replacing of defective goods / correction of services or refunding
                or crediting the Buyer with an amount equal to the purchase price of the goods. Links to other websites
                from the Administrator's website do not imply endorsement of the company, products or services available
                on such websites. Links to other sites are provided by the Administrator solely for the convenience of
                users.
              </li>
              <li>
                5.5. The Administrator is not responsible for the impossibility of using the Service for reasons
                depending on the User or third parties.
              </li>
              <li>
                5.6. The Administrator makes every possible effort to ensure the normal operation of the Service, but is
                not responsible for non-fulfilment or improper fulfilment of obligations under the Agreement, as well as
                for direct and indirect losses of the User, including the lost profits and possible damage arising,
                among other things, as a result of:
              </li>
              <li>5.6.1. Failures in the operation of the Service;</li>
              <li>
                5.6.2. Illegal actions of Internet users aimed at violation of information security or the normal
                functioning of the Website and the Service;
              </li>
              <li>
                5.6.3. Absence (impossibility of establishing, terminating, etc.) of Internet connection between the
                User's server and the Administrator's server;
              </li>
              <li>
                5.6.4. Performance by the state and municipal bodies, as well as other organizations, the measures
                within the framework of law enforcement intelligence;
              </li>
              <li>
                5.6.5. Establishment of state regulation (or regulation by other organizations) of the economic
                activities of commercial organizations on the Internet and / or the establishment by these entities of
                one-time restrictions that complicate or make impossible the execution of this Agreement;
              </li>
              <li>
                5.6.6. Other cases related to actions (inaction) of Internet users and / or other entities aimed at
                worsening the general situation with the use of the Internet and / or computer equipment that existed at
                the time of conclusion of Agreement.
              </li>
              <li>
                5.7. Administrator reserves the right to suspend the operation of the Website and the Service for
                preventive maintenance.
              </li>
              <li>
                5.8. When using the Service, the User undertakes not to violate the law and the interests of third
                parties. The Administrator is not responsible for the actions of the User performed using the Service,
                and is not responsible for the User's failure to fulfil his obligations against third parties.
              </li>
              <li>
                5.9. The user is solely responsible for the safety of his data, required for access to the Personal
                Account and for losses that may arise due to its unauthorized use.
              </li>
              <li>
                5.10. In the event of disputes or disagreements arising from or related to the Agreement between the
                User and the Administrator, the parties will take all measures to resolve them through negotiations. If
                the Parties fail to resolve the disputes and / or disagreements that have arisen between them through
                negotiations, such disputes will be resolved at the location of the Administrator.
              </li>
              <li>
                5.11. Administrator will not be responsible for the non-delivery by telecom operators of messages, keys,
                other information and documents, sent by the Administrator.
              </li>
              <li>
                5.12. The User is responsible for the accuracy of the information provided to the Administrator and its
                updating.
              </li>
              <li>5.13. Administrator will not be responsible for:</li>
              <li>5.13.1. User's violation of the terms of Agreement;</li>
              <li>
                5.13.2. Unlawful actions of third parties, including those related to the use of the User's Personal
                Account and the withdrawal of funds;
              </li>
              <li>
                5.13.3. Any indirect / indirect losses or lost profits of the User and / or third parties, incurred as a
                result of using the Service;
              </li>
              <li>
                5.13.4. Malfunctions, data errors and failures in the operation of the software and hardware complex
                that ensures the functioning of the Service, as well as for the temporary lack of access by the User to
                the Service and the functionality provided in connection with preventive and other works, as well as for
                losses of the User hereunder.
              </li>
              <li>
                5.14. The Parties will not be liable for partial or complete failure to fulfil their obligations under
                the Agreement in case if such failure resulted from the circumstances of force majeure that arose after
                the conclusion of the Agreement as a result of extraordinary circumstances that the Parties could not
                foresee or prevent, including: fires, floods, natural disasters , wars, acts of terrorism or
                modifications in legislation, as a result of which one of the Parties to the Agreement will not be able
                to fulfil its obligations. The Party under the Agreement, affected by the circumstances of force
                majeure, must notify the other Party about the occurrence of such circumstances within 10 (ten) calendar
                days.
              </li>
              <li>
                5.15. The user understands and agrees that 100% database accuracy is the goal, but cannot be guaranteed.
                The administration constantly works to maintain accurate records and up-to-date information, and
                regularly updates the database. The user understands and agrees that 100% accuracy is not possible.
                There may occur a definite time delay between the actual data change and the time when this data is
                reflected in the database.
              </li>
              <li>
                5.16. Any copying, re-use, modification, distribution, implementation or publication by the User of any
                part of the Administrator's website is strictly prohibited unless the Administrator had provided his
                prior written permission hereto.
              </li>
            </ul>
            <h2>6. REMUNERATION OF THE USER. SETTLEMENTS UNDER AGREEMENT</h2>
            <ul>
              <li>
                6.1. The User will pay to Administrator the User remuneration for use of the Service (API) and the
                Database in the amount, established by the Tariffs posted on the Website.
              </li>
              <li>
                6.2. The remuneration will be credited through transfer of funds to the Administrator's Virtual Personal
                Account by the means of Cloud Payments software, posted on the Website.
              </li>
              <li>
                6.3. The Administrator's remuneration is not subject to VAT due to the use of a simplified taxation
                system.
              </li>
              <li>6.4. Any commissions and fees, if charged, are paid by the User.</li>
              <li>
                6.5. The User will pay a one-time User fee for the right to use the database according to the Tariffs.
              </li>
              <li>
                6.6. To use the API, the User will replenish the Virtual Personal Account in the Service, which reflects
                the current financial obligations of the Administrator against the User.
              </li>
              <li>
                6.7. The Administrator's obligations against the User, reflected in the User's virtual personal account
                in the personal account of the Service, change in the course of disposal by the User of funds, kept by
                Administrator by the means of API functionality every time (funds are debited from the Virtual Personal
                Account for each call to the API, not including the test calls "Sandbox = 1").
              </li>
              <li>
                6.8. Upon acceptance of the Agreement, the User links the bank card to the Virtual Personal Account, and
                in case of spending of entire funds on the Virtual Personal Account, it will be automatically
                replenished from the User's card according to the Tariffs. The user can unlink the bank card from the
                Virtual Personal Account and disable the automatic replenishment in the Personal Account.
              </li>
              <li>
                6.9. All other conditions not covered by this Agreement are indicated on the Website and (or) in the
                Personal Account.
              </li>
              <li>
                6.10. Due to the intangible nature of products of Administrator, their electronic distribution and the
                ability of the User to test the products before purchasing them, the refund of money after purchase is
                not provided. The user agrees that any revealed problems or problems with the database must be
                immediately reported to the Administrator's support desk. The refund of money for errors or the missing
                data in the database is not provided. The user understands that the database may contain inaccuracies
                and incorrect information. The payment can be cancelled in case if the purchase had been made by
                mistake. Such requests must be sent by email and will be responded within 3 business days.
              </li>
            </ul>
            <h2>7. DURATION OF THE AGREEMENT. TERMINATION PROCEDURE</h2>
            <ul>
              <li>7.1. This agreement comes into force on the date of acceptance and remains valid within 10 years.</li>
              <li>7.2. This agreement is subject to pre-term cancellation in the following cases:</li>
              <li>7.2.1. by mutual agreement of the Administrator and the User;</li>
              <li>
                7.2.2. Upon initiative of Administrator unilaterally out of court in case of violation by the User of
                the terms of Agreement without refund of any previously paid funds to the latter.
              </li>
              <li>
                7.3. The notice of termination of Agreement will be sent by the Administrator through the Personal
                Account or an e-mail 5 (five) calendar days before the date of termination.
              </li>
              <li>
                7.4. Upon expiration of the period, specified in clause 7.3 of Agreement, an access to the User's
                Personal Account in the Service will be terminated.
              </li>
            </ul>
            <h2>8. OTHER TERMS AND CONDITIONS</h2>
            <ul>
              <li>
                8.1. The User has the right to use all subsequent updates / new versions of the Service on the terms of
                use of original Service.
              </li>
              <li>
                8.2. All issues not regulated by Agreement are subject to resolution in accordance with the legislation
                of the UK.
              </li>
              <li>
                8.3. By the purchase of a database, the User obtains a non-transferable, non-exclusive, revocable
                license for access and use the Website and / or the database in accordance with this Agreement. The User
                may not transfer the Service, API, DB for use to third parties, as well as make any other transactions
                in relation to these objects with third parties. The User has no right to resell or publish the database
                in any form for any purpose, and is not authorized to create or offer the free-of-charge search services
                using the Administrator's database.
              </li>
              <li>
                8.4. The resolution of disputes related to the authentication of EDS of a User, signed by the Simple ES,
                is carried out by the Working Group, which includes the representatives of the Parties. The powers of
                the members of the Working Group will be confirmed by a power of attorney or an executive act of the
                party which they represent. The meeting of the Working Group to consider the User's claim is held at the
                Administrator's address. The Working Group uses the data of the Electronic Journal for analysis and
                determines, including, but not limited to, the following:
              </li>
              <li>8.4.1. The subject of disagreement based on the User's claim.</li>
              <li>8.4.2. An operation related to the subject of the disagreement.</li>
              <li>8.4.3. The fact of Authentication, preceding the sending of a disputed EDS.</li>

              <li>
                8.4.4. The date and time of the introduction of the Key (if there is a corresponding request from the
                Service) to confirm the fact of the formation of a Simple Electronic Signature.
              </li>
              <li>
                8.5. Confirmation of the correctness of the execution of the disputed EDS by the Service will be the
                simultaneous fulfilment of the following conditions:
              </li>
              <li>8.5.1. Establishment of the fact of Authentication, preceding the sending of a disputed EDS.</li>
              <li>
                8.5.2. Establishment of the fact of sending the Key to the specified address of the User (subject to the
                existence of a corresponding request from the Service).
              </li>
              <li>
                8.5.3. Establishment of the fact of Key, which coincides with the Key sent to the User to confirm the
                fact of formation of EDS (subject to the existence of a corresponding request from the Service).
              </li>
              <li>
                8.6. If the Administrator confirms the correctness of the execution of the disputed EDS, the User's
                claims to the Administrator in relation to the consequences of execution of the specified EDS will be
                recognized as unfounded. Failure to meet any of the above conditions means that the correctness of
                execution of the specified EDS has not been confirmed, that is, the verified EDS was confirmed by an
                incorrect Simple ES or the EDS was not correctly executed by Administrator. In this case the claims
                against Administrator in relation to the consequences of the execution of the specified EDS are
                recognized as justified. An act of the Working Group must be drawn up following to results of the
                meeting of the Working Group.
              </li>
              <li>
                8.7. All disputes and disagreements that may arise from the Agreement on issues that have not been
                resolved in the text of the Agreement will be resolved through negotiations. In case if the Parties fail
                to resolve the disputes under Agreement in the course of negotiations, then the disputes with the User -
                a legal entity or an individual entrepreneur are subject to consideration in the Arbitration Court of
                the Samara Region, and the disputes with the participation of Users - individuals who do not possess the
                status of individual entrepreneurs, are subject to consideration in the Leninsky District Court of
                Samara.
              </li>
              <li>
                8.8. The Parties acknowledge that the systems of telecommunication, processing and storage of
                information used by them under this Agreement, are sufficient to ensure reliable and efficient operation
                be receipt, transmitting, processing and storage of information, and the information security system,
                which provides access control and encryption- as sufficient to protect against an unauthorized access,
                and for proof of authorship and the authenticity of the information contained in the received EDS, as
                well as for the analysis of disputable situations.
              </li>
              <li>
                8.9. The user agrees not to use the Administrator's website or its products and / or services for any
                purpose that is illegal or prohibited anywhere. The User also agrees not to use the Website in any way
                that may disable, damage, overload or degrade the operation of any Administrator's website, as well as
                prevent any other party from using the Website and / or the products or services of Administrator. The
                Buyer also agrees not to try to gain unauthorized access to any material of Administrator.
              </li>
              <li>8.10. The place of conclusion of this Agreement is the city of Samara.</li>
              <li>
                8.11. The Parties guarantee that during the term of the Agreement they will take all appropriate
                measures to prevent corrupt behaviour by representatives of both Parties and their counterparties. The
                Parties confirm that by virtue of the provisions of the Federal Law No. 273-FZ from 25.12.2008 "On
                Combating Corruption" they apply and comply with standards and procedures, aimed at ensuring the
                conscientious work of organizations and at prevention of corruption offenses.
              </li>
              <li>
                8.12. The Parties have hereby agreed that all and any correspondence between them in the process of
                execution of this Agreement, the provision of information, the sending of requests, notifications and
                claims (as well as the lawsuits), will be carried out by the means of Service and / or e-mail, specified
                by the User during the registration, and does not require any duplication by sending the paper
                documents.
              </li>
              <li>
                8.13. The Parties acknowledge that the information transmitted using the Service will be considered
                delivered to the second Party from the moment of sending in the Service, or from the moment of sending
                from the Administrator's e-mail: manager@binking.io
              </li>
              <li>
                8.14. A message / documentation are also considered delivered if it was dispatched to the destined
                person (addressee), but the addressee did not read it due to circumstances within his control.
              </li>
            </ul>
            <h2>9. DETAILS OF ADMINISTRATOR</h2>
            <p>
              Organization name: BINBASE LTD
              <br />
              The legal address of organization: 41 Florence Avenue, Hove, England, BN3 7GX
              <br />
              Company number: 14298599
            </p>
          </RichText>
        </MainSegment>
      </PagePadding>
    </Layout>
  )
}

export default TermsOfUse
