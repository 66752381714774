const lang = {
  ru: {
    success: 'Успех',
    validationMessage: 'Заполните все поля подсвеченные красным',
    remove: 'Удалить',
  },
  en: {
    success: 'Success',
    validationMessage: 'Fill out all fields highlighted in red',
    remove: 'Remove',
  }
}

export default lang
