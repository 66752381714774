import React from 'react'
import MainSegment from 'components/MainSegment'
import PageWrapper from 'components/PageWrapper'
import BankPreview from 'components/BankPreview'
import Button from 'components/Button'
import Colorpicker from 'components/Colorpicker'
import Textarea from 'components/Textarea'
import FieldArray from 'components/FieldArray'
import css from './styles.module.scss'
import { canCreateBanks } from 'lib/can'
import { getBankEditRoute } from 'lib/routes'
import Alert from 'components/Alert'
import UploadPngLogo from 'components/UploadPngLogo'
import UploadSvgLogo from 'components/UploadSvgLogo'
import Form, { FormItems, useForm } from 'components/Form'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import * as Yup from 'yup'
import useMutation from 'lib/useMutation'
import textToArray from 'lib/textToArray'
import Input from 'components/Input'
import { countryOptions } from 'lib/toHumanCountry'
import Select from 'components/Select'
import Helmet from 'components/Helmet'

const BankNewPage = ({ history }) => {
  const [createBank] = useMutation('createBank')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      alias: '',
      name: '',
      localName: '',
      logoBigOriginalSvg: '',
      logoBigInvertedSvg: '',
      logoSmallOriginalSvg: '',
      logoSmallInvertedSvg: '',
      logoBigOriginalPng: '',
      logoBigInvertedPng: '',
      logoSmallOriginalPng: '',
      logoSmallInvertedPng: '',
      color: '',
      colors: [],
      country: '',
      site: '',
      phone: '',
      formBackgroundColor: '',
      formBackgroundColors: [],
      formBackgroundLightness: '',
      formTextColor: '',
      formLogoScheme: '',
      formBorderColor: '',
      namesInBins: '',
      bins: '',
    },
    onSubmit: ({ namesInBins, bins, ...restValues }) => {
      return createBank({
        variables: {
          input: { namesInBins: textToArray(namesInBins), bins: textToArray(bins), ...restValues },
        },
      }).then(({ bank }) => {
        history.push(getBankEditRoute(bank.id))
        return false
      })
    },
    validationSchema: Yup.object({
      alias: Yup.string()
        .required('Обязательное поле')
        .matches(/^[a-z-]+$/, 'Только маленькие англиские буквы, вместо пробелов дефисы')
        .test('countryAlias', 'Должен начинаться с кода страны, затем дефис', function(value) {
          const { country } = this.parent
          return value && value.startsWith(country + '-')
        }),
    }),
    showValidationAlert: true,
  })
  return (
    <MainSegment title="Создание банка">
      <Helmet title="Создание банка" />
      <div className={css.bankPreview}>
        <BankPreview bank={formik.values} />
      </div>
      <Form formik={formik}>
        <FormItems>
          <Input
            name="alias"
            label="Алиас"
            hint={
              'Название банка на английском, без пробелов, маленькими буквами, вместо пробелов дефисы, начинается с кода страны, например "ru-sberbank". Нельзя изменить после создания банка.'
            }
            autoComplete="off"
            formik={formik}
          />
          <Input name="name" label="Название банка на английском" formik={formik} />
          <Input name="localName" label="Название банка на местном языке" formik={formik} />
          <Select options={countryOptions} name="country" label="Страна, в которой действует банк" formik={formik} />
          <UploadSvgLogo
            accept=".svg"
            name="logoBigOriginalSvg"
            pngName="logoBigOriginalPng"
            size="big"
            label="Большой оригинальный логотип SVG"
            formik={formik}
          />
          <UploadPngLogo
            accept=".png"
            name="logoBigOriginalPng"
            label="Большой оригинальный логотип PNG"
            formik={formik}
          />
          <UploadSvgLogo
            accept=".svg"
            name="logoBigInvertedSvg"
            pngName="logoBigInvertedPng"
            size="big"
            label="Большой инвертированный логотип SVG"
            formik={formik}
          />
          <UploadPngLogo
            accept=".png"
            name="logoBigInvertedPng"
            label="Большой инвертированный логотип PNG"
            formik={formik}
          />
          <UploadSvgLogo
            accept=".svg"
            name="logoSmallOriginalSvg"
            pngName="logoSmallOriginalPng"
            size="small"
            label="Маленький оригинальный логотип SVG"
            formik={formik}
          />
          <UploadPngLogo
            accept=".png"
            name="logoSmallOriginalPng"
            label="Маленький оригинальный логотип PNG"
            formik={formik}
          />
          <UploadSvgLogo
            accept=".svg"
            name="logoSmallInvertedSvg"
            pngName="logoSmallInvertedPng"
            size="small"
            label="Маленький инвертированный логотип SVG"
            formik={formik}
          />
          <UploadPngLogo
            accept=".png"
            name="logoSmallInvertedPng"
            label="Маленький инвертированный логотип PNG"
            formik={formik}
          />
          <Colorpicker label="Цвет банка" name="color" formik={formik} />
          <FieldArray label="Цвета банка" name="colors" addButtonText="Добавить цвет" formik={formik}>
            {({ name }) => <Colorpicker formik={formik} name={name} />}
          </FieldArray>
          <Colorpicker label="Цвет фона формы" name="formBackgroundColor" formik={formik} />
          <FieldArray
            label="Цвета фона формы для градинета"
            name="formBackgroundColors"
            addButtonText="Добавить цвет"
            formik={formik}
          >
            {({ name }) => <Colorpicker formik={formik} name={name} />}
          </FieldArray>
          <Colorpicker label="Цвет текста на форме" name="formTextColor" formik={formik} />
          <Colorpicker label="Цвет границ полей в форме" name="formBorderColor" formik={formik} />
          <RadioButtons label="Фон формы" name="formBackgroundLightness" formik={formik}>
            <RadioButton name="formBackgroundLightness" value="dark" label="Скорее тёмный" formik={formik} />
            <RadioButton name="formBackgroundLightness" value="light" label="Скорее светлый" formik={formik} />
          </RadioButtons>
          <RadioButtons label="Логотип на форме" name="formLogoScheme" formik={formik}>
            <RadioButton name="formLogoScheme" value="original" label="Оригинальный" formik={formik} />
            <RadioButton name="formLogoScheme" value="inverted" label="Инвертированный" formik={formik} />
          </RadioButtons>
          <Input name="site" label="Ссылка на сайт банка" formik={formik} />
          <Input name="phone" label="Телефон банка" formik={formik} />
          <Textarea name="namesInBins" label="Названия банка в таблице с бинами" formik={formik} />
          <Textarea name="bins" label="Бины банка" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Создать банк</Button>
        </FormItems>
      </Form>
    </MainSegment>
  )
}

export default props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canCreateBanks(me)}>
    {({ me }) => <BankNewPage me={me} {...props} />}
  </PageWrapper>
)
