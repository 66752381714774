import { useEffect } from 'react'
import { setUserData } from 'lib/mixpanel'
import useQuery from "lib/useQuery";

const UserMixpanel = () => {
  const queryResult = useQuery('getMe')
  const { me } = queryResult
  useEffect(() => {
    if (me) {
      setUserData({
        id: me.id,
        referrerCode: me.referrerCode,
        $name: me.displayName,
        $email: me.email,
        emailConfirmed: me.emailConfirmed,
        apiKey: me.apiKey,
        balance: me.balance,
        activatedAt: me.activatedAt,
        apiUrls: me.apiUrls,
        autoReplenishmentEnabled: me.autoReplenishmentEnabled,
        autoReplenishmentSum: me.autoReplenishmentSum,
        autoReplenishmentMonthlyLimit: me.autoReplenishmentMonthlyLimit,
        autoReplenishmentMinBalance: me.autoReplenishmentMinBalance,
        bankCards: me.bankCards && me.bankCards.length,
        apiUsageMonthTotalSpent: me.apiUsageMonth && me.apiUsageMonth.totalSpent,
        apiUsageMonthRequestsCount: me.apiUsageMonth && me.apiUsageMonth.apiRequestsCount,
        apiUsageMonth: me.apiUsageMonth && me.apiUsageMonth.totalSpent,
        archivesAccess: me.archivesAccess && me.archivesAccess.map(archiveAccess => archiveAccess.country),
        country: me.country,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(me)])
  return null
}

export default UserMixpanel
