const lang = {
  ru: {
    docs: 'Документация',
    introduction: 'Введение',
    apiGuid: 'Инструкции к API',
    archiveGuid: 'Инструкции к архиву',
    bankList: 'Перечень банков',
    plugin: 'JS-плагин',
    home: 'Главная',
    guid: 'Гайд',
    price: 'Стоимость',
    login: 'Вход',
    lk: 'Личный кабинет',
    made: 'Сделано',
    nameProg: 'Сергеем Дмитриевым и командой',
    copy: '© 2021 BinKing.',
    privacyPolicy: 'Политика конфиденциальности',
    terms: 'Пользовательское соглашение',
    save: 'Скачать документацию',
    about: 'О компании',
  },
  en: {
    docs: 'Documentation',
    introduction: 'Introduction',
    apiGuid: 'API documentation',
    archiveGuid: 'Archive documentation',
    bankList: 'List of banks',
    plugin: 'JS plugin',
    home: 'Main',
    guid: 'Guide',
    price: 'Price',
    login: 'Login',
    lk: 'Dashboard',
    made: 'Made',
    nameProg: 'by Sergei Dmitriev and team',
    copy: '© 2021 BinKing.',
    privacyPolicy: 'Privacy policy',
    terms: 'Terms of use',
    save: 'Download documentation',
    about: 'About the company',
  }
}

export default lang
