import React from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import yup from 'lib/validation'
import useMutation from 'lib/useMutation'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ChangeApiKey = ({ me }) => {
  const i18n = useLang(lang)
  const [changeMyApiKey] = useMutation('changeMyApiKey')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      yes: '',
    },
    onSubmit: () => {
      return changeMyApiKey()
    },
    validationSchema: yup.object({
      yes: yup.string()
        .required(i18n('word'))
        .oneOf(['YES'], i18n('errorWord')),
    }),
    withPristine: true,
    resetOnSuccess: true,
    forgetSuccessDelay: 0,
    successMessage: ({ apiKey }) =>
      `${i18n('apiKey')} ${apiKey} ${i18n('replace')}`,
  })
  return (
    <Form formik={formik}>
      <FormItems>
        <Input label={i18n('confirmation')} name="yes" hint={i18n('enter')} formik={formik} />
        <Alert {...alertProps} />
        <Button
          {...buttonProps}
          color='red'
          disabled={formik.values.yes !== 'YES' || buttonProps.disabled}
        >
          {i18n('newKey')}
        </Button>
      </FormItems>
    </Form>
  )
}

export default ChangeApiKey
