import { useQuery as useQueryApollo } from '@apollo/react-hooks'
import prettifyError from 'lib/prettifyError'
import batchGet from 'lib/batchGet'
import * as gqls from 'lib/gqls'
import * as Sentry from '@sentry/react'

const useQuery = (queryOrName, options = {}) => {
  let query
  let optionsHere
  if (typeof queryOrName === 'string') {
    query = gqls[`${queryOrName}Query`]
    if (!query)
      throw new Error(`useQuery: ${queryOrName} not implenented in gqls`)
    const optionsGqls = gqls[`${queryOrName}Options`] || {}
    optionsHere = { ...optionsGqls, ...options }
  } else {
    query = queryOrName
    optionsHere = options
  }
  const { errorCodes = {}, parser, ...queryOptions } = optionsHere
  const result = useQueryApollo(query, queryOptions)
  if (result.error) {
    prettifyError(result.error, errorCodes)
    if (result.error.isUnknown) {
      console.error(result.error)
      if (process.env.REACT_APP_SENTRY_ON === 'true') {
        Sentry.captureException(result.error)
      }
    }
  }
  if (parser) {
    Object.assign(result, batchGet(result.data, parser))
  }
  Object.assign(result, {
    refetching: result.networkStatus === 4,
    loading: result.networkStatus === 1 || result.networkStatus === 2,
    fetchingMore: result.networkStatus === 3,
  })
  return result
}

export default useQuery
