import React from 'react'
import PagePadding from 'components/PagePadding'
import MainSegment from 'components/MainSegment'
import Layout from 'components/Layout'
import RichText from 'components/RichText'
import Helmet from 'components/Helmet'
import Link from 'components/Link'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import css from './styles.module.scss'
import sd from 'assets/sd.png'
import am from 'assets/am.png'
import ps from 'assets/ps.png'

const CompanyPage = () => {
  const i18n = useLang(lang)
  return (
    <Layout perple={true} minimal={true}>
      <Helmet title={i18n('title')} />
      <PagePadding>
        <MainSegment title={i18n('title')}>
          <RichText>
            <p>{i18n('binKing')}</p>
            <h2>{i18n('team.title')}</h2>
            <div className={css.team}>
              <div className={css.item}>
                <div className={css.photo}>
                  <img src={sd} alt="" width="200" height="200" />
                </div>
                <div className={css.name}>
                  <Link style={{fontWeight: 'bold'}} href="https://10.com.ru" target="_blank">
                    {i18n('team.members.sd.name')}
                  </Link>
                </div>
                <div className={css.desc}>{i18n('team.members.sd.desc')}</div>
              </div>
              <div className={css.item}>
                <div className={css.photo}>
                  <img src={am} alt="" width="200" height="200" />
                </div>
                <div className={css.name}>{i18n('team.members.am.name')}</div>
                <div className={css.desc}>{i18n('team.members.am.desc')}</div>
              </div>
              <div className={css.item}>
                <div className={css.photo}>
                  <img src={ps} alt="" width="200" height="200" />
                </div>
                <div className={css.name}>{i18n('team.members.ps.name')}</div>
                <div className={css.desc}>{i18n('team.members.ps.desc')}</div>
              </div>
            </div>
            <h2>{i18n('contacts')}</h2>
            <p>
              E-mail: <Link href="mailto:manager@binking.io">manager@binking.io</Link>
            </p>
            <h2>{i18n('details')}</h2>
            <p>{i18n('text')}</p>
          </RichText>
        </MainSegment>
      </PagePadding>
    </Layout>
  )
}

export default CompanyPage
