import React from 'react'
import MainSegment from 'components/MainSegment'
import useQuery from 'lib/useQuery'
import PageWrapper from 'components/PageWrapper'
// import css from './styles.module.scss'
import { canViewUsers } from 'lib/can'
import RichText from 'components/RichText'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const UserPage = ({ user }) => {
  const i18n = useLang(lang)
  return (
    <MainSegment title={i18n('title')}>
      <Helmet title={i18n('title')} />
      <RichText>
        <SyntaxHighlighter style={github} language="javascript">
          {JSON.stringify(user, null, 2)}
        </SyntaxHighlighter>
      </RichText>
    </MainSegment>
  )
}

const GetUserWrapper = props => {
  const userId = props.match.params.userId
  const queryResult = useQuery('getUser', {
    variables: {
      input: {
        userId,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ user }) => !!user}>
      {({ user }) => <UserPage user={user} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canViewUsers(me)}>
    {({ me }) => <GetUserWrapper me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
