import React from 'react'
import MainSegment from 'components/MainSegment'
import useQuery from 'lib/useQuery'
import PageWrapper from 'components/PageWrapper'
import css from './styles.module.scss'
import { canViewStat } from 'lib/can'
import Segment from 'components/Segment'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const UnknownRequestedBinsPage = () => {
  const i18n = useLang(lang)
  const queryResult = useQuery('getUnknownRequestedBins')
  const { requestedBins } = queryResult
  return (
    <PageWrapper queryResult={queryResult}>
      {() => (
        <MainSegment title={i18n('title')}>
          <Helmet title={i18n('title')} />
          <Segment>
            <table className={css.requestedBins}>
              <thead>
                <tr>
                  <th>{i18n('card')}</th>
                  <th>{i18n('bank')}</th>
                  <th>{i18n('bin')}</th>
                </tr>
              </thead>
              <tbody>
                {requestedBins.map((requestedBin, i) => {
                  return (
                    <tr key={i} className={css.requestedBin}>
                      <td>{requestedBin.iinStart}</td>
                      <td>{JSON.stringify(requestedBin.bin)}</td>
                      <td>{JSON.stringify(requestedBin.bank)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Segment>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canViewStat(me)}>
    {({ me }) => <UnknownRequestedBinsPage me={me} {...props} />}
  </PageWrapper>
)
