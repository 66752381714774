import React, { useEffect } from 'react'
import PagePadding from 'components/PagePadding'
import MainSegment from 'components/MainSegment'
import Layout from 'components/Layout'
import RichText from 'components/RichText'
import Helmet from 'components/Helmet'
import Link from 'components/Link'
import _ from 'lodash'
import Input from 'components/Input'
import Select from 'components/Select'
import { countryOptions } from 'lib/toHumanCountry'
import Colorpicker from 'components/Colorpicker'
import FieldArray from 'components/FieldArray'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import Textarea from 'components/Textarea'
import { FormItems, useForm } from 'components/Form'
import BankPreview from 'components/BankPreview'
import css from '../BankNewPage/styles.module.scss'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const BankJSONPage = () => {
  const i18n = useLang(lang)
  const { formik } = useForm({
    initialValues: {
      name: '',
      localName: '',
      logoBigOriginalSvg: '',
      logoBigInvertedSvg: '',
      logoSmallOriginalSvg: '',
      logoSmallInvertedSvg: '',
      color: '',
      colors: [],
      country: '',
      site: '',
      phone: '',
      formBackgroundColor: '',
      formBackgroundColors: [],
      formBackgroundLightness: '',
      formTextColor: '',
      formLogoScheme: '',
      formBorderColor: '',
      bins: '',
      json: '',
    },
  })
  const values = _.fromPairs(
    _.toPairs(formik.values)
      .filter(([a, b]) => {
        return a + '' !== 'json' && a + '' !== '[[Prototype]]'
      })
      .map(([a, b]) => {
        return a === 'bins'
          ? [
              a,
              b
                .split(/\r?\n/g)
                .map(val => val.trim())
                .filter(val => !!val),
            ]
          : [a, b]
      })
  )
  useEffect(() => {
    formik.setFieldValue('json', JSON.stringify(values, null, 2))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values)])
  return (
    <Layout perple={true} minimal={true}>
      <div className={css.bankPreview} style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 10 }}>
        <BankPreview bank={formik.values} />
      </div>
      <Helmet title={i18n('formationBank')} />
      <PagePadding>
        <MainSegment title={i18n('formationBank')}>
          <FormItems>
            <Input name="name" label={i18n('nameEn')} formik={formik} />
            <Input name="localName" label={i18n('nameRu')} formik={formik} />
            <Select options={countryOptions} name="country" label={i18n('countryBankOperates')} formik={formik} />
            <Input name="logoBigOriginalSvg" label={i18n('linkSvgBigOriginal')} formik={formik} />
            <Input name="logoBigInvertedSvg" label={i18n('linkSvgBigInvert')} formik={formik} />
            <Input name="logoSmallOriginalSvg" label={i18n('linkSvgSmollOriginal')} formik={formik} />
            <Input
              name="logoSmallInvertedSvg"
              label={i18n('linkSvgSmollInvert')}
              formik={formik}
            />
            <Colorpicker label={i18n('color')} name="color" formik={formik} />
            <FieldArray label={i18n('color')} name="colors" addButtonText={i18n('addColor')} removeButtonText={i18n('_.delete')} formik={formik}>
              {({ name }) => <Colorpicker formik={formik} name={name} />}
            </FieldArray>
            <Colorpicker label={i18n('backgroundColor')} name="formBackgroundColor" formik={formik} />
            <FieldArray
              label={i18n('backgroundGradient')}
              name="formBackgroundColors"
              addButtonText={i18n('addColor')}
              removeButtonText={i18n('delete')}
              formik={formik}
            >
              {({ name }) => <Colorpicker formik={formik} name={name} />}
            </FieldArray>
            <Colorpicker label={i18n('fontColor')} name="formTextColor" formik={formik} />
            <Colorpicker label={i18n('borderColor')} name="formBorderColor" formik={formik} />
            <RadioButtons label={i18n('backgroundForm')} name="formBackgroundLightness" formik={formik}>
              <RadioButton name="formBackgroundLightness" value="dark" label={i18n('dark')} formik={formik} />
              <RadioButton name="formBackgroundLightness" value="light" label={i18n('lite')} formik={formik} />
            </RadioButtons>
            <RadioButtons label={i18n('logoForm')} name="formLogoScheme" formik={formik}>
              <RadioButton name="formLogoScheme" value="original" label={i18n('original')} formik={formik} />
              <RadioButton name="formLogoScheme" value="inverted" label={i18n('inverted')} formik={formik} />
            </RadioButtons>
            <Input name="site" label={i18n('linkBank')} formik={formik} />
            <Input name="phone" label={i18n('phone')} formik={formik} />
            <Textarea name="bins" label={i18n('bins')} hint={i18n('sixNumber')} formik={formik} />
            <Textarea name="json" label={i18n('jsonFile')} formik={formik} />
            <RichText>
              <p>
                {i18n('text')} <Link href="mailto:manager@binking.io">manager@binking.io</Link> {i18n('textLast')}
              </p>
            </RichText>
          </FormItems>
        </MainSegment>
      </PagePadding>
    </Layout>
  )
}

export default BankJSONPage
