import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import cn from 'classnames'
import css from './styles.module.scss'

const Link = ({
  to,
  href,
  children,
  className,
  currentClassName,
  type,
  color = 'blue',
  nowrap,
  loading,
  ...restProps
}) => {
  const location = useLocation()
  const isCurrent = location.pathname === to
  const classNameHere = cn({
    [css.link]: true,
    [css[`type-${type}`]]: !!type,
    [css[`color-${color}`]]: !!color,
    [className]: !!className,
    [css.nowrap]: !!nowrap,
    [css.loading]: !!loading,
    [currentClassName]: !!currentClassName && isCurrent,
  })
  if (to) {
    return (
      <RouterLink
        className={classNameHere}
        to={to}
        {...restProps}
      >
        {children}
      </RouterLink>
    )
  }
  return (
    <a href={href || '#'} className={classNameHere} {...restProps}>
      {children}
    </a>
  )
}

export default Link
