import moment from 'moment'
import 'moment/locale/ru'

const toHumanDate = (date, format, lang = 'en') => {
  moment.locale(lang)
  return format ? moment(date).format(format) : moment(date).calendar()
}

export default toHumanDate

export { moment }
