import React, { useEffect } from 'react'
import MainSegment from 'components/MainSegment'
import PagePadding from 'components/PagePadding'
import Alert from 'components/Alert'
import Loader from 'components/Loader'
import useMutation from 'lib/useMutation'
import Helmet from 'components/Helmet'
import Button from 'components/Button'
import { getHomeRoute } from 'lib/routes'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ConfirmEmailPage = ({ match }) => {
  const i18n = useLang(lang)
  const confirmEmailToken = match.params.confirmEmailToken
  const [confirmEmail, { called, loading, error }] = useMutation('confirmEmail', {
    errorCodes: {
      ERR_INVALID_CONFIRM_EMAIL_TOKEN: i18n('token'),
    },
  })
  useEffect(() => {
    confirmEmail({ variables: { input: { confirmEmailToken } } }).catch(e => {})
  }, [confirmEmailToken, confirmEmail])
  if (loading || !called) return <Loader type="page" />
  return (
    <PagePadding>
      <MainSegment title={i18n('title')}>
        <Helmet title={i18n('title')} />
        {error ? (
          <Alert type="danger" message={error.humanMessage} />
        ) : (
          <Alert type="success">{i18n('success')}</Alert>
        )}
        <Button to={getHomeRoute()}>{i18n('home')}</Button>
      </MainSegment>
    </PagePadding>
  )
}

export default ConfirmEmailPage
