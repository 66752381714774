import React from 'react'
import MainSegment from 'components/MainSegment'
import Helmet from 'components/Helmet'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import AddBankCard from 'components/AddBankCard'
import ChangeApiUrls from 'components/ChangeApiUrls'
import ChangeApiKey from 'components/ChangeApiKey'
import ApiUsageMonths from 'components/ApiUsageMonths'
import ApiUsageDays from 'components/ApiUsageDays'
import ApiPaymentsSettings from 'components/ApiPaymentsSettings'
import Alert from 'components/Alert'
import css from './styles.module.scss'
import useQuery from 'lib/useQuery'
import toMoney from 'lib/toMoney'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import PaymentMethod from '../../components/PaymentMethod'
import ReplenishBalancePP from '../../components/ReplenishBalancePP'
import { getRequestsCountByMoney } from 'lib/getRequestsCountByMoney'
import { Deprecated } from 'components/Deprecated'
// import cn from 'classnames'

const ApiSettingsPage = () => {
  const queryResultMe = useQuery('getMe')
  const queryResultUsdPrice = useQuery('getUsdPrice')
  const { me } = queryResultMe
  const { usdPrice } = queryResultUsdPrice
  const i18n = useLang(lang)
  return (
    <PageWrapper
      authorizedOnly
      queryResult={{ ...queryResultMe, loading: queryResultMe.loading || queryResultUsdPrice.loading }}
      meIncluded
    >
      {() => (
        <MainSegment title="API">
          <Helmet title={i18n('apiOption')} />
          {!me.activatedAt ? (
            <>
              <Deprecated />
              {/* {me.lang === 'ru' && <PaymentMethod me={me} />}
              <Segment title={i18n('active')}>
                <div className={css.activation}>
                  {me.paymentMethod === 'cp' ? (
                    <p>
                      {i18n(
                        'getKey',
                        toMoney({ sumUsd: (me && me.giftSum) || 4, usdPrice }),
                        getRequestsCountByMoney((me && me.giftSum) || 4)
                      )}
                    </p>
                  ) : (
                    <p>
                      {i18n(
                        'getKey1',
                        toMoney({ sumUsd: (me && me.giftSum) || 4, usdPrice }),
                        getRequestsCountByMoney((me && me.giftSum) || 4)
                      )}
                    </p>
                  )}
                  <div className={css.form}>
                    {me.paymentMethod === 'cp' ? <AddBankCard /> : <ReplenishBalancePP me={me} />}
                  </div>
                </div>
              </Segment> */}
            </>
          ) : (
            <>
              <Segment>
                <div className={css.general}>
                  <div className={css.textApi}>
                    <p>
                      {i18n('apiKey')} <span>{me.apiKey}</span>
                    </p>
                    <p>
                      {i18n('balance')}{' '}
                      <span>
                        {toMoney({ sumUsd: me.balance, usdPrice })} (
                        {getRequestsCountByMoney(me.balance, me.apiUsageMonth ? me.apiUsageMonth.totalSpent : 0)}{' '}
                        {i18n('requests')})
                      </span>
                    </p>
                    <p>
                      {i18n('allQueryMonth')} <span>{me.apiUsageMonth ? me.apiUsageMonth.apiRequestsCount : 0}</span>
                    </p>
                    <p>
                      {i18n('allPriceMonth')}{' '}
                      <span>{toMoney({ sumUsd: me.apiUsageMonth ? me.apiUsageMonth.totalSpent : 0, usdPrice })}</span>
                    </p>
                  </div>
                  {/* {me.balance <= 0 && me.activatedAt && (
                    <div className={css.form}>
                      <Alert className={css.alert} type="danger" message={i18n('unavailable')} />
                    </div>
                  )} */}
                </div>
              </Segment>
              {/* {me.paymentMethod === 'cp' && (
                <Segment title={i18n('autoBalance')}>
                  {!me.bankCards.length ? (
                    <div className={css.autoReplenishment}>
                      <p>{i18n('autoChangeText')}</p>
                      <div className={css.form}>
                        <AddBankCard temp />
                      </div>
                    </div>
                  ) : (
                    <div className={css.autoReplenishment}>
                      <p>{i18n('autoText')}</p>
                      <div className={css.form}>
                        <ApiPaymentsSettings me={me} />
                      </div>
                    </div>
                  )}
                </Segment>
              )} */}
              <Segment title={i18n('allUrl')}>
                <div className={css.apiUrls}>
                  <p>{i18n('urlText')}</p>
                  <div className={css.form}>
                    <ChangeApiUrls me={me} />
                  </div>
                </div>
              </Segment>
              <Segment title={i18n('changeApiKey')}>
                <div className={css.changeApiKey}>
                  <p>{i18n('yesText')}</p>
                  <div className={css.form}>
                    <ChangeApiKey />
                  </div>
                </div>
              </Segment>
              <Segment title={i18n('statsMonths')}>
                <ApiUsageMonths />
              </Segment>
              <Segment title={i18n('statsDay')}>
                <ApiUsageDays />
              </Segment>
            </>
          )}
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default ApiSettingsPage
