import React, { useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import css from './styles.module.scss'
import { ReactComponent as CrossIcon } from 'assets/cross.svg'
ReactModal.defaultStyles = {}
ReactModal.setAppElement('#root')

const Modal = ({
  onRequestClose,
  children,
  width = 500,
  isOpen,
  ...restProps
}) => {
  const wasOpenRef = useRef(false)
  useEffect(() => {
    wasOpenRef.current = isOpen
  }, [isOpen])
  const wasOpen = wasOpenRef.current
  if (!wasOpen && isOpen) {
    const scrollY = document.documentElement.scrollTop
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}px`
  }
  if (wasOpen && !isOpen) {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
  useEffect(() => {
    return () => {
      document.body.style.position = ''
      document.body.style.top = ''
    }
  }, [])
  return (
    <ReactModal
      {...restProps}
      isOpen={isOpen}
      style={{ content: { width } }}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
    >
      <button className={css.cross} onClick={onRequestClose}>
        <CrossIcon />
      </button>
      <div className={css.inner}>{children}</div>
    </ReactModal>
  )
}

export default Modal
