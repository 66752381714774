const lang = {
  ru: {
    title: 'Перечень банков',
  },
  en: {
    title: 'List of banks',
  }
}

export default lang
