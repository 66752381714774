import React from 'react'

const lang = {
  ru: {
    all: 'Все страны',
    other: 'Другая страна',
    ru: 'Россия',
    ca: 'Канада',
    am: 'Армения',
    us: 'США',
    gb: 'Англия',
    ua: 'Украина',
    by: 'Беларусь',
    de: 'Германия',
    it: 'Италия',
    au: 'Австралия',
    kz: 'Казахстан',
    se: 'Швеция',
    fr: 'Франция',
    es: 'Испания',
    in: 'Индия',
    cn: 'Китай',
    nl: 'Нидерланды',
    at: 'Австрия',
    jp: 'Япония',
    mx: 'Мексика',
    il: 'Израиль',
    tr: 'Турция',
    br: 'Бразилия',
    pt: 'Португалия',
    ae: 'ОАЭ',
  },
  en: {
    all: 'All countries',
    other: 'Other country',
    gb: 'United Kingdom',
    us: <>United States of&nbsp;America</>,
  },
}

const toHumanCountry = (countryCode, langCode = 'en') => {
  const langHere = {
    ru: {
      ...countryCodes,
      ...lang.ru,
    },
    en: {
      ...countryCodes,
      ...lang.en,
    },
  }
  return langHere[langCode][countryCode] || countryCode
}

export const countryCodes = {
  af: 'Afghanistan',
  ax: 'Åland Islands',
  al: 'Albania',
  dz: 'Algeria',
  as: 'American Samoa',
  ad: 'Andorra',
  ao: 'Angola',
  ai: 'Anguilla',
  aq: 'Antarctica',
  ag: 'Antigua and Barbuda',
  ar: 'Argentina',
  am: 'Armenia',
  aw: 'Aruba',
  au: 'Australia',
  at: 'Austria',
  az: 'Azerbaijan',
  bs: 'Bahamas',
  bh: 'Bahrain',
  bd: 'Bangladesh',
  bb: 'Barbados',
  by: 'Belarus',
  be: 'Belgium',
  bz: 'Belize',
  bj: 'Benin',
  bm: 'Bermuda',
  bt: 'Bhutan',
  bo: 'Bolivia (Plurinational State of)',
  bq: 'Bonaire, Sint Eustatius and Saba',
  ba: 'Bosnia and Herzegovina',
  bw: 'Botswana',
  bv: 'Bouvet Island',
  br: 'Brazil',
  io: 'British Indian Ocean Territory',
  bn: 'Brunei Darussalam',
  bg: 'Bulgaria',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  cv: 'Cabo Verde',
  kh: 'Cambodia',
  cm: 'Cameroon',
  ca: 'Canada',
  ky: 'Cayman Islands',
  cf: 'Central African Republic',
  td: 'Chad',
  cl: 'Chile',
  cn: 'China',
  cx: 'Christmas Island',
  cc: 'Cocos (Keeling) Islands',
  co: 'Colombia',
  km: 'Comoros',
  cg: 'Congo',
  cd: 'Congo, Democratic Republic of the',
  ck: 'Cook Islands',
  cr: 'Costa Rica',
  ci: "Côte d'Ivoire",
  hr: 'Croatia',
  cu: 'Cuba',
  cw: 'Curaçao',
  cy: 'Cyprus',
  cz: 'Czechia',
  dk: 'Denmark',
  dj: 'Djibouti',
  dm: 'Dominica',
  do: 'Dominican Republic',
  ec: 'Ecuador',
  eg: 'Egypt',
  sv: 'El Salvador',
  gq: 'Equatorial Guinea',
  er: 'Eritrea',
  ee: 'Estonia',
  sz: 'Eswatini',
  et: 'Ethiopia',
  fk: 'Falkland Islands (Malvinas)',
  fo: 'Faroe Islands',
  fj: 'Fiji',
  fi: 'Finland',
  fr: 'France',
  gf: 'French Guiana',
  pf: 'French Polynesia',
  tf: 'French Southern Territories',
  ga: 'Gabon',
  gm: 'Gambia',
  ge: 'Georgia',
  de: 'Germany',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gr: 'Greece',
  gl: 'Greenland',
  gd: 'Grenada',
  gp: 'Guadeloupe',
  gu: 'Guam',
  gt: 'Guatemala',
  gg: 'Guernsey',
  gn: 'Guinea',
  gw: 'Guinea-Bissau',
  gy: 'Guyana',
  ht: 'Haiti',
  hm: 'Heard Island and McDonald Islands',
  va: 'Holy See',
  hn: 'Honduras',
  hk: 'Hong Kong',
  hu: 'Hungary',
  is: 'Iceland',
  in: 'India',
  id: 'Indonesia',
  ir: 'Iran (Islamic Republic of)',
  iq: 'Iraq',
  ie: 'Ireland',
  im: 'Isle of Man',
  il: 'Israel',
  it: 'Italy',
  jm: 'Jamaica',
  jp: 'Japan',
  je: 'Jersey',
  jo: 'Jordan',
  kz: 'Kazakhstan',
  ke: 'Kenya',
  ki: 'Kiribati',
  kp: "Korea (Democratic People's Republic of)",
  kr: 'Korea, Republic of',
  kw: 'Kuwait',
  kg: 'Kyrgyzstan',
  la: "Lao People's Democratic Republic",
  lv: 'Latvia',
  lb: 'Lebanon',
  ls: 'Lesotho',
  lr: 'Liberia',
  ly: 'Libya',
  li: 'Liechtenstein',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  mo: 'Macao',
  mg: 'Madagascar',
  mw: 'Malawi',
  my: 'Malaysia',
  mv: 'Maldives',
  ml: 'Mali',
  mt: 'Malta',
  mh: 'Marshall Islands',
  mq: 'Martinique',
  mr: 'Mauritania',
  mu: 'Mauritius',
  yt: 'Mayotte',
  mx: 'Mexico',
  fm: 'Micronesia (Federated States of)',
  md: 'Moldova, Republic of',
  mc: 'Monaco',
  mn: 'Mongolia',
  me: 'Montenegro',
  ms: 'Montserrat',
  ma: 'Morocco',
  mz: 'Mozambique',
  mm: 'Myanmar',
  na: 'Namibia',
  nr: 'Nauru',
  np: 'Nepal',
  nl: 'Netherlands',
  nc: 'New Caledonia',
  nz: 'New Zealand',
  ni: 'Nicaragua',
  ne: 'Niger',
  ng: 'Nigeria',
  nu: 'Niue',
  nf: 'Norfolk Island',
  mk: 'North Macedonia',
  mp: 'Northern Mariana Islands',
  no: 'Norway',
  om: 'Oman',
  pk: 'Pakistan',
  pw: 'Palau',
  ps: 'Palestine, State of',
  pa: 'Panama',
  pg: 'Papua New Guinea',
  py: 'Paraguay',
  pe: 'Peru',
  ph: 'Philippines',
  pn: 'Pitcairn',
  pl: 'Poland',
  pt: 'Portugal',
  pr: 'Puerto Rico',
  qa: 'Qatar',
  re: 'Réunion',
  ro: 'Romania',
  ru: 'Russian Federation',
  rw: 'Rwanda',
  bl: 'Saint Barthélemy',
  sh: 'Saint Helena, Ascension and Tristan da Cunha',
  kn: 'Saint Kitts and Nevis',
  lc: 'Saint Lucia',
  mf: 'Saint Martin (French part)',
  pm: 'Saint Pierre and Miquelon',
  vc: 'Saint Vincent and the Grenadines',
  ws: 'Samoa',
  sm: 'San Marino',
  st: 'Sao Tome and Principe',
  sa: 'Saudi Arabia',
  sn: 'Senegal',
  rs: 'Serbia',
  sc: 'Seychelles',
  sl: 'Sierra Leone',
  sg: 'Singapore',
  sx: 'Sint Maarten (Dutch part)',
  sk: 'Slovakia',
  si: 'Slovenia',
  sb: 'Solomon Islands',
  so: 'Somalia',
  za: 'South Africa',
  gs: 'South Georgia and the South Sandwich Islands',
  ss: 'South Sudan',
  es: 'Spain',
  lk: 'Sri Lanka',
  sd: 'Sudan',
  sr: 'Suriname',
  sj: 'Svalbard and Jan Mayen',
  se: 'Sweden',
  ch: 'Switzerland',
  sy: 'Syrian Arab Republic',
  tw: 'Taiwan, Province of China',
  tj: 'Tajikistan',
  tz: 'Tanzania, United Republic of',
  th: 'Thailand',
  tl: 'Timor-Leste',
  tg: 'Togo',
  tk: 'Tokelau',
  to: 'Tonga',
  tt: 'Trinidad and Tobago',
  tn: 'Tunisia',
  tr: 'Turkey',
  tm: 'Turkmenistan',
  tc: 'Turks and Caicos Islands',
  tv: 'Tuvalu',
  ug: 'Uganda',
  ua: 'Ukraine',
  ae: 'United Arab Emirates',
  gb: 'United Kingdom',
  us: 'United States of America',
  um: 'United States Minor Outlying Islands',
  uy: 'Uruguay',
  uz: 'Uzbekistan',
  vu: 'Vanuatu',
  ve: 'Venezuela (Bolivarian Republic of)',
  vn: 'Viet Nam',
  vg: 'Virgin Islands (British)',
  vi: 'Virgin Islands (U.S.)',
  wf: 'Wallis and Futuna',
  eh: 'Western Sahara',
  ye: 'Yemen',
  zm: 'Zambia',
  zw: 'Zimbabwe',
}

export const countryOptions = [
  { value: 'af', label: 'Afghanistan' },
  { value: 'ax', label: 'Åland Islands' },
  { value: 'al', label: 'Albania' },
  { value: 'dz', label: 'Algeria' },
  { value: 'as', label: 'American Samoa' },
  { value: 'ad', label: 'Andorra' },
  { value: 'ao', label: 'Angola' },
  { value: 'ai', label: 'Anguilla' },
  { value: 'aq', label: 'Antarctica' },
  { value: 'ag', label: 'Antigua and Barbuda' },
  { value: 'ar', label: 'Argentina' },
  { value: 'am', label: 'Armenia' },
  { value: 'aw', label: 'Aruba' },
  { value: 'au', label: 'Australia' },
  { value: 'at', label: 'Austria' },
  { value: 'az', label: 'Azerbaijan' },
  { value: 'bs', label: 'Bahamas' },
  { value: 'bh', label: 'Bahrain' },
  { value: 'bd', label: 'Bangladesh' },
  { value: 'bb', label: 'Barbados' },
  { value: 'by', label: 'Belarus' },
  { value: 'be', label: 'Belgium' },
  { value: 'bz', label: 'Belize' },
  { value: 'bj', label: 'Benin' },
  { value: 'bm', label: 'Bermuda' },
  { value: 'bt', label: 'Bhutan' },
  { value: 'bo', label: 'Bolivia (Plurinational State of)' },
  { value: 'bq', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'ba', label: 'Bosnia and Herzegovina' },
  { value: 'bw', label: 'Botswana' },
  { value: 'bv', label: 'Bouvet Island' },
  { value: 'br', label: 'Brazil' },
  { value: 'io', label: 'British Indian Ocean Territory' },
  { value: 'bn', label: 'Brunei Darussalam' },
  { value: 'bg', label: 'Bulgaria' },
  { value: 'bf', label: 'Burkina Faso' },
  { value: 'bi', label: 'Burundi' },
  { value: 'cv', label: 'Cabo Verde' },
  { value: 'kh', label: 'Cambodia' },
  { value: 'cm', label: 'Cameroon' },
  { value: 'ca', label: 'Canada' },
  { value: 'ky', label: 'Cayman Islands' },
  { value: 'cf', label: 'Central African Republic' },
  { value: 'td', label: 'Chad' },
  { value: 'cl', label: 'Chile' },
  { value: 'cn', label: 'China' },
  { value: 'cx', label: 'Christmas Island' },
  { value: 'cc', label: 'Cocos (Keeling) Islands' },
  { value: 'co', label: 'Colombia' },
  { value: 'km', label: 'Comoros' },
  { value: 'cg', label: 'Congo' },
  { value: 'cd', label: 'Congo, Democratic Republic of the' },
  { value: 'ck', label: 'Cook Islands' },
  { value: 'cr', label: 'Costa Rica' },
  { value: 'ci', label: "Côte d'Ivoire" },
  { value: 'hr', label: 'Croatia' },
  { value: 'cu', label: 'Cuba' },
  { value: 'cw', label: 'Curaçao' },
  { value: 'cy', label: 'Cyprus' },
  { value: 'cz', label: 'Czechia' },
  { value: 'dk', label: 'Denmark' },
  { value: 'dj', label: 'Djibouti' },
  { value: 'dm', label: 'Dominica' },
  { value: 'do', label: 'Dominican Republic' },
  { value: 'ec', label: 'Ecuador' },
  { value: 'eg', label: 'Egypt' },
  { value: 'sv', label: 'El Salvador' },
  { value: 'gq', label: 'Equatorial Guinea' },
  { value: 'er', label: 'Eritrea' },
  { value: 'ee', label: 'Estonia' },
  { value: 'sz', label: 'Eswatini' },
  { value: 'et', label: 'Ethiopia' },
  { value: 'fk', label: 'Falkland Islands (Malvinas)' },
  { value: 'fo', label: 'Faroe Islands' },
  { value: 'fj', label: 'Fiji' },
  { value: 'fi', label: 'Finland' },
  { value: 'fr', label: 'France' },
  { value: 'gf', label: 'French Guiana' },
  { value: 'pf', label: 'French Polynesia' },
  { value: 'tf', label: 'French Southern Territories' },
  { value: 'ga', label: 'Gabon' },
  { value: 'gm', label: 'Gambia' },
  { value: 'ge', label: 'Georgia' },
  { value: 'de', label: 'Germany' },
  { value: 'gh', label: 'Ghana' },
  { value: 'gi', label: 'Gibraltar' },
  { value: 'gr', label: 'Greece' },
  { value: 'gl', label: 'Greenland' },
  { value: 'gd', label: 'Grenada' },
  { value: 'gp', label: 'Guadeloupe' },
  { value: 'gu', label: 'Guam' },
  { value: 'gt', label: 'Guatemala' },
  { value: 'gg', label: 'Guernsey' },
  { value: 'gn', label: 'Guinea' },
  { value: 'gw', label: 'Guinea-Bissau' },
  { value: 'gy', label: 'Guyana' },
  { value: 'ht', label: 'Haiti' },
  { value: 'hm', label: 'Heard Island and McDonald Islands' },
  { value: 'va', label: 'Holy See' },
  { value: 'hn', label: 'Honduras' },
  { value: 'hk', label: 'Hong Kong' },
  { value: 'hu', label: 'Hungary' },
  { value: 'is', label: 'Iceland' },
  { value: 'in', label: 'India' },
  { value: 'id', label: 'Indonesia' },
  { value: 'ir', label: 'Iran (Islamic Republic of)' },
  { value: 'iq', label: 'Iraq' },
  { value: 'ie', label: 'Ireland' },
  { value: 'im', label: 'Isle of Man' },
  { value: 'il', label: 'Israel' },
  { value: 'it', label: 'Italy' },
  { value: 'jm', label: 'Jamaica' },
  { value: 'jp', label: 'Japan' },
  { value: 'je', label: 'Jersey' },
  { value: 'jo', label: 'Jordan' },
  { value: 'kz', label: 'Kazakhstan' },
  { value: 'ke', label: 'Kenya' },
  { value: 'ki', label: 'Kiribati' },
  { value: 'kp', label: "Korea (Democratic People's Republic of)" },
  { value: 'kr', label: 'Korea, Republic of' },
  { value: 'kw', label: 'Kuwait' },
  { value: 'kg', label: 'Kyrgyzstan' },
  { value: 'la', label: "Lao People's Democratic Republic" },
  { value: 'lv', label: 'Latvia' },
  { value: 'lb', label: 'Lebanon' },
  { value: 'ls', label: 'Lesotho' },
  { value: 'lr', label: 'Liberia' },
  { value: 'ly', label: 'Libya' },
  { value: 'li', label: 'Liechtenstein' },
  { value: 'lt', label: 'Lithuania' },
  { value: 'lu', label: 'Luxembourg' },
  { value: 'mo', label: 'Macao' },
  { value: 'mg', label: 'Madagascar' },
  { value: 'mw', label: 'Malawi' },
  { value: 'my', label: 'Malaysia' },
  { value: 'mv', label: 'Maldives' },
  { value: 'ml', label: 'Mali' },
  { value: 'mt', label: 'Malta' },
  { value: 'mh', label: 'Marshall Islands' },
  { value: 'mq', label: 'Martinique' },
  { value: 'mr', label: 'Mauritania' },
  { value: 'mu', label: 'Mauritius' },
  { value: 'yt', label: 'Mayotte' },
  { value: 'mx', label: 'Mexico' },
  { value: 'fm', label: 'Micronesia (Federated States of)' },
  { value: 'md', label: 'Moldova, Republic of' },
  { value: 'mc', label: 'Monaco' },
  { value: 'mn', label: 'Mongolia' },
  { value: 'me', label: 'Montenegro' },
  { value: 'ms', label: 'Montserrat' },
  { value: 'ma', label: 'Morocco' },
  { value: 'mz', label: 'Mozambique' },
  { value: 'mm', label: 'Myanmar' },
  { value: 'na', label: 'Namibia' },
  { value: 'nr', label: 'Nauru' },
  { value: 'np', label: 'Nepal' },
  { value: 'nl', label: 'Netherlands' },
  { value: 'nc', label: 'New Caledonia' },
  { value: 'nz', label: 'New Zealand' },
  { value: 'ni', label: 'Nicaragua' },
  { value: 'ne', label: 'Niger' },
  { value: 'ng', label: 'Nigeria' },
  { value: 'nu', label: 'Niue' },
  { value: 'nf', label: 'Norfolk Island' },
  { value: 'mk', label: 'North Macedonia' },
  { value: 'mp', label: 'Northern Mariana Islands' },
  { value: 'no', label: 'Norway' },
  { value: 'om', label: 'Oman' },
  { value: 'pk', label: 'Pakistan' },
  { value: 'pw', label: 'Palau' },
  { value: 'ps', label: 'Palestine, State of' },
  { value: 'pa', label: 'Panama' },
  { value: 'pg', label: 'Papua New Guinea' },
  { value: 'py', label: 'Paraguay' },
  { value: 'pe', label: 'Peru' },
  { value: 'ph', label: 'Philippines' },
  { value: 'pn', label: 'Pitcairn' },
  { value: 'pl', label: 'Poland' },
  { value: 'pt', label: 'Portugal' },
  { value: 'pr', label: 'Puerto Rico' },
  { value: 'qa', label: 'Qatar' },
  { value: 're', label: 'Réunion' },
  { value: 'ro', label: 'Romania' },
  { value: 'ru', label: 'Russian Federation' },
  { value: 'rw', label: 'Rwanda' },
  { value: 'bl', label: 'Saint Barthélemy' },
  { value: 'sh', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'kn', label: 'Saint Kitts and Nevis' },
  { value: 'lc', label: 'Saint Lucia' },
  { value: 'mf', label: 'Saint Martin (French part)' },
  { value: 'pm', label: 'Saint Pierre and Miquelon' },
  { value: 'vc', label: 'Saint Vincent and the Grenadines' },
  { value: 'ws', label: 'Samoa' },
  { value: 'sm', label: 'San Marino' },
  { value: 'st', label: 'Sao Tome and Principe' },
  { value: 'sa', label: 'Saudi Arabia' },
  { value: 'sn', label: 'Senegal' },
  { value: 'rs', label: 'Serbia' },
  { value: 'sc', label: 'Seychelles' },
  { value: 'sl', label: 'Sierra Leone' },
  { value: 'sg', label: 'Singapore' },
  { value: 'sx', label: 'Sint Maarten (Dutch part)' },
  { value: 'sk', label: 'Slovakia' },
  { value: 'si', label: 'Slovenia' },
  { value: 'sb', label: 'Solomon Islands' },
  { value: 'so', label: 'Somalia' },
  { value: 'za', label: 'South Africa' },
  { value: 'gs', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'ss', label: 'South Sudan' },
  { value: 'es', label: 'Spain' },
  { value: 'lk', label: 'Sri Lanka' },
  { value: 'sd', label: 'Sudan' },
  { value: 'sr', label: 'Suriname' },
  { value: 'sj', label: 'Svalbard and Jan Mayen' },
  { value: 'se', label: 'Sweden' },
  { value: 'ch', label: 'Switzerland' },
  { value: 'sy', label: 'Syrian Arab Republic' },
  { value: 'tw', label: 'Taiwan, Province of China' },
  { value: 'tj', label: 'Tajikistan' },
  { value: 'tz', label: 'Tanzania, United Republic of' },
  { value: 'th', label: 'Thailand' },
  { value: 'tl', label: 'Timor-Leste' },
  { value: 'tg', label: 'Togo' },
  { value: 'tk', label: 'Tokelau' },
  { value: 'to', label: 'Tonga' },
  { value: 'tt', label: 'Trinidad and Tobago' },
  { value: 'tn', label: 'Tunisia' },
  { value: 'tr', label: 'Turkey' },
  { value: 'tm', label: 'Turkmenistan' },
  { value: 'tc', label: 'Turks and Caicos Islands' },
  { value: 'tv', label: 'Tuvalu' },
  { value: 'ug', label: 'Uganda' },
  { value: 'ua', label: 'Ukraine' },
  { value: 'ae', label: 'United Arab Emirates' },
  {
    value: 'gb',
    label: 'United Kingdom',
  },
  { value: 'us', label: 'United States of America' },
  { value: 'um', label: 'United States Minor Outlying Islands' },
  { value: 'uy', label: 'Uruguay' },
  { value: 'uz', label: 'Uzbekistan' },
  { value: 'vu', label: 'Vanuatu' },
  { value: 've', label: 'Venezuela (Bolivarian Republic of)' },
  { value: 'vn', label: 'Viet Nam' },
  { value: 'vg', label: 'Virgin Islands (British)' },
  { value: 'vi', label: 'Virgin Islands (U.S.)' },
  { value: 'wf', label: 'Wallis and Futuna' },
  { value: 'eh', label: 'Western Sahara' },
  { value: 'ye', label: 'Yemen' },
  { value: 'zm', label: 'Zambia' },
  { value: 'zw', label: 'Zimbabwe' },
]

export default toHumanCountry
