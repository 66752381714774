import React from 'react'
import MainSegment from 'components/MainSegment'
import useQuery from 'lib/useQuery'
import PageWrapper from 'components/PageWrapper'
import css from './styles.module.scss'
import { canViewStat } from 'lib/can'
import Segment from 'components/Segment'
import toHumanCountry from 'lib/toHumanCountry'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const BanksStatPage = () => {
  const i18n = useLang(lang)
  const queryResult = useQuery('getBanksStat')
  const { banksStat } = queryResult
  return (
    <PageWrapper queryResult={queryResult}>
      {() => (
        <MainSegment title={i18n('title')}>
          <Helmet title={i18n('title')} />
          <Segment>
            <table className={css.banksStat}>
              <thead>
                <tr>
                  <th>{i18n('name')}</th>
                  <th>{i18n('country')}</th>
                  <th>{i18n('col')}</th>
                  <th>{i18n('bank')}</th>
                </tr>
              </thead>
              <tbody>
                {banksStat.map((bankStat, i) => {
                  return (
                    <tr key={i} className={css.bankStat}>
                      <td>{bankStat.name}</td>
                      <td>{toHumanCountry(bankStat.country, i18n('lang'))}</td>
                      <td>{bankStat.apiRequestsCount}</td>
                      <td>{bankStat.bankExists ? i18n('yes') : i18n('no')}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Segment>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canViewStat(me)}>
    {({ me }) => <BanksStatPage me={me} {...props} />}
  </PageWrapper>
)
