import React from 'react'
import MainSegment from 'components/MainSegment'
import useQuery from 'lib/useQuery'
import PageWrapper from 'components/PageWrapper'
import css from './styles.module.scss'
import { canViewStat } from 'lib/can'
import Segment from 'components/Segment'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ServiceStatPage = () => {
  const queryResult = useQuery('getServiceStats')
  const i18n = useLang(lang)
  const { serviceStats } = queryResult
  return (
    <PageWrapper queryResult={queryResult}>
      {() => (
        <MainSegment title={i18n('title')}>
          <Helmet title={i18n('title')} />
          <Segment>
            <table className={css.serviceStats}>
              <thead>
                <tr>
                  <th />
                  <th>{i18n('beforeLast')}</th>
                  <th>{i18n('before')}</th>
                  <th>{i18n('thisMonth')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={css.label}>{i18n('registrations')}</td>
                  <td>{serviceStats[2].registrations}</td>
                  <td>{serviceStats[1].registrations}</td>
                  <td>{serviceStats[0].registrations}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('activations')}</td>
                  <td>{serviceStats[2].activations}</td>
                  <td>{serviceStats[1].activations}</td>
                  <td>{serviceStats[0].activations}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('starters')}</td>
                  <td>{serviceStats[2].starters}</td>
                  <td>{serviceStats[1].starters}</td>
                  <td>{serviceStats[0].starters}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('leavers')}</td>
                  <td>{serviceStats[2].leavers}</td>
                  <td>{serviceStats[1].leavers}</td>
                  <td>{serviceStats[0].leavers}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('activeUsers')}</td>
                  <td>{serviceStats[2].activeUsers}</td>
                  <td>{serviceStats[1].activeUsers}</td>
                  <td>{serviceStats[0].activeUsers}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('regularUsers')}</td>
                  <td>{serviceStats[2].regularUsers}</td>
                  <td>{serviceStats[1].regularUsers}</td>
                  <td>{serviceStats[0].regularUsers}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('activePayers')}</td>
                  <td>{serviceStats[2].activePayers}</td>
                  <td>{serviceStats[1].activePayers}</td>
                  <td>{serviceStats[0].activePayers}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('newPayers')}</td>
                  <td>{serviceStats[2].newPayers}</td>
                  <td>{serviceStats[1].newPayers}</td>
                  <td>{serviceStats[0].newPayers}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('totalUsers')}</td>
                  <td>{serviceStats[2].totalUsers}</td>
                  <td>{serviceStats[1].totalUsers}</td>
                  <td>{serviceStats[0].totalUsers}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('apiRequests')}</td>
                  <td>{serviceStats[2].apiRequests}</td>
                  <td>{serviceStats[1].apiRequests}</td>
                  <td>{serviceStats[0].apiRequests}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('apiRevenue')}</td>
                  <td>{serviceStats[2].apiRevenue}</td>
                  <td>{serviceStats[1].apiRevenue}</td>
                  <td>{serviceStats[0].apiRevenue}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('archives')}</td>
                  <td>{serviceStats[2].archives}</td>
                  <td>{serviceStats[1].archives}</td>
                  <td>{serviceStats[0].archives}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('archivesRevenue')}</td>
                  <td>{serviceStats[2].archivesRevenue}</td>
                  <td>{serviceStats[1].archivesRevenue}</td>
                  <td>{serviceStats[0].archivesRevenue}</td>
                </tr>
                <tr>
                  <td className={css.label}>{i18n('totalRevenue')}</td>
                  <td>{serviceStats[2].totalRevenue}</td>
                  <td>{serviceStats[1].totalRevenue}</td>
                  <td>{serviceStats[0].totalRevenue}</td>
                </tr>
              </tbody>
            </table>
          </Segment>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canViewStat(me)}>
    {({ me }) => <ServiceStatPage me={me} {...props} />}
  </PageWrapper>
)
