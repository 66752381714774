const lang = {
  ru: {
    delete: 'Удалить',
    formationBank: 'Формирование JSON для банка',
    nameEn: 'Название банка на английском',
    nameRu: 'Название банка на местном языке',
    countryBankOperates: 'Страна, в которой действует банк',
    linkSvgBigOriginal: 'Ссылка на большой оригинальный логотип SVG',
    linkSvgBigInvert: 'Ссылка на большой инвертированный логотип SVG',
    linkPngSmollOriginal: 'Ссылка на маленький оригинальный логотип SVG',
    linkPngSmollInvert: 'Ссылка на маленький инвертированный логотип SVG',
    color: 'Цвет банка',
    addColor: 'Добавить цвет',
    backgroundColor: 'Цвет фона формы',
    backgroundGradient: 'Цвета фона формы для градинета',
    fontColor: 'Цвет текста на форме',
    borderColor: 'Цвет границ полей в форме',
    backgroundForm: 'Фон формы',
    dark: 'Скорее тёмный',
    lite: 'Скорее светлый',
    logoForm: 'Логотип на форме',
    original: 'Оригинальный',
    inverted: 'Инвертированный',
    linkBank: 'Ссылка на сайт банка',
    phone: 'Телефон банка',
    bins: 'Бины банка',
    sixNumber: 'По 6 цифр каждый на новой строке',
    jsonFile: 'JSON файл',
    text: `Скопируйте содержимое файла и сохраните его по секретному адресу на сайте вашего банка. Отправьте нам
      ссылку на этот файл по адресу`,
    textLast: `и мы синхронизируем его с нашей базой данных.`,
  },
  en: {
    delete: 'Delete',
    formationBank: 'Compilation of JSON for a bank',
    nameEn: 'Bank name in English',
    nameRu: 'Bank name in local language',
    countryBankOperates: 'Country where the bank operates',
    linkSvgBigOriginal: 'Link to large original SVG logo',
    linkSvgBigInvert: 'Link to large inverted SVG logo',
    linkSvgSmollOriginal: 'Link to small original SVG logo',
    linkSvgSmollInvert: 'Link to small inverted SVG logo',
    color: 'Bank color',
    addColor: 'Add colour',
    backgroundColor: 'Colour of a form background',
    backgroundGradient: 'Colours of form background for Gradient',
    fontColor: 'Colour of text on the form',
    borderColor: 'Colour of field borders on the form',
    backgroundForm: 'Background of the form',
    dark: 'Rather dark',
    lite: 'Rather light',
    logoForm: 'Logo on the form',
    original: 'Original',
    inverted: 'Inverted',
    linkBank: 'Link to website of a bank',
    phone: 'Bank phone',
    bins: 'Bins of bank',
    sixNumber: '6 digits each one on a new line',
    jsonFile: 'JSON file',
    text: `Copy the contents of the file and save it to a secret address on website of your bank. Send us a link to this file at`,
    textLast: `and we shall synchronize it with our database.`,
  },
}

export default lang
