import React, { useState, useRef } from 'react'
import css from './styles.module.scss'
import Link from 'components/Link'
import Footer from 'components/Footer'
import Header from 'components/Header'
import logoPerplePath from 'assets/logo-perple.png'
import logoPerplePath2x from 'assets/logo-perple@2x.png'
import useQuery from 'lib/useQuery'
import {
  getHomeRoute,
  getSignInRoute,
  getDashboardRoute,
  getGuideRoute,
  getDocsArchiveRoute,
  getDocsIntroRoute,
  getDocsApiRoute,
  getPriceRoute,
  getPluginUrl,
  getDocsBanksRoute,
  getPrivacyPolicyRoute,
  getTermsOfUseRoute,
  getApiSettingsRoute,
} from 'lib/routes'
import useWindowSize from 'lib/useWindowSize'
import { ReactComponent as MenuOpenIcon } from 'assets/lkMenu.svg'
import { ReactComponent as SignIn } from 'assets/account.svg'
import { ReactComponent as MenuCloseIcon } from 'assets/closeMenu.svg'
import cn from 'classnames'
import { switchLang, useLang } from 'lib/i18n'
import lang from './lang.js'
import russia from '../../assets/Russia.png'
import britain from '../../assets/UK.png'
import useGlobalState from 'lib/globalState'
import useDropdown from 'lib/useDropdown'
import useMutation from '../../lib/useMutation'
import { useHistory, useLocation } from 'react-router-dom'

const DocsLayout = ({ children }) => {
  const i18n = useLang(lang)
  const [mobileMenu, setMobileMenu] = useState(false)
  const windowSize = useWindowSize()
  const mobile = windowSize.width <= 900
  const { me, loading } = useQuery('getMe')
  const [globalState, dispatchGlobalAction] = useGlobalState()
  const langName = globalState.lang
  const dropdownLangRef = useRef()
  const langRef = useRef()
  const location = useLocation()
  const history = useHistory()
  const [isDropdownLangOpen, setDropdownLangOpen] = useState(false)
  const [changeMyLang] = useMutation('changeMyLang')
  useDropdown(langRef, dropdownLangRef, isDropdownLangOpen, setDropdownLangOpen)
  return (
    <div className={css.docsLayout}>
      <div className={css.wrapper}>
        {mobile && (
          <>
            <Header perple />
            {!mobileMenu && (
              <div className={css.buttonLk} onClick={() => setMobileMenu(true)}>
                <span>{i18n('docs')}</span>
                <MenuOpenIcon />
              </div>
            )}
          </>
        )}
        {!mobile && (
          <div className={css.sideBar}>
            <Link className={css.logo} currentClassName={css.current} to={getHomeRoute()}>
              <img
                width={117}
                height={24}
                src={logoPerplePath}
                srcSet={logoPerplePath2x}
                className={css.logoImage}
                alt="BinKing"
              />
            </Link>
            <div className={css.links}>
              <Link className={css.link} currentClassName={css.current} to={getDocsIntroRoute()}>
                {i18n('introduction')}
              </Link>
              <Link className={css.link} currentClassName={css.current} to={getDocsApiRoute()}>
                {i18n('apiGuid')}
              </Link>
              <Link className={css.link} currentClassName={css.current} to={getDocsArchiveRoute()}>
                {i18n('archiveGuid')}
              </Link>
              <Link className={css.link} currentClassName={css.current} to={getDocsBanksRoute()}>
                {i18n('bankList')}
              </Link>
              <Link className={css.link} href={getPluginUrl()} target="_blank">
                {i18n('plugin')}
              </Link>
            </div>
          </div>
        )}
        <div className={css.contentBox}>
          {!mobile && (
            <div className={css.mainMenu}>
              {!loading && (
                <>
                  <div className={css.menu}>
                    <Link className={css.link} currentClassName={css.current} to={getHomeRoute()}>
                      {i18n('home')}
                    </Link>
                    <Link className={css.link} currentClassName={css.current} to={getGuideRoute()}>
                      {i18n('guid')}
                    </Link>
                    <Link className={css.link} currentClassName={css.current} to={getDocsIntroRoute()}>
                      {i18n('docs')}
                    </Link>
                    <Link className={css.link} currentClassName={css.current} to={getPriceRoute()}>
                      {i18n('price')}
                    </Link>
                  </div>
                  <div className={css.signAndLang}>
                    <div className={css.lang}>
                      <a href="#null" className={css.langLink} ref={langRef} onClick={e => e.preventDefault()}>
                        <span>
                          {langName === 'ru' ? (
                            <img src={russia} alt="ru" width="76px" height="auto" />
                          ) : langName === 'en' ? (
                            <img src={britain} alt="en" width="76px" height="auto" />
                          ) : (
                            <img src={britain} alt="en" width="76px" height="auto" />
                          )}
                        </span>
                      </a>
                      <div
                        ref={dropdownLangRef}
                        className={cn({ [css.dropdown]: true, [css.hidden]: !isDropdownLangOpen })}
                      >
                        <Link
                          onClick={e => {
                            e.preventDefault()
                            switchLang('ru', dispatchGlobalAction, location, history, changeMyLang, me)
                            setDropdownLangOpen(false)
                          }}
                        >
                          <img src={russia} alt="ru" width="76px" height="auto" />
                        </Link>
                        <Link
                          onClick={e => {
                            e.preventDefault()
                            switchLang('en', dispatchGlobalAction, location, history, changeMyLang, me)
                            setDropdownLangOpen(false)
                          }}
                        >
                          <img src={britain} alt="en" width="76px" height="auto" />
                        </Link>
                      </div>
                    </div>
                    {!me ? (
                      <div className={css.sign}>
                        <Link className={css.link} currentClassName={css.current} to={getSignInRoute()}>
                          <SignIn className={css.icon} /> {i18n('login')}
                        </Link>
                      </div>
                    ) : (
                      <div className={css.sign}>
                        <Link className={css.link} currentClassName={css.current} to={getDashboardRoute()}>
                          <SignIn className={css.icon} /> <span>{i18n('lk')}</span>
                        </Link>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
          {mobile && mobileMenu && (
            <div className={cn({ [css.menu]: true, [css.open]: mobileMenu })}>
              <div className={css.menuBox}>
                <div className={css.mobileHead}>
                  <Link className={css.logo} currentClassName={css.current} to={getHomeRoute()}>
                    <img
                      width={116}
                      height={24}
                      src={logoPerplePath}
                      srcSet={logoPerplePath2x}
                      className={css.logoImage}
                      alt="BinKing"
                    />
                  </Link>
                </div>
                <div className={css.buttonLk} onClick={() => setMobileMenu(false)}>
                  <span>{i18n('docs')}</span>
                  <MenuCloseIcon />
                </div>
                <div className={css.links}>
                  <Link
                    className={css.link}
                    currentClassName={css.current}
                    onClick={() => setMobileMenu(false)}
                    to={getDocsIntroRoute()}
                  >
                    {i18n('introduction')}
                  </Link>
                  <Link
                    className={css.link}
                    currentClassName={css.current}
                    onClick={() => setMobileMenu(false)}
                    to={getDocsApiRoute()}
                  >
                    {i18n('apiGuid')}
                  </Link>
                  <Link
                    className={css.link}
                    currentClassName={css.current}
                    onClick={() => setMobileMenu(false)}
                    to={getDocsArchiveRoute()}
                  >
                    {i18n('archiveGuid')}
                  </Link>
                  <Link
                    className={css.link}
                    currentClassName={css.current}
                    onClick={() => setMobileMenu(false)}
                    to={getDocsBanksRoute()}
                  >
                    {i18n('bankList')}
                  </Link>
                  <Link className={css.link} href={getPluginUrl()} target="_blank">
                    {i18n('plugin')}
                  </Link>
                </div>
              </div>
              <div className={css.copyright}>
                <p>
                  {i18n('made')} <Link href={'https://10.com.ru'}>{i18n('nameProg')}</Link> {i18n('copy')}
                </p>
                <div className={css.copyLinks}>
                  <Link className={css.link} to={getPrivacyPolicyRoute()}>
                    {i18n('privacyPolicy')}
                  </Link>
                  <Link className={css.link} to={getTermsOfUseRoute()}>
                    {i18n('terms')}
                  </Link>
                  <Link className={css.link} to={getApiSettingsRoute()}>
                    {i18n('save')}
                  </Link>
                </div>
              </div>
            </div>
          )}

          <div className={css.content}>{children}</div>
        </div>
      </div>
      <Footer minimal />
    </div>
  )
}

export default DocsLayout
