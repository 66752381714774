const lang = {
  ru: {
    errorPass: 'Неверно указан текущий пароль',
    required: 'Обязательное поле',
    miss: 'Пароли не совпадают',
    successMessage: 'Пароль успешно изменён',
    password: 'Текущий пароль',
    newPassword: 'Новый пароль',
    newPasswordAgain: 'Подтвердите новый пароль',
    change: 'Изменить пароль',
  },
  en: {
    errorPass: 'The current password is incorrect',
    required: 'Required field',
    miss: 'Passwords do not match',
    successMessage: 'Password changed successfully',
    password: 'Current password',
    newPassword: 'New Password',
    newPasswordAgain: 'Confirm new password',
    change: 'Change password',
  }
}

export default lang
