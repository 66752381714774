import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ym from 'react-yandex-metrika'
import { YMInitializer } from 'react-yandex-metrika'

export default function YandexMetrika() {
  const { pathname } = useLocation()

  useEffect(() => {
    if (process.env.REACT_APP_YM) {
      ym('hit', pathname)
    }
  }, [pathname])

  return process.env.REACT_APP_YM ? (
    <YMInitializer accounts={[process.env.REACT_APP_YM]} options={{ webvisor: true }} />
  ) : null
}
