const lang = {
  ru: {
    title: 'Статистика сервиса',
    beforeLast: 'Позапрошлый',
    before: 'Прошлый',
    thisMonth: 'Этот месяц',
    registrations: 'Регистраций',
    activations: 'Активаций',
    starters: 'Начали пользоваться',
    leavers: 'Перестали пользоваться',
    activeUsers: 'Используют',
    regularUsers: 'Используют второй месяц или дольше',
    activePayers: 'Платящих среди пользующихся',
    newPayers: 'Начали платить',
    totalUsers: 'Всего зарегистрировано',
    apiRequests: 'Запросов к API',
    apiRevenue: 'Всего оплачено за API',
    archives: 'Архивов куплено',
    archivesRevenue: 'Всего оплачено за архивы',
    totalRevenue: 'Всего оплачено',
  },
  en: {
    title: 'Service statistics',
    beforeLast: 'the one before last',
    before: 'Last',
    thisMonth: 'This month',
    registrations: 'Registrations',
    activations: 'Activations',
    starters: 'Starters',
    leavers: 'Leavers',
    activeUsers: 'Active users',
    regularUsers: 'Use for the second month or longer',
    activePayers: 'Payers among users',
    newPayers: 'Started paying',
    totalUsers: 'Total registered',
    apiRequests: 'API requests',
    apiRevenue: 'Total Paid for API',
    archives: 'Archives purchased',
    archivesRevenue: 'Total paid for archives',
    totalRevenue: 'Total paid ',
  }
}

export default lang
