import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import cookieStore from 'lib/cookieStore'

const MakeArchivePriceVisible = () => {
  const location = useLocation()
  const [makeArchivePriceVisible] = useMutation('makeArchivePriceVisible')
  const { me } = useQuery('getMe')
  useEffect(() => {
    if (location.search === '?makeArchivePriceVisible') {
      cookieStore.set('archivePriceVisible', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, JSON.stringify(me)])
  useEffect(() => {
    const archivePriceVisible = cookieStore.get('archivePriceVisible') || location.search === '?makeArchivePriceVisible'
    if (archivePriceVisible && me && !me.archivePriceVisible) {
      makeArchivePriceVisible()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, JSON.stringify(me)])
  return null
}

export default MakeArchivePriceVisible
