// import React from 'react'
// import { FormItems, useForm } from '../Form'
// import RadioButton, { RadioButtons } from '../RadioButton'
// import useMutation from 'lib/useMutation'
// import Segment from '../Segment'
// import {useLang} from "../../lib/i18n";
// import lang from "./lang";

const PaymentMethod = ({ me }) => {
  return null
  // const i18n = useLang(lang)
  // const [setPaymentMethod] = useMutation('setPaymentMethod')
  // const { formik } = useForm({
  //   initialValues: {
  //     paymentMethod: me.paymentMethod,
  //   },
  //   enableReinitialize: true,
  //   onSubmit: ({ paymentMethod }) => {
  //     return setPaymentMethod({
  //       variables: { input: { paymentMethod } },
  //       optimisticResponse: {
  //         setPaymentMethod: {
  //           me: {
  //             id: me.id,
  //             __typename: 'User',
  //             paymentMethod,
  //           },
  //           __typename: 'MePayload',
  //         },
  //       },
  //     })
  //   },
  //   autoSend: true,
  // })
  // return (
  //   <Segment title={i18n('paymentMethod')}>
  //     <FormItems>
  //       <RadioButtons name="paymentMethod" formik={formik}>
  //         <RadioButton name="paymentMethod" value="pp" label="PayPal" formik={formik} />
  //         <RadioButton name="paymentMethod" value="cp" label="Российская банковская карта" formik={formik} />
  //       </RadioButtons>
  //     </FormItems>
  //   </Segment>
  // )
}

export default PaymentMethod
