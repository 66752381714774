import React, { useEffect, useRef, useState } from 'react'
import css from './style.module.scss'
import { SketchPicker } from 'react-color'
import Button from 'components/Button'
import get from 'lodash/get'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const Colorpicker = ({ label, formik, name, hint, onChange, ...restProps }) => {
  const i18n = useLang(lang)
  const [isOpen, setIsOpen] = useState(false)
  const popupEl = useRef()
  const onWindowClick = () => {
    setIsOpen(false)
  }
  const onPopupClick = e => {
    e.stopPropagation()
  }
  const onButtonClick = e => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    popupEl.current.addEventListener('click', onPopupClick)
    window.addEventListener('click', onWindowClick)
    return () => {
      popupEl.current.removeEventListener('click', onPopupClick)
      window.removeEventListener('click', onWindowClick)
    }
  })
  const wasOpen = useRef(false)
  const { setFieldTouched } = formik
  useEffect(
    () => {
      if (!isOpen && wasOpen.current) {
        setFieldTouched(name)
      }
      wasOpen.current = isOpen
    },
    [isOpen, name, setFieldTouched]
  )
  const error = get(formik.errors, name)
  const value = get(formik.values, name)
  const touched = get(formik.touched, name)
  return (
    <div className={css.colorpicker}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      {!value ? (
        <Button type="button" color="gray" onClick={onButtonClick}>
          {i18n('color')}
        </Button>
      ) : (
        <div
          className={css.colorPreview}
          onClick={onButtonClick}
          style={{ backgroundColor: value || 'transparent' }}
        />
      )}
      <div ref={node => (popupEl.current = node)} className={css.popup}>
        {isOpen && (
          <SketchPicker
            disableAlpha
            color={value}
            presetColors={[]}
            onChange={({ hex }) => {
              if (onChange) onChange(hex)
              formik.setFieldValue(name, hex)
              setIsOpen(true)
            }}
          />
        )}
      </div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default Colorpicker
