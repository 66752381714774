import { useLocation } from 'react-router-dom'
import parseLocationSearch from 'lib/parseLocationSearch'
import cookieStore from 'lib/cookieStore'
import { useEffect } from 'react'

const ReferralLink = () => {
  const location = useLocation()
  useEffect(() => {
    const parsedLocationSearch = parseLocationSearch(location.search)
    if (parsedLocationSearch.ref) {
      cookieStore.set('referralCode', parsedLocationSearch.ref)
    }
  }, [location.search])
  return null
}

export default ReferralLink
