const lang = {
  ru: {
    doneByStart: 'Сделано',
    doneByEnd: 'Сергеем Дмитриевым и командой',
    home: 'Главная',
    guid: 'Гайд',
    documents: 'Документация',
    price: 'Стоимость',
    login: 'Вход',
    logout: 'Выход',
    lk: 'Личный кабинет',
    police: 'Политика конфиденциальности',
    userDocs: 'Пользовательское соглашение',
  },
  en: {
    doneByStart: 'Made',
    doneByEnd: 'by Sergei Dmitriev and team',
    home: 'Main',
    guid: 'Guide',
    documents: 'Documentation',
    price: 'Pricing',
    login: 'Sign in',
    logout: 'Sign out',
    lk: 'Dashboard',
    police: 'Privacy policy',
    userDocs: 'Terms of use',
  }
}

export default lang
