import React, { useState } from 'react'
import css from './styles.module.scss'
import Link from 'components/Link'
import Button from 'components/Button'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Alert from 'components/Alert'
import {
  getApiSettingsRoute,
  getArchiveSettingsRoute,
  getPaymentsSettingsRoute,
  getProfileSettingsRoute,
  getBankNewRoute,
  getBanksRoute,
  getBanksStatRoute,
  getServiceStatRoute,
  getBinBanksRoute,
  getBanksLogsRoute,
  getUsersRoute,
  getReferersRoute,
  getTermsOfUseRoute,
  getUnknownRequestedBinsPageRoute,
  getPrivacyPolicyRoute,
  getHomeRoute,
} from 'lib/routes'
import logoPerplePath from 'assets/logo-perple.png'
import logoPerplePath2x from 'assets/logo-perple@2x.png'
import useQuery from 'lib/useQuery'
import {
  canViewBanks,
  canCreateBanks,
  canViewStat,
  canViewBins,
  canViewBanksLogs,
  canViewUsers,
  canViewReferers,
  canViewRequestedBins,
} from 'lib/can'
import { FormItems, useForm } from 'components/Form'
import useMutation from 'lib/useMutation'
import { ReactComponent as MenuOpenIcon } from 'assets/lkMenu.svg'
import { ReactComponent as MenuCloseIcon } from 'assets/closeMenu.svg'
import cn from 'classnames'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import { Deprecated } from 'components/Deprecated'

const ConfirmEmailAlert = ({ me }) => {
  const i18n = useLang(lang)
  const [resendEmailConfirmation] = useMutation('resendEmailConfirmation')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {},
    onSubmit: () => {
      return resendEmailConfirmation()
    },
    showValidationAlert: true,
    successMessage: i18n('successMessage'),
  })
  return (
    <div className={css.message}>
      <Alert>
        <p className={css.text}>
          {i18n('email')} {me.email} {i18n('emailText')}
        </p>
        <div className={css.button}>
          <FormItems>
            <Alert {...alertProps} />
            <Button color="gray" onClick={() => formik.handleSubmit()} {...buttonProps}>
              {i18n('resend')}
            </Button>
          </FormItems>
        </div>
      </Alert>
    </div>
  )
}

const DashboardLayout = ({ children }) => {
  const { me, loading } = useQuery('getMe')
  const i18n = useLang(lang)
  const [mobileMenu, setMobileMenu] = useState(false)
  return (
    <div className={css.dashboardLayout}>
      <Header perple />
      <div className={css.buttonLk} onClick={() => setMobileMenu(true)}>
        <span>{i18n('lk')}</span>
        <MenuOpenIcon />
      </div>
      <div className={css.wrapper}>
        <div className={cn({ [css.menu]: true, [css.open]: mobileMenu })}>
          <div className={css.menuBox}>
            {!!mobileMenu && (
              <>
                <div className={css.mobileHead}>
                  <Link className={css.logo} currentClassName={css.current} to={getHomeRoute()}>
                    <img
                      className={css.logoImage}
                      width={116}
                      height={24}
                      src={logoPerplePath}
                      srcSet={logoPerplePath2x + ' 2x'}
                      alt="BinKing"
                    />
                  </Link>
                </div>
                <div className={css.buttonLk} onClick={() => setMobileMenu(false)}>
                  <span>{i18n('lk')}</span>
                  <MenuCloseIcon />
                </div>
              </>
            )}
            {!loading && (
              <>
                <div className={css.links}>
                  <Link
                    className={css.link}
                    onClick={() => setMobileMenu(false)}
                    currentClassName={css.current}
                    to={getApiSettingsRoute()}
                  >
                    {i18n('optionApi')}
                  </Link>
                  <Link
                    className={css.link}
                    onClick={() => setMobileMenu(false)}
                    currentClassName={css.current}
                    to={getArchiveSettingsRoute()}
                  >
                    {i18n('archive')}
                  </Link>
                  <Link
                    className={css.link}
                    onClick={() => setMobileMenu(false)}
                    currentClassName={css.current}
                    to={getPaymentsSettingsRoute()}
                  >
                    {i18n('payOption')}
                  </Link>
                  <Link
                    className={css.link}
                    onClick={() => setMobileMenu(false)}
                    currentClassName={css.current}
                    to={getProfileSettingsRoute()}
                  >
                    {i18n('userOption')}
                  </Link>
                  {canCreateBanks(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getBankNewRoute()}
                    >
                      {i18n('newBank')}
                    </Link>
                  )}
                  {canViewBanks(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getBanksRoute()}
                    >
                      {i18n('banks')}
                    </Link>
                  )}
                  {canViewBanksLogs(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getBanksLogsRoute()}
                    >
                      {i18n('logBanks')}
                    </Link>
                  )}
                  {canViewUsers(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getUsersRoute()}
                    >
                      {i18n('users')}
                    </Link>
                  )}
                  {canViewReferers(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getReferersRoute()}
                    >
                      {i18n('saites')}
                    </Link>
                  )}
                  {canViewBins(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getBinBanksRoute()}
                    >
                      {i18n('binBanks')}
                    </Link>
                  )}
                  {canViewStat(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getBanksStatRoute()}
                    >
                      {i18n('statsBanks')}
                    </Link>
                  )}
                  {canViewStat(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getServiceStatRoute()}
                    >
                      {i18n('statsService')}
                    </Link>
                  )}
                  {canViewRequestedBins(me) && (
                    <Link
                      className={css.link}
                      onClick={() => setMobileMenu(false)}
                      currentClassName={css.current}
                      to={getUnknownRequestedBinsPageRoute()}
                    >
                      {i18n('unidentifiedBins')}
                    </Link>
                  )}
                </div>
              </>
            )}
          </div>
          {!!mobileMenu && (
            <div className={css.copyright}>
              <p>
                {i18n('made')}{' '}
                <Link href={'https://10.com.ru'} color="white">
                  {i18n('nameProg')}
                </Link>{' '}
                {i18n('copy')}
              </p>
              <div className={css.copyLinks}>
                <Link className={css.link} to={getPrivacyPolicyRoute()}>
                  {i18n('privacyPolicy')}
                </Link>
                <Link className={css.link} to={getTermsOfUseRoute()}>
                  {i18n('terms')}
                </Link>
                <Link className={css.link} to={getApiSettingsRoute()}>
                  {i18n('save')}
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className={css.contentBox}>
          <div className={css.messages}>
            {me && !me.emailConfirmed && <ConfirmEmailAlert me={me} />}
            <Deprecated />
            <br />
            <br />
          </div>
          <div className={css.content}>{children}</div>
        </div>
      </div>
      <Footer minimal />
    </div>
  )
}

export default DashboardLayout
