import startsWith from 'lodash/startsWith'

const getUploadUrl = (path) => {
  if (!path) return ''
  if (
    startsWith(path, 'http://') ||
    startsWith(path, 'https://')
  )
    return path
  return `${process.env.REACT_APP_S3_URL}/${path}`
}

export default getUploadUrl
