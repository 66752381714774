import React from 'react'
import PageWrapper from 'components/PageWrapper'
import PagePadding from 'components/PagePadding'
import Layout from 'components/Layout'
import Input from 'components/Input'
import Button, { Buttons } from 'components/Button'
import Link from 'components/Link'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import * as Yup from 'yup'
import css from './styles.module.scss'
import useMutation from 'lib/useMutation'
import { getDashboardRoute, getRestorePasswordRoute, getSignUpRoute, getSignInRoute } from 'lib/routes'
import cookieStore from 'lib/cookieStore'
import { useApolloClient } from '@apollo/react-hooks'
import Helmet from 'components/Helmet'
import { identifyUser, track } from 'lib/mixpanel'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const SignInPage = ({ history }) => {
  const i18n = useLang(lang)
  const apolloClient = useApolloClient()
  const [signIn] = useMutation('signIn', {
    errorCodes: {
      ERR_UNAUTHORIZED: i18n('err'),
    },
  })
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => {
      return signIn({
        variables: { input: values },
        parser: [['token', 'signIn.token'], ['user', 'signIn.user']],
      }).then(({ token, user }) => {
        if (token) {
          identifyUser(user.id)
          track('signIn')
          cookieStore.set('token', token)
          return apolloClient.resetStore().then(() => {
            history.push(getDashboardRoute())
            return false
          })
        }
      })
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18n('errMail'))
        .required(i18n('required')),
      password: Yup.string().required(i18n('required')),
    }),
    successMessage: false,
  })
  return (
    <div className={css.signInPage}>
      <Helmet title={i18n('login')} />
      <Layout perple={true} minimal={true}>
        <PagePadding>
          <PageWrapper redirerctAuthorized>
            <div className={css.modal}>
              <div className={css.menu}>
                <Link type="solid" className={css.link} currentClassName={css.current} nowrap to={getSignInRoute()}>
                  {i18n('login')}
                </Link>
                <Link type="solid" className={css.link} currentClassName={css.current} nowrap to={getSignUpRoute()}>
                  {i18n('registrations')}
                </Link>
              </div>
              <div className={css.content}>
                <Form formik={formik}>
                  <FormItems>
                    <Input label={i18n('mail')} name="email" formik={formik} />
                    <Input label={i18n('pass')} type="password" name="password" formik={formik} />
                    <Alert {...alertProps} />
                    <Buttons withLinks>
                      <Button {...buttonProps}>{i18n('login')}</Button>
                      <Link type="solid" nowrap to={getRestorePasswordRoute()}>
                        {i18n('forgot')}
                      </Link>
                    </Buttons>
                  </FormItems>
                </Form>
              </div>
            </div>
          </PageWrapper>
        </PagePadding>
      </Layout>
    </div>
  )
}

export default SignInPage
