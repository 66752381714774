import React from 'react'
import MainSegment from 'components/MainSegment'
import RichText from 'components/RichText'
import Link from 'components/Link'
import { getPluginUrl, getApiSettingsRoute } from 'lib/routes'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import Helmet from 'components/Helmet'
import useGlobalState from 'lib/globalState'

const DocsApiPage = () => {
  const [globalState] = useGlobalState()
  const langName = globalState.lang
  return langName === 'ru' ? (
    <MainSegment title="Инструкции к API">
      <Helmet title="Инструкции к API" />
      <RichText>
        <p>
          Для использования API вам потребуется API ключ. Ваш API ключ находится в личном кабинете{' '}
          <Link type="solid" to={getApiSettingsRoute()}>
            на странице «Настройки API».
          </Link>{' '}
          Для взаимодействия с API необходимо отправить один из GET запросов к нашему серверу. Чтобы протестировать
          работу API, вы можете добавить к любому запросу GET параметр <code>sandbox=1</code>, такие запросы работают с
          тестовыми данными и являются абсолютно бесплатными.
        </p>

        <p>
          Мы рекомендуем использовать API в связке с нашим{' '}
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            JS-плагином.
          </Link>{' '}
          Плагин упрощает взаимодействие с API и минимизирует количество запросов. Кроме того плагин позволяет
          определять бренд банковской карты (Mastercard, Visa, ...), включает в себя все логотипы брендов и возвращает
          некоторые вспомогательные свойства, такие как маска номера карты, название защитного поля и прочее.
        </p>

        <h2>Получение данных по номеру карты для вёрстки формы</h2>

        <p>
          Отправьте GET запрос по адресу <code>https://api.binking.io/form</code> с параметрами:
        </p>
        <ul>
          <li>
            <code>apiKey</code> (обязательно) — ваш ключ API
          </li>
          <li>
            <code>cardNumber</code> (обязательно) — первые 6 цифр номера карты. Если отправите меньше 6 цифр, банк
            определён не будет. Если отправите больше 6 цифр, результат останется тем же, что и при 6 отправленных.
          </li>
          <li>
            <code>fields</code> — перечень полей через запятую, которые необходимо вернуть. Если оставить пустым, будут
            возвращены все поля.
          </li>
          <li>
            <code>sandbox</code> — если передать «1», то запрос будет являться бесплатным, банк будет определён, только
            если в cardNumber передать номер карты, начинающийся этими цифрами: <code>402333</code> (Сбербанк),{' '}
            <code>546918</code> (Сбербанк), <code>400812</code> (Росбанк), <code>677721</code> (Росбанк).
          </li>
        </ul>

        <SyntaxHighlighter
          style={github}
          language="javascript"
        >{`fetch('https://api.binking.io/form?apiKey=YOUR_API_KEY&cardNumber=CARD_NUMBER&sandbox=1')
      .then(function(response) {
        return response.json()
      }).then(function(data) {
        // Ваш код для работы с полученными данными
      }).catch(function(error) {
        console.error(error)
      })
    `}</SyntaxHighlighter>

        <p>
          В ответ вы получите JSON объект из пар ключ-значение. Используйте их, чтобы сверстать особую форму, или как
          вам угодно ещё. Вот перечень всего, что BinKing может сказать по номеру банковской карты:
        </p>

        <SyntaxHighlighter style={github} language="javascript">{`{
  // Название банка на английском без пробелов + код страны
  bankAlias: "ru-sberbank",

  // Название банка на английском
  bankName: "Sberbank",

  // Название банка на местном языке
  bankLocalName: "Сбербанк России",

  // Ссылка на большой цветной логотип банка
  bankLogoBigOriginalSvg: "https://static.binking.io/banks-logos/c4902a868555835e.svg",
  bankLogoBigOriginalPng: "https://static.binking.io/banks-logos/01be7d8dcbb14510.png",

  // Большой белый логотип банка
  bankLogoBigInvertedSvg: "https://static.binking.io/banks-logos/dd12813fcdc36407.svg",
  bankLogoBigInvertedPng: "https://static.binking.io/banks-logos/0e73c94e945364bc.png",

  // Маленький, вписывающийся в квадрат, цветной логотип банка
  bankLogoSmallOriginalSvg: "https://static.binking.io/banks-logos/3dc348ddd7af05f0.svg",
  bankLogoSmallOriginalPng: "https://static.binking.io/banks-logos/efe5e16478056787.png",

  // Маленький белый логотип банка
  bankLogoSmallInvertedSvg: "https://static.binking.io/banks-logos/0d4639f082f52d37.svg",
  bankLogoSmallInvertedPng: "https://static.binking.io/banks-logos/5310e5d14dbf7c17.png",

  // Основной цвет банка
  bankColor: "#1a9f29",

  // Все цвета банка
  bankColors: ["#1a9f29"],

  // Страна, в которой действует банк
  bankCountry: "ru",

  // Ссылка на сайт банка
  bankSite: "https://www.sberbank.ru",

  // Телефон банка
  bankPhone: "8 800 555-55-50",

  // Цвет для фона формы
  formBackgroundColor: "#1a9f29",

  // Цвета для градиентного фона формы
  formBackgroundColors: ["#1a9f29", "#0d7518"],

  // Фон скорее тёмный "dark" или светлый "light"
  formBackgroundLightness: "dark",

  // Цвет текста, который хорошо будет смотреться на фоне формы
  formTextColor: "#ffffff",

  // Цвет границ для полей в форме
  formBorderColor: "#ffffff",

  // Ссылка на большой логотип банка, который хорошо будет смотреться на фоне формы
  formBankLogoBigSvg: "https://static.binking.io/banks-logos/dd12813fcdc36407.svg",
  formBankLogoBigPng: "https://static.binking.io/banks-logos/0e73c94e945364bc.png",

  // Ссылка на маленький логотип банка, который хорошо будет смотреться на фоне формы
  formBankLogoSmallSvg: "https://static.binking.io/banks-logos/0d4639f082f52d37.svg",
  formBankLogoSmallPng: "https://static.binking.io/banks-logos/5310e5d14dbf7c17.png",

  // Цветовая схема логотипа для формы "original" или "inverted"
  formLogoScheme: "inverted",
}
`}</SyntaxHighlighter>

        <p>
          Если банк не был найден, то в теле ответа будет <code>null</code>.
        </p>

        <h2>Получение данных о конкретном банке</h2>

        <p>
          Этот запрос может пригодится для вывода сохранённых карт польхователя. Когда пользователь будет вводить данные
          ккарты впервые, запишите алиас банка <code>bankAlias</code> в свою базу данных, и используйте его позже для
          получениня данных о его банке.
        </p>

        <p>
          Отправьте GET запрос по адресу <code>https://api.binking.io/bank</code> с параметрами:
        </p>
        <ul>
          <li>
            <code>apiKey</code> (обязательно) — ваш ключ API
          </li>
          <li>
            <code>bankAlias</code> (обязательно) — алиас банка
          </li>
          <li>
            <code>fields</code> — перечень полей через запятую, которые необходимо вернуть. Если оставить пустым, будут
            возвращены все поля.
          </li>
          <li>
            <code>sandbox</code> — если передать «1», то запрос будет являться бесплатным, банк будет определён, только
            если в bankAlias передать одно из 2 значений:
            <code>ru-sberbank</code>, <code>ru-rosbank</code>.
          </li>
        </ul>

        <SyntaxHighlighter
          style={github}
          language="javascript"
        >{`fetch('https://api.binking.io/bank?apiKey=YOUR_API_KEY&bankAlias=ru-sberbank&sandbox=1')
`}</SyntaxHighlighter>

        <p>Структура ответа точно такая же, как при запросе к https://api.binking.io/form</p>

        <p>
          Если банк не был найден, то в теле ответа будет <code>null</code>.
        </p>

        <h2>Получение данных о списке банков</h2>

        <p>
          Отправьте GET запрос по адресу <code>https://api.binking.io/banks</code> с параметрами:
        </p>
        <ul>
          <li>
            <code>apiKey</code> (обязательно) — ваш ключ API
          </li>
          <li>
            <code>banksAliases</code> (обязательно) — алиасы банков через запятую без пробелов
          </li>
          <li>
            <code>fields</code> — перечень полей через запятую, которые необходимо вернуть. Если оставить пустым, будут
            возвращены все поля.
          </li>
          <li>
            <code>sandbox</code> — если передать «1», то запрос будет являться бесплатным, банк будет определён, только
            если в banksAliases передавать значения: ru-sberbank, ru-rosbank.
          </li>
        </ul>

        <SyntaxHighlighter
          style={github}
          language="javascript"
        >{`fetch('https://api.binking.io/banks?apiKey=YOUR_API_KEY&banksAliases=ru-sberbank,ru-rosbank&sandbox=1')
`}</SyntaxHighlighter>

        <p>
          В ответ вы получите JSON массив из объектов. Структура каждого отдельного объекта точно такая же, как при
          запросе к<code>https://api.binking.io/form</code>
        </p>

        <p>
          Если банк не был найден, то на его месте в массиве будет <code>null</code>.
        </p>
      </RichText>
    </MainSegment>
  ) : (
    <MainSegment title="API Instructions">
      <Helmet title="API Instructions" />
      <RichText>
        <p>
          You need an API key to use the API. Your API key is in your personal cabinet{' '}
          <Link type="solid" to={getApiSettingsRoute()}>
            on the page "API Settings".
          </Link>{' '}
          To interact with the API, you need to send one of the GET requests to our server. To test
          API work, you can add the <code>sandbox=1</code> parameter to any GET request, such requests work with
          test data and are absolutely free.
        </p>

        <p>
          We recommend to use the API in conjunction with our{' '}
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            JS plugin.
          </Link>{' '}
          The plugin simplifies an interaction with the API and minimizes the number of requests. In addition, the plugin allow to   identify the brand of bank card (Mastercard, Visa, ...), includes all brand logos and returns   some auxiliary properties, such as the mask of the card number, the name of  protective field and others..
        </p>

        <h2>Reception of information by the card number for the form layout</h2>

        <p>
          Send a GET request to <code>https://api.binking.io/form</code> with the following parameters:
        </p>
        <ul>
          <li>
            <code>apiKey</code> (required) - your API key
          </li>
          <li>
            <code>cardNumber</code> (required) - the first 6 digits of the card number. If you send less than 6 digits, the bank will not be identified. If you send more than 6 digits, the result will remain the same as with 6 digits.
          </li>
          <li>
            <code>fields</code> — list of fields to be returned, separated by commas. If left blank, all fields will be returned.
          </li>
          <li>
            <code>sandbox</code> — <code>sandbox</code> - if you send "1", then the request will be free of charge, the bank will be identified, only if you send the card number starting with these numbers to cardNumber: <code>402333</code> (Sberbank),{' '}    <code>546918</code> (Sberbank), <code>400812</code> (Rosbank), <code>677721</code> (Rosbank).
          </li>
        </ul>

        <SyntaxHighlighter
          style={github}
          language="javascript"
        >{`fetch('https://api.binking.io/form?apiKey=YOUR_API_KEY&cardNumber=CARD_NUMBER&sandbox=1')
      .then(function(response) {
        return response.json()
      }).then(function(data) {
        // Your code for work with the received information
      }).catch(function(error) {
        console.error(error)
      })
    `}</SyntaxHighlighter>

        <p>
          In response you will receive a JSON object from pairs key-value. Use them to lay out a special form or in any other convenient way. Here is a list of everything that BinKing can identify by the number of a bank card:
        </p>

        <SyntaxHighlighter style={github} language="javascript">{`{
  // Bank name in English without spaces + country code
  bankAlias: "ru-sberbank",

  // Bank name in English
  bankName: "Sberbank",

  // Bank name in local language
  bankLocalName: "Sberbank of Russia",

  // Link to a large colored bank logo
  bankLogoBigOriginalSvg: "https://static.binking.io/banks-logos/c4902a868555835e.svg",
  bankLogoBigOriginalPng: "https://static.binking.io/banks-logos/01be7d8dcbb14510.png",

  // Big white bank logo
  bankLogoBigInvertedSvg: "https://static.binking.io/banks-logos/dd12813fcdc36407.svg",
  bankLogoBigInvertedPng: "https://static.binking.io/banks-logos/0e73c94e945364bc.png",

  // A small colored bank logo that fits into a square,
  bankLogoSmallOriginalSvg: "https://static.binking.io/banks-logos/3dc348ddd7af05f0.svg",
  bankLogoSmallOriginalPng: "https://static.binking.io/banks-logos/efe5e16478056787.png",

  // Small white bank logo
  bankLogoSmallInvertedSvg: "https://static.binking.io/banks-logos/0d4639f082f52d37.svg",
  bankLogoSmallInvertedPng: "https://static.binking.io/banks-logos/5310e5d14dbf7c17.png",

  // Main color of the bank
  bankColor: "#1a9f29",

  // All colors of the bank
  bankColors: ["#1a9f29"],

  // The country in which the bank operates
  bankCountry: "ru",

  // Link to website of the bank
  bankSite: "https://www.sberbank.ru",

  // Bank phone number
  bankPhone: "8 800 555-55-50",

  // Color for background of the form
  formBackgroundColor: "#1a9f29",

  // Colors for the gradient form of background
  formBackgroundColors: ["#1a9f29", "#0d7518"],

  // The background is rather dark "dark" or light "light"
  formBackgroundLightness: "dark",

  // Text color that will look good on the background of the form
  formTextColor: "#ffffff",

  // Colour of borders for fields in the form
  formBorderColor: "#ffffff",

  // Link to a large bank logo that will appear attractive at the background of the form
  formBankLogoBigSvg: "https://static.binking.io/banks-logos/dd12813fcdc36407.svg",
  formBankLogoBigPng: "https://static.binking.io/banks-logos/0e73c94e945364bc.png",

  // Link to a small bank logo that will appear attractive at the background of the form
  formBankLogoSmallSvg: "https://static.binking.io/banks-logos/0d4639f082f52d37.svg",
  formBankLogoSmallPng: "https://static.binking.io/banks-logos/5310e5d14dbf7c17.png",

  // Colour scheme of a logo for forms "original" or "inverted" 
  formLogoScheme: "inverted",
}
`}</SyntaxHighlighter>

        <p>
          If the bank was not found, then the response body will contain <code>null</code>.
        </p>

        <h2>Acquisition of information about specific bank </h2>

        <p>
          This request may be useful for displaying saved user cards. When a user enters the card details for the first time, write the bank alias <code>bankAlias</code> in your database, and use it later to receive information about his bank.
        </p>

        <p>
          Send a GET request to <code>https://api.binking.io/bank</code> with the following parameters:
        </p>
        <ul>
          <li>
            <code>apiKey</code> (required) - your API key
          </li>
          <li>
            <code>bankAlias</code> (required) — bank alias
          </li>
          <li>
            <code>fields</code> - a list of fields, separated by commas, that must be returned. If left blank, all fields will be returned.
          </li>
          <li>
            <code>sandbox</code> if you send "1", then the request will be free of charge, the bank will be identified only if you send one of 2 values to bankAlias:
            <code>ru-sberbank</code>, <code>ru-rosbank</code>.
          </li>
        </ul>

        <SyntaxHighlighter
          style={github}
          language="javascript"
        >{`fetch('https://api.binking.io/bank?apiKey=YOUR_API_KEY&bankAlias=ru-sberbank&sandbox=1')
`}</SyntaxHighlighter>

        <p>The structure of the response is exactly the same as when requesting https://api.binking.io/form</p>

        <p>
          If the bank was not found, then the body of response will contain <code>null</code>.
        </p>

        <h2>Acquisition of information about list of banks </h2>

        <p>Send a GET request to <code>https://api.binking.io/banks</code> with the following parameters:
        </p>
        <ul>
          <li>
            <code>apiKey</code> (required) — your API key
          </li>
          <li>
            <code>banksAliases</code> (required) — bank aliases separated by commas without spaces
          </li>
          <li>
            <code>fields</code> -  list of fields, separated by commas, that must be returned. If left blank, all fields will be returned.
          </li>
          <li>
            <code>sandbox</code> - if you send "1", then the request will be free of charge, the bank will be identified only if the following values are sent to banksAliases: ru-sberbank, ru-rosbank.
          </li>
        </ul>

        <SyntaxHighlighter
          style={github}
          language="javascript"
        >{`fetch('https://api.binking.io/banks?apiKey=YOUR_API_KEY&banksAliases=ru-sberbank,ru-rosbank&sandbox=1')
`}</SyntaxHighlighter>

        <p>
          In response you will receive a JSON array of objects. The structure of each individual object is exactly the same as by request to <code>https://api.binking.io/form</code>
        </p>

        <p>
          If the bank was not found, then in its place in the array will be <code>null</code>.
        </p>
      </RichText>
    </MainSegment>
  )
}

export default DocsApiPage
