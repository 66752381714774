import React, { useState, useRef } from 'react'
import css from './styles.module.scss'
import useMutation from 'lib/useMutation'
import getUploadUrl from 'lib/getUploadUrl'
import Button, { Buttons } from 'components/Button'
import cn from 'classnames'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const UploadPngLogo = ({ label, formik, name, hint, disabled, attention, accept }) => {
  const inputEl = useRef()
  const i18n = useLang(lang)
  const [loading, setLoading] = useState(false)
  const [uploadPngLogo] = useMutation('uploadPngLogo')
  const error = formik.errors[name]
  const value = formik.values[name]
  const touched = formik.touched[name]
  return (
    <div className={cn({ [css.uploadLogo]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
          {!attention ? null : (
            <>
              {' '}
              <span className={css.attention}>{attention === true ? i18n('attention') : attention}</span>
            </>
          )}
        </label>
      )}
      {!!value &&
        !loading && (
          <div className={css.previewPlace}>
            <img className={css.preview} alt="" src={getUploadUrl(value)} />
          </div>
        )}
      <div className={css.buttons}>
        <Buttons>
          <label className={css.uploadButton}>
            <input
              className={css.fileInput}
              type="file"
              disabled={loading || disabled}
              accept={accept}
              ref={el => (inputEl.current = el)}
              onChange={async ({
                target: {
                  validity,
                  files: [file],
                },
              }) => {
                setLoading(true)
                try {
                  const { uploadedPngFile } = await uploadPngLogo({
                    variables: { input: { file } },
                  })
                  formik.setFieldValue(name, uploadedPngFile.path)
                } catch (err) {
                  console.error(err)
                  formik.setFieldError(name, err.humanMessage || err.message)
                } finally {
                  formik.setFieldTouched(name, true, true)
                  setLoading(false)
                  inputEl.current.value = ''
                }
              }}
            />
            <Button
              type="button"
              loading={loading}
              disabled={loading || disabled}
              color="gray"
              asSpan
            >
              {value ? i18n('upLoadAny') : i18n('upLoad')}
            </Button>
          </label>
          {!!value &&
            !loading && (
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  formik.setFieldValue(name, '')
                  formik.setFieldError(name, false)
                  formik.setFieldTouched(name, true, true)
                }}
                disabled={disabled}
              >
                {i18n('remove')}
              </Button>
            )}
        </Buttons>
      </div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default UploadPngLogo
