import React from 'react'
import MainSegment from 'components/MainSegment'
import PageWrapper from 'components/PageWrapper'
import BanksLogs from 'components/BanksLogs'
// import css from './styles.module.scss'
import { canViewBanksLogs } from 'lib/can'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const BanksLogsPage = ({ me, history }) => {
  const i18n = useLang(lang)
  return (
    <MainSegment title={i18n('title')}>
      <Helmet title={i18n('title')} />
      <BanksLogs />
    </MainSegment>
  )
}

export default props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canViewBanksLogs(me)}>
    {({ me }) => <BanksLogsPage me={me} {...props} />}
  </PageWrapper>
)
