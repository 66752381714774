import React from 'react'
import PagePadding from 'components/PagePadding'
import MainSegment from 'components/MainSegment'
import Layout from 'components/Layout'
import RichText from 'components/RichText'
import Helmet from 'components/Helmet'
import Link from 'components/Link'
import useGlobalState from 'lib/globalState'
import { Deprecated } from 'components/Deprecated'

const PrivacyPolicyPage = () => {
  const [globalState] = useGlobalState()
  const langName = globalState.lang
  return langName === 'ru' ? (
    <Layout perple={true} minimal={true}>
      <Helmet title="Политика конфиденциальности" />
      <PagePadding>
        <MainSegment title="Политика конфиденциальности">
          <RichText>
            <Deprecated />
            <p>
              Настоящая Политика конфиденциальности персональных данных (далее - Политика конфиденциальности) действует
              в отношении всей информации, размещенной на сайте в сети Интернет по адресу: binking.io (далее - Сайт),
              которую ИП «Дмитриев Сергей Игоревич» (ОГРН 317631300071877, ИНН 631110961004, адрес места нахождения: г.
              Самара, ул. Магнитогорская, д. 1, кв. 122, 443013) (далее – Администрация Сайта) может получить от
              посетителей Сайта (далее – Пользователи) во время использования Сайта, его сервисов, программ и продуктов.
            </p>
            <p>
              Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и
              указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями
              Пользователь должен воздержаться от использования сервисов.
            </p>
            <h2>1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
            <ul>
              <li>1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:</li>
              <li>
                1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации
                (создании учетной записи) или в процессе использования Сервисов, включая персональные данные
                Пользователя: адрес электронной почты, фамилия и имя, номер телефона, номер банковской карты, код CVC и
                срок действия банковской карты, логин и пароль для доступа на Сайт. Обязательная для предоставления
                Сервисов информация помечена специальным образом. Иная информация предоставляется Пользователем на его
                усмотрение.
              </li>
              <li>
                1.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью
                установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов
                cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ
                к сервисам), технические характеристики оборудования и программного обеспечения, используемых
                Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация.
              </li>
              <li>
                1.1.3. Иная информация о Пользователе, обработка которой предусмотрена Пользовательским соглашением.
              </li>
              <li>
                1.1.4. Настоящая Политика конфиденциальности применяется только к Сайту binking.io . Сайт binking.io не
                контролирует и не несет ответственности за 1 сайты третьих лиц, на которые Пользователь может перейти по
                ссылкам, доступным на Сайте binking.io .
              </li>
              <li>
                1.1.5. Нажатие кнопки «ок» на электронном уведомлении об использовании сookie-файлов и продолжение
                пользования Сайтом означает, что Пользователь Сайта дал согласие на обработку данных, указанных в п.
                1.1.2 Политики. Пользователь Сайта вправе не давать свое согласие на обработку этих данных. В этом
                случае он должен покинуть Сайт либо изменить в настройках возможность собирать Сайтом сookie-файлы.
              </li>
              <li>
                1.1.6. Персональные данные, указанные в пункте 1.1.1 Политики, могут быть переданы как на бумажных
                носителях, так и посредством заполнения электронной формы на Сайте, и с использованием иных электронных
                средств связи.
              </li>
              <li>
                1.1.7. Персональные данные в виде номера банковской карты, кода CVC и срока действия банковской карты не
                хранятся Администрацией Сайта, а передаются третьим лицам с целью совершения платежей.
              </li>
            </ul>
            <h2>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h2>
            <ul>
              <li>
                2.1. Администрация Сайта собирает и хранит только ту персональную информацию, которая необходима для
                предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев,
                когда законодательством предусмотрено обязательное хранение персональной информации в течение
                определенного законом срока.
              </li>
              <li>2.2. Персональную информацию Пользователя Администрация Сайта обрабатывает в следующих целях:</li>
              <li>
                2.2.1. Идентификации Пользователя, зарегистрированного на Сайте, создания личного кабинета, для
                предоставления возможности использования ресурсов Сайта.
              </li>
              <li>
                2.2.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта, включая, но не
                ограничиваясь: предоставление базы данных, API и плагинов.
              </li>
              <li>
                2.2.3. Возможности осуществления Пользователем оплат (в том числе, банковских переводов) на Сайте.
              </li>
              <li>
                2.2.4. Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
                касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.
              </li>
              <li>
                2.2.5. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения
                мошенничества.
              </li>
              <li>2.2.6. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</li>
              <li>
                2.2.7. Уведомления Пользователя Сайта о внесении изменений в настоящую Политику и/или Пользовательское
                соглашение.
              </li>
              <li>
                2.2.8. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении
                проблем, связанных с использованием Сайта.
              </li>
              <li>2.2.9. Для аналитики действий Пользователя на Сайте и для функционирования Сайта.</li>
              <li>2.2.10. Осуществления рекламной деятельности с согласия Пользователя.</li>
            </ul>
            <h2>3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ И ЕЕ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ</h2>
            <ul>
              <li>
                3.1. Администрация Сайта хранит персональную информацию Пользователей в соответствии с внутренними
                регламентами конкретных сервисов.
              </li>
              <li>
                3.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев
                добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу
                лиц. При использовании отдельных сервисов Пользователь соглашается с тем, что определенная часть его
                персональной информации становится общедоступной.
              </li>
              <li>
                3.3. Администрация Сайта вправе передать персональную информацию Пользователя третьим лицам в следующих
                случаях:
              </li>
              <li>3.3.1. Пользователь выразил согласие на такие действия.</li>
              <li>
                3.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения
                определенного соглашения или договора с Пользователем, в том числе для совершения платежей.
              </li>
              <li>
                3.3.4. Передача предусмотрена российским или иным применимым законодательством в рамках установленной
                законодательством процедуры.
              </li>
              <li>
                3.3.5. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий
                настоящей Политики применительно к полученной им персональной информации.
              </li>
              <li>
                3.4. Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным
                способом, в том числе в информационных системах персональных данных с использованием средств
                автоматизации или без использования таких средств. Обработка персональных данных Пользователей
                осуществляется в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».
              </li>
              <li>
                3.5. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об
                утрате или разглашении персональных данных.
              </li>
              <li>
                3.6. Администрация Сайта принимает необходимые организационные и технические меры для защиты
                персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
              </li>
              <li>
                3.7. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению
                убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных
                Пользователя.
              </li>
            </ul>
            <h2>4. ОБЯЗАТЕЛЬСТВА СТОРОН</h2>
            <ul>
              <li>4.1. Пользователь обязан:</li>
              <li>4.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.</li>
              <li>
                4.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной
                информации.
              </li>
              <li>4.2. Администрация Сайта обязана:</li>
              <li>
                4.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей Политике
                конфиденциальности.
              </li>
              <li>
                4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного
                письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо
                разглашение иными возможными способами переданных персональных данных Пользователя, за исключением
                предусмотренных настоящей Политикой конфиденциальности.
              </li>
              <li>
                4.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя
                согласно порядку, обычно используемому для защиты такого рода информации в существующем деловом обороте.
              </li>
              <li>
                4.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с
                момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа
                по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных
                персональных данных или неправомерных действий.
              </li>
            </ul>
            <h2>5. ПРАВА ПОЛЬЗОВАТЕЛЯ</h2>
            <ul>
              <li>
                5.1. Пользователь имеет право на получение информации, касающейся обработки его Персональных данных, в
                том числе содержащей:
              </li>
              <li>5.1.1. Подтверждение факта обработки Персональных данных Сайтом;</li>
              <li>5.1.2. Правовые основания и цели обработки Персональных данных;</li>
              <li>5.1.3. Способы обработки Персональных данных;</li>
              <li>
                5.1.4. Наименование и место нахождения Администрации Сайта, сведения о лицах, которые имеют доступ к
                Персональным данным или которым могут быть раскрыты Персональные данные на основании договора или на
                основании федерального закона;
              </li>
              <li>5.1.5. Обрабатываемые Персональные данные, относящиеся к соответствующему Пользователю;</li>
              <li>5.1.6. Сроки обработки Персональных данных, в том числе сроки их хранения;</li>
              <li>
                5.1.7. Наименование или ФИО и адрес лица, осуществляющего обработку Персональных данных по поручению
                Администрации Сайта, если обработка поручена или будет поручена такому лицу;
              </li>
              <li>5.1.8. Иные сведения, предусмотренные федеральными законами.</li>
              <li>
                5.2. Пользователь вправе требовать от Администрации Сайта уточнения его Персональных данных, их
                блокирования или уничтожения в случае, если Персональные данные являются устаревшими, неточными,
                незаконно полученными, а также принимать иные законные меры, необходимые для защиты своих прав.
              </li>
            </ul>
            <h2>6. ПРЕКРАЩЕНИЕ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
            <ul>
              <li>6.1. Администрация Сайта прекращает обработку персональных данных в следующих случаях:</li>
              <li>
                6.1.1. При наступлении условий прекращения обработки Персональных данных или по истечении установленных
                сроков;
              </li>
              <li>
                6.1.2. По достижении целей их обработки либо в случае утраты необходимости в достижении этих целей;
              </li>
              <li>
                6.1.3. По требованию Пользователя, если обрабатываемые Персональные данные являются неполными,
                устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели
                обработки;
              </li>
              <li>
                6.1.4. В случае отзыва Пользователем согласия на обработку его персональных данных или истечения срока
                действия такого согласия (если персональные данные обрабатываются исключительно на основании согласия
                Пользователя);
              </li>
              <li>6.1.5. В случае прекращения деятельности Администрации Сайта.</li>
              <li>
                6.2. Пользователь вправе отозвать согласие на обработку персональных данных путем направления
                письменного заявления по адресу: г. Самара, ул. Магнитогорская, д. 1, кв. 122, 443013 либо по
                электронной почте manager@binking.io.
              </li>
              <li>
                6.3. Администрация Сайта прекращает обработку персональных данных в случае отзыва согласия Пользователем
                в течение 30 календарных дней.
              </li>
            </ul>
            <h2>7. МЕРЫ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
            <ul>
              <li>
                7.1. Защите подлежат как информация, содержащая Персональные данные, на бумажных носителях, так и
                информация, содержащая Персональные данные, на электронных носителях.
              </li>
              <li>
                7.2. Администрация Сайта в целях защиты Персональных данных принимает меры, предусмотренные Федеральным
                законом от 27.07.06 № 152-ФЗ «О персональных данных», иными нормативными актами в области защиты
                Персональных данных и Политикой.
              </li>
              <li>
                7.3. Администрация Сайта принимает следующие меры, необходимые для обеспечения выполнения обязанностей,
                предусмотренных Федеральным законом от 27.07.06 № 152-ФЗ «О персональных данных»:
              </li>
              <li>7.3.1. Назначено лицо, ответственное за соблюдение законодательства о персональных данных;</li>
              <li>
                7.3.2. Изданы локальные акты по вопросам обработки и обеспечения безопасности персональных данных,
                включая Политику;
              </li>
              <li>
                7.3.3. Применяются организационные и технические меры по обеспечению безопасности персональных данных
                при их обработке в информационных системах персональных данных, необходимых для выполнения требований к
                защите персональных данных, исполнение которых обеспечивает установленные Правительством Российской
                Федерации уровни защищенности персональных данных;
              </li>
              <li>
                7.3.4. Сотрудники ознакомлены под роспись с Политикой, другими локальными актами, регулирующими
                обработку и защиту персональных данных, требованиями законодательства о персональных данных;
              </li>
              <li>7.3.5. Осуществляется внутренний контроль в сфере защиты персональных данных;</li>
              <li>7.3.6. Для всеобщего доступа на Сайте опубликована Политика.</li>
              <li>7.4. Меры по обеспечению безопасности персональных данных при их обработке:</li>
              <li>
                7.4.1. Определены угрозы безопасности персональных данных при их обработке в информационной системе
                персональных данных;
              </li>
              <li>
                7.4.2. Предприняты меры для хранения информации, содержащей Персональные данные, исключающие доступ к
                ним третьих лиц;
              </li>
              <li>
                7.4.3. Получены от сотрудников письменные обязательства о соблюдении конфиденциальности Персональных
                данных, ставших им известными в связи с должностными обязанностями, и соблюдения правил обработки этих
                Персональных данных;
              </li>
              <li>7.4.4. Контролируется соблюдение сотрудниками требований Политики;</li>
              <li>
                .4.5. Утвержден список лиц, имеющих право доступа в помещения, в которых хранятся персональные данные;
              </li>
              <li>
                7.4.6. Доступ к Персональным данным имеют только сотрудники, которым эти Персональные данные необходимы
                для выполнения их трудовых обязанностей. Доступ сотрудника к Персональным данным оформляется приказом
                единоличного исполнительного органа.
              </li>
              <li>
                7.5. Сотрудники, имеющие доступ к Персональным данным в связи с исполнением своих трудовых обязанностей,
                обязаны:
              </li>
              <li>
                7.5.1. Обеспечить исключение возможности доступа посторонних лиц к информации, содержащей Персональные
                данные;
              </li>
              <li>
                7.5.2. Обеспечить исключение ситуаций, при которых во время отсутствия сотрудника на его рабочем месте
                или в его персональном рабочем компьютере остаются в свободном для третьих лиц доступе Персональные
                данные, в том числе установить пароль для входа.
              </li>
              <li>
                7.6. При увольнении сотрудника, имеющего доступ к Персональным данным, документы и иные носители,
                содержащие Персональные данные, передаются другому сотруднику по приказу единоличного исполнительного
                органа.
              </li>
              <li>
                7.7. Документы и иные бумажные носители, содержащие Персональные данные, хранятся в запирающихся шкафах
                или сейфах, обеспечивающих защиту от несанкционированного доступа. Доступ к шкафу или сейфу имеют только
                сотрудники, допущенные к работе с Персональными данными в соответствии с приказом единоличного
                исполнительного органа.
              </li>
              <li>
                7.8. В конце рабочего дня все бумажные носители, содержащие Персональные данные, помещаются в шкафы или
                сейфы, обеспечивающие защиту от несанкционированного доступа.
              </li>
              <li>7.9. Защита доступа к электронным носителям с Персональными данными обеспечивается, в том числе:</li>
              <li>
                7.9.1. Организацией такого контроля доступа в помещение, в котором находятся электронные носители с
                Персональными данными, который исключает доступ к ним посторонних лиц;
              </li>
              <li>
                7.9.2. Использованием лицензированных антивирусных программ, не допускающих несанкционированный доступ к
                персональным данным;
              </li>
              <li>
                7.9.3. Разграничением прав доступа к файлам, содержащим Персональные данные, с использованием учетной
                записи, принадлежащей только сотрудникам, допущенным к работе с Персональными данными;
              </li>
              <li>7.9.4. Установлением паролей на компьютерах.</li>
              <li>
                7.10. Хранение Персональных данных осуществляется в форме, позволяющей определить Пользователя, не
                дольше, чем этого требуют цели обработки персональных данных, если срок хранения Персональных данных не
                установлен федеральным законом, договором, стороной которого или выгодоприобретателем по которому
                является Пользователь. Обрабатываемые Персональные данные подлежат уничтожению по достижении целей
                обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено
                федеральным законом.
              </li>
            </ul>
            <h2>8. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
            <ul>
              <li>
                8.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные
                Пользователем в связи с неправомерным использованием персональных данных, в соответствии с
                законодательством Российской Федерации.
              </li>
              <li>
                8.2. В случае утраты или разглашения конфиденциальной информации Администрация Сайта не несет
                ответственности, если данная конфиденциальная информация:
              </li>
              <li>8.2.1. Стала публичным достоянием до ее утраты или разглашения.</li>
              <li>8.2.2. Была получена от третьей стороны до момента ее получения Администрацией Сайта.</li>
              <li>8.2.3. Была разглашена с согласия Пользователя.</li>
            </ul>
            <h2>9. РАЗРЕШЕНИЕ СПОРОВ</h2>
            <ul>
              <li>
                9.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и
                Администрацией Сайта, обязательным является предъявление претензии (письменного предложения о
                добровольном урегулировании спора).
              </li>
              <li>
                9.2. Получатель претензии в течение 10 (десяти) календарных дней со дня получения претензии письменно
                уведомляет заявителя претензии о результатах рассмотрения претензии.
              </li>
              <li>
                9.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим
                законодательством Российской Федерации.
              </li>
              <li>
                9.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией Сайта
                применяется действующее законодательство Российской Федерации.
              </li>
            </ul>
            <h2>10. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>
            <ul>
              <li>
                10.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия
                Пользователя.
              </li>
              <li>
                10.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не
                предусмотрено новой редакцией Политики конфиденциальности.
              </li>
              <li>
                10.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по
                электронному адресу: <address>manager@binking.io</address>.
              </li>
            </ul>
          </RichText>
        </MainSegment>
      </PagePadding>
    </Layout>
  ) : (
    <Layout perple={true} minimal={true}>
      <Helmet title="Privacy Policy" />
      <PagePadding>
        <MainSegment title="Privacy Policy">
          <RichText>
            <Deprecated />
            <p>
              This Privacy Policy of personal data (hereinafter referred to as “ Privacy Policy”) applies to all and any
              information posted on the website on the Internet at the address: binking.io (hereinafter referred to as
              “Website”), which may be obtained by BINBASE LTD (ID 14298599, location address: 41 Florence Avenue, Hove,
              England, BN3 7GX) (hereinafter referred to as “Administration of Website”) from visitors of the Website
              (hereinafter referred to as Users) while using the Website, its services, programs and products. <br />
              The use of services of the Website means the unconditional consent of the User with this Policy and the
              terms of processing his personal information specified therein; in case of disagreement with these
              conditions, the User must refrain from using the services.
            </p>
            <h2>1. GENERAL PROVISIONS</h2>
            <ul>
              <li>1.1. Within the framework of this Policy, the User's personal information implies the following:</li>
              <li>
                1.1.1. Personal information that the User provides about himself independently by the registration (by
                establishment of account) or in the process of using the Services, including the User's personal data:
                email address, surname and first name, phone number, bank card number, CVC code and bank card expiration
                date, login and password to access the Website. The information, required for the provision of the
                Services is marked in a special way. Further information is provided by the User at his own discretion.
              </li>
              <li>
                1.1.2. The data that is automatically transmitted to the services of the Website in the course of their
                deployment using the software installed on the User's devices, including the IP address, the cookie
                data, information about the User's browser (or other program through which the services are accessed),
                technical characteristics of the equipment and software used by the User, the date and time of access to
                the services, addresses of the requested pages and other similar information.
              </li>
              <li>1.1.3. Other information about the User, which processing is envisaged by the User Agreement.</li>
              <li>
                1.1.4. This Privacy Policy applies only to the Website binking.io. The Website binking.io does not
                control and is not responsible for third-party Websites which the User can access via the links, posted
                on the Website binking.io.
              </li>
              <li>
                1.1.5. Pressing the "ok" button on the electronic notification of the use of cookies and further use of
                the Website means that the User of a Website has granted consent processing of the data, specified in
                clause 1.1.2 of the Policy. The Website user has the right to deny processing of this data. In this
                case, he must leave the Website or change in the settings the ability of a Website to collect cookies.
              </li>
              <li>
                1.1.6. Personal information, specified in clause 1.1.1 of this Policy, can be transferred both on paper
                and by filling out an electronic form on the Website, as well as via other electronic means of
                communication.
              </li>
              <li>
                1.1.7. Personal data in the form of a bank card number, CVC code and bank card validity period are not
                stored by the Website Administration, but are transferred to third parties in order to make the
                payments.
              </li>
            </ul>
            <h2>2. PURPOSES OF PROCESSING THE PERSONAL INFORMATION OF USERS</h2>
            <ul>
              <li>
                2.1. The Administration of Website collects and stores only that personal information, required for the
                provision of services or the execution of agreements and contracts with the User, with exception for
                cases when the legislation envisages mandatory storage of personal information for a period specified by
                law.
              </li>
              <li>
                2.2. Administration of Website processes the User's personal information for the following purposes:
              </li>
              <li>
                2.2.1. Identification of the User registered on the Website and creation of a personal account in order
                to provide an opportunity to use the resources of the Website.
              </li>
              <li>
                2.2.2. Provision to the User access to the personalized resources of the Website, including, but not
                limited to: a database, APIs and plugins.
              </li>
              <li>2.2.3. Possibilities for the User to make payments (including the bank transfers) on the Website.</li>
              <li>
                2.2.4. Establishment of a two-way communication with the User, including the sending of notifications
                and requests regarding the use of the Website, provision of services, processing of requests and
                applications from the User.
              </li>
              <li>2.2.5. Establishment of location of the User to ensure security and prevent fraud assaults.</li>
              <li>2.2.6. Confirmation of the accuracy and completeness of personal data, provided by the User.</li>
              <li>
                2.2.7. Notifications of a User of a Website about modifications in this Policy and / or User Agreement.
              </li>
              <li>
                2.2.8. Provision to the User with an efficient customer’ and technical support by occurrence of problems
                related to the use of the Website.
              </li>
              <li>2.2.9. In order to analyze the User's actions on the Website and to ensure its functioning.</li>
              <li>2.2.10. Performance of advertising activities upon consent of the User.</li>
            </ul>
            <h2>3. TERMS OF PROCESSING OF PERSONAL INFORMATION OF USERS AND ITS TRANSFER TO THIRD PARTIES</h2>
            <ul>
              <li>
                3.1. Administration of Website stores personal information of Users in accordance with internal
                regulations of specific services.{' '}
              </li>
              <li>
                3.2. Administration of Website carefully preserves confidentiality of personal information of a User
                except for the cases when the User voluntarily provides his personal information in the general access
                to an unlimited number of persons. When using certain services, the User agrees that a certain part of
                his personal information becomes publicly available.
              </li>
              <li>
                3.3. Administration of Website reserves the right to transfer the User's personal information to third
                parties in the following cases:
              </li>
              <li>3.3.1. The user has provided his consent hereto.</li>
              <li>
                3.3.2. The transfer is necessary for the User to use a certain service or to fulfil a certain agreement
                or contract with the User, among such for making the payments.
              </li>
              <li>
                3.3.3. The transfer is provided for by the UK or other applicable legislation in accordance with the
                procedure established by law.
              </li>
              <li>
                3.3.4. In the event of the sale of the Website, the purchaser acquires all obligations on compliance
                with the terms of this Policy in relation to the personal information received by him.
              </li>
              <li>
                3.4. The processing of the User's personal data is carried out within an unlimited period of time in any
                legal way, among such by the means of informational systems of personal data with or without automation
                tools. The processing of personal data of Users is carried out in accordance with the Federal Law of
                July 27, 2006 No. 152-FZ "On Personal Data".
              </li>
              <li>
                3.5. In case of loss or disclosure of personal data Administration of Website will notify the User about
                it.
              </li>
              <li>
                3.6. Administration of Website takes all necessary organizational and technical measures to protect the
                User's personal information from unauthorized or accidental access, destruction, modification, blocking,
                copying, distribution, as well as from other illegal actions of third parties.{' '}
              </li>
              <li>
                3.7. Administration of Website together with the User takes all necessary measures to prevent losses or
                other negative consequences, caused by the loss or disclosure of the User's personal data.
              </li>
            </ul>
            <h2>4. OBLIGATIONS OF PARTIES</h2>
            <ul>
              <li>4.1. The user undertakes to the following:</li>
              <li>4.1.1. Provide information about personal data, required to use the Website.</li>
              <li>
                4.1.2. Update and supplement the provided information about personal data in case of its modification.
              </li>
              <li>4.2. Administration of Website undertakes to the following:</li>
              <li>4.2.1. Use the information received solely for the purposes specified in this Privacy Policy.</li>
              <li>
                4.2.2. Ensure the storage of confidential information in secret, not disclose it without the prior
                written consent of the User, refrain from sale, exchange, publishing or disclosure in other possible
                ways the transferred personal data of the User, except for the cases, provided for in this Privacy
                Policy.
              </li>
              <li>
                4.2.3. Take precautions in order to protect the confidentiality of the User's personal data in
                accordance with the procedure, normally used to protect this kind of information in the existing
                business practice.
              </li>
              <li>
                4.2.4. Block the personal data related to the relevant User from the moment of application or request of
                a User or his legal representative or the authorized body for the protection of the rights of personal
                data subjects for the duration of verification period in case of detection of inaccurate personal data
                or illegal actions.
              </li>
            </ul>
            <h2>5. RIGHTS OF USER</h2>
            <ul>
              <li>
                5.1. The User has the right to receive information about processing of his Personal Data, among such the
                following:
              </li>
              <li>5.1.1. Confirmation of the fact of processing of Personal Data by the Website;</li>
              <li>5.1.2. Legal grounds and purposes for processing of Personal Data;</li>
              <li>5.1.3. Methods of processing of Personal data;</li>
              <li>
                5.1.4. The name and location of Administration of Website, information about the persons who have access
                to Personal Data or to whom Personal Data may be disclosed on the basis of an agreement or on the basis
                of federal law;
              </li>
              <li>5.1.5. Processed Personal Data related to the relevant User;</li>
              <li>5.1.6. Time terms of processing the Personal data, including the terms of their storage;</li>
              <li>
                5.1.7. Name or full name and address of the person who processes the Personal Data on behalf of
                Administration of Website ,in case if the processing is entrusted or will be entrusted to such person;
              </li>
              <li>5.1.8. Further information, specified in the Federal legislation.</li>
              <li>
                5.2. The User has the right to demand from Administration of Website specification of his Personal Data,
                their blocking or destruction if the Personal Data is outdated, inaccurate or illegally obtained, as
                well as undertake other legal measures, required to protect his rights.
              </li>
            </ul>
            <h2>6. TERMINATION OF PROCESSING OF PERSONAL DATA</h2>
            <ul>
              <li>6.1. Administration of Website stops processing the personal data in the following cases:</li>
              <li>
                6.1.1. Upon the occurrence of conditions for termination of processing of Personal Data or after the
                expiration of the established time frame;
              </li>
              <li>
                6.1.2. Upon reaching the goals of their processing or in case of loss of the need to achieve these
                goals;
              </li>
              <li>
                6.1.3. By the request of the User, in case if the processed Personal Data is incomplete, outdated,
                inaccurate, illegally obtained or is not necessary for the stated purpose of processing;
              </li>
              <li>
                6.1.4. In the event that the User withdraws consent to the processing of his personal data or the
                expiration of such consent (if personal data is processed solely on the basis of the User's consent);
              </li>
              <li>6.1.5. In case of termination of activities of Administration of Website.</li>
              <li>
                6.2. The User has the right to revoke his consent to the processing of personal data by sending a
                written request to the address: Samara, Magnitogorskaya Street, house 1, apt. 122, 443013 or by email{' '}
                <Link href={'manager@binking.io'}>manager@binking.io</Link> .
              </li>
              <li>
                6.3. Administration of Website stops processing the personal data in case of withdrawal of consent of a
                User within 30 calendar days.
              </li>
            </ul>
            <h2>7. MEASURES OF PROTECTION OF PERSONAL DATA</h2>
            <ul>
              <li>
                7.1. Both information containing the Personal Data in paper form and the information, which contains the
                Personal data in electronic form, are subject to protection.
              </li>
              <li>
                7.2. In order to protect the Personal Data Administration of Website takes measures provided for by
                Federal Law No. 152-FZ dated from 27.07.06 "On Personal Data", other regulations in the field of
                Personal Data protection and by this Policy.
              </li>
              <li>
                7.3. Administration of Website takes the following measures, required to ensure the fulfilment of
                obligations, stipulated by the Federal Law of 27.07.06 No. 152-FZ "On Personal Data":
              </li>
              <li>
                7.3.1. A person responsible for compliance with the legislation on personal data has been appointed;
              </li>
              <li>
                7.3.2. Local acts on processing and security of personal data have been issued, including this Policy;
              </li>
              <li>
                7.3.3. Administration adopts organizational and technical measures to ensure the security of personal
                data during their processing within personal data information systems, necessary to meet the
                requirements for the protection of personal data, the implementation of which is ensured by the levels
                of personal data protection, established by the Government of the UK;
              </li>
              <li>
                7.3.4. Employees have read, understood and signed this Policy and other local acts which regulate the
                processing and protection of personal data and the requirements of legislation on personal data;
              </li>
              <li>7.3.5. Internal control in the field of personal data protection is steadily implemented;</li>
              <li>7.3.6. The Policy has been published on the Website for general access.</li>
              <li>7.4. Measures to ensure the security of personal data during their processing:</li>
              <li>
                7.4.1. The threats to the security of personal data during its processing in the personal data
                information system have been identified;
              </li>
              <li>
                7.4.2. The measures to store information containing Personal Data which exclude an access by third
                parties have been taken;
              </li>
              <li>
                7.4.3. The written commitments from employees to respect the confidentiality of Personal Data that they
                became aware of in connection with their job duties, and to comply with the rules for processing this
                Personal Data have been received;
              </li>
              <li>7.4.4. Compliance by employees with the requirements of the Policy is monitored;</li>
              <li>
                7.4.5. A list of persons entitled to access to the premises in which personal data is stored has been
                approved;
              </li>
              <li>
                7.4.6. Only employees who need the Personal Data to perform their job duties have access to Personal
                Data. An employee's access to Personal Data is formalized by order of the sole executive body.
              </li>
              <li>
                7.5. Employees who have access to Personal Data in connection with the performance of their job duties
                are obliged to the following:
              </li>
              <li>
                7.5.1. Ensure the exclusion of the possibility of access of unauthorized persons to information
                containing the Personal Data;
              </li>
              <li>
                7.5.2. Ensure the exclusion of situations in which, during the absence of an employee at his workplace
                or by his personal work computer the Personal data remains freely available for third parties, including
                setting a password for entering.
              </li>
              <li>
                7.6. By dismissal of an employee who has access to Personal Data, the documents and other media
                containing the Personal Data are transferred to another employee by order of the sole executive body.
              </li>
              <li>
                7.7. The documents and other paper media, containing the Personal Data, are stored in lockers or safes
                that provide protection against unauthorized access. Only employees who are allowed to work with
                Personal Data in accordance with the order of the sole executive body have access to the closet or safe.
              </li>
              <li>
                7.8. At the end of the working day, all paper media containing Personal Data are placed in cabinets or
                safes that provide protection against unauthorized access.
              </li>

              <li>
                7.9. Protection of access to electronic media with Personal Data is provided, among others, by the
                following measures:
              </li>
              <li>
                7.9.1. Establishment of such access control to the room in which are stored the electronic media with
                Personal Data, which excludes an access to them by unauthorized persons;
              </li>
              <li>
                7.9.2. Utilization of licensed anti-virus programs that exclude an unauthorized access to personal data;
              </li>
              <li>
                7.9.3. Differentiation of access rights to files which contain the Personal Data by the means of an
                account which belong only to employees who are allowed to work with Personal Data;
              </li>
              <li>7.9.4. Setting of passwords on computers.</li>
              <li>
                7.10. The storage of Personal Data is ensured in a form that makes it possible to determine the User no
                longer than the purpose of processing personal data requires, unless the storage period for Personal
                Data is established by federal law or by agreement to which the User is a party or beneficiary. The
                processed Personal Data is subject to destruction upon achievement of the goals of processing or in case
                of loss of the need to achieve these goals, unless otherwise provided by federal law.
              </li>
            </ul>
            <h2>8. LIABILITY OF THE PARTIES</h2>
            <ul>
              <li>
                8.1. Administration of Website, which has not fulfilled its obligations, is liable for losses incurred
                by the User in connection with the unlawful use of personal data in accordance with the legislation of
                the UK.
              </li>
              <li>
                8.2. In case of loss or disclosure of confidential information Administration of Website will not bear
                responsibility if this confidential information:
              </li>
              <li>8.2.1. Became publicly accessible before its loss or disclosure.</li>
              <li>8.2.2. Was received from a third party before it was received by Administration of Website.</li>
              <li>8.2.3. Was disclosed upon consent of the User.</li>
            </ul>
            <h2>9. RESOLUTION OF DISPUTES</h2>
            <ul>
              <li>
                9.1. Before application to court with a claim on disputes, arising from the relationship between the
                Site User and the Site Administration, a Party must submit a claim (a written offer for a voluntary
                settlement of the dispute).
              </li>
              <li>
                9.2. The recipient of the claim within 10 (ten) calendar days from the date of receipt of the claim will
                notify the applicant of the claim in writing about the results of the consideration of the claim.
              </li>
              <li>
                9.3. In the case if an agreement has not been reached, the dispute will be referred to the court in
                accordance with the current legislation of the UK.
              </li>
              <li>
                9.4. The current legislation of the UK applies to this Privacy Policy and the relationship between the
                User and Administration of Website.
              </li>
            </ul>
            <h2>10. ADDITIONAL TERMS AND CONDITIONS.</h2>
            <ul>
              <li>
                10.1. Administration of Website has the right to make changes to this Privacy Policy without consent of
                the User.
              </li>
              <li>
                10.2. The new Privacy Policy comes into force from the moment of publishing on the Website, unless
                otherwise provided by the new edition of the Privacy Policy.
              </li>
              <li>
                10.3. Any suggestions or questions about this Privacy Policy should be reported to the email address:{' '}
                <Link href={'mailto:manager@binking.io'}>manager@binking.io </Link>
              </li>
            </ul>
          </RichText>
        </MainSegment>
      </PagePadding>
    </Layout>
  )
}

export default PrivacyPolicyPage
