import React from 'react'
import css from './styles.module.scss'

const Segment = ({ title, children, ...restProps }) => (
  <div className={css.segment} {...restProps}>
    {!!title && <h2 className={css.title}>{title}</h2>}
    {children && <div className={css.content}>{children}</div>}
  </div>
)

export default Segment
