const lang = {
  ru: {
    attention: 'Внимание',
    upLoadAny: 'Загрузить другой',
    upLoad: 'Загрузить',
    remove: 'Удалить',
  },
  en: {
    attention: 'Attention',
    upLoadAny: 'Upload another',
    upLoad: 'Upload',
    remove: 'Remove',
  }
}

export default lang
