import useGlobalState from 'lib/globalState'
import React from 'react'

export const Deprecated = () => {
  const [globalState] = useGlobalState()
  const lang = globalState.lang
  return lang === 'ru' ? (
    <p style={{ lineHeight: 1.4 }}>
      Сервис прекращает свою работу. API перестаёт работать 1 октября 2024 года, до этого момента будет работать даже
      при отрицательных балансах. Платные функции отключены. Архивы со всеми данными (бины, банки, логотипы) выложены в
      бесплатный доступ:{' '}
      <a href="https://github.com/svagcrew/binking/tree/master/archives" target="_blank">
        https://github.com/svagcrew/binking/tree/master/archives
      </a>{' '}
      Если вы раньше использовали API, скачайте архивы и используйте их вместо API.
    </p>
  ) : (
    <p style={{ lineHeight: 1.4 }}>
      The service is shutting down. The API will stop working on October 1, 2024, but will remain operational until
      then, even with negative balances. Paid features have been disabled. Archives with all the data (BINs, banks,
      logos) have been made freely available:{' '}
      <a href="https://github.com/svagcrew/binking/tree/master/archives" target="_blank">
        https://github.com/svagcrew/binking/tree/master/archives
      </a>{' '}
      If you previously used the API, download the archives and use them instead of the API.
    </p>
  )
}
