const lang = {
  ru: {
    errorCodes: 'Пользователь с таким адресом электронной почты уже существует',
    emailErr: 'Некорректный адрес электронной почты',
    required: 'Обязательное поле',
    passErr: 'Пароли не совпадают',
    title: 'Регистрация',
    login: 'Вход',
    regLink: 'Регистрация',
    email: 'Адрес электронной почты',
    country: 'Страна',
    countryHit: 'Выберите основную страну, где ваша компания оказывает услуги',
    pass: 'Пароль',
    confirmPass: 'Подтвердите пароль',
    registrations: 'Зарегистрироваться',
  },
  en: {
    errorCodes: 'User with this email address already exists',
    emailErr: 'Incorrect email address',
    required: 'Required field',
    passErr: 'Passwords do not match',
    title: 'Registration',
    login: 'Login',
    regLink: 'Registration',
    email: 'E-mail address',
    country: 'Country',
    countryHit: 'Select the main country where your company provides services',
    pass: 'Password',
    confirmPass: 'Confirm the password',
    registrations: 'Register',
  }
}

export default lang
