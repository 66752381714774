import React from 'react'
import Textarea from 'components/Textarea'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import { isUrl } from 'lib/validation'
import useMutation from 'lib/useMutation'
import textToArray from 'lib/textToArray'
import trimEnd from 'lodash/trimEnd'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ChangeApiUrls = ({ me }) => {
  const i18n = useLang(lang)
  const [changeMyApiUrls] = useMutation('changeMyApiUrls')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      apiUrls: me.apiUrls ? me.apiUrls.join('\n') : '',
    },
    onSubmit: ({ apiUrls }) => {
      const urlsArray = textToArray(apiUrls).map(url => trimEnd(url, '/'))
      return changeMyApiUrls({
        variables: { input: { apiUrls: urlsArray } },
      })
    },
    validate: values => {
      const errors = {}
      const apiUrls = textToArray(values.apiUrls)
      for (const apiUrl of apiUrls) {
        if (!isUrl(apiUrl)) {
          errors.apiUrls = `${i18n('error')} "${apiUrl}", ${i18n('url')}`
          break
        }
      }
      return errors
    },
    withPristine: true,
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: i18n('successMessage'),
  })
  return (
    <Form formik={formik}>
      <FormItems>
        <Textarea
          label={i18n('urlList')}
          name="apiUrls"
          hint={i18n('newUrl')}
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button {...buttonProps}>{i18n('save')}</Button>
      </FormItems>
    </Form>
  )
}

export default ChangeApiUrls
