import React from 'react'
import css from './styles.module.scss'

const MainSegment = ({ title, children, ...restProps }) => (
  <div className={css.mainSegment} {...restProps}>
    {!!title && <h2 className={css.title}>{title}</h2>}
    {children && <div className={css.content}>{children}</div>}
  </div>
)

export default MainSegment
