const lang = {
  ru: {
    title: 'Банки из базы бинов',
    name: 'Название',
    country: 'Страна',
    col: 'Количество бинов',
  },
  en: {
    title: 'Banks from database of bins',
    name: 'Name',
    country: 'Country',
    col: 'Number of bins',
  }
}

export default lang
