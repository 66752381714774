const lang = {
  ru: {
    api: 'Пополнение баланса прошло успешно',
    archive: 'Покупка архива прошла успешно',
    card: 'Привязка карты прошла успешно',
    error: 'Произошла ошибка',
    next: 'Продолжить',
  },
  en: {
    api: 'The balance refilled successfully',
    archive: 'Archive acquired  successfully',
    card: 'The card successfully binded',
    error: 'An error occured',
    next: 'Continue',
  }
}

export default lang
