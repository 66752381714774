import React, { useRef } from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import Tippy from '@tippyjs/react'
import { IMaskInput } from 'react-imask'
import 'tippy.js/dist/tippy.css'
import { useLang } from 'lib/i18n'
import lang from './lang.js'

const Input = ({
  label,
  formik,
  name,
  mask,
  hint,
  onChange,
  fullWidth,
  disabled,
  noName,
  card,
  attention,
  className,
  ...restProps
}) => {
  const i18n = useLang(lang)
  const error = formik.errors[name]
  const value = formik.values[name]
  const touched = formik.touched[name]
  const inputRef = useRef()
  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled, [css.card]: card, [className]: !!className })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
          {!attention ? null : (
            <>
              {' '}
              <span className={css.attention}>{attention === true ? i18n('attention') : attention}</span>
            </>
          )}
        </label>
      )}
      <Tippy content={error} visible={!!error && touched && card} theme="custom">
        <div>
          <IMaskInput
            mask={mask}
            inputRef={el => (inputRef.current = el)}
            {...(mask
              ? {
                  onAccept: value => {
                    if (onChange) onChange(value)
                    formik.setFieldValue(name, inputRef.current.type === 'number' ? parseFloat(value) : value)
                  },
                }
              : {
                  onChange: e => {
                    if (onChange) onChange(e.target.value)
                    formik.setFieldValue(name, e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value)
                  },
                })}
            onBlur={() => {
              formik.setFieldTouched(name)
            }}
            name={!noName ? name : null}
            value={value}
            className={cn({
              [css.input]: true,
              [css.fullWidth]: !!fullWidth,
              [css.invalid]: !!error && touched,
            })}
            disabled={disabled}
            {...restProps}
          />
        </div>
      </Tippy>
      {!!error && touched && !card && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default Input
