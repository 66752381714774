const lang = {
  ru: {
    noUseApi: 'Вы ещё не использовали API',
    date: 'Дата',
    colQuery: 'Количество запросов',
    spent: 'Потрачено',
    showAll: 'Показать ещё',
  },
  en: {
    noUseApi: `You haven't used the API yet`,
    date: 'Date',
    colQuery: 'Number of queries',
    spent: 'Spent',
    showAll: 'Show more',
  }
}

export default lang
