import React, { useEffect, useRef, useState } from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import Checkbox, { Checkboxes } from 'components/Checkbox'
import useMutation from 'lib/useMutation'
import toMoney from 'lib/toMoney'
import redirectPost from 'lib/redirectPost'
import _ from 'lodash'
import binking from 'lib/binking'
import css from './styles.module.scss'
import cn from 'classnames'
import useQuery from '../../lib/useQuery'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import { useApolloClient } from '@apollo/react-hooks'
import { Deprecated } from 'components/Deprecated'

const ReplenishBalance = ({ temp, me }) => {
  // const { usdPrice } = useQuery('getUsdPrice')
  // const i18n = useLang(lang)
  // const checkout = useRef()
  // const formEl = useRef()
  // const [replenishBalance] = useMutation('replenishBalance', {
  //   refetchQueries: ['getTxs', 'getMe'],
  // })
  // const apolloClient = useApolloClient()
  // const { formik, buttonProps, alertProps } = useForm({
  //   initialValues: {
  //     // cardNumber: '4242424242424242',
  //     // month: '12',
  //     // year: '24',
  //     // code: '123',
  //     // name: 'Sergey X',
  //     cardNumber: '',
  //     month: '',
  //     year: '',
  //     code: '',
  //     name: '',
  //     saveCard: true,
  //     savedCard: (me.bankCards && me.bankCards.length && me.bankCards[0].hash) || 'new',
  //     amount: me.autoReplenishmentSum,
  //   },
  //   onSubmit: async ({ amount, saveCard, name, savedCard }) => {
  //     let result
  //     if (savedCard === 'new') {
  //       const checkoutResult = checkout.current.createCryptogramPacket()
  //       if (!checkoutResult.success) {
  //         throw new Error(checkoutResult.messages[_.keys(checkoutResult.messages)[0]])
  //       }
  //       result = await replenishBalance({
  //         variables: {
  //           input: {
  //             amount,
  //             saveCard,
  //             cardCryptogramPacket: checkoutResult.packet,
  //             name,
  //           },
  //         },
  //       })
  //     } else {
  //       result = await replenishBalance({
  //         variables: {
  //           input: {
  //             amount,
  //             hash: savedCard,
  //           },
  //         },
  //       })
  //     }
  //     if (result.success) {
  //       await apolloClient.reFetchObservableQueries()
  //       return !temp
  //     }
  //     if (result.acsUrl) {
  //       return new Promise(function() {
  //         redirectPost(result.acsUrl, {
  //           PaReq: result.paReq,
  //           MD: result.transactionId,
  //           TermUrl:
  //             process.env.REACT_APP_BACKEND_URI +
  //             '/cloudpayments/acs?redirectUrl=' +
  //             encodeURIComponent(window.location.href),
  //         })
  //       })
  //     }
  //     throw new Error('Something went wrong')
  //   },
  //   validate: values => {
  //     const errors = {}
  //     if (isNaN(parseInt(values.amount)) || +values.amount < 5) {
  //       errors.amount = i18n('amount')
  //     }
  //     if (values.savedCard === 'new') {
  //       if (!values.name) {
  //         errors.name = i18n('name')
  //       }
  //       const validationResult = binking.validate(values.cardNumber, values.month, values.year, values.code)
  //       for (const validationError of Object.values(validationResult.errors)) {
  //         errors[validationError.field] = validationError.message
  //       }
  //     }
  //     return errors
  //   },
  //   successMessage: i18n('successMessage'),
  //   resetOnSuccess: true,
  // })
  // useEffect(() => {
  //   if (formik.values.savedCard === 'new') {
  //     checkout.current =
  //       window.cp && new window.cp.Checkout(process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID, formEl.current)
  //   }
  // }, [formik.values.savedCard])
  // const [savedBankCardsBkResult, setSavedBankCardsBkResult] = useState({})
  // useEffect(() => {
  //   let savedBankCardsBkResult1 = {}
  //   for (const bankCard of me.bankCards) {
  //     // eslint-disable-next-line no-loop-func
  //     binking(bankCard.firstSix, result => {
  //       savedBankCardsBkResult1 = { ...savedBankCardsBkResult1, [bankCard.hash]: result }
  //       setSavedBankCardsBkResult(savedBankCardsBkResult1)
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.stringify(me.bankCards)])
  // const [bkResult, setBkResult] = useState(binking.defaultResult)
  // useEffect(() => {
  //   binking(formik.values.cardNumber, result => {
  //     setBkResult(result)
  //   })
  // }, [formik.values.cardNumber])
  return (
    <Deprecated />
    // <Form formik={formik} ref={el => (formEl.current = el)}>
    //   <FormItems>
    //     <Input
    //       label={i18n('sum')}
    //       name="amount"
    //       formik={formik}
    //       hint={
    //         usdPrice &&
    //         !isNaN(parseFloat(formik.values.amount)) &&
    //         toMoney({ sumRub: formik.values.amount, usdPrice, type: 'usd', decimalsCount: 2 })
    //       }
    //       type="number"
    //     />
    //     {!!me.bankCards.length && (
    //       <div className={css.savedCards}>
    //         {me.bankCards.map((bankCard, i) => (
    //           <div
    //             className={cn({ [css.savedCard]: true, [css.active]: formik.values.savedCard === bankCard.hash })}
    //             onClick={() => {
    //               formik.setFieldValue('savedCard', bankCard.hash)
    //             }}
    //             key={i}
    //           >
    //             <img
    //               alt=""
    //               src={
    //                 savedBankCardsBkResult[bankCard.hash] &&
    //                 savedBankCardsBkResult[bankCard.hash].bankLogoSmallOriginalSvg
    //               }
    //               className={css.bankLogo}
    //             />
    //             <img
    //               alt=""
    //               src={
    //                 savedBankCardsBkResult[bankCard.hash] && savedBankCardsBkResult[bankCard.hash].brandLogoOriginalSvg
    //               }
    //               className={css.brandLogo}
    //             />
    //             <span className={css.lastFour}>... {bankCard.lastFour}</span>
    //             <span className={css.expDate}>{bankCard.expDate}</span>
    //           </div>
    //         ))}
    //         <div
    //           className={cn({
    //             [css.savedCard]: true,
    //             [css.newCard]: true,
    //             [css.active]: formik.values.savedCard === 'new',
    //           })}
    //           onClick={() => {
    //             formik.setFieldValue('savedCard', 'new')
    //           }}
    //         >
    //           <div className={css.plus}></div>
    //           <div className={css.text}>{i18n('newCart')}</div>
    //         </div>
    //       </div>
    //     )}
    //     {formik.values.savedCard === 'new' && (
    //       <>
    //         <div className={css.card}>
    //           <div className={css.cardFront} style={{ backgroundColor: bkResult.formBackgroundColor }}>
    //             <div className={css.logoBox}>
    //               {bkResult.formBankLogoBigSvg && <img src={bkResult.formBankLogoBigSvg} alt="logo" />}
    //             </div>
    //             <Input
    //               name="cardNumber"
    //               autocomplete="cc-number"
    //               noName
    //               formik={formik}
    //               data-cp="cardNumber"
    //               placeholder="0000 0000 0000 0000"
    //               mask={bkResult.cardNumberMask}
    //               style={{ borderColor: bkResult.formBorderColor }}
    //               card
    //             />
    //             <div className={css.cardDate}>
    //               <Input
    //                 name="month"
    //                 style={{ borderColor: bkResult.formBorderColor }}
    //                 noName
    //                 formik={formik}
    //                 data-cp="expDateMonth"
    //                 placeholder="MM"
    //                 autocomplete="cc-exp-month"
    //                 mask={'00'}
    //                 card
    //               />
    //               <Input
    //                 name="year"
    //                 style={{ borderColor: bkResult.formBorderColor }}
    //                 noName
    //                 mask={'00'}
    //                 formik={formik}
    //                 data-cp="expDateYear"
    //                 placeholder="YY"
    //                 autocomplete="cc-exp-year"
    //                 card
    //               />
    //             </div>
    //             <div className={css.lastRow}>
    //               <Input
    //                 name="name"
    //                 style={{ borderColor: bkResult.formBorderColor }}
    //                 noName
    //                 formik={formik}
    //                 data-cp="name"
    //                 placeholder={i18n('nameCard')}
    //                 card
    //                 className={css.nameField}
    //               />
    //               {bkResult.formBrandLogoSvg && (
    //                 <img className={css.brandLogo} src={bkResult.formBrandLogoSvg} alt="logo" />
    //               )}
    //             </div>
    //           </div>
    //           <div className={css.cardBack}>
    //             <div className={css.inputPosition}>
    //               <Input
    //                 name="code"
    //                 autocomplete="cc-csc"
    //                 type="password"
    //                 noName
    //                 card
    //                 formik={formik}
    //                 placeholder={bkResult.codeName}
    //                 data-cp="cvv"
    //                 mask={'0000'}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //         <Checkboxes name="saveCard" formik={formik}>
    //           <Checkbox name="saveCard" type="boolean" label={i18n('saveCard')} bold formik={formik} />
    //         </Checkboxes>
    //       </>
    //     )}
    //     <Alert {...alertProps} />
    //     <Button {...buttonProps}>
    //       {formik.values.amount && !formik.errors.amount
    //         ? `${i18n('balance')} ${toMoney({
    //             sumRub: formik.values.amount,
    //             usdPrice,
    //             type: 'both',
    //             decimalsCount: 2,
    //           })}`
    //         : i18n('balanceUp')}
    //     </Button>
    //   </FormItems>
    // </Form>
  )
}

export default ReplenishBalance
