import React, { useState, useRef, useEffect, forwardRef } from 'react'
import { useFormik } from 'formik'
import css from './styles.module.scss'
import _ from 'lodash'
import { useLang } from 'lib/i18n'
import lang from './lang.js'

const Form = forwardRef(({ formik, ...restProps }, ref) => {
  return <form onSubmit={formik.handleSubmit} ref={ref} {...restProps} />
})

export const FormItems = ({ ...rerstProps }) => <div className={css.formItems} {...rerstProps} />

export const useForm = ({
  onSubmit,
  successMessage: successMessageOriginal,
  resetOnSuccess,
  showValidationAlert = false,
  validationMessageOriginal,
  forgetSuccessDelay = 5000,
  onSuccess,
  withPristine,
  originalUnknownErrors = true,
  autoSend = false,
  ...restProps
}) => {
  const i18n = useLang(lang)
  const successMessage = successMessageOriginal || i18n('success')
  const validationMessage = validationMessageOriginal || i18n('validationMessage')
  const successTimeout = useRef()
  useEffect(() => {
    return () => clearTimeout(successTimeout.current)
  }, [])
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessageHere, setSuccessMessage] = useState()
  const onSuccessHere = (...args) => {
    if (args[0] === false) {
      return
    }
    if (onSuccess) onSuccess(...args)
    setSuccess(true)
    setSuccessMessage(_.isFunction(successMessage) ? successMessage(...args) : successMessage)
    if (resetOnSuccess) {
      formik.handleReset()
    }
    if (forgetSuccessDelay) {
      clearTimeout(successTimeout.current)
      successTimeout.current = setTimeout(() => {
        setSuccess(false)
      }, forgetSuccessDelay)
    }
  }
  const onError = e => {
    setError(e)
  }
  const onSubmitHere = (values, ...args) => {
    const executionResult = onSubmit(values, ...args)
    const promise = executionResult && executionResult.then ? executionResult : Promise.resolve(executionResult)
    clearTimeout(successTimeout.current)
    setSuccess(false)
    setError(false)
    return promise.then(onSuccessHere).catch(onError)
  }
  const formik = useFormik({
    ...restProps,
    onSubmit: onSubmitHere,
  })
  let isAlertVisible = false
  let alertType
  let alertMessage
  if (success && !!successMessageHere && !formik.isSubmitting) {
    isAlertVisible = true
    alertType = 'success'
    alertMessage = successMessageHere
  }
  if (error && !formik.isSubmitting) {
    isAlertVisible = true
    alertType = 'danger'
    if (originalUnknownErrors && error.isUnknown) {
      alertMessage = error.message
    } else {
      alertMessage = error.humanMessage || error.message || error
    }
  }
  if (showValidationAlert && !!Object.keys(formik.errors).length && !!formik.submitCount && !formik.isSubmitting) {
    isAlertVisible = true
    alertType = 'danger'
    alertMessage = validationMessage
  }
  useEffect(() => {
    if (autoSend) {
      formik.submitForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formik.values)])
  return {
    error,
    success,
    formik,
    buttonProps: {
      loading: formik.isSubmitting,
      disabled: formik.isSubmitting || (withPristine && !formik.dirty),
      type: 'submit',
    },
    alertProps: {
      hidden: !isAlertVisible,
      type: alertType,
      message: alertMessage,
    },
  }
}

export default Form
