const lang = {
  ru: {
    err: 'Неверная почта или пароль',
    errMail: 'Некорректный адрес электронной почты',
    required: 'Обязательное поле',
    login: 'Вход',
    registrations: 'Регистрация',
    mail: 'Адрес электронной почты',
    pass: 'Пароль',
    forgot: 'Забыли пароль?',
  },
  en: {
    err: 'Invalid email or password',
    errMail: 'Incorrect email address',
    required: 'Required field',
    login: 'Login',
    registrations: 'Registration',
    mail: 'E-mail address',
    pass: 'Password',
    forgot: 'Forgot your password?',
  }
}

export default lang
