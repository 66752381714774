import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'

const Textarea = ({ label, formik, name, hint, onChange, fullWidth, attention, ...restProps }) => {
  const error = formik.errors[name]
  const value = formik.values[name]
  const touched = formik.touched[name]
  return (
    <div className={css.field}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
          {!attention ? null : (
            <>
              {' '}
              <span className={css.attention}>{attention === true ? 'Внимание' : attention}</span>
            </>
          )}
        </label>
      )}
      <textarea
        id={name}
        onChange={(...agrs) => {
          if (onChange) onChange(...agrs)
          formik.handleChange(...agrs)
        }}
        onBlur={formik.handleBlur}
        name={name}
        value={value}
        className={cn({
          [css.textarea]: true,
          [css.fullWidth]: !!fullWidth,
          [css.invalid]: !!error && touched,
        })}
        {...restProps}
      />
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default Textarea
