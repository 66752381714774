const withDecimalsNice = (sum, decimalsCount) => {
  const withDecimals4 = `${sum.toFixed(4)}`
  if (withDecimals4.slice(-1) === '0' || decimalsCount < 4) {
    const withDecimals3 = `${sum.toFixed(3)}`
    if (withDecimals3.slice(-1) === '0' || decimalsCount < 3) {
      const withDecimals2 = `${sum.toFixed(2)}`
      return withDecimals2
    } else {
      return withDecimals3
    }
  } else {
    return withDecimals4
  }
}

const toMoney = ({ sumRub, type, usdPrice = 75, sumUsd, decimalsCount = 4 }) => {
  const sumUsdExists = sumUsd !== undefined && sumUsd !== null
  const sumRubExists = sumRub !== undefined && sumRub !== null
  const typeHere = type || (!sumRubExists ? 'usd' : 'both')
  const sumUsdHere = sumUsdExists ? parseFloat(sumUsd) : sumRubExists ? parseFloat(sumRub) / usdPrice : 0
  const sumRubHere = sumRubExists ? parseFloat(sumRub) : sumUsdExists ? parseFloat(sumUsd) * usdPrice : 0
  const sumUsdNice = withDecimalsNice(sumUsdHere, decimalsCount)
  const sumRubNice = withDecimalsNice(sumRubHere, decimalsCount)
  if (typeHere === 'both') return `${sumUsdNice} $ (${sumRubNice} ₽)`
  if (typeHere === 'rub') return `${sumRubNice} ₽`
  if (typeHere === 'usd') return `${sumUsdNice} $`
  return `${sumUsdNice} $`
}

export default toMoney
