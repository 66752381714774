const lang = {
  ru: {
    error: 'Некорректный адрес электронной почты',
    required: 'Обязательное поле',
    successMessage: 'Перейдите по ссылке в письме, чтобы подтвердить смену почты',
    email: 'Новый адрес почты',
    changeEmail: 'Сменить почту',
  },
  en: {
    error: 'Incorrect email address',
    required: 'Required field',
    successMessage: 'Follow the link in the email to confirm the change of email',
    email: 'New mail address',
    changeEmail: 'Change mail',
  }
}

export default lang
