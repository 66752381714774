import React, { useState, useEffect } from 'react'
import { IMaskInput } from 'react-imask'
import Layout from 'components/Layout'
import Button, { Buttons } from 'components/Button'
import css from './styles.module.scss'
import Link from 'components/Link'
import DemoForm from 'components/DemoForm'
import cookieStore from 'lib/cookieStore'
import {
  getDocsApiRoute,
  getDocsArchiveRoute,
  getPluginUrl,
  getSignUpRoute,
  getArchiveSettingsRoute,
  getDocsBanksRoute,
  getApiSettingsRoute,
  getPriceRoute,
  getGuideRoute,
  getBankJSONRoute,
} from 'lib/routes'
import useQuery from 'lib/useQuery'
import plural from 'lib/plural'
import PageWrapper from 'components/PageWrapper'
import headerBackgroundPath from 'assets/bgOne.png'
import backgroundPathTwo from 'assets/bgTwo.png'
import backgroundPathTree from 'assets/bgTree.png'
import backgroundPathFour from 'assets/bgFour.png'
import backgroundPathFive from 'assets/bgFive.png'
import headerBackgroundPathTablet from 'assets/bgOneTablet.png'
import backgroundPathTwoTablet from 'assets/bgTwoTablet.png'
import headerBackgroundPathMobile from 'assets/bgOneMobile.png'
import backgroundPathTwoMobile from 'assets/bgTwoMobile.png'
import pluginImagePath from 'assets/animate/9.svg'
import apiImagePath from 'assets/animate/12.svg'
import archiveImagePath from 'assets/animate/13.svg'
import britain from 'assets/UK.png'
import usa from 'assets/USA.png'
import russia from 'assets/Russia.png'
import armenia from 'assets/Armenia.svg'
import canada from 'assets/Canada.png'
import ukraine from 'assets/Ukraine.svg'
import belarus from 'assets/Belarus.svg'
import germany from 'assets/Germany.svg'
import australia from 'assets/Australia.svg'
import italy from 'assets/Italy.svg'
import kazakhstan from 'assets/Kazakhstan.svg'
import sweden from 'assets/Sweden.svg'
import spain from 'assets/Spain.svg'
import france from 'assets/France.svg'
import india from 'assets/India.svg'
import austria from 'assets/Austria.svg'
import mexico from 'assets/Mexico.svg'
import israel from 'assets/Israel.svg'
import turkey from 'assets/Turkey.svg'
import japan from 'assets/Japan.svg'
import netherlands from 'assets/Netherlands.svg'
import china from 'assets/China.svg'
import brazil from 'assets/Brazil.svg'
import portugal from 'assets/Portugal.svg'
import uae from 'assets/UAE.svg'
import { ReactComponent as GiftIcon } from 'assets/gift.svg'
import { ReactComponent as BankIcon } from 'assets/bank.svg'
import { ReactComponent as MerchIcon } from 'assets/merch.svg'
import { ReactComponent as CartIcon } from 'assets/cart.svg'
import useWindowSize from 'lib/useWindowSize'
import binking from 'lib/binking'
import cn from 'classnames'
import Helmet from 'components/Helmet'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import toHumanCountry from '../../lib/toHumanCountry'
import toMoney from '../../lib/toMoney'
import _ from 'lodash'
import { getRequestsCountByMoney } from 'lib/getRequestsCountByMoney'

const HomePage = () => {
  const i18n = useLang(lang)
  const windowSize = useWindowSize()
  const tablet = windowSize.width <= 1100
  const mobile = windowSize.width <= 800
  const [bkResult, setBkResult] = useState(binking.defaultResult)
  const getSetCardNumberHandler = (cardNumber) => (e) => {
    e.preventDefault()
    if (!window.$cardNumberField || !window.cardNumberChangeHandler) return
    window.cardNumberMask.unmaskedValue = cardNumber
    window.cardNumberChangeHandler()
  }
  const { banksCountries } = useQuery('getBanksPublicInfo')
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (window.globalBkResult && !_.isEqual(bkResult, window.globalBkResult)) {
  //       setBkResult(window.globalBkResult)
  //     }
  //   }, 100)
  //   return () => clearInterval(interval)
  // }, [bkResult])
  const [dataExampleInputValue, setDataExampleInputValue] = useState('')
  const handleDataExampleInputValueChange = (value) => {
    setDataExampleInputValue(value)
    binking(value, (result) => {
      setBkResult(result)
    })
  }
  const queryResult = useQuery('getMe')
  const { usdPrice } = useQuery('getUsdPrice')
  const queryResult1 = useQuery('getMyIpCountry')
  const { ipCountry } = queryResult1
  useEffect(() => {
    let exampleCardNumber = '4023 3300 0000 0006'
    switch (ipCountry) {
      case 'UK':
        exampleCardNumber = '5381 4900 0000 0001'
        break
      case 'US':
        exampleCardNumber = '4017 4900 0000 0006'
        break
      case 'RU':
        exampleCardNumber = '4023 3300 0000 0006'
        break
      case 'AM':
        exampleCardNumber = '4890 9800 0000 0008'
        break
      default:
        exampleCardNumber = '5381 4900 0000 0001'
        break
    }
    handleDataExampleInputValueChange(exampleCardNumber)
  }, [ipCountry])
  return (
    <Layout>
      <Helmet />
      <PageWrapper queryResult={queryResult} meIncluded>
        {({ me }) => {
          const giftSum = (me && me.giftSum) || cookieStore.get('referralCode') === 'producthunt' ? 14 : 4
          return (
            <div className={css.mainPage}>
              <div className={css.backgroundOne}>
                <div className={css.background}>
                  <img
                    src={
                      mobile ? headerBackgroundPathMobile : tablet ? headerBackgroundPathTablet : headerBackgroundPath
                    }
                    alt="background"
                  />
                </div>
                <div className={css.intro}>
                  <h1 className={css.title}>{i18n('text1')}</h1>
                  <p className={css.desc}>{i18n('text2')}</p>
                </div>
                <div className={css.infoBlocks}>
                  <div className={css.item}>
                    <div className={css.text}>
                      <h2>API</h2>
                      <p>{i18n('text3')}</p>
                    </div>
                    <Button to={me ? getApiSettingsRoute() : getSignUpRoute(getApiSettingsRoute())}>
                      {i18n('text4')}
                    </Button>
                  </div>
                  <div className={css.item}>
                    <div className={css.text}>
                      <h2>{i18n('text5')}</h2>
                      <p>{i18n('text6')}</p>
                    </div>
                    <Button to={me ? getArchiveSettingsRoute() : getSignUpRoute(getArchiveSettingsRoute())}>
                      {i18n('text7')}
                    </Button>
                  </div>
                  <div className={css.item}>
                    <div className={css.text}>
                      <h2>{i18n('text8')}</h2>
                      <p>{i18n('text9')}</p>
                    </div>
                    <Button buttonStyle="git" href={getPluginUrl()}>
                      {i18n('text10')}
                    </Button>
                  </div>
                </div>
              </div>

              <div className={css.backgroundTwo}>
                <div className={css.background}>
                  <img
                    src={mobile ? backgroundPathTwoMobile : tablet ? backgroundPathTwoTablet : backgroundPathTwo}
                    alt="background"
                  />
                </div>
                <div className={css.example}>
                  <div className={css.formPlace}>
                    <h2 className={css.title}>{i18n('text11')}</h2>
                    <div className={css.exampleInfo}>
                      <div className={cn(css.item, css.itemOne)}>
                        <p>{i18n('text12')}</p>
                      </div>
                      <div className={cn(css.item, css.itemTwo)}>
                        <p>{i18n('text13')}</p>
                      </div>
                      <div className={cn(css.item, css.itemTree)}>
                        <p>{i18n('text14')}</p>
                      </div>
                      <div className={cn(css.item, css.itemFour)}>
                        <p>{i18n('text15')}</p>
                      </div>
                    </div>
                    <p className={css.desc}>
                      {i18n('text16')}{' '}
                      <Link type="dashed" href="#" onClick={getSetCardNumberHandler(i18n('firstBankCard'))}>
                        {i18n('text17')},
                      </Link>{' '}
                      <Link type="dashed" href="#" onClick={getSetCardNumberHandler(i18n('secondBankCard'))}>
                        {i18n('text18')},
                      </Link>{' '}
                      <Link type="dashed" href="#" onClick={getSetCardNumberHandler(i18n('thirdBankCard'))}>
                        {i18n('text19')}.
                      </Link>
                    </p>
                    <div className={css.form}>{!!ipCountry && <DemoForm ipCountry={ipCountry} />}</div>
                  </div>
                  <div className={css.guideLinks}>
                    <h3 className={css.title}>{i18n('text20')}</h3>
                    <p>{i18n('text21')}</p>
                    <Buttons>
                      <Button buttonStyle="secondYellow" to={getGuideRoute()}>
                        {i18n('text22')}
                      </Button>
                      <Button buttonStyle="secondYellow" target="_blank" href={'http://jsfiddle.net/iserdmi/9sj53x01/'}>
                        {i18n('text23')}
                      </Button>
                    </Buttons>
                  </div>
                </div>
              </div>

              <div className={css.dataPlace}>
                <div className={css.form}>
                  <h2 className={css.title}>{i18n('text24')}</h2>
                  <p className={css.desc}>
                    {i18n('text25')}{' '}
                    <Link
                      type="dashed"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        handleDataExampleInputValueChange(i18n('firstBankCard'))
                      }}
                    >
                      {i18n('text17')},
                    </Link>{' '}
                    <Link
                      type="dashed"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        handleDataExampleInputValueChange(i18n('secondBankCard'))
                      }}
                    >
                      {i18n('text18')},
                    </Link>{' '}
                    <Link
                      type="dashed"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        handleDataExampleInputValueChange(i18n('thirdBankCard'))
                      }}
                    >
                      {i18n('text19')}.
                    </Link>
                  </p>

                  <div className={css.inputBlock}>
                    <IMaskInput
                      mask={bkResult.cardNumberMask}
                      type="text"
                      value={dataExampleInputValue}
                      onAccept={(value) => {
                        handleDataExampleInputValueChange(value)
                      }}
                    />
                  </div>

                  <div className={css.info}>
                    <div className={css.item}>
                      <h4>API</h4>
                      <p>{i18n('text26')}</p>
                      <pre className={css.code}>
                        fetch('https://api.binking.io/form?apiKey=YOUR_API_KEY&cardNumber=123456') .then((bankData) =>{' '}
                        {'{'}console.log(bankData)
                        {'}'})
                      </pre>
                    </div>
                    <div className={css.item}>
                      <h4>{i18n('text27')}</h4>
                      <p>{i18n('text28')}</p>
                      <pre className={css.code}>
                        const banks = require('/path/to/banks.json'){'\n'}
                        const bins = require('/path/to/bins.json'){'\n'}
                        const bankAlias = bins['123456']
                        {'\n'}
                        const bankData = banks[bankAlias] console.log(bankData){'\n'}
                      </pre>
                    </div>
                    <div className={css.item}>
                      <h4>{i18n('text8')}</h4>
                      <p>{i18n('text29')}</p>
                      <pre className={css.code}>
                        binking('123456').then((bankAndBrandData) =>
                        {'{'}console.log(bankAndBrandData){'}'})
                      </pre>
                    </div>
                  </div>
                </div>
                <div className={css.options}>
                  <div className={css.optionsItem}>
                    <h3>{i18n('text30')}</h3>
                    <div className={css.flex}>
                      <div className={css.left}>
                        <div className={css.item}>
                          <h4>{i18n('text31')}</h4>
                          <p>
                            formBackgroundColor: <span>{JSON.stringify(bkResult.formBackgroundColor)}</span>
                          </p>
                          <div className={css.square} style={{ backgroundColor: bkResult.formBackgroundColor }}></div>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text32')}</h4>
                          <p>
                            formTextColor: <span>{JSON.stringify(bkResult.formTextColor)}</span>
                          </p>
                          <div className={css.square} style={{ backgroundColor: bkResult.formTextColor }}></div>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text33')}</h4>
                          <p>
                            formBorderColor: <span>{JSON.stringify(bkResult.formBorderColor)}</span>
                          </p>
                          <div className={css.square} style={{ backgroundColor: bkResult.formBorderColor }}></div>
                        </div>
                      </div>
                      <div className={css.right}>
                        <div className={css.item}>
                          <h4>{i18n('text34')}</h4>
                          <p>
                            formBankLogoBigSvg: <span>{JSON.stringify(bkResult.formBankLogoBigSvg)}</span>
                          </p>
                          <p>
                            formBankLogoBigPng: <span>{JSON.stringify(bkResult.formBankLogoBigPng)}</span>
                          </p>
                          {bkResult.formBankLogoBigSvg && (
                            <div className={css.logo} style={{ backgroundColor: bkResult.formBackgroundColor }}>
                              <img src={bkResult.formBankLogoBigSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text35')}</h4>
                          <p>
                            formBankLogoSmallSvg: <span>{JSON.stringify(bkResult.formBankLogoBigSvg)}</span>
                          </p>
                          <p>
                            formBankLogoSmallPng: <span>{JSON.stringify(bkResult.formBankLogoBigPng)}</span>
                          </p>
                          {bkResult.formBankLogoSmallSvg && (
                            <div className={css.logo} style={{ backgroundColor: bkResult.formBackgroundColor }}>
                              <img src={bkResult.formBankLogoSmallSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                        <div className={css.item}>
                          <h4>
                            {i18n('text36')} <span className={css.jsPlagin}>{i18n('text37')}</span>
                          </h4>
                          <p>
                            formBrandLogoSvg: <span>{JSON.stringify(bkResult.formBrandLogoSvg)}</span>
                          </p>
                          <p>
                            formBrandLogoPng: <span>{JSON.stringify(bkResult.formBrandLogoPng)}</span>
                          </p>
                          {bkResult.formBrandLogoSvg && (
                            <div className={css.logo} style={{ backgroundColor: bkResult.formBackgroundColor }}>
                              <img src={bkResult.formBrandLogoSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={css.optionsItem}>
                    <h3>{i18n('text38')}</h3>
                    <div className={css.flex}>
                      <div className={css.left}>
                        <div className={css.item}>
                          <h4>{i18n('text39')}</h4>
                          <p>
                            bankName: <span>{JSON.stringify(bkResult.bankName)}</span>
                          </p>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text40')}</h4>
                          <p>
                            bankLocalName: <span>{JSON.stringify(bkResult.bankLocalName)}</span>
                          </p>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text41')}</h4>
                          <p>
                            bankCountry: <span>{JSON.stringify(bkResult.bankCountry)}</span>
                          </p>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text42')}</h4>
                          <p>
                            bankSite: <span>{JSON.stringify(bkResult.bankSite)}</span>
                          </p>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text43')}</h4>
                          <p>
                            bankPhone: <span>{JSON.stringify(bkResult.bankPhone)}</span>
                          </p>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text44')}</h4>
                          <p>
                            bankColor: <span>{JSON.stringify(bkResult.bankColor)}</span>
                          </p>
                          {bkResult.bankColor && (
                            <div className={css.square} style={{ backgroundColor: bkResult.bankColor }}></div>
                          )}
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text45')}</h4>
                          <p>
                            bankColors: <span>{JSON.stringify(bkResult.bankColors)}</span>
                          </p>
                          {bkResult.bankColors && (
                            <div className={css.squares}>
                              {bkResult.bankColors.map((bankColor, i) => (
                                <div key={i} className={css.square} style={{ backgroundColor: bankColor }}></div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={css.right}>
                        <div className={css.item}>
                          <h4>{i18n('text46')}</h4>
                          <p>
                            bankLogoBigOriginalSvg: <span>{JSON.stringify(bkResult.bankLogoBigOriginalSvg)}</span>
                          </p>
                          <p>
                            bankLogoBigOriginalPng: <span>{JSON.stringify(bkResult.bankLogoBigOriginalPng)}</span>
                          </p>
                          {bkResult.bankLogoBigOriginalSvg && (
                            <div className={css.logo}>
                              <img src={bkResult.bankLogoBigOriginalSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text47')}</h4>
                          <p>
                            bankLogoBigInvertedSvg: <span>{JSON.stringify(bkResult.bankLogoBigInvertedSvg)}</span>
                          </p>
                          <p>
                            bankLogoBigInvertedPng: <span>{JSON.stringify(bkResult.bankLogoBigInvertedPng)}</span>
                          </p>
                          {bkResult.bankLogoBigInvertedSvg && (
                            <div className={css.logo} style={{ backgroundColor: '#cccccc' }}>
                              <img src={bkResult.bankLogoBigInvertedSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text48')}</h4>
                          <p>
                            bankLogoSmallOriginalSvg: <span>{JSON.stringify(bkResult.bankLogoSmallOriginalSvg)}</span>
                          </p>
                          <p>
                            bankLogoSmallOriginalPng: <span>{JSON.stringify(bkResult.bankLogoSmallOriginalPng)}</span>
                          </p>
                          {bkResult.bankLogoSmallOriginalSvg && (
                            <div className={css.logo}>
                              <img src={bkResult.bankLogoSmallOriginalSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text49')}</h4>
                          <p>
                            bankLogoSmallInvertedSvg: <span>{JSON.stringify(bkResult.bankLogoSmallInvertedSvg)}</span>
                          </p>
                          <p>
                            bankLogoSmallInvertedPng: <span>{JSON.stringify(bkResult.bankLogoSmallInvertedPng)}</span>
                          </p>
                          {bkResult.bankLogoSmallInvertedSvg && (
                            <div className={css.logo} style={{ backgroundColor: '#cccccc' }}>
                              <img src={bkResult.bankLogoSmallInvertedSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={css.optionsItem}>
                    <h3>
                      {i18n('text50')} <span className={css.jsPlagin}>{i18n('text51')}</span>
                    </h3>
                    <div className={css.flex}>
                      <div className={css.left}>
                        <div className={css.item}>
                          <h4>{i18n('text52')}</h4>
                          <p>
                            brandName: <span>{JSON.stringify(bkResult.brandName)}</span>
                          </p>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text53')}</h4>
                          <p>
                            codeName: <span>{JSON.stringify(bkResult.codeName)}</span>
                          </p>
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text54')}</h4>
                          <p>
                            codeMinLength: <span>{JSON.stringify(bkResult.codeMinLength)}</span>
                          </p>
                          <p>
                            codeMaxLength: <span>{JSON.stringify(bkResult.codeMaxLength)}</span>
                          </p>
                        </div>

                        <div className={css.item}>
                          <h4>{i18n('text55')}</h4>
                          <p>
                            cardNumberMask: <span>{JSON.stringify(bkResult.cardNumberMask)}</span>
                          </p>
                        </div>
                      </div>
                      <div className={css.right}>
                        <div className={css.item}>
                          <h4>{i18n('text56')}</h4>
                          <p>
                            brandLogoOriginalSvg: <span>{JSON.stringify(bkResult.brandLogoOriginalSvg)}</span>
                          </p>
                          <p>
                            brandLogoOriginalPng: <span>{JSON.stringify(bkResult.brandLogoOriginalPng)}</span>
                          </p>
                          {bkResult.brandLogoOriginalSvg && (
                            <div className={css.logo}>
                              <img src={bkResult.brandLogoOriginalSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                        <div className={css.item}>
                          <h4>{i18n('text57')}</h4>
                          <p>
                            brandLogoInvertedSvg: <span>{JSON.stringify(bkResult.brandLogoInvertedSvg)}</span>
                          </p>
                          <p>
                            brandLogoInvertedPng: <span>{JSON.stringify(bkResult.brandLogoInvertedPng)}</span>
                          </p>
                          {bkResult.brandLogoInvertedSvg && (
                            <div className={css.logo} style={{ backgroundColor: '#ccc' }}>
                              <img src={bkResult.brandLogoInvertedSvg} width="auto" height="48px" alt="logo" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.plugin}>
                <div className={css.background}>
                  <img src={backgroundPathTree} alt="background" />
                </div>
                <div className={css.container}>
                  <div className={css.text}>
                    <h2 className={css.title}>{i18n('text58')}</h2>
                    <p>{i18n('text59')}</p>
                    <p className={css.lastChild}>{i18n('text60')}</p>
                    <Button buttonStyle="git" href={getPluginUrl()}>
                      {i18n('text11')}
                    </Button>
                  </div>
                  <div className={css.image}>
                    <img src={pluginImagePath} width="318px" height="auto" alt="plugin" />
                  </div>
                </div>
              </div>

              <div className={css.api}>
                <div className={css.container}>
                  <div className={css.image}>
                    <img src={apiImagePath} width="573px" height="auto" alt="api" />
                  </div>
                  <div className={css.text}>
                    <h2 className={css.title}>{i18n('text61')}</h2>
                    <div className={css.gift}>
                      <GiftIcon />{' '}
                      {i18n('text62', toMoney({ sumUsd: giftSum, usdPrice }), getRequestsCountByMoney(giftSum))}
                    </div>
                    <p>{i18n('text63')}</p>
                    <p className={css.lastChild}>{i18n('text64')}</p>
                    <Buttons start>
                      <Button buttonStyle="yellow" to={getApiSettingsRoute()}>
                        {i18n('text65')}
                      </Button>

                      <Button buttonStyle="violet" to={getDocsApiRoute()}>
                        {i18n('text66')}
                      </Button>
                    </Buttons>
                  </div>
                </div>
              </div>

              <div className={css.archive}>
                <div className={css.background}>
                  <img src={backgroundPathFour} alt="background" />
                </div>
                <div className={css.container}>
                  <div className={css.text}>
                    <h2 className={css.title}>{i18n('text67')}</h2>
                    <p className={css.lastChild}>{i18n('text68')}</p>
                    <Buttons start>
                      <Button
                        buttonStyle="yellow"
                        to={me ? getArchiveSettingsRoute() : getSignUpRoute(getArchiveSettingsRoute())}
                      >
                        {i18n('text7')}
                      </Button>

                      <Button buttonStyle="violet" to={getDocsArchiveRoute()}>
                        {i18n('text69')}
                      </Button>
                    </Buttons>
                  </div>
                  <div className={css.image}>
                    <img src={archiveImagePath} width="519px" height="auto" alt="archive" />
                  </div>
                </div>
              </div>

              <div className={css.auditory}>
                <div className={css.container}>
                  <h2 className={css.title}>{i18n('text70')}</h2>
                  <p>{i18n('text71')}</p>
                  <div className={css.items}>
                    <div className={css.item}>
                      <div className={css.icon}>
                        <CartIcon />
                      </div>
                      <h3 className={css.title}>{i18n('text72')}</h3>
                      <p className={css.text}>{i18n('text73')}</p>
                    </div>
                    <div className={css.item}>
                      <div className={css.icon}>
                        <MerchIcon />
                      </div>
                      <h3 className={css.title}>{i18n('text74')}</h3>
                      <p className={css.text}>{i18n('text75')}</p>
                    </div>
                    <div className={css.item}>
                      <div className={css.icon}>
                        <BankIcon />
                      </div>
                      <h3 className={css.title}>{i18n('text76')}</h3>
                      <p className={css.text}>{i18n('text77')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.advantages}>
                <h2>{i18n('text78')}</h2>
                <div className={css.background}>
                  <img src={backgroundPathFive} alt="background" />
                </div>
                <div className={css.container}>
                  <div className={css.items}>
                    <div className={css.item}>
                      <h3>{i18n('text79')}</h3>
                      <p className={css.text}>{i18n('text80')}</p>
                    </div>
                    <div className={css.item}>
                      <h3>{i18n('text81')}</h3>
                      <p className={css.text}>{i18n('text82')}</p>
                    </div>
                    <div className={css.item}>
                      <h3>{i18n('text83')}</h3>
                      <p className={css.text}>{i18n('text84')}</p>
                    </div>
                  </div>
                  <div className={css.items}>
                    <div className={css.item}>
                      <h3>{i18n('text85')}</h3>
                      <p className={css.text}>{i18n('text86')}</p>
                    </div>
                    <div className={css.item}>
                      <h3>{i18n('text87')}</h3>
                      <p className={css.text}>{i18n('text88')}</p>
                    </div>
                    <div className={css.item}>
                      <h3>{i18n('text89')}</h3>
                      <p className={css.text}>{i18n('text90')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.dataBase}>
                <div className={css.container}>
                  <h2>{i18n('text91')}</h2>
                  <p className={css.desc}>{i18n('text92')}</p>
                  <div className={css.items}>
                    {banksCountries &&
                      _.sortBy(banksCountries, [
                        function (bankCountry) {
                          return toHumanCountry(bankCountry.countryCode)
                        },
                      ]).map((banksCountry, i) => (
                        <Link
                          className={css.item}
                          to={
                            banksCountry.banks.length
                              ? getDocsBanksRoute() + '#' + banksCountry.countryCode
                              : getDocsBanksRoute()
                          }
                          key={i}
                        >
                          <div className={css.flag}>
                            {banksCountry.countryCode === 'ru' ? (
                              <img src={russia} alt="ru" width="76px" height="auto" />
                            ) : banksCountry.countryCode === 'ca' ? (
                              <img src={canada} alt="ca" width="76px" height="auto" />
                            ) : banksCountry.countryCode === 'us' ? (
                              <img src={usa} alt="us" width="76px" height="auto" />
                            ) : banksCountry.countryCode === 'gb' ? (
                              <img src={britain} alt="gb" width="76px" height="auto" />
                            ) : banksCountry.countryCode === 'am' ? (
                              <img src={armenia} alt="am" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'ua' ? (
                              <img src={ukraine} alt="am" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'by' ? (
                              <img src={belarus} alt="by" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'de' ? (
                              <img src={germany} alt="de" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'it' ? (
                              <img src={italy} alt="it" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'au' ? (
                              <img src={australia} alt="au" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'kz' ? (
                              <img src={kazakhstan} alt="kz" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'se' ? (
                              <img src={sweden} alt="se" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'fr' ? (
                              <img src={france} alt="fr" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'es' ? (
                              <img src={spain} alt="es" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'in' ? (
                              <img src={india} alt="in" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'nl' ? (
                              <img src={netherlands} alt="nl" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'cn' ? (
                              <img src={china} alt="cn" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'at' ? (
                              <img src={austria} alt="at" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'jp' ? (
                              <img src={japan} alt="jp" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'il' ? (
                              <img src={israel} alt="il" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'tr' ? (
                              <img src={turkey} alt="tr" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'mx' ? (
                              <img src={mexico} alt="mx" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'br' ? (
                              <img src={brazil} alt="br" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'pt' ? (
                              <img src={portugal} alt="pt" width="76px" height="52px" />
                            ) : banksCountry.countryCode === 'ae' ? (
                              <img src={uae} alt="ae" width="76px" height="52px" />
                            ) : null}
                          </div>
                          <div className={css.text}>
                            <h3>{toHumanCountry(banksCountry.countryCode, i18n('lang'))}</h3>
                            <p>
                              {banksCountry.banks.length
                                ? `(${plural(
                                  banksCountry.banks.length,
                                  [i18n('bank1'), i18n('bank2'), i18n('bankMany')],
                                  true
                                )})`
                                : i18n('text93')}
                            </p>
                          </div>
                        </Link>
                      ))}
                  </div>

                  <div className={css.linkBox}>
                    <Link to={getDocsBanksRoute()} className={css.link}>
                      {i18n('text94')}
                    </Link>
                  </div>
                </div>
              </div>

              <div className={css.bankPartner}>
                <div className={css.container}>
                  <h2 className={css.title}>{i18n('text95')}</h2>
                  <p className={css.text}>{i18n('text96')}</p>
                  <div className={css.buttonPlace}>
                    <Button to={getBankJSONRoute()}>{i18n('text97')}</Button>
                  </div>
                </div>
              </div>

              {/* <div className={css.startupMakers}>
                <div className={css.container}>
                  <h2 className={css.title}>{i18n('startupTitle')}</h2>
                  <p className={css.text}>{i18n('startupDesc')}</p>
                  <div className={css.buttonPlace}>
                    <Button href="https://10.com.ru" target="_blank">
                      {i18n('startupButton')}
                    </Button>
                  </div>
                </div>
              </div> */}

              <div className={css.information}>
                <div className={css.container}>
                  <div className={css.item}>
                    <div className={css.text}>
                      <h3>{i18n('text98')}</h3>
                      <p>{i18n('text99')} </p>
                    </div>
                  </div>
                  <div className={css.item}>
                    <div className={css.text}>
                      <h3>{i18n('text100')}</h3>
                      <p>{i18n('text101')}</p>
                    </div>
                    <Button to={getPriceRoute()}>{i18n('text102')}</Button>
                  </div>
                  <div className={css.item}>
                    <div className={css.text}>
                      <h3>{i18n('text103')}</h3>
                      <p>{i18n('text104')}</p>
                    </div>
                    <Button to={getSignUpRoute()}>{i18n('text105')}</Button>
                  </div>
                </div>
              </div>

              {/*<div className={css.guide}>
              <h2 className={css.title}>Как создать такую форму?</h2>
              <div className={css.text}>
                <p>
                  Создание платёжной формы — это комплексная задача. BinKing помогает в её решении. Однако вам в любом
                  случае какую-то часть придётся сделать самостоятельно, потому что многое завязано на том, какие
                  технологии вы используете в своём проекте, и какой мерчант предоставляет вам услуги обработки
                  платежей.
                </p>
                <p>
                  Чтобы вам было как можно проще решить эту задачу, мы написали{' '}
                  <Link type="solid" to={getGuideRoute()}>
                    подробный гайд,
                  </Link>{' '}
                  где описываем процесс создания формы поэтапно. Рассказываем и показываем, как определять логотип
                  банка, как перекрасить фон формы в цвета банка, как сделать автоматический перенос курсора в следующее
                  поле, как сделать валидацию формы и много другое.
                </p>
                <p>
                  Гайд содержит полный пример кода, который можно просто скопировать к себе и использовать. При желании
                  вы легко сможете его доработать.
                </p>
              </div>
              <Button className={css.button} to={getGuideRoute()}>
                Читать гайд
              </Button>
            </div>
            <div className={css.price}>
              <h2 className={css.title}>Сколько стоит?</h2>
              <div className={css.apiAndArchive}>
                <div className={css.api}>
                  <h3 className={css.title}>API</h3>
                  <p className={css.text}>
                    До 1 000 запросов, 1 цент за запрос.
                    <br />
                    До 10 000 запросов, 0.5 цента за запрос.
                    <br />
                    Свыше 10 000 запросов, 0.2 цента за запрос.
                    <br />
                    На одного платящего пользователя достаточно всего 1 запроса к API.
                  </p>
                </div>
                <div className={css.archive}>
                  <h3 className={css.title}>Архив</h3>
                  <p className={css.text}>
                    Стоимость архива 700 $ за одну отдельную страну, или 2000 $ за все страны. Архив обновляется
                    ежемесячно. Ссылка доступна в течение года.
                  </p>
                </div>
              </div>
            </div>
            <div className={css.gift}>
              <h2 className={css.title}>Дарим 10$ на использование API</h2>
              <Button className={css.button} to={getSignUpRoute()}>
                Получить подарок
              </Button>
            </div>
            <div className={css.service}>
              <h2 className={css.title}>Вы можете помочь в создании формы?</h2>
              <p className={css.text}>
                Да. Мы можем взять на себя задачу настройки платежей на вашем сайте «под ключ». Поможем выбрать
                подходящий для вас мерчант, подключим и настроем его для вас. Напишите нам в чат в парвом нижнем углу.
                Будем рады помочь.
              </p>
            </div>
            <div className={css.database}>
              <h2 className={css.title}>О базе данных</h2>
              <div className={css.text}>
                <p>
                  Сейчас в нашей базе банки Америки (20 банков), Канады (28 банков), Англии (34 банка), России (49
                  банков). Мы постоянно добавляем новые страны, и каждый месяц добавляем банки в уже имеющиеся страны.
                  Полный перечень банков смотрите{' '}
                  <Link type="solid" to={getDocsBanksRoute()}>
                    на этой странице.
                  </Link>
                </p>
                <p>
                  Логотипы банков, цвета для формы и прочее мы подбираем самостоятельно, следим за актуальностью
                  имеющихся данных и постоянно расширяем базу банков.
                </p>
                <p>
                  Информацию о том, какому банку, какой номер карты принадлежит мы получаем из множества источников,
                  чтобы обеспечить максимальную вероятность определения банка по номеру карты.
                </p>
              </div>
            </div>
            <div className={css.nocountry}>
              <h2 className={css.title}>Нет вашей страны?</h2>
              <p className={css.text}>
                Укажите вашу страну при регистрации, и мы уведомим вас, когда банки вашей страны будут добавлены в базу.
              </p>
              <Button className={css.button} to={getSignUpRoute()}>
                Зарегистрироваться
              </Button>
            </div>
            }<div className={css.merchants}>
          <h2 className={css.title}>
            Какие мерчанты позволяют создать такую форму?
          </h2>
          <p className={css.text}>
            Такую форму можно создать только пользуясь услугами тех мерчантов,
            которые позволяют создавать форму на своей стороне. Перечень
            рекомендуемых мерчантов в формате сравнительной таблицы вы найдёте{' '}
            <Link type="solid" to={getDocsMerchantsRoute()}>
              на странице с мерчантами.
            </Link>
          </p>
        </div>*/}
            </div>
          )
        }}
      </PageWrapper>
    </Layout>
  )
}

export default HomePage
