const lang = {
  ru: {
    code: 'Код',
    date: 'Дата',
    user: 'Пользователь',
    bank: 'Банк',
  },
  en: {
    code: 'Code',
    date: 'Date',
    user: 'User',
    bank: 'Bank',
  }
}

export default lang
