import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import cookieStore from 'lib/cookieStore'

const request = async operation => {
  const token = cookieStore.get('token')
  operation.setContext({
    headers: {
      ...(token && { authorization: `Bearer ${token}` }),
    },
  })
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        if (networkError.bodyText === 'Unauthorized') {
          cookieStore.remove('token')
          apolloClient.resetStore()
          window.location.reload(false)
        }
      }
    }),
    requestLink,
    createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_URI }),
  ]),
  cache: new InMemoryCache(),
})

export default props => <ApolloProvider client={apolloClient} {...props} />
