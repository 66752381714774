import React from 'react'
import css from './style.module.scss'
import getUploadUrl from 'lib/getUploadUrl'

const ucFirst = string => {
  if (!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const BankPreview = props => {
  const { bank, ...restProps } = props
  return (
    <div className={css.preview} {...restProps}>
      <div
        className={css.header}
        style={{
          backgroundColor: bank.formBackgroundColor,
          borderColor: bank.formBorderColor,
          color: bank.formTextColor,
        }}
      >
        {bank.name}
        <br />
        {bank.localName}
        <br />
        {bank.alias}
      </div>
      <div className={css.logoLine}>
        <div
          className={css.formBackgroundColors}
          style={{
            background: `linear-gradient(to right bottom, ${bank.formBackgroundColors.join(
              ','
            )})`,
            borderColor: bank.formBorderColor,
          }}
        >
          <img
            alt=""
            src={getUploadUrl(
              bank[`logoBig${ucFirst(bank.formLogoScheme)}Svg`]
            )}
          />
          <img
            alt=""
            src={getUploadUrl(
              bank[`logoSmall${ucFirst(bank.formLogoScheme)}Svg`]
            )}
          />
        </div>
        <div className={css.lightBackgroundColor}>
          <img alt="" src={getUploadUrl(bank.logoBigOriginalSvg)} />
          <img alt="" src={getUploadUrl(bank.logoSmallOriginalSvg)} />
        </div>
      </div>
      <div className={css.logoLine}>
        <div className={css.darkBackgroundColor}>
          <img alt="" src={getUploadUrl(bank.logoBigInvertedSvg)} />
          <img alt="" src={getUploadUrl(bank.logoSmallInvertedSvg)} />
        </div>
      </div>
    </div>
  )
}

export default BankPreview
