import React from 'react'
import MainSegment from 'components/MainSegment'
import PageWrapper from 'components/PageWrapper'
import ChangeEmail from 'components/ChangeEmail'
import ChangePassword from 'components/ChangePassword'
import Segment from 'components/Segment'
import css from './styles.module.scss'
import useQuery from 'lib/useQuery'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ProfileSettingsPage = () => {
  const i18n = useLang(lang)
  const queryResult = useQuery('getMe')
  const { me } = queryResult
  return (
    <PageWrapper authorizedOnly queryResult={queryResult} meIncluded>
      {() => (
        <MainSegment title={i18n('settings')} >
          <Helmet title={i18n('settings')}  />
          <Segment title={i18n('mailManagement')} >
            <div className={css.emailSettings}>
              <p className={css.text}>
                {i18n('address')}  {me.email}
                <br />
                {i18n('form')}
              </p>
              <div className={css.form}>
                <ChangeEmail />
              </div>
            </div>
          </Segment>
          <Segment title={i18n('ChangePassword')} >
            <div className={css.passwordSettings}>
              <div className={css.form}>
                <ChangePassword />
              </div>
            </div>
          </Segment>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default ProfileSettingsPage
