const lang = {
  ru: {
    title: 'Неопознанные запрошеные бины',
    card: 'Номер карты',
    bank: 'Банк',
    bin: 'Бин',
  },
  en: {
    title: 'Unidentified requested Bins',
    card: 'Card number',
    bank: 'Bank',
    bin: 'Bin',
  }
}

export default lang
