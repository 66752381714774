import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import ReactSelect from 'react-select'
import _ from 'lodash'

const Select = ({
  label,
  formik,
  name,
  hint,
  onChange,
  fullWidth,
  disabled,
  options,
  placeholder = '',
  attention,
  ...restProps
}) => {
  const error = formik.errors[name]
  const value = formik.values[name]
  const touched = formik.touched[name]
  const selectedOption = _.find(options, option => option.value === value)
  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
          {!attention ? null : (
            <>
              {' '}
              <span className={css.attention}>{attention === true ? 'Внимание' : attention}</span>
            </>
          )}
        </label>
      )}
      <ReactSelect
        id={name}
        value={selectedOption}
        onChange={selectedOption => {
          if (onChange) onChange(selectedOption)
          formik.setFieldValue(name, selectedOption.value, true)
          setTimeout(() => {
            formik.setFieldTouched(name)
          }, 1)
        }}
        onBlur={() => {
          formik.setFieldTouched(name)
        }}
        name={name}
        className={css.select}
        isDisabled={disabled}
        styles={{
          option: (provided, state) => ({
            ...provided,
          }),
          control: (provided, state) => ({
            ...provided,
            borderRadius: 6,
            transition: 'none',
            borderColor: !!error && touched ? '#c00' : '#eee',
            paddingLeft: '16px',
            width: '389px',
            maxWidth: '100%',
            height: '44px',
            fontSize: '16px',
            fontWeight: 500,
            borderWidth: 2,
            pointerEvents: disabled ? 'none' : 'auto',
            opacity: disabled ? 0.5 : 1,
            '&:hover': {
              borderColor: state.isFocused ? '#2684ff' : !!error && touched ? '#c00' : '#666',
              boxShadow: 'inset 0 0.2em 0.4em rgba(0, 0, 0, 0.2)',
            },
          }),
          indicatorSeparator: provided => ({
            ...provided,
            display: 'none',
          }),
          dropdownIndicator: provided => ({
            color: '#666',
            padding: 0,
            position: 'absolute',
            marginTop: -10,
            right: 7,
            top: '50%',
            '&:hover': {
              color: '#666',
            },
          }),
          valueContainer: provided => ({
            ...provided,
            padding: 0,
          }),
          input: provided => ({
            ...provided,
            padding: 0,
            margin: 0,
            lineHeight: 1,
            fontFamily: 'Rubik, Helvetica, Arial, sans-serif',
          }),
          singleValue: provided => ({
            ...provided,
            maxWidth: '100%',
            margin: 0,
            transform: 'translateY(-50%)',
            top: '52%',
          }),
          placeholder: provided => ({
            ...provided,
            maxWidth: '100%',
            margin: 0,
            transform: 'translateY(-50%)',
            top: '52%',
          }),
          menu: provided => ({
            ...provided,
            margin: 0,
            maxWidth: fullWidth ? 'none' : 300,
            boxShadow: 'none',
            border: '2px solid #ddd',
            borderTop: 'none',
            borderRadius: '0 0 4px 4px',
            padding: 0,
          }),
        }}
        options={options}
        placeholder={placeholder}
        {...restProps}
      />
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default Select
