import mixpanel from 'mixpanel-browser'
mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY)
const defaultSilent = false
const shouldSendData = process.env.REACT_APP_MIXPANEL_ON === 'true'

export const track = (eventName, eventData = {}, silent = defaultSilent) => {
  return new Promise(resolve => {
    if (!silent)
      console.info('Mixpanel event', eventName, {
        ...eventData,
      })
    if (shouldSendData) {
      return mixpanel.track(eventName, eventData, resolve)
    } else {
      return resolve()
    }
  })
}

export const setUserData = (userData = {}, silent = defaultSilent) => {
  return new Promise(resolve => {
    identifyUser(userData.id, silent)
    if (shouldSendData) {
      return mixpanel.people.set(userData, resolve)
    } else {
      return resolve()
    }
  })
}

export const trackCharge = (amount, silent = defaultSilent) => {
  return new Promise(resolve => {
    if (!silent) console.info('Mixpanel track charge', amount)
    if (shouldSendData) {
      mixpanel.people.increment('Total spent', amount)
      return mixpanel.people.track_charge(amount)
    } else {
      return resolve()
    }
  })
}

export const identifyUser = (userId, silent = defaultSilent) => {
  if (!silent) console.info('Mixpanel identify', userId)
  if (shouldSendData) return mixpanel.identify(userId)
}

export const aliasUser = (userId, silent = defaultSilent) => {
  return new Promise(resolve => {
    if (!silent) console.info('Mixpanel alias', userId)
    if (shouldSendData) {
      mixpanel.alias(userId)
      return resolve()
    } else {
      return resolve()
    }
  })
}
