import React from 'react'
// import { Query as ApolloQuery } from 'react-apollo'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import isFunction from 'lodash/isFunction'

const QueryWrapper = ({
  queryResult,
  loaderType = 'hidden',
  loaderRender = true,
  errorRender = true,
  children,
}) => {
  const loaderRenderHere =
    loaderRender && loaderRender !== true
      ? loaderRender
      : () => <Loader type={loaderType} />
  const errorRenderHere =
    errorRender && errorRender !== true
      ? errorRender
      : queryResult => (
          <Alert type="danger" message={queryResult.error.humanMessage} />
        )
  if (queryResult.loading && loaderRender) {
    return loaderRenderHere(queryResult)
  }
  if (queryResult.error && errorRender) {
    return errorRenderHere(queryResult)
  }
  return isFunction(children) ? children(queryResult) : children
}

export default QueryWrapper
