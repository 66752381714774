import React from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import * as Yup from 'yup'
import useMutation from 'lib/useMutation'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ChangePassword = ({ history }) => {
  const i18n = useLang(lang)
  const [changeMyPassword] = useMutation('changeMyPassword', {
    errorCodes: {
      ERR_UNAUTHORIZED: i18n('errorPass'),
    },
  })
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordAgain: '',
    },
    onSubmit: ({ password, newPassword }) => {
      return changeMyPassword({
        variables: { input: { password, newPassword } },
      })
    },
    validationSchema: Yup.object({
      password: Yup.string().required(i18n('required')),
      newPassword: Yup.string().required(i18n('required')),
      newPasswordAgain: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], i18n('miss'))
        .required(i18n('required')),
    }),
    successMessage: i18n('successMessage'),
    resetOnSuccess: true,
  })
  return (
    <Form formik={formik}>
      <FormItems>
        <Input
          label={i18n('password')}
          name="password"
          type="password"
          formik={formik}
        />
        <Input
          label={i18n('newPassword')}
          name="newPassword"
          type="password"
          formik={formik}
        />
        <Input
          label={i18n('newPasswordAgain')}
          name="newPasswordAgain"
          type="password"
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button {...buttonProps}>{i18n('change')}</Button>
      </FormItems>
    </Form>
  )
}

export default ChangePassword
