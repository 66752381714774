const lang = {
  ru: {
    link: 'Ссылка для восстановления пароля не действительна',
    required: 'Обязательное поле',
    error: 'Пароли не совпадают',
    new: 'Новый пароль установлен, теперь вы можете',
    enter: 'Войти',
    help: 'с помощью него',
    setNewPasswoed: 'Установите новый пароль',
    newPasswoed: 'Новый пароль',
    confirm: 'Подтвердите новый пароль',
    setPassword: 'Установить пароль',
  },
  en: {
    required: 'Required field',
    error: 'Passwords do not match',
    new: 'The new password is set up, now you can',
    enter: 'Login',
    help: 'with it',
    setNewPasswoed: 'Set up a new password',
    newPasswoed: 'New Password',
    confirm: 'Confirm new password',
    setPassword: 'Set up a new password',
  }
}

export default lang
