const lang = {
  ru: {
    successMessage: 'Письмо отправлено',
    email: 'На вашу почту',
    emailText: `было отправлено письмо с паролем и ссылкой
    для подтверждения регистрации. Пожалуйста, перейдите по ссылке в
    письме.`,
    resend: 'Выслать повторно',
    lk: 'Личный кабинет',
    optionApi: 'Настройки API',
    archive: 'Архив с базой данных',
    payOption: 'Настройки платежей',
    userOption: 'Настройки профиля',
    newBank: 'Новый банк',
    banks: 'Банки',
    logBanks: 'Логи банков',
    users: 'Пользователи',
    saites: 'Сайты пользователей',
    binBanks: 'Банки бинов',
    statsBanks: 'Статистика банков',
    statsService: 'Статистика сервиса',
    unidentifiedBins: 'Неопознанные бины',
    made: 'Сделано',
    nameProg: 'Сергеем Дмитриевым и командой',
    copy: '© 2021 BinKing.',
    privacyPolicy: 'Политика конфиденциальности',
    terms: 'Пользовательское соглашение',
    save: 'Скачать документацию',
  },
  en: {
    successMessage: 'The letter has been sent',
    email: 'To your email',
    emailText: `An email was sent with a password and a link to confirm registration. Please follow the link in the email.`,
    resend: 'Send again',
    lk: 'Dashboard',
    optionApi: 'API settings',
    archive: 'Database archive',
    payOption: 'Payment settings',
    userOption: 'Profile settings',
    newBank: 'New bank',
    banks: 'Banks',
    logBanks: 'Logs of banks',
    users: 'Users',
    saites: 'Websites of users',
    binBanks: 'Banks of bines',
    statsBanks: 'Statistic of banks',
    statsService: 'Statistic of a service',
    unidentifiedBins: 'unidentified Bins',
    made: 'Made',
    nameProg: 'by Sergei Dmitriev and team',
    copy: '© 2021 BinKing.',
    privacyPolicy: 'Privacy Policy',
    terms: 'Terms of use',
    save: 'Download documentation',
  },
}

export default lang
