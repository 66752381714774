const lang = {
  ru: {
    yourcard: 'Ваши привязанные карты:',
    oneMoreCard: 'Вы можете привязать ещё одну карту:',
    linkYourCard: 'Привяжите карту, и получите подарочные',
    topUpGift: 'Пополните баланс на любую сумму и получите в подарок',
    queryApi: 'на счёт, которые сможете потратить на запросы к API:',
    linkCard: 'Привяжите карту:',
    balance: 'Вы можете пополнить баланс воспользовавшись этой формой.',
    history: 'История платежей',
    paymentSettings: 'Настройки платежей',
    currentBalance: 'Текущий баланс',
    attachedCards: 'Привязанные карты',
  },
  en: {
    yourcard: 'Your linked cards:',
    oneMoreCard: 'You can link one more card:',
    linkYourCard: 'Link your card and get a',
    topUpGift: 'Replenish the balance for any amount and receive as a gift',
    queryApi: 'Rewards to your account, which you can spend on API requests:',
    linkCard: 'Link your card:',
    balance: 'You can top up your balance using this form.',
    history: 'Payment history',
    paymentSettings: 'Payment settings',
    currentBalance: 'Current balance',
    attachedCards: 'Linked cards',
  }
}

export default lang
