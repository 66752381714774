const lang = {
  ru: {
    attention: 'Внимание',
    new: 'Добавить элемент',
    remove: 'Удалить',
  },
  en: {
    attention: 'Attention',
    new: 'Add element',
    remove: 'Remove',
  }
}

export default lang
