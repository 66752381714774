import React from 'react'
import MainSegment from 'components/MainSegment'
import useQuery from 'lib/useQuery'
import PageWrapper from 'components/PageWrapper'
import css from './styles.module.scss'
import { canViewBins } from 'lib/can'
import Segment from 'components/Segment'
import toHumanCountry from 'lib/toHumanCountry'
import queryString from 'query-string'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const BinBanksPage = ({ location }) => {
  const i18n = useLang(lang)
  const params = queryString.parse(location.search)
  const { country } = params
  const queryResult = useQuery('getBinBanks', {
    variables: {
      input: {
        country,
      },
    },
  })
  const { binBanks } = queryResult
  return (
    <PageWrapper queryResult={queryResult}>
      {() => (
        <MainSegment title={i18n('title')}>
          <Helmet title={i18n('title')} />
          <Segment>
            <table className={css.binBanks}>
              <thead>
                <tr>
                  <th>{i18n('name')}</th>
                  <th>{i18n('country')}</th>
                  <th>{i18n('col')}</th>
                </tr>
              </thead>
              <tbody>
                {binBanks.map((binBank, i) => {
                  return (
                    <tr key={i} className={css.binBank}>
                      <td>{binBank.name}</td>
                      <td>{toHumanCountry(binBank.country, i18n('lang'))}</td>
                      <td>{binBank.binsCount}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Segment>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canViewBins(me)}>
    {({ me }) => <BinBanksPage me={me} {...props} />}
  </PageWrapper>
)
