import React from 'react'
import css from './styles.module.scss'
import Header from 'components/Header'
import Footer from 'components/Footer'

const Layout = ({ children, perple, minimal }) => {
  return (
    <div className={css.layout}>
      <Header perple={perple}/>
      <div className={css.content}>{children}</div>
      <Footer minimal={minimal}/>
    </div>
  )
}

export default Layout
