import localStore from 'lib/localStore'
import _ from 'lodash'
// https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

const savedGlobalState = localStore.get('globalState') || {}

let browserLang = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage
if (browserLang !== 'ru') browserLang = 'en'

const initialState = {
  lang: browserLang,
  ...savedGlobalState,
}

const reducer = (state, action) => {
  let newState
  switch (action.type) {
    case 'switchLang':
      newState = {
        ...state,
        lang: action.input.lang,
      }
      break

    default:
      newState = state
  }

  localStore.set(
    'globalState',
    _.pickBy(newState, (value, key) => {
      return _.includes(['lang'], key)
    })
  )
  return newState
}

export { initialState, reducer }
