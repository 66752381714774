const lang = {
  ru: {
    title: 'Нет такой страницы',
    text: 'Страницы с указанным адресом не существует.',
    btn: 'Перейти на главную',
  },
  en: {
    title: 'Such page does not exist',
    text: 'The page with the specified address does not exist.',
    btn: 'Go to main page',
  }
}

export default lang
