import React from 'react'
import css from './styles.module.scss'

const RichText = ({ children }) => (
  <div className={css.richText}>
    {children}
  </div>
)

export default RichText
