const lang = {
  ru: {
    list: (sum) => `До 1 000 запросов, ${sum}`,
    list2: (sum) => `До 10 000 запросов, ${sum}`,
    list3: (sum) => `До 100 000 запросов, ${sum}`,
    list4: (sum) => `Свыше 100 000 запросов, ${sum}`,
    query: 'за запрос',
    queryApi: 'На одного платящего пользователя достаточно всего 1 запроса к API.',
    giftApi: (sum, requests) => `Дарим ${sum} (${requests} запросов) на использование API`,
    getAPIKey: 'Получить ключ API',
    APIInstructions: 'Инструкции к API',
    db: 'База данных',
    archivePrice: (sum) => `Стоимость архива ${sum}`,
    allPrice: (sum) => `за одну отдельную страну, или ${sum}`,
    allPrice2: 'за все страны. Архив обновляется ежемесячно. Ссылка доступна в течение года.',
    saveArchive: 'Скачать архив',
    archiveInstructions: 'Инструкции к архиву',
    plugin: 'JS-плагин',
    pluginText: 'JS-плагин бесплатный, но использует подключение к API или скаченный архив.',
    gitHub: 'Смотреть на GitHub',
    price: 'Стоимость',
  },
  en: {
    list: (sum) => `Up to 1,000 queries, ${sum}`,
    list2: (sum) => `Up to 10,000 queries, ${sum}`,
    list3: (sum) => `Up to 100,000 queries, ${sum}`,
    list4: (sum) => `More than 100,000 queries, ${sum}`,
    query: 'per each query',
    queryApi: 'Only 1 request to the API is enough for one paying user.',
    giftApi: (sum, requests) => `Free ${requests} API requests to try BinKing`,
    getAPIKey: 'Get API Key',
    APIInstructions: 'API documentation',
    db: 'Database',
    archivePrice: (sum) => `The cost of archive is ${sum}`,
    allPrice: (sum) => `for one single country, or ${sum}`,
    allPrice2: 'for all countries. The archive is updated monthly. The link is available during a year.',
    saveArchive: 'Download archive',
    archiveInstructions: 'Archive documentation',
    plugin: 'JS-plugin',
    pluginText: 'The JS plugin is free, but it uses an API connection or a downloaded archive.',
    gitHub: 'View on GitHub',
    price: 'Pricing',
  },
}

export default lang
