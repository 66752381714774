import toHumanDate from 'lib/toHumanDate'
import toMoney from 'lib/toMoney'
import toHumanCountry from 'lib/toHumanCountry'
import _ from 'lodash'

const toHumanTx = (tx, lang = 'en', usdPrice) => {
  const settings = {
    ru: {
      TX_PAYPAL_ACTIVATION_BONUS: {
        type: 'Подарочные деньги за первое пополнение баланса',
      },
      TX_ADD_CARD_BONUS: {
        type: 'Подарочные деньги за привязку банковской карты к аккаунту',
      },
      TX_MANUAL: { type: 'Ручное пополнение баланса #' + (tx.cloudPaymentsId || tx.payPalId) },
      TX_AUTO: { type: 'Автоматическое пополнение баланса #' + (tx.cloudPaymentsId || tx.payPalId) },
      TX_API_USAGE_DAY: {
        type: 'Плата за обращения к API',
        date: () =>
          new Date() < new Date(tx.createdAt) ? 'За сегодня' : `В течение ${toHumanDate(tx.createdAt, 'DD.MM.YYYY')}`,
      },
      TX_ARCHIVE: {
        type: () => `Оплата доступа к архиву "${toHumanCountry(tx.archiveCountry, lang)}"`,
      },
    },
    en: {
      TX_PAYPAL_ACTIVATION_BONUS: {
        type: 'A gift for the first balance replenishment',
      },
      TX_ADD_CARD_BONUS: {
        type: 'A gift for linking a bank card to an account',
      },
      TX_MANUAL: { type: 'Manual top up of the balance #' + (tx.cloudPaymentsId || tx.payPalId) },
      TX_AUTO: { type: 'Automatic top up of the balance #' + (tx.cloudPaymentsId || tx.payPalId) },
      TX_API_USAGE_DAY: {
        type: 'Payment for requests to API',
        date: () =>
          new Date() < new Date(tx.createdAt) ? 'For today' : `During ${toHumanDate(tx.createdAt, 'DD.MM.YYYY')}`,
      },
      TX_ARCHIVE: {
        type: () => `Payment for access to the archive "${toHumanCountry(tx.archiveCountry, lang)}"`,
      },
    },
  }[lang]
  const setting = settings[tx.type]
  if (!setting) return tx
  return {
    type: _.isFunction(setting.type) ? setting.type() : setting.type,
    extra: setting.extra,
    date: setting.date ? setting.date() : toHumanDate(tx.createdAt, 'DD.MM.YYYY HH:mm:ss'),
    sum: toMoney({ sumUsd: tx.sum, usdPrice, sumRub: tx.sumRub }),
  }
}

export default toHumanTx
