/* eslint-disable no-template-curly-in-string, no-useless-escape */
import * as yup from 'yup'

export default yup

export const isUrl = (str) => {
  const pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return !!pattern.test(str);
}

export const setLocaleYup = (lang) => {
  if (lang === 'ru') {
    yup.setLocale({
      number: {
        integer: 'Должно быть целым числом',
        positive: 'Должно быть положительным числом',
        min: 'Должно быть не меньше ${min}',
      },
      mixed: {
        required: 'Обязательное поле',
        notType: _ref => {
          switch (_ref.type) {
            case 'number':
              return 'Должно быть числом'
            case 'string':
              return 'Должно быть строкой'
            default:
              return 'Wrong type'
          }
        },
      },
    })
  } else {
    yup.setLocale({
      number: {
        integer: 'Should be an integer',
        positive: 'Should be positive',
        min: 'Should be not less then ${min}',
      },
      mixed: {
        required: 'Required field',
        notType: _ref => {
          switch (_ref.type) {
            case 'number':
              return 'Should be number'
            case 'string':
              return 'Should be string'
            default:
              return 'Wrong type'
          }
        },
      },
    })
  }
}
