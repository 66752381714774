import React, { useRef, useEffect, useState } from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import useMutation from 'lib/useMutation'
import redirectPost from 'lib/redirectPost'
import binking from 'lib/binking'
import _ from 'lodash'
import css from './styles.module.scss'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import { Deprecated } from 'components/Deprecated'

const AddBankCard = ({ temp }) => {
  // const checkout = useRef()
  // const i18n = useLang(lang)
  // const formEl = useRef()
  // const [addBankCard] = useMutation('addBankCard', {
  //   refetchQueries: ['getTxs'],
  // })
  // const { formik, buttonProps, alertProps } = useForm({
  //   initialValues: {
  //     // cardNumber: '4111111111111111',
  //     // month: '12',
  //     // year: '24',
  //     // code: '123',
  //     // name: 'Sergey Dmitriev',
  //     cardNumber: '',
  //     month: '',
  //     year: '',
  //     code: '',
  //     name: '',
  //   },
  //   onSubmit: async ({ country, saveCard, name, savedCard }) => {
  //     const checkoutResult = checkout.current.createCryptogramPacket()
  //     if (!checkoutResult.success) {
  //       throw new Error(checkoutResult.messages[_.keys(checkoutResult.messages)[0]])
  //     }
  //     const result = await addBankCard({
  //       variables: {
  //         input: {
  //           cardCryptogramPacket: checkoutResult.packet,
  //           name,
  //         },
  //       },
  //     })
  //     if (result.success) return !temp
  //     // if (result.success) {
  //     //   window.location.reload()
  //     //   return true
  //     // }
  //     if (result.acsUrl) {
  //       return new Promise(function() {
  //         redirectPost(result.acsUrl, {
  //           PaReq: result.paReq,
  //           MD: result.transactionId,
  //           TermUrl:
  //             process.env.REACT_APP_BACKEND_URI +
  //             '/cloudpayments/acs?redirectUrl=' +
  //             encodeURIComponent(window.location.href),
  //         })
  //       })
  //     }
  //     throw new Error('Something went wrong')
  //   },
  //   validate: values => {
  //     const errors = {}
  //     if (!values.name) {
  //       errors.name = i18n('nameError')
  //     }
  //     const validationResult = binking.validate(values.cardNumber, values.month, values.year, values.code)
  //     for (const validationError of Object.values(validationResult.errors)) {
  //       errors[validationError.field] = validationError.message
  //     }
  //     return errors
  //   },
  //   successMessage: i18n('successMessage'),
  //   resetOnSuccess: true,
  // })
  // const [bkResult, setBkResult] = useState(binking.defaultResult)
  // useEffect(() => {
  //   binking(formik.values.cardNumber, result => {
  //     setBkResult(result)
  //   })
  // }, [formik.values.cardNumber])
  // useEffect(() => {
  //   checkout.current = window.cp && new window.cp.Checkout(process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID, formEl.current)
  // }, [])
  return (
    <Deprecated />
    // <Form formik={formik}>
    //   <FormItems>
    //     <div className={css.card}>
    //       <div className={css.cardFront} style={{ backgroundColor: bkResult.formBackgroundColor }}>
    //         <div className={css.logoBox}>
    //           {bkResult.formBankLogoBigSvg && <img src={bkResult.formBankLogoBigSvg} alt="logo" />}
    //         </div>
    //         <Input
    //           name="cardNumber"
    //           autocomplete="cc-number"
    //           noName
    //           formik={formik}
    //           data-cp="cardNumber"
    //           placeholder="0000 0000 0000 0000"
    //           mask={bkResult.cardNumberMask}
    //           style={{ borderColor: bkResult.formBorderColor }}
    //           card
    //         />
    //         <div className={css.cardDate}>
    //           <Input
    //             style={{ borderColor: bkResult.formBorderColor }}
    //             name="month"
    //             noName
    //             formik={formik}
    //             data-cp="expDateMonth"
    //             placeholder="MM"
    //             autocomplete="cc-exp-month"
    //             mask={'00'}
    //             card
    //           />
    //           <Input
    //             style={{ borderColor: bkResult.formBorderColor }}
    //             name="year"
    //             noName
    //             formik={formik}
    //             data-cp="expDateYear"
    //             placeholder="YY"
    //             autocomplete="cc-exp-year"
    //             mask={'00'}
    //             card
    //           />
    //         </div>
    //         <div className={css.lastRow}>
    //           <Input
    //             style={{ borderColor: bkResult.formBorderColor }}
    //             name="name"
    //             noName
    //             formik={formik}
    //             data-cp="name"
    //             placeholder={i18n('nameCard')}
    //             card
    //           />
    //           {bkResult.formBrandLogoSvg && (
    //             <img className={css.brandLogo} src={bkResult.formBrandLogoSvg} alt="logo" />
    //           )}
    //         </div>
    //       </div>
    //       <div className={css.cardBack}>
    //         <div className={css.inputPosition}>
    //           <Input name="code" autocomplete="cc-csc" mask={'0000'} type="password" noName card formik={formik} placeholder={bkResult.codeName} data-cp="cvv" />
    //         </div>
    //       </div>
    //     </div>
    //     <Alert {...alertProps} />
    //     <Button {...buttonProps}>{i18n('connectCard')}</Button>
    //   </FormItems>
    // </Form>
  )
}

export default AddBankCard
