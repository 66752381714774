import React from 'react'
import MainSegment from 'components/MainSegment'
import useQuery from 'lib/useQuery'
import toHumanDate from 'lib/toHumanDate'
import PageWrapper from 'components/PageWrapper'
import css from './styles.module.scss'
import { canViewReferers } from 'lib/can'
import { getUserRoute } from 'lib/routes'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from 'components/Loader'
import Link from 'components/Link'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ReferersPage = ({ me, history }) => {
  const i18n = useLang(lang)
  const queryResult = useQuery('getReferers')
  const { referers, pageInfo, fetchMore } = queryResult
  return (
    <PageWrapper queryResult={queryResult}>
      {() => (
        <MainSegment title={i18n('title')}>
          <Helmet title={i18n('title')} />
          <table className={css.referers}>
            <thead>
              <tr>
                <th>{i18n('data')}</th>
                <th>{i18n('address')}</th>
                <th>{i18n('user')}</th>
              </tr>
            </thead>
            <InfiniteScroll
              threshold={250}
              element="tbody"
              loadMore={async () => {
                fetchMore({
                  variables: {
                    input: {
                      cursor: pageInfo.cursor,
                      limit: 30,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev
                    return {
                      getReferers: {
                        ...fetchMoreResult.getReferers,
                        referers: [...prev.getReferers.referers, ...fetchMoreResult.getReferers.referers],
                      },
                    }
                  },
                })
              }}
              hasMore={pageInfo.hasNextPage}
              loader={
                <tr key="loader">
                  <td colSpan="2">
                    <Loader type="section" />
                  </td>
                </tr>
              }
            >
              {referers.map((referer, i) => {
                return (
                  <tr key={i}>
                    <td>{toHumanDate(referer.createdAt)}</td>
                    <td className={css.url}>
                      <Link type="solid" target="_blank" href={referer.url}>
                        {referer.url}
                      </Link>
                    </td>
                    <td>
                      <Link type="solid" to={getUserRoute(referer.user.id)}>
                        {referer.user.email}
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </InfiniteScroll>
          </table>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canViewReferers(me)}>
    {({ me }) => <ReferersPage me={me} {...props} />}
  </PageWrapper>
)
