import React from 'react'
import css from './styles.module.scss'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Button from 'components/Button'
import useQuery from 'lib/useQuery'
import toHumanDate from 'lib/toHumanDate'
import toMoney from 'lib/toMoney'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ApiUsageDays = () => {
  const i18n = useLang(lang)
  const { apiUsageDays, pageInfo, loading, error, fetchMore, fetchingMore } = useQuery('getApiUsageDays', {
    variables: {
      input: {
        limit: 10,
      },
    },
  })
  const { loading: loadingUsdPrice, usdPrice } = useQuery('getUsdPrice')
  if (loading || loadingUsdPrice) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!apiUsageDays.length) return <Alert message={i18n('noUseApi')} />
  return (
    <>
      <table className={css.apiUsageDays}>
        <thead>
          <tr>
            <th>{i18n('date')}</th>
            <th>{i18n('colQuery')}</th>
            <th>{i18n('spent')}</th>
          </tr>
        </thead>
        <tbody>
          {apiUsageDays.map((apiUsageDay, i) => {
            return (
              <tr key={i} className={css.apiUsageDay}>
                <td className={css.date}>{toHumanDate(apiUsageDay.date, 'DD.MM.YYYY')}</td>
                <td>{apiUsageDay.apiRequestsCount}</td>
                <td className={css.sum}>{toMoney({ sumUsd: apiUsageDay.totalSpent, usdPrice, sumRub: apiUsageDay.totalSpentRub })}</td>
              </tr>
            )
          })}
          {fetchingMore && (
            <tr key="loader">
              <td colSpan="3">
                <Loader type="section" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pageInfo.hasNextPage && (
        <div className={css.buttonPlace}>
          <Button
            buttonStyle="violet"
            loading={fetchingMore}
            onClick={() =>
              fetchMore({
                variables: {
                  input: {
                    cursor: pageInfo.cursor,
                    limit: 10,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  return {
                    getApiUsageDays: {
                      ...fetchMoreResult.getApiUsageDays,
                      apiUsageDays: [
                        ...prev.getApiUsageDays.apiUsageDays,
                        ...fetchMoreResult.getApiUsageDays.apiUsageDays,
                      ],
                    },
                  }
                },
              })
            }
          >
            {i18n('showAll')}
          </Button>
        </div>
      )}
    </>
  )
}

export default ApiUsageDays
