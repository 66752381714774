import React from 'react'
import Modal from 'components/Modal'
import Button from 'components/Button'
import css from './styles.module.scss'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const PaymentResultModal = () => {
  const i18n = useLang(lang)
  const location = useLocation()
  const history = useHistory()
  const params = queryString.parse(location.search)
  const { success, message, purpose } = params
  const title =
    success === 'true'
      ? {
          api: i18n('api'),
          archive: i18n('archive'),
          card: i18n('card'),
        }[purpose]
      : i18n('error')
  return (
    <Modal
      isOpen={params.action === 'payment'}
      onRequestClose={() => history.replace(location.pathname)}
    >
      <h2 className={css.title}>{title}</h2>
      {message && <p className={css.message}>{message}</p>}
      <p className={css.buttonPlace}>
        <Button color="gray" onClick={() => history.replace(location.pathname)}>
          {i18n('next')}
        </Button>
      </p>
    </Modal>
  )
}

export default PaymentResultModal
