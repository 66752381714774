import { useEffect } from 'react'
import useQuery from 'lib/useQuery'
import useGlobalState from 'lib/globalState'
import { useHistory, useLocation } from 'react-router-dom'
import { getHomeRoute } from 'lib/routes'
import binking from 'lib/binking'
import { setLocaleYup } from '../../lib/validation'
import useMutation from '../../lib/useMutation'
import { switchLang } from '../../lib/i18n'

const LangNormalizer = () => {
  const queryResult = useQuery('getMe')
  const [setPaymentMethod] = useMutation('setPaymentMethod')
  const { me, loading } = queryResult
  const [globalState, dispatchGlobalAction] = useGlobalState()
  const [changeMyLang] = useMutation('changeMyLang')
  const langName = globalState.lang
  const location = useLocation()
  const history = useHistory()
  const domain = window.location.hostname
  useEffect(() => {
    const byDomainLangShouldBe = domain === 'binking.ru' || domain === '127.0.0.1' ? 'ru' : 'en'
    switchLang(byDomainLangShouldBe, dispatchGlobalAction, location, history, changeMyLang, me)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, langName, JSON.stringify(me)])
  useEffect(() => {
    if (location.pathname === '/') {
      history.replace(getHomeRoute(langName) + location.search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  useEffect(() => {
    const currentUrlLang = location.pathname.split('/')[1]
    if (['ru', 'en'].includes(currentUrlLang)) {
      if (currentUrlLang !== langName) {
        const newPath = location.pathname.replace(`/${currentUrlLang}/`, `/${langName}/`) + location.search
        history.replace(newPath)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, langName])
  useEffect(() => {
    if (!loading && me && me.lang) {
      if (langName !== me.lang) {
        dispatchGlobalAction({
          type: 'switchLang',
          input: {
            lang: me.lang,
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me && me.lang, loading])
  useEffect(() => {
    if (globalState.lang === 'ru') {
      binking.setValidationErrors({
        CARD_NUMBER_REQUIRED: 'Укажите номер вашей банковской карты',
        CARD_NUMBER_INVALID: 'Номер карты содержит недопустимые символы',
        CARD_NUMBER_INCOMPLETE: 'Номер карты заполнен не до конца',
        CARD_NUMBER_OVERCOMPLETE: 'В номере карты слишком много символов',
        CARD_NUMBER_LUHN: 'В номере карты содержится опечатка',
        MONTH_REQUIRED: 'Укажите месяц истечения карты',
        MONTH_INVALID: 'Ошибка в месяце истечения карты',
        YEAR_REQUIRED: 'Укажите год истечения карты',
        YEAR_INVALID: 'Ошибка в годе истечения карты',
        YEAR_IN_PAST: 'Год указан в прошедшем времени',
        MONTH_IN_PAST: 'Месяц указан в прошедшем времени',
        CODE_REQUIRED: 'Укажите код безопасности',
        CODE_INVALID: 'Код безопасности указан неверно',
      })
    } else {
      binking.setValidationErrors({
        CARD_NUMBER_REQUIRED: 'Indicate your bank card number',
        CARD_NUMBER_INVALID: 'Card number contains invalid symbols',
        CARD_NUMBER_INCOMPLETE: 'Card number entry is incomplete',
        CARD_NUMBER_OVERCOMPLETE: 'Card number input exceeds allowable number of symbols',
        CARD_NUMBER_LUHN: 'Card number entry contains typo',
        MONTH_REQUIRED: 'Indicate card expiry date',
        MONTH_INVALID: 'Error in card expiry date',
        YEAR_REQUIRED: 'Indicate year of card expiry',
        YEAR_INVALID: 'Error in year of card expiry',
        YEAR_IN_PAST: 'Year of card expiry can not be in the past',
        MONTH_IN_PAST: 'Month of card expiry can not be in the past',
        CODE_REQUIRED: 'Please indicate security code',
        CODE_INVALID: 'Security code is invalid',
      })
    }
  }, [globalState.lang])
  useEffect(() => {
    setLocaleYup(globalState.lang)
  }, [globalState.lang])
  useEffect(() => {
    // TODO: turn on going to live
    // if (me && me.email === 'paypal@example.com' && me.paymentMethod !== 'pp') {
    //   setPaymentMethod({
    //     variables: { input: { paymentMethod: 'pp' } },
    //     optimisticResponse: {
    //       setPaymentMethod: {
    //         me: {
    //           id: me.id,
    //           __typename: 'User',
    //           paymentMethod: 'pp',
    //         },
    //         __typename: 'MePayload',
    //       },
    //     },
    //   })
    // }
    // if (me && me.email !== 'paypal@example.com' && me.paymentMethod !== 'cp') {
    //   setPaymentMethod({
    //     variables: { input: { paymentMethod: 'cp' } },
    //     optimisticResponse: {
    //       setPaymentMethod: {
    //         me: {
    //           id: me.id,
    //           __typename: 'User',
    //           paymentMethod: 'cp',
    //         },
    //         __typename: 'MePayload',
    //       },
    //     },
    //   })
    // }

    // TODO: turn on paypal will be ready
    if (langName === 'en' && me && me.paymentMethod !== 'pp') {
      setPaymentMethod({
        variables: { input: { paymentMethod: 'pp' } },
        optimisticResponse: {
          setPaymentMethod: {
            me: {
              id: me.id,
              __typename: 'User',
              paymentMethod: 'pp',
            },
            __typename: 'MePayload',
          },
        },
      })
    }
    if (langName === 'ru' && me && me.paymentMethod !== 'cp') {
      setPaymentMethod({
        variables: { input: { paymentMethod: 'cp' } },
        optimisticResponse: {
          setPaymentMethod: {
            me: {
              id: me.id,
              __typename: 'User',
              paymentMethod: 'cp',
            },
            __typename: 'MePayload',
          },
        },
      })
    }

    // TODO: remove after enable paypal
    // if (me && me.paymentMethod !== 'cp') {
    //   setPaymentMethod({
    //     variables: { input: { paymentMethod: 'cp' } },
    //     optimisticResponse: {
    //       setPaymentMethod: {
    //         me: {
    //           id: me.id,
    //           __typename: 'User',
    //           paymentMethod: 'cp',
    //         },
    //         __typename: 'MePayload',
    //       },
    //     },
    //   })
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langName, me && me.paymentMethod, me && me.email])
  return null
}

export default LangNormalizer
