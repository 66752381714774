// import stringifyQueryObject from 'lib/stringifyQueryObject'
const getLang = lang => {
  if (lang) return lang
  function getLocation(href) {
    // eslint-disable-next-line  no-useless-escape
    var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/)
    return (
      match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7],
      }
    )
  }
  const location = getLocation(window.location.href)
  const langInUrl = location.pathname.split('/')[1]
  return ['ru', 'en'].includes(langInUrl) ? langInUrl : 'en'
}
export const getHomeRoute = lang => `/${getLang(lang)}/home/`
export const getSignOutRoute = lang => `/${getLang(lang)}/sign-out`
export const getSignInRoute = lang => `/${getLang(lang)}/sign-in`
export const getSignUpRoute = (then, lang) =>
  !then ? `/${getLang(lang)}/sign-up` : `/${getLang(lang)}/sign-up?then=${then}`
export const getConfirmEmailRoute = (confirmationToken, lang) => `/${getLang(lang)}/confirm-email/${confirmationToken}`
export const getConfirmNewEmailRoute = (confirmNewEmailToken, lang) =>
  `/${getLang(lang)}/confirm-new-email/${confirmNewEmailToken}`
export const getResetPasswordRoute = (passwordResetToken, lang) =>
  `/${getLang(lang)}/reset-password/${passwordResetToken}`
export const getRestorePasswordRoute = lang => `/${getLang(lang)}/restore-password`
export const getAboutRoute = lang => `/${getLang(lang)}/about`
export const getGuideRoute = lang => `/${getLang(lang)}/guide`
export const getPriceRoute = lang => `/${getLang(lang)}/price`
export const getPrivacyPolicyRoute = lang => `/${getLang(lang)}/privacy-policy`
export const getTermsOfUseRoute = lang => `/${getLang(lang)}/terms-of-use`
export const getCompanyRoute = lang => `/${getLang(lang)}/company`
export const getBankJSONRoute = lang => `/${getLang(lang)}/bank-json`
// export const getServiceRoute = () => '/service'
export const getDocsRouteGroup = lang => `/${getLang(lang)}/docs`
export const getDocsIntroRoute = lang => `/${getLang(lang)}/docs/intro`
export const getDocsArchiveRoute = lang => `/${getLang(lang)}/docs/archive`
export const getDocsApiRoute = lang => `/${getLang(lang)}/docs/api`
export const getDocsBanksRoute = lang => `/${getLang(lang)}/docs/banks`
export const getTempPageRoute = lang => `/${getLang(lang)}/docs/temp`
// export const getDocsMerchantsRoute = () => '/docs/merchants'
export const getPluginUrl = () => 'https://github.com/sdandteam/binking'
export const getBanksRoute = lang => `/${getLang(lang)}/dashboard/banks`
export const getBankRoute = (bankId, lang) => `/${getLang(lang)}/dashboard/banks/${bankId}`
export const getBankNewRoute = lang => `/${getLang(lang)}/dashboard/new-bank`
export const getBanksLogsRoute = lang => `/${getLang(lang)}/dashboard/banks-logs`
export const getReferersRoute = lang => `/${getLang(lang)}/dashboard/referers`
export const getUsersRoute = lang => `/${getLang(lang)}/dashboard/users`
export const getUserRoute = (userId, lang) => `/${getLang(lang)}/dashboard/users/${userId}`
export const getBanksStatRoute = lang => `/${getLang(lang)}/dashboard/banks-stat`
export const getBinBanksRoute = lang => `/${getLang(lang)}/dashboard/bin-banks`
export const getBankEditRoute = (bankId, lang) => `/${getLang(lang)}/dashboard/banks/${bankId}/edit`
export const getApiSettingsRoute = lang => `/${getLang(lang)}/dashboard/api`
export const getArchiveSettingsRoute = lang => `/${getLang(lang)}/dashboard/archive`
export const getPaymentsSettingsRoute = lang => `/${getLang(lang)}/dashboard/payments`
export const getProfileSettingsRoute = lang => `/${getLang(lang)}/dashboard/profile`
export const getDashboardRouteGroup = lang => `/${getLang(lang)}/dashboard`
export const getServiceStatRoute = lang => `/${getLang(lang)}/dashboard/service-stat`
export const getUnknownRequestedBinsPageRoute = lang => `/${getLang(lang)}/dashboard/unknown-requested-bins`
export const getDashboardRoute = getApiSettingsRoute
