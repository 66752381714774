const lang = {
  ru: {
    word: 'Введите подтверждающее слово',
    errorWord: 'Подтверждающие слово указано не верно',
    apiKey: 'Ваш новый API ключ:',
    replace: '. Замените старый ключ на новый везде, где вы его используете',
    confirmation: 'Подтверждение',
    enter: 'Введите слово "YES"',
    newKey: 'Изменить ключ API'
  },
  en: {
    word: 'Enter the confirmation word',
    successWord: 'Confirming word is incorrect',
    apiKey: 'Your new API key:',
    replace: '. Replace the old key for the new one wherever you use it',
    confirmation: 'Confirmation',
    enter: 'Enter the word "YES"',
    newKey: 'Change API Key'
  }
}

export default lang
