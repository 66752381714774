import React from 'react'

const lang = {
  ru: {
    title: 'О компании',
    binKing:
      'BinKing — сервис для определения логотипа банка, цветов банка, номера телефона и прочего по номеру карты.',
    contacts: 'Контакты',
    details: 'Реквизиты организации',
    team: {
      title: 'Команда',
      members: {
        sd: {
          name: 'Сергей Дмитриев',
          desc: 'CEO/CTO',
        },
        am: {
          name: 'Алексей Михнович',
          desc: 'CPO',
        },
        ps: {
          name: 'Павел Шароватов',
          desc: 'Lead Developer',
        },
      },
    },
    text: (
      <>
        Название организации: ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ ДМИТРИЕВ СЕРГЕЙ ИГОРЕВИЧ
        <br />
        Юридический адрес организации: Российская Федерация, 443013, САМАРСКАЯ ОБЛ, Г САМАРА, УЛ МАГНИТОГОРСКАЯ, д. 1,
        кв. 122
        <br />
        ИНН: 631110961004
        <br />
        Расчетный счет: 40802810100000152466
        <br />
        ОГРН: 317631300071877
        <br />
        Банк: АО «Тинькофф Банк»
        <br />
        БИК банка: 044525974
        <br />
        ИНН банка: 7710140679
        <br />
        Корреспондентский счет банка: 30101810145250000974
        <br />
        Юридический адрес банка: 127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26
      </>
    ),
  },
  en: {
    title: 'About the company',
    binKing:
      'BinKing - a service for determination of bank logo, bank colours, phone number and other details by the card number.',
    contacts: 'Contacts',
    details: 'Contact details of organization',
    team: {
      title: 'Team',
      members: {
        sd: {
          name: 'Sergei Dmitriev',
          desc: 'CEO/CTO',
        },
        am: {
          name: 'Aleksei Mihnovits',
          desc: 'CPO',
        },
        ps: {
          name: 'Pavel Sharovatov',
          desc: 'Lead Developer',
        },
      },
    },
    text: (
      <>
        Organization name: BINBASE LTD
        <br />
        The legal address of organization: 41 Florence Avenue, Hove, England, BN3 7GX
        <br />
        Company number: 14298599
      </>
    ),
  },
}

export default lang
