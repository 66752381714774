const lang = {
  ru: {
    title: 'Сайты пользователей',
    data: 'Дата',
    address: 'Адрес',
    user: 'Пользователь',
  },
  en: {
    title: 'Websites of users',
    data: 'Date',
    address: 'Address',
    user: 'User',
  }
}

export default lang
