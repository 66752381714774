export const hasPerm = (user, permName) => {
  if (!user || !user.permissions) return false
  return !!user.permissions.find(p => p === 'PERM_ROOT' || p === permName)
}

export const canCreateBanks = user => hasPerm(user, 'PERM_CREATE_BANKS')

export const canEditBanks = user => hasPerm(user, 'PERM_EDIT_BANKS')

export const canViewBanks = user => hasPerm(user, 'PERM_VIEW_BANKS')

export const canRemoveBanks = user => hasPerm(user, 'PERM_REMOVE_BANKS')

export const canApproveBanks = user => hasPerm(user, 'PERM_APPROVE_BANKS')

export const canActualizeBanks = user => hasPerm(user, 'PERM_ACTUALIZE_BANKS')

export const canViewBanksLogs = user => hasPerm(user, 'PERM_VIEW_BANKS_LOGS')

export const canViewStat = user => hasPerm(user, 'PERM_VIEW_STAT')

export const canViewBins = user => hasPerm(user, 'PERM_VIEW_BINS')

export const canViewUsers = user => hasPerm(user, 'PERM_VIEW_USERS')

export const canViewReferers = user => hasPerm(user, 'PERM_VIEW_REFERERS')

export const canViewRequestedBins = user => hasPerm(user, 'PERM_VIEW_REQUESTED_BINS')
