import React from 'react'
import MainSegment from 'components/MainSegment'
import RichText from 'components/RichText'
import PageWrapper from 'components/PageWrapper'
import useQuery from 'lib/useQuery'
import Helmet from 'components/Helmet'
import Banks from 'components/Banks'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const DocsBanksPage = () => {
  const i18n = useLang(lang)
  const queryResult = useQuery('getBanksPublicInfo')
  const { banksCountries } = queryResult
  return (
    <PageWrapper queryResult={queryResult}>
      {() => (
        <MainSegment title={i18n('title')}>
          <Helmet title={i18n('title')} />
          <RichText>
            <Banks banksCountries={banksCountries} />
          </RichText>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default DocsBanksPage
