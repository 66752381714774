import binking from 'binking'

binking.setDefaultOptions({
  strategy: 'api',
  apiKey: process.env.REACT_APP_BINKING_API_KEY,
  // sandbox: true
})

binking.setValidationErrors({
  CARD_NUMBER_REQUIRED: 'Укажите номер вашей банковской карты',
  CARD_NUMBER_INVALID: 'Номер карты содержит недопустимые символы',
  CARD_NUMBER_INCOMPLETE: 'Номер карты заполнен не до конца',
  CARD_NUMBER_OVERCOMPLETE: 'В номере карты слишком много символов',
  CARD_NUMBER_LUHN: 'В номере карты содержится опечатка',
  MONTH_REQUIRED: 'Укажите месяц истечения карты',
  MONTH_INVALID: 'Ошибка в месяце истечения карты',
  YEAR_REQUIRED: 'Укажите год истечения карты',
  YEAR_INVALID: 'Ошибка в годе истечения карты',
  YEAR_IN_PAST: 'Год указан в прошедшем времени',
  MONTH_IN_PAST: 'Месяц указан в прошедшем времени',
  CODE_REQUIRED: 'Укажите код безопасности',
  CODE_INVALID: 'Код безопасности указан неверно',
})

export default binking
