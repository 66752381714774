import React from 'react'
import MainSegment from 'components/MainSegment'
import Layout from 'components/Layout'
import RichText from 'components/RichText'
import Button, { Buttons } from 'components/Button'
import css from './styles.module.scss'
import pluginImagePath from 'assets/animate/9.svg'
import apiImagePath from 'assets/animate/12.svg'
import archiveImagePath from 'assets/animate/13.svg'
import {
  getApiSettingsRoute,
  getArchiveSettingsRoute,
  getDocsApiRoute,
  getDocsArchiveRoute,
  getPluginUrl,
  getSignUpRoute,
} from 'lib/routes'
import useQuery from 'lib/useQuery'
import Helmet from 'components/Helmet'
import PageWrapper from '../../components/PageWrapper'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import toMoney from '../../lib/toMoney'
import { getRequestsCountByMoney } from '../../lib/getRequestsCountByMoney'

const PricePage = () => {
  const i18n = useLang(lang)
  const queryResultMe = useQuery('getMe')
  const queryResultUsdPrice = useQuery('getUsdPrice')
  const { me } = queryResultMe
  const { usdPrice } = queryResultUsdPrice
  return (
    <PageWrapper
      queryResult={{ ...queryResultMe, loading: queryResultMe.loading || queryResultUsdPrice.loading }}
      meIncluded
    >
      {() => (
        <div className={css.price}>
          <Layout perple={true} minimal={true}>
            <Helmet title={i18n('price')} />
            <div className={css.wrapper}>
              <MainSegment title={i18n('price')}>
                <div className={css.item}>
                  <div className={css.image}>
                    <img src={apiImagePath} width="197px" alt="API" />
                  </div>
                  <div className={css.text}>
                    <RichText>
                      <h2>API</h2>
                      <ul className={css.link}>
                        <li>
                          {i18n('list', toMoney({ sumUsd: 0.004, usdPrice }))} {i18n('query')}.
                        </li>
                        <li>
                          {i18n('list2', toMoney({ sumUsd: 0.0015, usdPrice }))} {i18n('query')}.
                        </li>
                        <li>
                          {i18n('list3', toMoney({ sumUsd: 0.0008, usdPrice }))} {i18n('query')}.
                        </li>
                        <li>
                          {i18n('list4', toMoney({ sumUsd: 0.0006, usdPrice }))} {i18n('query')}.
                        </li>
                      </ul>
                      <p>{i18n('queryApi')}</p>
                      <p>
                        {i18n(
                          'giftApi',
                          toMoney({ sumUsd: (me && me.giftSum) || 4, usdPrice }),
                          getRequestsCountByMoney((me && me.giftSum) || 4)
                        )}
                      </p>
                      <Buttons className={css.left}>
                        <Button to={me ? getApiSettingsRoute() : getSignUpRoute(getApiSettingsRoute())}>
                          {i18n('getAPIKey')}
                        </Button>
                        <Button to={getDocsApiRoute()} buttonStyle="violet">
                          {i18n('APIInstructions')}
                        </Button>
                      </Buttons>
                    </RichText>
                  </div>
                </div>
                <div className={css.item}>
                  <div className={css.image}>
                    <img src={archiveImagePath} width="197px" alt={i18n('db')} />
                  </div>
                  <div className={css.text}>
                    <RichText>
                      <h2>{i18n('db')}</h2>
                      <p>
                        {i18n('archivePrice', toMoney({ sumUsd: 350, usdPrice }))}{' '}
                        {i18n('allPrice', toMoney({ sumUsd: 1300, usdPrice }))} {i18n('allPrice2')}
                      </p>
                      <Buttons className={css.left}>
                        <Button to={me ? getArchiveSettingsRoute() : getSignUpRoute(getArchiveSettingsRoute())}>
                          {i18n('saveArchive')}
                        </Button>
                        <Button to={getDocsArchiveRoute()} buttonStyle="violet">
                          {i18n('archiveInstructions')}
                        </Button>
                      </Buttons>
                    </RichText>
                  </div>
                </div>
                <div className={css.item}>
                  <div className={css.image}>
                    <img src={pluginImagePath} width="120px" alt={i18n('plugin')} />
                  </div>
                  <div className={css.text}>
                    <RichText>
                      <h2>{i18n('plugin')}</h2>
                      <p>{i18n('pluginText')}</p>
                      <Button buttonStyle="git" href={getPluginUrl()}>
                        {i18n('gitHub')}
                      </Button>
                    </RichText>
                  </div>
                </div>
              </MainSegment>
            </div>
          </Layout>
        </div>
      )}
    </PageWrapper>
  )
}

export default PricePage
