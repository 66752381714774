import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'

const Alert = ({
  type,
  title,
  message,
  className,
  children,
  hidden,
  ...restProps
}) =>
  hidden ? null : (
    <div
      className={cn({
        [css.alert]: true,
        [css[type]]: !!type,
        [className]: !!className,
      })}
      {...restProps}
    >
      {!!title && <p className={css.title}>{title}</p>}
      {(!!message || !!children) && (
        <div className={css.message}>{message || children}</div>
      )}
    </div>
  )

export default Alert
