/* eslint-disable no-useless-escape */
import React from 'react'
import MainSegment from 'components/MainSegment'
import RichText from 'components/RichText'
import Link from 'components/Link'
import { getPluginUrl, getArchiveSettingsRoute } from 'lib/routes'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import Helmet from 'components/Helmet'
import useGlobalState from 'lib/globalState'

const DocsArchivePage = () => {
  const [globalState] = useGlobalState()
  const langName = globalState.lang
  return langName === 'ru' ? (
    <MainSegment title="Инструкции к архиву">
      <Helmet title="Инструкции к архиву" />
      <RichText>
        <p>
          Купить и скачать архив можно{' '}
          <Link type="solid" to={getArchiveSettingsRoute()}>
            на странице «Архив с базой данных».
          </Link>{' '}
          Архив можно приобрести с банками всех стран сразу, или отдельно с банками нужных стран.
        </p>
        <p>
          В состав архива входят логотипы банков в формате SVG, JSON файлы со свойствами банков (цвета для создания
          формы, телефон, сайт и прочее), JSON файлы с принадлежащими к каждому конкретному банку начальные цифры номера
          карты.
        </p>
        <p>
          Архив содержит в себе всё, что необходимо вам, чтобы полностью контролировать работу формы на своей стороне.
          Вы можете использовать их на фронте, а можете сделать своё API. Подробности о содержимом архива и способов его
          применения читайте ниже.
        </p>

        <h2>Содержимое архива</h2>
        <p>
          <Link type="solid" href="https://static.binking.io/archives/example.zip" target="_blank">
            Скачайте пример архива,
          </Link>{' '}
          чтобы ознакомиться с его содержимым.
        </p>

        <h3>Файлы с описанием банков</h3>
        <p>
          Путь <code>data/banks-COUNTY_CODE.json</code>, где <code>COUNTRY_CODE</code> это код страны, или
          <code>data/banks-all.json</code> для файла со всеми странами. В файле хранится объект, где каждый ключ, это
          алиас банка, а значением выступает объект с информацией о банке.
        </p>

        <SyntaxHighlighter style={github} language="javascript">{`{
  "ru-sberbank": {
    // Название банка на английском без пробелов + код страны
    bankAlias: "ru-sberbank",

    // Название банка на английском
    bankName: "Sberbank",

    // Название банка на местном языке
    bankLocalName: "Сбербанк России",

    // Основной цвет банка
    bankColor: "#1a9f29",

    // Все цвета банка
    bankColors: ["#1a9f29"],

    // Страна, в которой действует банк
    bankCountry: "ru",

    // Ссылка на сайт банка
    bankSite: "https://www.sberbank.ru",

    // sdcsdc
    bankPhone: "8 800 555-55-50",

    // Цвет для фона формы
    formBackgroundColor: "#1a9f29",

    // Цвета для градиентного фона формы
    formBackgroundColors: ["#1a9f29", "#0d7518"],

    // Фон скорее тёмный "dark" или светлый "light"
    formBackgroundLightness: "dark",

    // Цвет текста, который хорошо будет смотреться на фоне формы
    formTextColor: "#ffffff",

    // Цвет границ для полей в форме
    formBorderColor: "#ffffff",

    // Цветовая схема логотипа для формы "original" или "inverted"
    formLogoScheme: "inverted",
  },
  "ru-rosbank": {
    bankAlias: "ru-rosbank",
    ...
  },
  ...
}`}</SyntaxHighlighter>

        <h3>Файлы с префиксами карт</h3>
        <p>
          Путь <code>data/bins-COUNTY_CODE.json</code>, где <code>COUNTRY_CODE</code> это код страны, или{' '}
          <code>data/bins-all.json</code> для файла со всеми странами. В файле хранится объект, где ключи, это первые
          цифры номеров карт, а значения, это алиасы банков к котором принадлежат эти карты.
        </p>

        <SyntaxHighlighter style={github} language="javascript">{`{
  402333: 'ru-sberbank',
  546918: 'ru-sberbank',
  400812: 'ru-rosbank',
  677721: 'ru-rosbank',
  ...
}`}</SyntaxHighlighter>

        <h3>Папка с логотипами банков</h3>
        <p>
          Путь <code>banks-logos</code>. Файлы называются по схеме <code>BANK_ALIAS-SIZE-COLOR_SCHEME.(svg|png)</code>.
          Где <code>BANK_ALIAS</code>, это алиас банка, такой же, как и в значении <code>bankAlias</code> в файле с
          банками и бинами. <code>SIZE</code> — или <code>big</code> (большой логотип банка), или <code>small</code>{' '}
          (эмблема банка, которая хорошо смотрится будучи вписанной в квадрат). <code>COLOR_SCHEME</code> — или{' '}
          <code>original</code> (цветной логотип), или <code>inverted</code> (белый). Таким образом для каждого банка в
          папке находится 8 логотипа:
        </p>
        <ul>
          <li>ru-sberbank-big-original.svg</li>
          <li>ru-sberbank-big-inverted.svg</li>
          <li>ru-sberbank-small-original.svg</li>
          <li>ru-sberbank-small-inverted.svg</li>
          <li>ru-sberbank-big-original.png</li>
          <li>ru-sberbank-big-inverted.png</li>
          <li>ru-sberbank-small-original.png</li>
          <li>ru-sberbank-small-inverted.png</li>
        </ul>

        <h2>Использование с применением JS-плагина</h2>

        <p>
          Мы разработали
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            JS-плагин
          </Link>{' '}
          для упрощения работы с данными из архива. Он поможет вам, как с интеграцией даннных из архива в ваш фронтэнд,
          так и с интеграцией вашего собсвтенного API. Подробнее о том, как установить и применять плагин, читайте{' '}
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            в README на гитхабе.
          </Link>
        </p>
        <p>
          Кроме того плагин позволяет определять бренд банковской карты (Mastercard, Visa, ...), включает в себя все
          логотипы брендов и возвращает некоторые вспомогательные свойства, такие как маска номера карты, название
          защитного поля и прочее.
        </p>

        <h2>Создание собственнного API</h2>

        <p>
          Имея архив с базой данных, вы можете воспроизвести работу API на своей стороне. Ниже пример кода с
          использованием Node.js и фреймворка express. Работа показанного в примере API идентична работе того API,
          которое представляем мы. Вы можете настроить какое вам угодно взаимодействие с этим API, а можете использовать{' '}
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            наш JS-плагин,
          </Link>{' '}
          который можно настроить на работу с этим API.
        </p>

        <SyntaxHighlighter style={github} language="javascript">{`const express = require('express')
const fs = require('fs')
const path = require('path')

const app = express()
const banks = fs.fileReadSync(path.resolve(__dirname, 'path/to/data/banks-all.json'))
const bins = fs.fileReadSync(path.resolve(__dirname, 'path/to/data/bins-all.json'))

const withLogos = bank => {
  return {
    ...bank,
    bankLogoBigOriginalSvg: bank.bankAlias + '-big-original.svg',
    bankLogoBigInvertedSvg: bank.bankAlias + '-big-inverted.svg',
    bankLogoSmallOriginalSvg: bank.bankAlias + '-small-original.svg',
    bankLogoSmallInvertedSvg: bank.bankAlias + '-small-inverted.svg',
    formBankLogoBigSvg: bank.bankAlias + '-big-' + bank.formLogoScheme + '.svg',
    formBankLogoSmallSvg: bank.bankAlias + '-small-' + bank.formLogoScheme + '.svg',
  }
}

app.get('/form', (req, res) => {
  const { cardNumber } = req.query
  const cardNumberCuted = cardNumber.replace(/\D/g, '').substr(0, 6)
  const bankAlias = bins[cardNumberCuted]
  const bank = banks[bankAlias]
  if (!bank) return res.send('null')
  res.send(withLogos(bank))
})

app.get('/bank', (req, res) => {
  const { bankAlias } = req.query
  const bank = banks[bankAlias]
  if (!bank) return res.send('null')
  res.send(withLogos(bank))
})

app.get('/banks', (req, res) => {
  const { banksAliases } = req.query
  const result = banksAliases.split(',').map(bankAlias => {
    const bank = banks[bankAlias]
    return bank ? withLogos(bank) : null
  })
  res.send(result)
})`}</SyntaxHighlighter>

        <h2>Использование на фронте</h2>

        <p>
          Суть использование данных из архива на фронте в обход API заключается в том, что вы добавляете содержимое JSON
          файлов прямо в свой JS код и ищите в них данные о банке, по номеру карты, который вводит пользователь. Проще
          всего это сделать используя{' '}
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            наш JS-плагин,
          </Link>{' '}
          но ниже представлен пример, как сделать это самостоятельно.
        </p>

        <SyntaxHighlighter
          style={github}
          language="javascript"
        >{`var banks = /* ...Содержимое файла banks-all.json... */
var bins = /* ...Содержимое файла bins-all.json... */
var cardNumberInput = document.querySelector(".card-number-input")
cardNumberInput.onkeyup = () => {
  var value = ncardNumberInput.value;
  var cardNumberCuted = cardNumber.replace(/\D/g, '').substr(0, 6);
  var bankAlias = bins[cardNumberCuted];
  var bank = banks[bankAlias];
  if (bank) {
     // ваш код для применения свойств банка к форме
  }
}`}</SyntaxHighlighter>
      </RichText>
    </MainSegment>
  ) : (
    <MainSegment title="Instructions for the archive">
      <Helmet title="Instructions for the archive" />
      <RichText>
        <p>
          You can buy and download the archive{' '}
          <Link type="solid" to={getArchiveSettingsRoute()}>
            on the page "Archive with the database".
          </Link>{' '}
          The archive can be purchased with the banks of all countries at once, or separately with the banks of the
          desired countries.
        </p>
        <p>
          The archive includes bank logos in SVG format, JSON files with bank properties (colors for creation of a form,
          a phone number, a website, etc.), and the JSON files with the initial digits of the card number belonging to
          each particular bank.
        </p>
        <p>
          The archive contains everything you need to fully control the operation of a form on your side. You can use
          them on the front, or you can make your own API. Read the details about the contents of archive and the ways
          of its application hereinafter.
        </p>

        <h2>Contents of archive </h2>
        <p>
          <Link type="solid" href="https://static.binking.io/archives/example-en.zip" target="_blank">
            Download a sample of archive,
          </Link>{' '}
          to view its content.
        </p>

        <h3>Files with description of banks</h3>
        <p>
          Path <code>data/banks-COUNTY_CODE.json</code>, where <code>COUNTRY_CODE</code> is the country code, or{' '}
          <code>data/banks-all.json</code> for a file with all countries. The file stores an object, where each key is
          an alias of the bank, and the value is an object with information about the bank.
        </p>

        <SyntaxHighlighter style={github} language="javascript">{`{
  "ru-sberbank": {
    // Bank name in English without spaces + country code
    bankAlias: "ru-sberbank",

    // Bank name in English without spaces 
    bankName: "Sberbank",

    // Bank name in local language
    bankLocalName: "Sberbank of Russia",

    // Primary bank color
    bankColor: "#1a9f29",

    // All colors of the bank
    bankColors: ["#1a9f29"],

    // The country in which the bank operates
    bankCountry: "ru",

    // Link to website of the bank
    bankSite: "https://www.sberbank.ru",

    // sdcsdc
    bankPhone: "8 800 555-55-50",

    // Colour for background of a form
    formBackgroundColor: "#1a9f29",

    // Colour for gradient background of a form
    formBackgroundColors: ["#1a9f29", "#0d7518"],

    // The background is rather dark "dark" or light "light"
    formBackgroundLightness: "dark",

    // Text colour that will appear attractive at the background of the form
    formTextColor: "#ffffff",

    // Colour of borders for fields in the form
    formBorderColor: "#ffffff",

    // Colour scheme of a logo for forms "original" or "inverted" 
    formLogoScheme: "inverted",
  },
  "ru-rosbank": {
    bankAlias: "ru-rosbank",
    ...
  },
  ...
}`}</SyntaxHighlighter>

        <h3>Files with prefixes of cards</h3>
        <p>
          The path <code>data/bins-COUNTY_CODE.json</code>, where <code>COUNTRY_CODE</code> is the country code, or{' '}
          <code>data/bins-all.json</code> for a file with all countries. The file stores an object where the keys are
          the first digits of card numbers, and the values are aliases of banks to which these cards belong.
        </p>

        <SyntaxHighlighter style={github} language="javascript">{`{
  402333: 'ru-sberbank',
  546918: 'ru-sberbank',
  400812: 'ru-rosbank',
  677721: 'ru-rosbank',
  ...
}`}</SyntaxHighlighter>

        <h3>Folder with bank logos</h3>
        <p>
          Path <code>banks-logos</code>. The files are named according to the scheme{' '}
          <code>BANK_ALIAS-SIZE-COLOR_SCHEME.(svg|png)</code>, where <code>BANK_ALIAS</code> is the bank alias, the same
          as in the <code>bankAlias</code> value in the file with banks and bins. <code>SIZE</code> — or{' '}
          <code>big</code> (large bank logo), or <code>small</code> (the emblem of the bank, which appears attractive
          when inscribed in a square). <code>COLOR_SCHEME</code> - or <code>original</code> (coloured logo), or{' '}
          <code>inverted</code> (white). Thus, the folder contains 8 logos for each bank:
        </p>
        <ul>
          <li>ru-sberbank-big-original.svg</li>
          <li>ru-sberbank-big-inverted.svg</li>
          <li>ru-sberbank-small-original.svg</li>
          <li>ru-sberbank-small-inverted.svg</li>
          <li>ru-sberbank-big-original.png</li>
          <li>ru-sberbank-big-inverted.png</li>
          <li>ru-sberbank-small-original.png</li>
          <li>ru-sberbank-small-inverted.png</li>
        </ul>

        <h2>Deployment with the JS Plugin</h2>

        <p>
          We have developed
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            a JS Plugin
          </Link>{' '}
          In order to simplify the work with information from the archive. It will help you both to integrate the data
          from the archive into your frontend and to integrate your own API. Read more about how to install and use the
          plugin.{' '}
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            in README on github.
          </Link>
        </p>
        <p>
          Besides, the plugin allows to identify the brand of a bank card (MasterCard, Visa, ...), it includes all brand
          logos and returns some auxiliary properties such as the mask of card number, the name of protective field and
          further.
        </p>

        <h2>Creation of your own API</h2>

        <p>
          While having an archive with a database, you can reproduce the work of the API on your side. Below is an
          example code with using the Node.js and the framework “express”. The operation of the API shown in the example
          is identical to operation of the API which we represent. You can customize any kind of interaction with this
          API, or you can use{' '}
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            our JS plugin,
          </Link>{' '}
          which can be configured to work with this API.
        </p>

        <SyntaxHighlighter style={github} language="javascript">{`const express = require('express')
const fs = require('fs')
const path = require('path')

const app = express()
const banks = fs.fileReadSync(path.resolve(__dirname, 'path/to/data/banks-all.json'))
const bins = fs.fileReadSync(path.resolve(__dirname, 'path/to/data/bins-all.json'))

const withLogos = bank => {
  return {
    ...bank,
    bankLogoBigOriginalSvg: bank.bankAlias + '-big-original.svg',
    bankLogoBigInvertedSvg: bank.bankAlias + '-big-inverted.svg',
    bankLogoSmallOriginalSvg: bank.bankAlias + '-small-original.svg',
    bankLogoSmallInvertedSvg: bank.bankAlias + '-small-inverted.svg',
    formBankLogoBigSvg: bank.bankAlias + '-big-' + bank.formLogoScheme + '.svg',
    formBankLogoSmallSvg: bank.bankAlias + '-small-' + bank.formLogoScheme + '.svg',
  }
}

app.get('/form', (req, res) => {
  const { cardNumber } = req.query
  const cardNumberCuted = cardNumber.replace(/\D/g, '').substr(0, 6)
  const bankAlias = bins[cardNumberCuted]
  const bank = banks[bankAlias]
  if (!bank) return res.send('null')
  res.send(withLogos(bank))
})

app.get('/bank', (req, res) => {
  const { bankAlias } = req.query
  const bank = banks[bankAlias]
  if (!bank) return res.send('null')
  res.send(withLogos(bank))
})

app.get('/banks', (req, res) => {
  const { banksAliases } = req.query
  const result = banksAliases.split(',').map(bankAlias => {
    const bank = banks[bankAlias]
    return bank ? withLogos(bank) : null
  })
  res.send(result)
})`}</SyntaxHighlighter>

        <h2>Usage at the front</h2>

        <p>
          The essence of usage data from the archive on the front bypassing the API is that you add the contents of JSON
          files directly into your JS code and search in them the bank data by the card number that the user enters. The
          easiest way to do this is using{' '}
          <Link type="solid" target="_blank" href={getPluginUrl()}>
            our JS plugin,
          </Link>{' '}
          however hereunder you can find an example of how to do it by yourself.
        </p>

        <SyntaxHighlighter
          style={github}
          language="javascript"
        >{`var banks = /* ...Contents of a file banks-all.json... */
var bins = /* ...Contents of a file bins-all.json... */
var cardNumberInput = document.querySelector(".card-number-input")
cardNumberInput.onkeyup = () => {
  var value = ncardNumberInput.value;
  var cardNumberCuted = cardNumber.replace(/\D/g, '').substr(0, 6);
  var bankAlias = bins[cardNumberCuted];
  var bank = banks[bankAlias];
  if (bank) {
     // your code to apply the bank properties to the form
  }
}`}</SyntaxHighlighter>
      </RichText>
    </MainSegment>
  )
}

export default DocsArchivePage
