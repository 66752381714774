const lang = {
  ru: {
    color: 'Выбрать цвет',
  },
  en: {
    color: 'Choose color',
  }
}

export default lang
