import React from 'react'
import css from './styles.module.scss'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import useQuery from 'lib/useQuery'
import toHumanTx from 'lib/toHumanTx'
import InfiniteScroll from 'react-infinite-scroller'
import cn from 'classnames'
import { useLang } from 'lib/i18n'
import lang from './lang.js'

const Txs = () => {
  const i18n = useLang(lang)
  const { txs, pageInfo, loading: lodaingTxs, error, fetchMore } = useQuery('getTxs')
  const { usdPrice, loading: loadingUsdPrice } = useQuery('getUsdPrice')
  if (lodaingTxs || loadingUsdPrice) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!txs.length) return <Alert message={i18n('alert')} />
  return (
    <table className={css.txs}>
      <thead>
        <tr>
          <th>{i18n('date')}</th>
          <th>{i18n('sum')}</th>
          <th>{i18n('type')}</th>
        </tr>
      </thead>
      <InfiniteScroll
        threshold={250}
        element="tbody"
        loadMore={async () => {
          fetchMore({
            variables: {
              input: {
                cursor: pageInfo.cursor,
                limit: 30,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev
              return {
                getTxs: {
                  ...fetchMoreResult.getTxs,
                  txs: [...prev.getTxs.txs, ...fetchMoreResult.getTxs.txs],
                },
              }
            },
          })
        }}
        hasMore={pageInfo.hasNextPage}
        loader={
          <tr key="loader">
            <td colSpan="3">
              <Loader type="section" />
            </td>
          </tr>
        }
      >
        {txs.map((tx, i) => {
          const humanTx = toHumanTx(tx, i18n('lang'), usdPrice)
          return (
            <tr key={i} className={css.tx}>
              <td className={css.date}>{humanTx.date}</td>
              <td
                className={cn({
                  [css.sum]: true,
                  [css.income]: tx.sum > 0,
                  [css.outcome]: tx.sum < 0,
                })}
              >
                {humanTx.sum}
              </td>
              <td>{humanTx.type}</td>
              {/*<td>{tx.extra}</td>*/}
            </tr>
          )
        })}
      </InfiniteScroll>
    </table>
  )
}

export default Txs
