import React, { useEffect } from 'react'
import NotFoundPage from 'pages/NotFoundPage'
import MainSegment from 'components/MainSegment'
import Loader from 'components/Loader'
import useQuery from 'lib/useQuery'
import headerBackgroundPath from 'assets/bgOne.png'
import { useHistory } from 'react-router-dom'
import { getDashboardRoute, getSignInRoute } from 'lib/routes'
import isFunction from 'lodash/isFunction'
import css from './styles.module.scss'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const PageWrapper = ({
  authorizedOnly,
  redirerctUnauthorized,
  redirerctAuthorized,
  checkAccess,
  checkExists,
  checkExistsTitle,
  checkExistsText,
  queryResult = {},
  meIncluded,
  children,
}) => {
  const i18n = useLang(lang)
  const history = useHistory()
  const skipMeQuery =
    (!authorizedOnly &&
      !redirerctUnauthorized &&
      !redirerctAuthorized &&
      !checkAccess) ||
    !!meIncluded
  const { me: meFromLocalQuery, loading: meLoading, error: meError } = useQuery(
    'getMe',
    {
      skip: skipMeQuery,
    }
  )
  const loading = meLoading || queryResult.loading
  const error = meError || queryResult.error
  const queryResultCombined = { me: meFromLocalQuery, ...queryResult }
  const me = queryResultCombined.me
  let redirectTo = false
  if (me && redirerctAuthorized) {
    redirectTo =
      typeof redirerctAuthorized === 'string'
        ? redirerctAuthorized
        : getDashboardRoute()
  }
  if (!me && redirerctUnauthorized) {
    redirectTo =
      typeof redirerctUnauthorized === 'string'
        ? redirerctUnauthorized
        : getSignInRoute()
  }
  useEffect(
    () => {
      if (redirectTo) history.push(redirectTo)
    },
    [redirectTo, history]
  )
  if (loading || redirectTo) return <Loader type="page" />

  if (error) {
    return history.location.pathname === '/' ? (
      <div className={css.error}>
        <h1 className={css.title}>{i18n('error')}</h1>
        <p>{error.humanMessage || error.message || error}</p>
          <div className={css.background}>
            <img
              src={headerBackgroundPath}
              alt="background"
            />
          </div>

      </div>
    ) : (
      <div className={css.errorBlack}>
        <h1 className={css.title}>{i18n('error')}</h1>
        <p>{error.humanMessage || error.message || error}</p>
      </div>
    )
  }
  if (!me && authorizedOnly)
    return (
      <MainSegment title={i18n('authorizedOnly')}>
        <p>
          {i18n('authorizedOnlyText')}
        </p>
      </MainSegment>
    )
  if (checkAccess) {
    const accessDenied = !checkAccess(queryResultCombined)
    if (accessDenied)
      return (
        <MainSegment title={i18n('access')}>
          <p>{i18n('accessText')}</p>
        </MainSegment>
      )
  }
  if (checkExists) {
    const notExists = !checkExists(queryResultCombined)
    if (notExists)
      return <NotFoundPage title={checkExistsTitle} text={checkExistsText} />
  }
  return isFunction(children) ? children(queryResultCombined) : children
}

export default PageWrapper
