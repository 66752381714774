import React, { useState, useRef } from 'react'
import css from './styles.module.scss'
import Link from 'components/Link'
import Button from 'components/Button'
import britain from 'assets/UK.png'
import russia from 'assets/Russia.png'
import {
  getHomeRoute,
  getDocsIntroRoute,
  getSignInRoute,
  getDashboardRoute,
  getGuideRoute,
  getPriceRoute,
  getPrivacyPolicyRoute,
  getTermsOfUseRoute,
} from 'lib/routes'
import logoPath from 'assets/logo.png'
import logoPath2x from 'assets/logo@2x.png'
import { ReactComponent as SignIn } from 'assets/account.svg'
import { ReactComponent as Close } from 'assets/close.svg'
import useQuery from 'lib/useQuery'
import useLogout from 'lib/useLogout'
import useDropdown from 'lib/useDropdown'
import useGlobalState from 'lib/globalState'
import lang from './lang.js'
import { useLang, switchLang } from 'lib/i18n'
import cn from 'classnames'
import logoPerplePath from 'assets/logo-perple.png'
import logoPerplePath2x from 'assets/logo-perple@2x.png'
// import closeImage from 'assets/menu.png'
import menuBackgroundCube from 'assets/menuBackground.png'
import { useLocation, useHistory } from 'react-router-dom'
import useMutation from '../../lib/useMutation'

const Header = ({ perple }) => {
  const logout = useLogout()
  const i18n = useLang(lang)
  const location = useLocation()
  const history = useHistory()
  const [openMenu, setOpenMenu] = useState(false)
  const [isDropdownLangOpen, setDropdownLangOpen] = useState(false)
  const { me, loading } = useQuery('getMe')
  const [changeMyLang] = useMutation('changeMyLang')
  const [globalState, dispatchGlobalAction] = useGlobalState()
  const langName = globalState.lang
  const dropdownLangRef = useRef()
  const langRef = useRef()
  useDropdown(langRef, dropdownLangRef, isDropdownLangOpen, setDropdownLangOpen)
  return (
    <div className={cn({ [css.header]: true, [css.headerLk]: perple })}>
      <div className={css.wrapper}>
        <Link className={css.logo} currentClassName={css.current} to={getHomeRoute()}>
          <img
            width={116}
            height={24}
            src={perple ? logoPerplePath : logoPath}
            srcSet={perple ? logoPerplePath2x + ' 2x' : logoPath2x + ' 2x'}
            className={css.logoImage}
            alt="BinKing"
          />
        </Link>
        {!loading && (
          <>
            <div className={css.menu}>
              <Link className={css.link} currentClassName={css.current} to={getHomeRoute()}>
                {i18n('home')}
              </Link>
              <Link className={css.link} currentClassName={css.current} to={getGuideRoute()}>
                {i18n('guid')}
              </Link>
              <Link className={css.link} currentClassName={css.current} to={getDocsIntroRoute()}>
                {i18n('documents')}
              </Link>
              <Link className={css.link} currentClassName={css.current} to={getPriceRoute()}>
                {i18n('price')}
              </Link>
            </div>

            <div className={css.signAndLang}>
              <div className={css.lang}>
                <a href="#null" className={css.langLink} ref={langRef} onClick={e => e.preventDefault()}>
                  <span>
                    {langName === 'ru' ? (
                      <img src={russia} alt="ru" width="76px" height="auto" />
                    ) : langName === 'en' ? (
                      <img src={britain} alt="en" width="76px" height="auto" />
                    ) : (
                      <img src={britain} alt="en" width="76px" height="auto" />
                    )}
                  </span>
                </a>
                <div ref={dropdownLangRef} className={cn({ [css.dropdown]: true, [css.hidden]: !isDropdownLangOpen })}>
                  <Link
                    onClick={e => {
                      e.preventDefault()
                      switchLang('ru', dispatchGlobalAction, location, history, changeMyLang, me)
                      setDropdownLangOpen(false)
                    }}
                  >
                    <img src={russia} alt="ru" width="76px" height="auto" />
                  </Link>
                  <Link
                    onClick={e => {
                      e.preventDefault()
                      switchLang('en', dispatchGlobalAction, location, history, changeMyLang, me)
                      setDropdownLangOpen(false)
                    }}
                  >
                    <img src={britain} alt="en" width="76px" height="auto" />
                  </Link>
                </div>
              </div>
              {!me ? (
                <div className={css.sign}>
                  <Link className={cn(css.link, css.enter)} currentClassName={css.current} to={getSignInRoute()}>
                    <SignIn className={css.icon} /> <span>{i18n('login')}</span>
                  </Link>
                  <Button
                    mobile
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  />
                </div>
              ) : (
                <div className={css.sign}>
                  <Link className={cn(css.link, css.lk)} currentClassName={css.current} to={getDashboardRoute()}>
                    <SignIn className={css.icon} /> <span>{i18n('lk')}</span>
                  </Link>
                  <Link
                    className={cn(css.link, css.enter)}
                    onClick={e => {
                      e.preventDefault()
                      logout()
                    }}
                  >
                    <span>{i18n('logout')}</span>
                  </Link>
                  <Button
                    mobile
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {!!openMenu && (
        <div className={css.mobileMneuWrap}>
          <div className={css.mobileHead}>
            <Link className={css.logo} currentClassName={css.current} to={getHomeRoute()}>
              <img
                src={perple ? logoPerplePath : logoPath}
                srcSet={perple ? logoPerplePath2x + ' 2x' : logoPath2x + ' 2x'}
                className={css.logoImage}
                alt="BinKing"
              />
            </Link>
            <div
              className={css.close}
              onClick={() => {
                setOpenMenu(false)
              }}
            >
              <Close />
            </div>
          </div>
          <div className={css.mobileMneu}>
            <Link className={css.link} currentClassName={css.current} onClick={() => setOpenMenu(false)} to={getHomeRoute()}>
              {i18n('home')}
            </Link>
            <Link className={css.link} currentClassName={css.current} onClick={() => setOpenMenu(false)} to={getGuideRoute()}>
              {i18n('guid')}
            </Link>
            <Link className={css.link} currentClassName={css.current} onClick={() => setOpenMenu(false)} to={getDocsIntroRoute()}>
              {i18n('documents')}
            </Link>
            <Link className={css.link} currentClassName={css.current} onClick={() => setOpenMenu(false)} to={getPriceRoute()}>
              {i18n('price')}
            </Link>
            {!me ? (
              <Link className={cn(css.link, css.enter)} currentClassName={css.current} onClick={() => setOpenMenu(false)} to={getSignInRoute()}>
                {i18n('login')}
              </Link>
            ) : (
              <>
                <Link className={cn(css.link, css.lk)} currentClassName={css.current} onClick={() => setOpenMenu(false)} to={getDashboardRoute()}>
                  {i18n('lk')}
                </Link>
                <Link
                  className={cn(css.link, css.enter)}
                  onClick={e => {
                    setOpenMenu(false)
                    e.preventDefault()
                    logout()
                  }}
                >
                  {i18n('logout')}
                </Link>
              </>
            )}
          </div>
          <div className={css.copyright}>
            <p>
              {i18n('doneByStart')}{' '}
              <Link href={'https://10.com.ru'} color={perple ? '' : 'white'}>
                {i18n('doneByEnd')}
              </Link>{' '}
              © 2021 BinKing.
            </p>
            <div className={css.links}>
              <Link className={css.link} to={getTermsOfUseRoute()}>
                {i18n('police')}
              </Link>
              <Link className={css.link} to={getPrivacyPolicyRoute()}>
                {i18n('userDocs')}
              </Link>
            </div>
          </div>
          <div className={css.backgroundCube}>
            <img src={menuBackgroundCube} alt="backgroundCube" />
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
