import React from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import Input from 'components/Input'
import Form, { FormItems, useForm } from 'components/Form'
import { useLang } from '../../lib/i18n'
import lang from './lang'
import yup from '../../lib/validation'
import { Deprecated } from 'components/Deprecated'

const ReplenishBalancePP = ({ temp, me, amount }) => {
  // const i18n = useLang(lang)
  // const apolloClient = useApolloClient()
  // const { formik } = useForm({
  //   initialValues: {
  //     amount: amount || '10',
  //   },
  //   validationSchema: yup.object({
  //     amount: yup
  //       .number()
  //       .min(0.1)
  //       .max(10000)
  //       .required(),
  //   }),
  // })
  return (
    <Deprecated />
    // <PayPalScriptProvider options={{ 'client-id': process.env.REACT_APP_PP_CLIENT_ID }}>
    //   <Form formik={formik}>
    //     <FormItems>
    //       <Input label={i18n('sum')} name="amount" formik={formik} />
    //       {!Object.keys(formik.errors).length && (
    //         <PayPalButtons
    //           forceReRender={[formik.values.amount]}
    //           createOrder={(data, actions) => {
    //             return fetch(process.env.REACT_APP_BACKEND_URI + '/paypal-create-order', {
    //               method: 'post',
    //               headers: {
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //               },
    //               body: JSON.stringify({
    //                 type: 'api',
    //                 amount: parseFloat(formik.values.amount).toFixed(2),
    //                 userId: me.id,
    //                 redirect: window.location.href,
    //               }),
    //             })
    //               .then(response => response.json())
    //               .then(order => order.id)
    //           }}
    //           onApprove={(data, actions) => {
    //             return fetch(process.env.REACT_APP_BACKEND_URI + '/paypal-capture-payment', {
    //               method: 'post',
    //               headers: {
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //               },
    //               body: JSON.stringify({
    //                 orderId: data.orderID,
    //               }),
    //             })
    //               .then(response => response.json())
    //               .then(orderData => {
    //                 return apolloClient.reFetchObservableQueries()
    //               })
    //           }}
    //         />
    //       )}
    //     </FormItems>
    //   </Form>
    // </PayPalScriptProvider>
  )
}

export default ReplenishBalancePP
