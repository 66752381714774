const lang = {
  ru: {
    banks: 'Банки',
    name: 'Название',
    relevance: 'Актуальность',
    check: 'Проверка',
    logo: 'Логотипы',
    bins: 'Бинов',
    readiness: 'Готовность',
    days: ['день', 'дня', 'дней'],
    complit: 'Проврен',
    needCheck: 'Требует проверки',
    thereLogos: 'Есть логотипы',
    noLogos: 'Нет логотипов',
    readi: 'Готово',
    notReady: 'Не готово',
    country: 'Страна',
    alias: 'Алиас',
    namesInBins: 'Доп. названия',
  },
  en: {
    banks: 'Banks',
    name: 'Name',
    relevance: 'Relevance',
    check: 'Verification',
    logo: 'Logos',
    bins: 'Bins',
    readiness: 'Readiness',
    days: ['day', 'day', 'days'],
    complit: 'Verified',
    needCheck: 'Must be verified',
    thereLogos: 'There are logos',
    noLogos: 'No logos',
    readi: 'Ready',
    notReady: 'Not ready',
    country: 'Country',
    alias: 'Alias',
    namesInBins: 'Extra names',
  },
}

export default lang
