import React from 'react'
import css from './styles.module.scss'
import Button from 'components/Button'
import { FormikProvider, FieldArray as FormikFieldArray } from 'formik'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const FieldArray = ({
  label,
  formik,
  name,
  hint,
  onChange,
  fullWidth,
  addButtonText = 'Добавить элемент',
  removeButtonText = 'Удалить',
  children,
  newItemDefaultValue = '',
  attention,
  ...restProps
}) => {
  const i18n = useLang(lang)
  const error = formik.errors[name]
  const valuesArray = formik.values[name] || []
  const touched = formik.touched[name]
  const getItemName = i => {
    return `${name}.${i}`
  }
  return (
    <FormikProvider value={formik}>
      <FormikFieldArray
        name={name}
        render={arrayHelpers => (
          <div className={css.fieldArray}>
            {!!label && (
              <label htmlFor={name} className={css.label}>
                {label}
                {!attention ? null : (
                  <>
                    {' '}
                    <span className={css.attention}>{attention === true ? i18n('attention') : attention}</span>
                  </>
                )}
              </label>
            )}
            <div className={css.items}>
              {valuesArray.map((value, i) => (
                <div key={i} className={css.item}>
                  <div className={css.field}>{children({ i, name: getItemName(i) })}</div>
                  <div className={css.removePlace}>
                    <Button type="button" color="gray" onClick={() => arrayHelpers.remove(i)}>
                      {removeButtonText}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div className={css.buttonPlace}>
              <Button type="button" color="gray" onClick={() => arrayHelpers.push(newItemDefaultValue)}>
                {addButtonText}
              </Button>
            </div>
            {!!error && touched && <p className={css.error}>{error}</p>}
            {!!hint && <p className={css.hint}>{hint}</p>}
          </div>
        )}
      />
    </FormikProvider>
  )
}

export default FieldArray
