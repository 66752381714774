import React from 'react'
import MainSegment from 'components/MainSegment'
import PagePadding from 'components/PagePadding'
import PageWrapper from 'components/PageWrapper'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Link from 'components/Link'
import Form, { FormItems, useForm } from 'components/Form'
import * as Yup from 'yup'
import useMutation from 'lib/useMutation'
import { getSignInRoute } from 'lib/routes'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ResetPasswordPage = ({ match }) => {
  const i18n = useLang(lang)
  const [resetPassword] = useMutation('resetPassword', {
    errorCodes: {
      ERR_NOT_FOUND: i18n('link'),
    },
  })
  const { passwordResetToken } = match.params
  const { formik, buttonProps, alertProps, success } = useForm({
    initialValues: {
      newPassword: '',
      newPasswordAgain: '',
    },
    onSubmit: ({ newPassword }) => {
      return resetPassword({
        variables: {
          input: {
            newPassword,
            passwordResetToken,
          },
        },
      })
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required(i18n('required')),
      newPasswordAgain: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], i18n('error'))
        .required(i18n('required')),
    }),
    successMessage: (
      <>
        {i18n('new')}{' '}
        <Link type="solid" nowrap to={getSignInRoute()}>
          {i18n('enter')}
        </Link>{' '}
        {i18n('help')}
      </>
    ),
    resetOnSuccess: true,
    forgetSuccessDelay: 0,
  })
  return (
    <PagePadding>
      <Helmet title={i18n('setNewPasswoed')} />
      <PageWrapper redirerctAuthorized>
        <MainSegment title={i18n('setNewPasswoed')}>
          {!success ? (
            <Form formik={formik}>
              <FormItems>
                <Input label={i18n('newPasswoed')} name="newPassword" type="password" formik={formik} />
                <Input label={i18n('confirm')} name="newPasswordAgain" type="password" formik={formik} />
                <Alert {...alertProps} />
                <Button {...buttonProps}>{i18n('setPassword')}</Button>
              </FormItems>
            </Form>
          ) : (
            <Alert {...alertProps} />
          )}
        </MainSegment>
      </PageWrapper>
    </PagePadding>
  )
}

export default ResetPasswordPage
