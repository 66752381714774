const lang = {
  ru: {
    error: 'Ой, ошибка',
    authorizedOnly: 'Только для авторизованных',
    authorizedOnlyText: `Эта страница доступна только авторизованным пользователям. Войдите или
    зарегистрируйтесь.`,
    access: 'Доступ запрещён',
    accessText: 'У вас недостаточно прав для просмотра этой страницы.',
  },
  en: {
    error: 'Oops, an error',
    authorizedOnly: 'Only for authorized users',
    authorizedOnlyText: `This page is available only for authorized users. Login or Register.`,
    access: 'Access denied',
    accessText: 'You are not authorized to view this page.',
  }
}

export default lang
