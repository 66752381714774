import React from 'react'
import css from './styles.module.scss'
import Link from 'components/Link'
import {
  getHomeRoute,
  getDocsIntroRoute,
  getDashboardRoute,
  getGuideRoute,
  getDocsArchiveRoute,
  getDocsApiRoute,
  getDocsBanksRoute,
  getPluginUrl,
  getCompanyRoute,
  getPriceRoute,
  getTermsOfUseRoute,
  getPrivacyPolicyRoute,
} from 'lib/routes'
import logoPath from 'assets/logo.png'
import facebook from 'assets/facebook.svg'
import logoPath2x from 'assets/logo@2x.png'
import footerBackgroundPath from 'assets/footerBackground.png'
import footerBackgroundPathTablet from 'assets/footerBackgroundTablet.png'
import footerBackgroundPathMobile from 'assets/footerBackgroundMobile.png'
import cn from 'classnames'
import useWindowSize from 'lib/useWindowSize'
import { useLang } from 'lib/i18n'
import lang from './lang.js'

const Footer = ({ minimal }) => {
  const i18n = useLang(lang)
  const windowSize = useWindowSize()
  const tablet = windowSize.width <= 1100
  const mobile = windowSize.width <= 900
  return (
    <div className={cn({ [css.footer]: true, [css.footerLk]: minimal })}>
      <div className={css.wrapper}>
        <Link className={css.logo} to={getHomeRoute()}>
          <img src={logoPath} srcSet={logoPath2x + ' 2x'} className={css.logoImage} alt="BinKing" />
        </Link>
        <div className={css.menus}>
          <ul>
            <li>
              <Link className={css.linkBold} to={getGuideRoute()}>
                {i18n('guid')}
              </Link>
            </li>
            <li>
              <Link className={css.linkBold} to={getPriceRoute()}>
                {i18n('price')}
              </Link>
            </li>
            <li>
              <Link className={css.linkBold} to={getCompanyRoute()}>
                {i18n('about')}
              </Link>
            </li>
            <li>
              <Link className={css.linkBold} to={getDashboardRoute()}>
                {i18n('lk')}
              </Link>
            </li>
            <li>
              <Link target="_blank" className={css.linkBold} href="https://www.facebook.com/binking.io">
                <img className={css.icon} src={facebook} alt="in" width="20px" height="20px" /> Facebook
              </Link>
            </li>
          </ul>
          <ul className={css.docs}>
            <li>
              <Link className={css.linkBold} to={getDocsIntroRoute()}>
                {i18n('docs')}
              </Link>
            </li>
            <li>
              <Link className={css.link} to={getDocsIntroRoute()}>
                {i18n('introduction')}
              </Link>
            </li>
            <li>
              <Link className={css.link} to={getDocsApiRoute()}>
                {i18n('apiGuid')}
              </Link>
            </li>
            <li>
              <Link className={css.link} to={getDocsArchiveRoute()}>
                {i18n('archiveGuid')}
              </Link>
            </li>
            <li>
              <Link className={css.link} to={getDocsBanksRoute()}>
                {i18n('bankList')}
              </Link>
            </li>
            <li>
              <Link className={css.link} href={getPluginUrl()}>
                {i18n('plugin')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={css.wrapper}>
        <div className={css.copyright}>
          <p>
            {i18n('made')}{' '}
            <Link href={'https://10.com.ru'} color="white">
              {i18n('nameProg')}
            </Link>{' '}
            {i18n('copy')}
          </p>
          <div className={css.links}>
            <Link className={css.link} to={getPrivacyPolicyRoute()}>
              {i18n('privacyPolicy')}
            </Link>
            <Link className={css.link} to={getTermsOfUseRoute()}>
              {i18n('terms')}
            </Link>
          </div>
        </div>
      </div>
      <div className={css.background}>
        <img
          src={mobile ? footerBackgroundPathMobile : tablet ? footerBackgroundPathTablet : footerBackgroundPath}
          alt="background"
        />
      </div>
    </div>
  )
}

export default Footer
