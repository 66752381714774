const lang = {
  ru: {
    title: 'Пользователь',
  },
  en: {
    title: 'User',
  }
}

export default lang
