import gql from 'graphql-tag'

export const meFragment = `
  fragment Me on User {
    id
    referrerCode
    giftSum
    lang
    email
    emailConfirmed
    permissions
    apiKey
    balance
    activatedAt
    apiUrls
    paymentMethod
    autoReplenishmentEnabled
    autoReplenishmentSum
    autoReplenishmentMonthlyLimit
    autoReplenishmentMinBalance
    bankCards {
      hash
      firstSix
      lastFour
      expDate
      brand
    }
    apiUsageMonth  {
      id
      totalSpent
      totalSpentRub
      apiRequestsCount
    }
    archivesAccess {
      endsAt
      country
      active
    }
    date
    country
    archivePriceVisible
  }
`
export const userFragment = `
  fragment User on User {
    id
    createdAt
    email
    emailConfirmed
    permissions
    balance
    activatedAt
    apiUrls
    autoReplenishmentEnabled
    autoReplenishmentSum
    autoReplenishmentMonthlyLimit
    autoReplenishmentMinBalance
    bankCards {
      hash
    }
    apiUsageMonth  {
      id
      totalSpent
      totalSpentRub
      apiRequestsCount
    }
    archivesAccess {
      endsAt
      country
      active
    }
    country
  }
`
export const bankFragment = `
   fragment Bank on Bank {
    id
    alias
    name
    localName
    logoBigOriginalSvg
    logoBigInvertedSvg
    logoSmallOriginalSvg
    logoSmallInvertedSvg
    logoBigOriginalPng
    logoBigInvertedPng
    logoSmallOriginalPng
    logoSmallInvertedPng
    color
    colors
    country
    site
    phone
    formBackgroundColor
    formBackgroundColors
    formBackgroundLightness
    formTextColor
    formLogoScheme
    formBorderColor
    namesInBins
    bins
    fulfilled
    actualizedAt
    approved
    binsCount
  }
`
export const bankLogFragment = `
   fragment BankLog on BankLog {
    id
    extra
    createdAt
    code
    user {
      id
      email
    }
    bank {
      id
      alias
    }
  }
`
export const getMeFragment = `
  fragment GetMe on Query {
    getMe {
      me {
        ...Me
      }
    }
  }
  ${meFragment}
`
export const getMeQuery = gql`
  query {
    ...GetMe
  }
  ${getMeFragment}
`
export const getMeOptions = { parser: [['me', 'getMe.me']] }
export const getMyIpCountryQuery = gql`
  query {
    getMyIpCountry {
      ipCountry
    }
  }
`
export const getMyIpCountryOptions = { parser: [['ipCountry', 'getMyIpCountry.ipCountry']] }
export const getUsdPriceQuery = gql`
  query {
    getUsdPrice {
      usdPrice
    }
  }
`
export const getUsdPriceOptions = { parser: [['usdPrice', 'getUsdPrice.usdPrice']] }
export const getUnknownRequestedBinsQuery = gql`
  query {
    getUnknownRequestedBins {
      requestedBins {
        iinStart
        bank {
          ...Bank
        }
        bin {
          id
          iinStart
          bankName
          country
          brand
        }
      }
    }
  }
  ${bankFragment}
`
export const getUnknownRequestedBinsOptions = {
  parser: [['requestedBins', 'getUnknownRequestedBins.requestedBins']],
}
export const getBanksFragment = `
  fragment GetBanks on Query {
    getBanks(input: $input) {
      banks {
        ...Bank
      }
    }
  }
  ${bankFragment}
`
export const getBanksQuery = gql`
  query ($input: GetBanksInput) {
    ...GetBanks
  }
  ${getBanksFragment}
`
export const getBanksOptions = { parser: [['banks', 'getBanks.banks']] }
export const getBankFragment = `
  fragment GetBank on Query {
    getBank(input: $input) {
      bank {
        ...Bank
      }
    }
  }
  ${bankFragment}
`
export const getBankQuery = gql`
  query ($input: GetBankInput!) {
    ...GetBank
  }
  ${getBankFragment}
`
export const getBankOptions = { parser: [['bank', 'getBank.bank']] }
export const getUserFragment = `
  fragment GetUser on Query {
    getUser(input: $input) {
      user {
        ...User
      }
    }
  }
  ${userFragment}
`
export const getUserQuery = gql`
  query ($input: GetUserInput!) {
    ...GetUser
  }
  ${getUserFragment}
`
export const getUserOptions = { parser: [['user', 'getUser.user']] }
export const getBanksLogsFragment = `
  fragment GetBanksLogs on Query {
    getBanksLogs(input: $input) {
      banksLogs {
        ...BankLog
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
  ${bankLogFragment}
`
export const getBanksLogsQuery = gql`
  query ($input: GetBanksLogsInput!) {
    ...GetBanksLogs
  }
  ${getBanksLogsFragment}
`
export const getBanksLogsOptions = {
  parser: [
    ['banksLogs', 'getBanksLogs.banksLogs'],
    ['pageInfo', 'getBanksLogs.pageInfo'],
  ],
}
export const getUsersFragment = `
  fragment GetUsers on Query {
    getUsers(input: $input) {
      users {
        ...User
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
  ${userFragment}
`
export const getUsersQuery = gql`
  query ($input: GetUsersInput) {
    ...GetUsers
  }
  ${getUsersFragment}
`
export const getUsersOptions = {
  parser: [
    ['users', 'getUsers.users'],
    ['pageInfo', 'getUsers.pageInfo'],
  ],
}
export const getReferersFragment = `
  fragment GetReferers on Query {
    getReferers(input: $input) {
      referers {
        id
        createdAt
        url
        user {
          id
          email
        }
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`
export const getReferersQuery = gql`
  query ($input: GetReferersInput) {
    ...GetReferers
  }
  ${getReferersFragment}
`
export const getReferersOptions = {
  parser: [
    ['referers', 'getReferers.referers'],
    ['pageInfo', 'getReferers.pageInfo'],
  ],
}
export const getBanksStatQuery = gql`
  query ($input: GetBanksStatInput) {
    getBanksStat(input: $input) {
      banksStat {
        id
        name
        country
        bankExists
        apiRequestsCount
      }
    }
  }
`
export const getBanksStatOptions = {
  parser: [['banksStat', 'getBanksStat.banksStat']],
}
export const getBinBanksQuery = gql`
  query ($input: GetBinBanksInput) {
    getBinBanks(input: $input) {
      binBanks {
        name
        country
        binsCount
      }
    }
  }
`
export const getBinBanksOptions = {
  parser: [['binBanks', 'getBinBanks.binBanks']],
}
export const getBanksPublicInfoQuery = gql`
  query {
    getBanksPublicInfo {
      banksCountries {
        countryCode
        banks {
          site
          name
          localName
        }
      }
    }
  }
`
export const getBanksPublicInfoOptions = {
  parser: [['banksCountries', 'getBanksPublicInfo.banksCountries']],
}
export const getServiceStatsQuery = gql`
  query {
    getServiceStats {
      serviceStats {
        date
        registrations
        activations
        starters
        leavers
        activeUsers
        regularUsers
        activePayers
        totalUsers
        apiRevenue
        archives
        archivesRevenue
        totalRevenue
        apiRequests
        newPayers
      }
    }
  }
`
export const getServiceStatsOptions = {
  parser: [['serviceStats', 'getServiceStats.serviceStats']],
}
export const getTxsQuery = gql`
  query getTxs($input: GetTxsInput) {
    getTxs(input: $input) {
      txs {
        id
        createdAt
        sum
        sumRub
        cloudPaymentsId
        payPalId
        cloudKassirId
        archiveCountry
        type
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`
export const getTxsOptions = {
  parser: [
    ['txs', 'getTxs.txs'],
    ['pageInfo', 'getTxs.pageInfo'],
  ],
}
export const getApiUsageMonthsQuery = gql`
  query getApiUsageMonths($input: GetApiUsageMonthsInput) {
    getApiUsageMonths(input: $input) {
      apiUsageMonths {
        id
        date
        apiRequestsCount
        totalSpent
        totalSpentRub
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`
export const getApiUsageMonthsOptions = {
  parser: [
    ['apiUsageMonths', 'getApiUsageMonths.apiUsageMonths'],
    ['pageInfo', 'getApiUsageMonths.pageInfo'],
  ],
}
export const getApiUsageDaysQuery = gql`
  query getApiUsageDays($input: GetApiUsageDaysInput) {
    getApiUsageDays(input: $input) {
      apiUsageDays {
        id
        date
        apiRequestsCount
        totalSpent
        totalSpentRub
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`
export const getApiUsageDaysOptions = {
  parser: [
    ['apiUsageDays', 'getApiUsageDays.apiUsageDays'],
    ['pageInfo', 'getApiUsageDays.pageInfo'],
  ],
}
export const getArchiveDownloadLinkQuery = gql`
  query ($input: GetArchiveDownloadLinkInput!) {
    getArchiveDownloadLink(input: $input) {
      link
    }
  }
`
export const signInMutation = gql`
  mutation ($input: SignInInput!) {
    signIn(input: $input) {
      token
      user {
        id
      }
    }
  }
`
export const signUpMutation = gql`
  mutation ($input: SignUpInput!) {
    signUp(input: $input) {
      token
      user {
        id
      }
    }
  }
`
export const confirmEmailMutation = gql`
  mutation ($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      token
      user {
        id
        emailConfirmed
      }
    }
  }
`
export const confirmNewEmailMutation = gql`
  mutation ($input: ConfirmNewEmailInput!) {
    confirmNewEmail(input: $input) {
      token
      user {
        id
        email
        emailConfirmed
      }
    }
  }
`
export const requestPasswordResetMutation = gql`
  mutation ($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`
export const resetPasswordMutation = gql`
  mutation ($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`
export const requestEmailChangingMutation = gql`
  mutation ($input: RequestEmailChangingInput!) {
    requestEmailChanging(input: $input) {
      me {
        id
      }
    }
  }
`
export const changeMyPasswordMutation = gql`
  mutation ($input: ChangeMyPasswordInput!) {
    changeMyPassword(input: $input) {
      me {
        id
      }
    }
  }
`
export const changeMyLangMutation = gql`
  mutation ($input: ChangeMyLangInput!) {
    changeMyLang(input: $input) {
      me {
        id
        lang
      }
    }
  }
`
export const createBankMutation = gql`
  mutation ($input: CreateBankInput!) {
    createBank(input: $input) {
      bank {
        ...Bank
      }
    }
  }
  ${bankFragment}
`
export const createBankOptions = { parser: [['bank', 'createBank.bank']] }
export const updateBankMutation = gql`
  mutation ($input: UpdateBankInput!) {
    updateBank(input: $input) {
      bank {
        ...Bank
      }
    }
  }
  ${bankFragment}
`
export const updateBankOptions = { parser: [['bank', 'updateBank.bank']] }
export const uploadSvgLogoMutation = gql`
  mutation ($input: UploadSvgLogoInput!) {
    uploadSvgLogo(input: $input) {
      uploadedSvgFile {
        path
      }
      uploadedPngFile {
        path
      }
    }
  }
`
export const uploadSvgLogoOptions = {
  parser: [
    ['uploadedSvgFile', 'uploadSvgLogo.uploadedSvgFile'],
    ['uploadedPngFile', 'uploadSvgLogo.uploadedPngFile'],
  ],
}
export const uploadPngLogoMutation = gql`
  mutation ($input: UploadPngLogoInput!) {
    uploadPngLogo(input: $input) {
      uploadedPngFile {
        path
      }
    }
  }
`
export const uploadPngLogoOptions = {
  parser: [['uploadedPngFile', 'uploadPngLogo.uploadedPngFile']],
}
export const actualizeBankMutation = gql`
  mutation ($input: ActualizeBankInput!) {
    actualizeBank(input: $input) {
      bank {
        ...Bank
      }
    }
  }
  ${bankFragment}
`
export const actualizeBankOptions = { parser: [['bank', 'actualizeBank.bank']] }
export const approveBankMutation = gql`
  mutation ($input: ApproveBankInput!) {
    approveBank(input: $input) {
      bank {
        ...Bank
      }
    }
  }
  ${bankFragment}
`
export const approveBankOptions = { parser: [['bank', 'approveBank.bank']] }
export const changeMyApiUrlsMutation = gql`
  mutation ($input: ChangeMyApiUrlsInput!) {
    changeMyApiUrls(input: $input) {
      me {
        id
        apiUrls
      }
    }
  }
`
export const changeMyApiUrlsOptions = { parser: [['me', 'changeMyApiUrls.me']] }
export const changeApiPaymentsSettingsMutation = gql`
  mutation ($input: ChangeApiPaymentsSettingsInput!) {
    changeApiPaymentsSettings(input: $input) {
      me {
        id
        autoReplenishmentEnabled
        autoReplenishmentSum
        autoReplenishmentMonthlyLimit
        autoReplenishmentMinBalance
      }
    }
  }
`
export const changeApiPaymentsSettingsOptions = {
  parser: [['me', 'changeApiPaymentsSettings.me']],
}
export const replenishBalanceMutation = gql`
  mutation ($input: ReplenishBalanceInput!) {
    replenishBalance(input: $input) {
      acsUrl
      paReq
      transactionId
      success
      me {
        id
        activatedAt
        balance
        bankCards {
          hash
          firstSix
          lastFour
          expDate
          brand
        }
      }
    }
  }
`
export const replenishBalanceOptions = {
  parser: [
    ['me', 'replenishBalance.me'],
    ['acsUrl', 'replenishBalance.acsUrl'],
    ['paReq', 'replenishBalance.paReq'],
    ['transactionId', 'replenishBalance.transactionId'],
    ['success', 'replenishBalance.success'],
  ],
}
export const buyArchiveMutation = gql`
  mutation ($input: BuyArchiveInput!) {
    buyArchive(input: $input) {
      acsUrl
      paReq
      transactionId
      success
      me {
        id
        activatedAt
        balance
        bankCards {
          hash
          firstSix
          lastFour
          expDate
          brand
        }
        archivesAccess {
          endsAt
          country
          active
        }
      }
    }
  }
`
export const buyArchiveOptions = {
  parser: [
    ['me', 'buyArchive.me'],
    ['acsUrl', 'buyArchive.acsUrl'],
    ['paReq', 'buyArchive.paReq'],
    ['transactionId', 'buyArchive.transactionId'],
    ['success', 'buyArchive.success'],
    ['archivesAccess', 'buyArchive.archivesAccess'],
  ],
}
export const addBankCardMutation = gql`
  mutation ($input: AddBankCardInput!) {
    addBankCard(input: $input) {
      acsUrl
      paReq
      transactionId
      success
      me {
        id
        activatedAt
        balance
        bankCards {
          hash
          firstSix
          lastFour
          expDate
          brand
        }
      }
    }
  }
`
export const addBankCardOptions = {
  parser: [
    ['me', 'addBankCard.me'],
    ['acsUrl', 'addBankCard.acsUrl'],
    ['paReq', 'addBankCard.paReq'],
    ['transactionId', 'addBankCard.transactionId'],
    ['success', 'addBankCard.success'],
  ],
}
export const removeBankCardMutation = gql`
  mutation ($input: RemoveBankCardInput!) {
    removeBankCard(input: $input) {
      me {
        id
        bankCards {
          hash
          firstSix
          lastFour
          expDate
          brand
        }
      }
    }
  }
`
export const removeBankCardOptions = {
  parser: [['me', 'removeBankCard.me']],
}
export const resendEmailConfirmationMutation = gql`
  mutation {
    resendEmailConfirmation
  }
`
export const changeMyApiKeyMutation = gql`
  mutation {
    changeMyApiKey {
      me {
        id
        apiKey
      }
    }
  }
`
export const changeMyApiKeyOptions = {
  parser: [['apiKey', 'changeMyApiKey.me.apiKey']],
}
export const createReplenishBalanceInvoiceMutation = gql`
  mutation ($input: CreateReplenishBalanceInvoiceInput!) {
    createReplenishBalanceInvoice(input: $input) {
      invoiceAccessToken
    }
  }
`
export const createReplenishBalanceInvoiceOptions = {
  parser: [['invoiceAccessToken', 'createReplenishBalanceInvoice.invoiceAccessToken']],
}
export const makeArchivePriceVisibleMutation = gql`
  mutation {
    makeArchivePriceVisible {
      me {
        id
        archivePriceVisible
      }
    }
  }
`
export const makeArchivePriceVisibleOptions = {
  parser: [['me', 'makeArchivePriceVisible.me']],
}
export const setPaymentMethodMutation = gql`
  mutation($input: SetPaymentMethodInput!) {
    setPaymentMethod(input: $input) {
      me {
        id
        paymentMethod
      }
    }
  }
`
export const setPaymentMethodOptions = {
  parser: [['me', 'setPaymentMethod.me']],
}
