const lang = {
  ru: {
    title: 'Статистика по банкам за месяц',
    name: 'Название',
    country: 'Страна',
    col: 'Количество запросов',
    bank: 'Банк есть',
    yes: 'Да',
    no: 'Нет',
  },
  en: {
    title: 'Statistics of banks per month',
    name: 'Name',
    country: 'Country',
    col: 'Number of requests',
    bank: 'There is a bank',
    yes: 'Yes',
    no: 'No',
  }
}

export default lang
