import React, { useEffect } from 'react'
import MainSegment from 'components/MainSegment'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import Button from 'components/Button'
import BuyArchive from 'components/BuyArchive'
import css from './styles.module.scss'
import useQuery from 'lib/useQuery'
import plural from 'lib/plural'
import toHumanCountry from 'lib/toHumanCountry'
import toHumanDate, { moment } from 'lib/toHumanDate'
import { useLazyQuery } from '@apollo/react-hooks'
import { getArchiveDownloadLinkQuery } from 'lib/gqls'
import Helmet from 'components/Helmet'
import Link from 'components/Link'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import { Deprecated } from 'components/Deprecated'
// import cn from 'classnames'

const ArchiveSettingsPage = () => {
  const i18n = useLang(lang)
  const queryResult = useQuery('getMe')
  const { me } = queryResult
  const [getArchiveDownloadLink, { loading, data, refetch }] = useLazyQuery(getArchiveDownloadLinkQuery)
  const link = data && data.getArchiveDownloadLink.link
  useEffect(() => {
    if (!link) return
    const anchor = document.createElement('a')
    anchor.href = link
    anchor.target = '_blank'
    anchor.click()
  }, [link])
  const archivePrices = [
    {
      country: 'ru',
      price: 350,
    },
    {
      country: 'am',
      price: 350,
    },
    {
      country: 'ua',
      price: 350,
    },
    {
      country: 'us',
      price: 350,
    },
    {
      country: 'gb',
      price: 350,
    },
    {
      country: 'ca',
      price: 350,
    },
    {
      country: 'by',
      price: 350,
    },
    {
      country: 'de',
      price: 350,
    },
    {
      country: 'it',
      price: 350,
    },
    {
      country: 'au',
      price: 350,
    },
    {
      country: 'se',
      price: 350,
    },
    {
      country: 'kz',
      price: 350,
    },
    {
      country: 'fr',
      price: 350,
    },
    {
      country: 'es',
      price: 350,
    },
    {
      country: 'cn',
      price: 350,
    },
    {
      country: 'in',
      price: 350,
    },
    {
      country: 'nl',
      price: 350,
    },
    {
      country: 'at',
      price: 350,
    },
    {
      country: 'mx',
      price: 350,
    },
    {
      country: 'jp',
      price: 350,
    },
    {
      country: 'tr',
      price: 350,
    },
    {
      country: 'il',
      price: 350,
    },
    {
      country: 'br',
      price: 350,
    },
    {
      country: 'pt',
      price: 350,
    },
    {
      country: 'ae',
      price: 350,
    },
    {
      country: 'all',
      price: 1300,
    },
  ]
  const hasActiveArchiveAccess = !me ? false : !!me.archivesAccess.find((archiveAccess) => archiveAccess.active)
  let hasUnactiveArchiveAccess = !me ? true : false
  const hasArchiveAllAccess = !me
    ? false
    : !!me.archivesAccess.find((archiveAccess) => archiveAccess.country === 'all' && archiveAccess.active)
  if (me && !hasArchiveAllAccess) {
    for (const archivePrice of archivePrices) {
      const archiveAccess = me.archivesAccess.find(
        (archiveAccess) => archiveAccess.country === archivePrice.country && archiveAccess.active
      )
      if (!archiveAccess) {
        hasUnactiveArchiveAccess = true
        break
      }
    }
  }
  return (
    <PageWrapper authorizedOnly queryResult={queryResult} meIncluded>
      {() => (
        <MainSegment title={i18n('archive')}>
          <Deprecated />
          {/* <Helmet title={i18n('archive')} />
          {hasActiveArchiveAccess && (
            <Segment>
              {me.archivesAccess.map(
                (archiveAccess, i) =>
                  archiveAccess.active &&
                  (!hasArchiveAllAccess || archiveAccess.country === 'all') && (
                    <div key={i} className={css.access}>
                      <Button
                        loading={loading}
                        type="button"
                        onClick={() =>
                          refetch
                            ? refetch({
                              variables: {
                                input: {
                                  country: archiveAccess.country,
                                },
                              },
                            })
                            : getArchiveDownloadLink({
                              variables: {
                                input: {
                                  country: archiveAccess.country,
                                },
                              },
                            })
                        }
                      >
                        {i18n('saveArchive')} "{toHumanCountry(archiveAccess.country, i18n('lang'))}" {i18n('for')}{' '}
                        {toHumanDate(me.date, 'MMMM YYYY', i18n('lang'))}
                      </Button>
                      <p className={css.hint}>
                        {(() => {
                          const days = moment(archiveAccess.endsAt).diff(me.date, 'days')
                          if (!days) return i18n('subscriptionEnds')
                          return (
                            i18n('dateEnds') +
                            plural(days, i18n('stayed')) +
                            ' ' +
                            days +
                            ' ' +
                            plural(days, i18n('days'))
                          )
                        })()}
                      </p>
                    </div>
                  )
              )}
            </Segment>
          )}
          {hasUnactiveArchiveAccess && (
            <Segment title={i18n('archiveAcquisition')}>
              <div className={css.intro}>
                <div className={css.text}>
                  <p>{i18n('archiveText')}</p>
                  <p>{i18n('archiveText2')}</p>
                  <p>{i18n('archiveText3')}</p>
                </div>
                {true || me.archivePriceVisible ? (
                  <div className={css.form}>
                    <BuyArchive me={me} archivePrices={archivePrices} />
                  </div>
                ) : (
                  <div className={css.form}>
                    <p>
                      <Link onClick={() => window.Chatra('openChat', true)}>{i18n('sandUs')}</Link> {i18n('sandUsText')}
                    </p>
                    <Button onClick={() => window.Chatra('openChat', true)}>{i18n('queryPrice')}</Button>
                  </div>
                )}
              </div>
            </Segment>
          )} */}
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default ArchiveSettingsPage
