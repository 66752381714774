export const getRequestsCountByMoney = (money, alreadySpentMoney = 0) => {
  if (alreadySpentMoney) {
    return getRequestsCountByMoney(money + alreadySpentMoney) - getRequestsCountByMoney(alreadySpentMoney)
  }
  const lte1000Price = 0.004
  const lte10000Price = 0.0015
  const lte100000Price = 0.0008
  const gt100000Price = 0.0006
  // const sum1000 = lte1000Price * 1000
  // const sum10000 = lte10000Price * 10000
  // const sum100000 = lte100000Price * 100000
  // if (money <= sum1000) {
  //   return Math.floor(money / lte1000Price)
  // }
  // if (money <= sum10000) {
  //   return sum1000 + Math.floor((money - sum1000) / lte10000Price)
  // }
  // if (money <= sum100000) {
  //   return sum10000 + Math.floor((money - sum10000) / lte100000Price)
  // }
  // return sum1000 + sum10000 + sum100000 + Math.floor((money - sum1000 - sum10000 - sum100000) / gt100000Price)
  const first1000RequestsPrice = lte1000Price * 1000
  if (money <= first1000RequestsPrice) {
    return Math.floor(money / lte1000Price)
  }
  const first10000RequestsPrice = first1000RequestsPrice + lte10000Price * 9000
  if (money <= first10000RequestsPrice) {
    return 1000 + Math.floor((money - first1000RequestsPrice) / lte10000Price)
  }
  const first100000RequestsPrice = first10000RequestsPrice + lte100000Price * 90000
  if (money <= first100000RequestsPrice) {
    return 10000 + Math.floor((money - first10000RequestsPrice) / lte100000Price)
  }
  return 100000 + Math.floor((money - first100000RequestsPrice) / gt100000Price)
}
