const lang = {
  ru: {
    title: 'Документация',
    text1: `BinKing позволяет по номеру карты получить логотип банка, цвета, номер телефона, логотип бренда и прочее, а
    также помогает в валидации форм для приёма банковских карт. BinKing не привязывает вас к какому-то конкретному
    дизайну формы. Дизайн формы может быть любым, каким вы захотите.`,
    text2: `Все возможности BinKing раскрываются при использованнии`,
    text3: `нашего JS-плагина.`,
    text4: `Плагин внутри своего кода использует подключение к API для определения данных банка по номеру карты. Однако он
    может обращаться к данным о банках и на вашей стороне, если вы скачаете архив с нашей базой данных.`,
    text5: 'Мы написали',
    text6: 'подробный гайд по созданию формы для приёма банковских карт.',
    text7: `Там подробно описывается подключение и использование нашего JS-плагина. Также в этом гайде мы освещаем, как
    сделать автоматический перенос курсора в следующее поле, как сделать валидацию формы и многое другое.`,
    text8: `Если вам нужны только данные о банке (логотипы, цвета, телефон, адрес сайта), вам не нужны данные о бренде карты
    и инструменты для валидации формы, вы можете использовать`,
    text9: 'напрямую.',
    text10: `Тем, кому нужен полный контроль, предлагаем скачать нашу базу данных, включающую информацию о банках и их
    логотипы. Подробное описание содержимого архива и способов его применения читайте`,
    text11: `в инструкции к архиву.`,
    text12: 'Полный перечень банков и стран, которые есть в базе данных BinKing смотрите',
    text13: 'на этой странице.',
  },
  en: {
    title: 'Documentation',
    text1: `BinKing allows you to receive the bank logo, colors, phone number, brand logo, etc. by card number, and also helps in validating forms for accepting card payments. BinKing does not tie you to a specific form design. The design of the form can be whatever you want.`,
    text2: `All BinKing features are available when using our`,
    text3: `JS plugin`,
    text4: `In nutshell plugin uses an API connection to determine bank data by card number. However, it can also access necessary data on your side if you download an database archive.`,
    text5: 'We have written',
    text6: 'a detailed guide on creation of a form for receipt of bank cards.',
    text7: `It describes in detail the connection and the use of our JS plugin. Besides, in this guide we show the ways to   make the automatic hyphenation of cursor to the next field, to make the form validation and many more.`,
    text8: `If you only need information about the bank (logos, colours, phone number, website address), you do not need information about the brand of the card    and the form validation tools, you can use`,
    text9: 'directly.',
    text10: `For those who need full control, we suggest downloading our database, which includes information about banks and logos. For a detailed description please read`,
    text11: `archive documentation.`,
    text12: 'You can find the full list of banks and countries included in the database of BinKing',
    text13: 'on this page.',
  }
}

export default lang
