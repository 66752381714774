const lang = {
  ru: {
    date: 'Дата',
    sum: 'Сумма',
    type: 'Тип',
    alert: 'У вас ещё не было никаких платежей',
  },
  en: {
    date: 'Date',
    sum: 'Amount',
    type: 'Type',
    alert: "You haven't had any payments yet",
  }
}

export default lang
