import React from 'react'
import MainSegment from 'components/MainSegment'
import RichText from 'components/RichText'
import Link from 'components/Link'
import { getDocsApiRoute, getDocsArchiveRoute, getPluginUrl, getDocsBanksRoute, getGuideRoute } from 'lib/routes'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const DocsIntroPage = () => {
  const i18n = useLang(lang)
  return (
  <MainSegment title={i18n('title')}>
    <Helmet title={i18n('title')} />
    <RichText>
      <p>
        {i18n('text1')}
      </p>
      <p>
        {i18n('text2')}{' '}
        <Link type="solid" href={getPluginUrl()} target="_blank">
          {i18n('text3')}
        </Link>{' '}
        {i18n('text4')}
      </p>
      <p>
        {i18n('text5')}{' '}
        <Link type="solid" to={getGuideRoute()}>
          {i18n('text6')}
        </Link>{' '}
        {i18n('text7')}
      </p>
      <p>
        {i18n('text8')}{' '}
        <Link type="solid" to={getDocsApiRoute()}>
          API
        </Link>{' '}
        {i18n('text9')}
      </p>
      <p>
      {i18n('text10')}{' '}
        <Link type="solid" to={getDocsArchiveRoute()}>
        {i18n('text11')}
        </Link>
      </p>
      <p>
        {i18n('text12')}{' '}
        <Link type="solid" to={getDocsBanksRoute()}>
          {i18n('text13')}
        </Link>
      </p>
    </RichText>
  </MainSegment>
)}

export default DocsIntroPage
