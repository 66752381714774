import React from 'react'

const lang = {
  ru: {
    text1: `Создайте королевскую
    форму для приёма
    банковских карт`,
    text2: 'Используя BinKing для определения логотипа, цветов банка и прочего по номеру карты',
    text3: 'Используйте API напрямую, если вам нужны только данные о банке',
    text4: 'Получить ключ API',
    text5: 'База данных',
    text6: 'Включает полную информацию о банках и их логотипы',
    text7: 'Скачать архив',
    text8: 'JS-плагин',
    text9: 'Подключите готовый плагин в код своего проекта',
    text10: 'Смотреть на GitHub',
    text11: 'Пример формы',
    text12: 'Это пример формы, которую можно создать с нашей помощью.',
    text13: 'Внешний вид формы можно задать какой угодно. Это только пример.',
    text14: 'Форма работает в тестовом режиме и не отправляет данные на сервер. Оплата взыматься не будет.',
    text15: 'Для имитации успешной оплаты введите 777, или любое другое число, чтобы увидеть ошибку.',
    text16: 'Введите свой номер карты или выберете один из заготовленных:',
    text17: 'Сбербанк',
    text18: 'Тинькоф',
    text19: 'Росбанк',
    firstBankCard: '4023 3300 0000 0006',
    secondBankCard: '5536 9100 0000 0002',
    thirdBankCard: '6777 2100 0000 0003',
    text20: 'Мы написали подробный гайд, где описываем процесс создания формы поэтапно.',
    text21: `Гайд содержит полный пример кода, который можно просто скопировать к себе и использовать. При
    желании вы легко сможете его доработать.`,
    text22: 'Читать подробный гайд',
    text23: 'Смотреть исходный код',
    text24: 'Данные, определяемые по номеру карты',
    text25: 'Введите свой номер карты или выберете один из заготовленных:',
    text26: (
      <>
        Отправьте <span>GET</span> запрос по адресу <span>https://api.binking.io/form</span> и передайте первые 6 цифр
        номера карты и ключ APi в параметрах
      </>
    ),
    text27: 'Архив',
    text28: (
      <>
        Определите банк по первым 6 цифрам в номере карты в файле <span>banks.json</span> из архива. Затем найдите все
        необходимые данные об этом банке в файле <span>bins.json</span>
      </>
    ),
    text29: (
      <>
        Подключите JS-плагин к сайту, и вызовите функцию <span>binking</span> с номером карты в качестве аргумента.
        JS-плагин сам найдет данные банка через API или в архиве, а также выведет данные бренда (MasterCard, Visa, и
        т.д.)
      </>
    ),
    text30: 'Настройки дизайна формы',
    text31: 'Фон формы:',
    text32: 'Цвет текста:',
    text33: 'Цвет границ:',
    text34: 'Логотип банка:',
    text35: 'Эмблема банка:',
    text36: 'Логотип бренда:',
    text37: 'Доступны только в JS-плагине',
    text38: 'Данные банка',
    text39: 'Название банка на английском:',
    text40: 'Название банка на местном языке:',
    text41: 'Страна, в которой работает банк:',
    text42: 'Сайт банка:',
    text43: 'Телефон банка:',
    text44: 'Цвет банка:',
    text45: 'Цвета банка:',
    text46: 'Большой цветной логотип банка',
    text47: 'Большой белый логотип банка',
    text48: 'Маленький, вписывающийся в квадрат, цветной логотип банка',
    text49: 'Маленький, вписывающийся в квадрат, белый логотип банка',
    text50: 'Данные бренда',
    text51: 'Доступны только в JS-плагине',
    text52: 'Название бренда:',
    text53: 'Название кода безопасности:',
    text54: 'Длина кода безопасности:',
    text55: 'Маска номера карты:',
    text56: 'Цветной логотип бренда',
    text57: 'Белый логотип бренда',
    text58: 'Используйте JS-плагин',
    text59: `Подключите плагин в код своего проекта, чтобы легко определять данные по номеру карты. Также плагин
    помогает в валидации данных платёжной формы.`,
    text60: `  Для определения данных, связанных с банком, плагин использует подключение к нашей базе данных через
      API, при этом минимизируя количество запросов. Отправляется всего 1 запрос к API на одного платящего
      пользователя.`,
    text61: 'Подключитесь к API',
    text62: (sum, requests) => `Дарим ${sum} (${requests} запросов) на использование API`,
    text63: `Если вам нужны только данные о банке (логотипы, цвета, телефон, адрес сайта), вам не нужны данные о
    бренде карты и инструменты для валидации формы, вы можете использовать API напрямую.`,
    text64: `Отправить GET запрос с первыми 6 цифрами номера карты пользователя, и получить в ответ JSON с
    информацией о банке пользователя. Подробное описание читайте в инструкции к API.`,
    text65: 'Получить ключ API',
    text66: 'Инструкции к API',
    text67: 'Скачайте архив с базой данных',
    text68: `Тем, кому нужен полный контроль, предлагаем скачать нашу базу данных, включающую информацию о банках
    и их логотипы. Подробное описание содержимого архива и способов его применения читайте в инструкции
    к архиву.`,
    text69: 'Инструкции к архиву',
    text70: 'Кому это нужно?',
    text71: `Это решение для тех, кто принимает оплату прямо на своём сайте или приложении без перенаправления в
    сторонние сервисы`,
    text72: 'E-commerce и SaaS',
    text73: `Сделайте свои формы оплаты более конверсиоными и привлекательными. Поднимите качество ваших
    платёжных форм до уровня предоставляемых самыми прогрессивными банками. Поднимите свой престиж
    в глазах ваших пользователей.`,
    text74: 'Мерчантам',
    text75: `Добавьте возможность определения логотипа банка по номеру карты в свои платёжные виджеты. Выводите
    номер телефона банка при отклонениии платежа для сокращения нагрузки на отделы поддержки ваших
    пользователей. `,
    text76: 'Банкам',
    text77: `Добавьте возможность определения логотипа банка по номеру карты в форму для перевода денег с карты
    на карту.`,
    text78: 'Преимущества сервиса BinKing',
    text79: 'Увеличение конверсии',
    text80: `Качественная форма для приёма платежей увеличивает конверсию. Логотип банка пользователя особенно
    вызывает его доверие.`,
    text81: 'Увеличение лояльности пользователей',
    text82: `Столь отзывчивая платёжная форма никого не оставит равнодушным и увеличит ваш престиж в глазах
    пользователей.`,
    text83: 'Будьте на уровне с лучшими',
    text84: `Многие мерчанты и крупные банки уже определяют логотип банка по номеру карты. Раньше это могли
    позволить себе только самые крупные компании. Теперь можете и вы.`,
    text85: 'Снижение нагрузки отдела поддержки',
    text86: 'Показывайте телефон и ссылку на сайт банка пользователя, в случае отклонения платежа банком.',
    text87: 'Удобный поиск сохранённой карты',
    text88: `Покажите в блоке с сохранёнными картами не только последние 4 цифры и логотип бренда, но и логотип
    банка, для более удобного поиска.`,
    text89: 'Единый инструмент',
    text90: `С помощью нашего JS-плагина вы можете не только определять данные банка, но и данные бренда (Visa,
    MasterCard, и т.д.), маску номера карты, валидировать поля формы и многое другое.`,
    text91: 'База данных',
    text92: (
      <>
        Мы постоянно добавляем новые страны и банки в уже имеющиеся страны. <br /> Сейчас в нашей базе банки:
      </>
    ),
    text93: 'банков',
    text94: 'Полный перечень банков',
    text95: 'Сотрудничаем с банками',
    text96:
      'Если вы являетесь представителем банка, вы можете сгенерировать JSON файл с данными вашего банка и разместить его у себя на сайте, чтобы мы могли автоматически подгружать эти данные в нашу базу. Управляйте тем, как выглядит форма оплаты для миллионов пользователей при введении данных карты вашего банка.',
    text97: 'Составить JSON файл',
    startupTitle: 'Разрабатываем стартапы на заказ',
    startupDesc:
      'Основатели проекта BinKing также занимаются разработкой стартапов на заказ. Посетите наш сайт и узнайте как и в какие сроки мы можем разработать веб-сервис или мобильное приложение для вашего стартапа.',
    startupButton: 'Перейти на сайт',
    text98: 'Нужна помощь в создании формы?',
    text99: `Возьмем на себя настройку платежей на сайте. Поможем выбрать подходящий мерчант, подключим и
    настроим его. Напишите нам в чат в правом нижнем углу.`,
    text100: 'Сколько стоит?',
    text101: 'О стоимости использования базы данных и API можно узнать на странице расценок.',
    text102: 'Узнать цены',
    text103: 'Нет вашей страны?',
    text104: 'Укажите страну при регистрации, и мы уведомим вас, когда банки будут добавлены в базу.',
    text105: 'Зарегистрироваться',
    bank1: 'банк',
    bank2: 'банка',
    bankMany: 'банков',
  },
  en: {
    text1: `Create unique form to accept payments`,
    text2: 'Use BinKing to identify logo, bank colors, and more by card number',
    text3: 'Use API if you only need bank details',
    text4: 'Get API Key',
    text5: 'Database',
    text6: 'Includes full information about banks, logos and more',
    text7: 'Download archive',
    text8: 'JS plugin',
    text9: 'Connect plugin to your project code',
    text10: 'View on GitHub',
    text11: 'Form example',
    text12: 'This is an example of a form that can be created with BinKing.',
    text13: 'You can set any design for your payment form. This is just an example.',
    text14: 'The form works in test mode. You will not be charged.',
    text15: 'To simulate a successful payment, enter 777. Enter any other number to see an error.',
    text16: 'Enter your card number or choose one from of the examples:',
    text17: 'Bank of America',
    text18: 'Barclays',
    text19: 'Deutsche Bank',
    firstBankCard: '4002 7500 0000 0000',
    secondBankCard: '4257 1000 0000 0000',
    thirdBankCard: '4045 4700 0000 0004',
    text20: 'We have written a detailed guide, where we describe the process of creating a form in stages.',
    text21: `The guide contains a complete code example that you can simply copy and use. Besides, you can easily modify it .`,
    text22: 'Read the detailed guide',
    text23: 'View the source code',
    text24: 'Data identified by card number',
    text25: 'Enter your card number or choose one from of the examples:',
    text26: (
      <>
        Send a <span>GET</span> request to <span>https://api.binking.io/form</span> and pass the first 6 digits of the
        card number and APi key in parameters
      </>
    ),
    text27: 'Archive',
    text28: (
      <>
        Define the bank by the first 6 digits in the card number from <span>banks.json</span> file. Find all the
        required details about the bank in the <span>bins.json</span> file{' '}
      </>
    ),
    text29: (
      <>
        Connect a JS plugin to your site and call the <span>binking</span> function with the card number as an argument.
        The JS plugin will find the bank details via the API or in the archive and will display brand data (MasterCard,
        Visa, etc.)
      </>
    ),
    text30: 'Form design settings',
    text31: 'Form background:',
    text32: 'Text color:',
    text33: 'Border color:',
    text34: 'Bank logo:',
    text35: 'Bank icon:',
    text36: 'Brand logo:',
    text37: 'Available only in JS plugin',
    text38: 'Bank data',
    text39: 'Bank name in English:',
    text40: 'Bank name in local language:',
    text41: 'Country where the bank operates:',
    text42: 'Bank website:',
    text43: 'Bank phone:',
    text44: 'Bank color:',
    text45: 'Bank colors:',
    text46: 'Large color bank logo',
    text47: 'Large white bank logo',
    text48: 'Small square color bank logo',
    text49: 'Small square white bank logo',
    text50: 'Brand data',
    text51: 'Available only in JS plugin',
    text52: 'Brand name:',
    text53: 'Security code name:',
    text54: 'Security code length:',
    text55: 'Card number mask:',
    text56: 'Color brand logo',
    text57: 'White brand logo',
    text58: 'Use JS plugin',
    text59: `Connect a JS plugin to your site to easily identify data by card number. Plugin will also allow you to validate payment form input data.`,
    text60: `To get the data associated with the bank, JS plugin uses an API connection to our database, while minimizing the number of requests. Only 1 API request is sent per paying user.`,
    text61: 'Connect to API',
    text62: (sum, requests) => `Free ${requests} API requests to try BinKing`,
    text63: `If you only need bank data (logos, colors, phone number, website address), you do not need card brand data and form validation tools, you can use the API directly.`,
    text64: `Send a GET request with the first 6 digits of the user's card number, and receive JSON file with information about the user's bank. For a detailed description, see the API documentation.`,
    text65: 'Get API Key',
    text66: 'API documentation',
    text67: 'Download database archive',
    text68: `For those who need full control, we suggest downloading our database, which includes information about banks and logos. For a detailed description please read archive documentation.`,
    text69: 'Archive documentation',
    text70: 'Who needs it?',
    text71: `This solution is for those who accept payment directly on their website or application without redirecting to third-party services.`,
    text72: 'E-commerce и SaaS',
    text73: `Make your payment forms more attractive. Raise the quality of your payment forms to the level provided by the largest banks. Raise your prestige in the eyes of your users.`,
    text74: 'Merchants',
    text75: `Add the ability to define a bank logo by card number to your payment widgets. Display the bank's phone number when a payment is declined to reduce the load on your customers support departments.`,
    text76: 'Banks',
    text77: `Add the ability to identify the bank's logo by card number for card to card transfers.`,
    text78: 'BinKing benefits',
    text79: 'Increase conversions',
    text80: `A high-quality form for accepting payments increases conversion. The user's bank logo is especially credible.`,
    text81: 'Increased user loyalty',
    text82: `Responsive payment form will not leave anyone indifferent and will increase your prestige in the eyes of your customers.`,
    text83: 'Be on par with the best',
    text84: `Many merchants and large banks already identify the bank logo by the card number. Before only the largest companies could afford it. Now you can too.`,
    text85: 'Reduce the workload of your support department',
    text86: `Display the phone number and a link to the user's bank website if the bank declines the payment.`,
    text87: 'Convenient search for a saved cards',
    text88: `Show in the block with saved cards not only the last 4 digits and the brand logo, but also the bank logo, for a more convenient search.`,
    text89: 'Single tool',
    text90: `With BinKing JS plugin, you can not only define bank data, but also brand data (Visa, MasterCard, etc.), card number mask, validate form fields and much more.`,
    text91: 'Database',
    text92: (
      <>
        We are constantly adding new countries and banks.
        <br /> Currently in our database:
      </>
    ),
    text93: 'banks',
    text94: 'Complete list of banks',
    text95: 'We cooperate with banks',
    text96:
      'If you are a representative of a bank, you can generate a JSON file with the data of your bank data and place it on your website so that we can automatically upload this data to our database. Control how the payment form looks like for millions of users by the entering your bank card details.',
    text97: 'Compile a JSON file',
    startupTitle: 'We develop customized startups',
    startupDesc:
      'The founders of the BinKing project also develop the customized startups. Visit our website and find out how and within what time frame we can develop a web service or a mobile application for your startup.',
    startupButton: 'Go to website',
    text98: 'Need help creating a form?',
    text99: `We will take care of setting up payments on your site. We will help you choose a suitable merchant, connect and configure it. Contact customer service by chat via the icon in the lower right corner.`,
    text100: 'How much is it?',
    text101: 'The cost of using the database and API can be found on the pricing page.',
    text102: 'Check prices',
    text103: 'Your country is not in the list?',
    text104: 'Specify your country when registering, and we will notify you when the banks are added to the database.',
    text105: 'Sign up',
    bank1: 'bank',
    bank2: 'banks',
    bankMany: 'banks',
  },
}

export default lang
