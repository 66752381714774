import React from 'react'
import MainSegment from 'components/MainSegment'
import useQuery from 'lib/useQuery'
import toHumanDate, { moment } from 'lib/toHumanDate'
import PageWrapper from 'components/PageWrapper'
import css from './styles.module.scss'
import { canViewBanks } from 'lib/can'
import { getBankEditRoute } from 'lib/routes'
import plural from 'lib/plural'
import queryString from 'query-string'
import cn from 'classnames'
import Helmet from 'components/Helmet'
import { useLang } from 'lib/i18n'
import { useLocation } from 'react-router-dom'
import lang from './lang.js'

const BanksPage = ({ me, history }) => {
  const i18n = useLang(lang)
  const location = useLocation()
  const params = queryString.parse(location.search)
  const { country } = params
  const queryResult = useQuery('getBanks', {
    variables: {
      input: {
        country,
      },
    },
  })
  const { banks } = queryResult
  return (
    <PageWrapper queryResult={queryResult}>
      {() => (
        <MainSegment title={i18n('banks')}>
          <Helmet title={i18n('banks')} />
          <table className={css.banks}>
            <thead>
              <tr>
                <th>{i18n('name')}</th>
                <th>{i18n('relevance')}</th>
                <th>{i18n('check')}</th>
                <th>{i18n('logo')}</th>
                <th>{i18n('bins')}</th>
                <th>{i18n('readiness')}</th>
                <th>{i18n('country')}</th>
                <th>{i18n('alias')}</th>
                <th>{i18n('namesInBins')}</th>
              </tr>
            </thead>
            <tbody>
              {banks.map((bank, i) => {
                const daysAfterActualiztion = bank.actualizedAt ? moment().diff(bank.actualizedAt, 'days') : false
                const logosExists =
                  bank.logoBigOriginalSvg &&
                  bank.logoBigInvertedSvg &&
                  bank.logoSmallOriginalSvg &&
                  bank.logoSmallInvertedSvg
                return (
                  <tr onClick={() => history.push(getBankEditRoute(bank.id))} key={i} className={css.bank}>
                    <td>{bank.name || bank.localName || bank.alias}</td>
                    <td
                      className={cn({
                        [css.warning]: daysAfterActualiztion && daysAfterActualiztion > 30,
                      })}
                    >
                      {bank.actualizedAt
                        ? `${plural(daysAfterActualiztion, i18n('days'), true)} (${toHumanDate(
                            bank.actualizedAt,
                            'YYYY.MM.DD'
                          )})`
                        : '—'}
                    </td>
                    <td
                      className={cn({
                        [css.warning]: bank.fulfilled && !bank.approved,
                      })}
                    >
                      {!bank.fulfilled ? '—' : bank.approved ? i18n('complit') : i18n('needCheck')}
                    </td>
                    <td className={cn({ [css.warning]: !logosExists })}>
                      {logosExists ? i18n('thereLogos') : i18n('noLogos')}
                    </td>
                    <td className={cn({ [css.warning]: bank.binsCount === 0 })}>{bank.binsCount}</td>
                    <td className={cn({ [css.warning]: !bank.fulfilled })}>
                      {bank.fulfilled ? i18n('readi') : i18n('notReady')}
                    </td>
                    <td>{bank.country}</td>
                    <td>{bank.alias}</td>
                    <td>
                      {bank.namesInBins.map((name, i) => (
                        <span key={i}>
                          {name}
                          <br />
                        </span>
                      ))}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default (props) => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canViewBanks(me)}>
    {({ me }) => <BanksPage me={me} {...props} />}
  </PageWrapper>
)
