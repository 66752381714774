const lang = {
  ru: {
    attention: 'Внимание',
  },
  en: {
    attention: 'Attention',
  }
}

export default lang
