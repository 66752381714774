import React, { useState, useEffect, useRef } from 'react'
import css from './styles.module.scss'
import Link from 'components/Link'
import cn from 'classnames'
import toHumanCountry from 'lib/toHumanCountry'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { useLang  } from 'lib/i18n'

const Banks = ({ banksCountries }) => {
  return (
    <>
      {_.sortBy(_.filter(banksCountries, bc => !!bc.banks.length), [
        function(o) {
          return toHumanCountry(o.countryCode)
        },
      ]).map((banksCountry, i) => (
        <React.Fragment key={banksCountry.countryCode}>
          <Bank bankinfo={banksCountry} />
        </React.Fragment>
      ))}
    </>
  )
}

const Bank = ({ bankinfo }) => {
  const i18n = useLang()
  const [countryOpen, setCountryOpen] = useState(false)
  const location = useLocation()
  const headerEl = useRef()
  const neededCountryCode = location.hash.substring(1)
  useEffect(() => {
    if (bankinfo.countryCode === neededCountryCode) {
      setCountryOpen(true)
      setTimeout(() => {
        headerEl.current.scrollIntoView()
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <h2
        className={cn({ [css.title]: true, [css.active]: countryOpen })}
        onClick={() => setCountryOpen(!countryOpen)}
        ref={headerEl}
      >
        <span>{toHumanCountry(bankinfo.countryCode, i18n('lang'))}</span>
      </h2>
      <div className={cn({ [css.banksBox]: true, [css.active]: countryOpen })}>
        {_.sortBy(bankinfo.banks, [
          function(o) {
            return o.name
          },
        ]).map(bank => {
          return (
            <div key={bank.name} className={css.bank}>
              <div className={css.bankName}>
                <Link className={css.link} href={bank.site}>
                  <span className={css.name}>{bank.name}</span>
                  {bank.name !== bank.localName ? <span>{bank.localName}</span> : ''}
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Banks
