import React from 'react'
import MainSegment from 'components/MainSegment'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import ReplenishBalance from 'components/ReplenishBalance'
import ReplenishBalancePP from 'components/ReplenishBalancePP'
import AddBankCard from 'components/AddBankCard'
import ManageBankCards from 'components/ManageBankCards'
import Txs from 'components/Txs'
import css from './styles.module.scss'
import useQuery from 'lib/useQuery'
import toMoney from 'lib/toMoney'
import Helmet from 'components/Helmet'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import PaymentMethod from '../../components/PaymentMethod'
// import cn from 'classnames'

const PaymentsSettingsPage = () => {
  const i18n = useLang(lang)
  const queryResultMe = useQuery('getMe')
  const queryResultUsdPrice = useQuery('getUsdPrice')
  const { me } = queryResultMe
  const { usdPrice } = queryResultUsdPrice
  return (
    <PageWrapper
      authorizedOnly
      queryResult={{ ...queryResultMe, loading: queryResultMe.loading || queryResultUsdPrice.loading }}
      meIncluded
    >
      {() => (
        <MainSegment title={i18n('paymentSettings')}>
          <Helmet title={i18n('paymentSettings')} />
          {me.lang === 'ru' && <PaymentMethod me={me} />}
          <Segment title={`${i18n('currentBalance')}: ${toMoney({ sumUsd: me.balance, usdPrice })}`}>
            <div className={css.replenishBalance}>
              {/* <p>
                {i18n('balance')}
                {!me.activatedAt && me.paymentMethod === 'pp' && (
                  <>
                    {' '}
                    {i18n('topUpGift')} {toMoney({ sumUsd: (me && me.giftSum) || 4, usdPrice })}
                  </>
                )}
              </p> */}
              <div className={css.form}>
                {me.paymentMethod === 'cp' ? <ReplenishBalance me={me} /> : <ReplenishBalancePP me={me} />}
              </div>
            </div>
          </Segment>
          {me.paymentMethod === 'cp' && (
            <Segment title={i18n('attachedCards')}>
              <div className={css.bankCards}>
                {!!me.bankCards.length && (
                  <div className={css.list}>
                    <p>{i18n('yourcard')}</p>
                    <div className={css.form}>
                      <ManageBankCards me={me} />
                    </div>
                  </div>
                )}
                <div className={css.new}>
                  {!!me.bankCards.length ? (
                    <p>{i18n('oneMoreCard')}</p>
                  ) : !me.activatedAt ? (
                    <p>
                      {i18n('linkYourCard')} {toMoney({ sumUsd: (me && me.giftSum) || 4, usdPrice })} {i18n('queryApi')}
                    </p>
                  ) : (
                    <p>{i18n('linkCard')}</p>
                  )}
                  <div className={css.form}>
                    <AddBankCard />
                  </div>
                </div>
              </div>
            </Segment>
          )}
          <Segment title={i18n('history')}>
            <Txs />
          </Segment>
        </MainSegment>
      )}
    </PageWrapper>
  )
}

export default PaymentsSettingsPage
