import React from 'react'
import MainSegment from 'components/MainSegment'
import PageWrapper from 'components/PageWrapper'
import BankPreview from 'components/BankPreview'
import Button, { Buttons } from 'components/Button'
import Colorpicker from 'components/Colorpicker'
import Textarea from 'components/Textarea'
import FieldArray from 'components/FieldArray'
import css from './styles.module.scss'
import { canEditBanks, canApproveBanks, canActualizeBanks, canViewBanksLogs } from 'lib/can'
import Alert from 'components/Alert'
import UploadPngLogo from 'components/UploadPngLogo'
import UploadSvgLogo from 'components/UploadSvgLogo'
import Form, { FormItems, useForm } from 'components/Form'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import * as Yup from 'yup'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import Input from 'components/Input'
import pick from 'lodash/pick'
import mapValues from 'lodash/mapValues'
import textToArray from 'lib/textToArray'
import BanksLogs from 'components/BanksLogs'
import Segment from 'components/Segment'
import { getBanksLogsQuery } from 'lib/gqls'
import { countryOptions } from 'lib/toHumanCountry'
import Select from 'components/Select'
import Helmet from 'components/Helmet'

const BankEditPage = ({ bank, me }) => {
  const [updateBank] = useMutation('updateBank', {
    refetchQueries: [
      {
        query: getBanksLogsQuery,
        variables: { input: { bankId: bank.id } },
      },
    ],
  })
  const [actualizeBank] = useMutation('actualizeBank', {
    refetchQueries: [
      {
        query: getBanksLogsQuery,
        variables: { input: { bankId: bank.id } },
      },
    ],
  })
  const [approveBank] = useMutation('approveBank', {
    refetchQueries: [
      {
        query: getBanksLogsQuery,
        variables: { input: { bankId: bank.id } },
      },
    ],
  })
  const { formik: actualizeFormik, buttonProps: buttonActualizeProps, alertProps: alertActualizeProps } = useForm({
    initialValues: {},
    onSubmit: values => {
      return actualizeBank({
        variables: {
          input: {
            bankId: bank.id,
          },
        },
      })
    },
    showValidationAlert: true,
    successMessage: 'Банк актуализирован',
  })
  const { formik: approveFormik, buttonProps: buttonApproveProps, alertProps: alertApproveProps } = useForm({
    initialValues: {},
    onSubmit: values => {
      return approveBank({
        variables: {
          input: {
            bankId: bank.id,
          },
        },
      })
    },
    showValidationAlert: true,
    successMessage: 'Банк подтверждён',
  })
  const { formik, buttonProps: buttonUpdateProps, alertProps: alertUpdateProps } = useForm({
    initialValues: {
      alias: '',
      name: '',
      localName: '',
      logoBigOriginalSvg: '',
      logoBigInvertedSvg: '',
      logoSmallOriginalSvg: '',
      logoSmallInvertedSvg: '',
      logoBigOriginalPng: '',
      logoBigInvertedPng: '',
      logoSmallOriginalPng: '',
      logoSmallInvertedPng: '',
      color: '',
      colors: [],
      country: 'ru',
      site: '',
      phone: '',
      formBackgroundColor: '',
      formBackgroundColors: [],
      formBackgroundLightness: '',
      formTextColor: '',
      formLogoScheme: '',
      formBorderColor: '',
      namesInBins: bank.namesInBins ? bank.namesInBins.join('\n') : '',
      bins: bank.bins ? bank.bins.join('\n') : '',
      ...mapValues(
        pick(bank, [
          'alias',
          'name',
          'localName',
          'logoBigOriginalSvg',
          'logoBigInvertedSvg',
          'logoSmallOriginalSvg',
          'logoSmallInvertedSvg',
          'logoBigOriginalPng',
          'logoBigInvertedPng',
          'logoSmallOriginalPng',
          'logoSmallInvertedPng',
          'color',
          'colors',
          'country',
          'site',
          'phone',
          'formBackgroundColor',
          'formBackgroundColors',
          'formBackgroundLightness',
          'formTextColor',
          'formLogoScheme',
          'formBorderColor',
        ]),
        value => (value === null ? '' : value)
      ),
    },
    onSubmit: ({ namesInBins, bins, alias, ...restValues }) => {
      return updateBank({
        variables: {
          input: {
            bankId: bank.id,
            namesInBins: textToArray(namesInBins),
            bins: textToArray(bins),
            ...restValues,
          },
        },
      })
    },
    validationSchema: Yup.object({
      // alias: Yup.string().required('Обязательное поле'),
    }),
    showValidationAlert: true,
    successMessage: 'Изменения успешно сохранены',
  })
  return (
    <MainSegment title={`Редактирование банка «${bank.name || bank.localName || bank.alias}»`}>
      <Helmet title={`Редактирование банка «${bank.name || bank.localName || bank.alias}`} />
      <div className={css.bankPreview}>
        <BankPreview bank={formik.values} />
      </div>
      <Segment>
        <Form formik={formik}>
          <FormItems>
            {!bank.fulfilled && <Alert type={'danger'} message={'Банк заполнен не полностью'} />}
            <Input
              disabled
              name="alias"
              attention={!formik.values.alias && 'Не заполнено'}
              label="Алиас"
              formik={formik}
            />
            <Input
              name="name"
              attention={!formik.values.name && 'Не заполнено'}
              label="Название банка на английском"
              formik={formik}
            />
            <Input
              name="localName"
              attention={!formik.values.localName && 'Не заполнено'}
              label="Название банка на местном языке"
              formik={formik}
            />
            <Select
              options={countryOptions}
              disabled
              name="country"
              attention={!formik.values.country && 'Не заполнено'}
              label="Страна, в которой действует банк"
              formik={formik}
            />
            <UploadSvgLogo
              accept=".svg"
              name="logoBigOriginalSvg"
              pngName="logoBigOriginalPng"
              size="big"
              attention={!formik.values.logoBigOriginalSvg && 'Не заполнено'}
              label="Большой оригинальный логотип SVG"
              formik={formik}
            />
            <UploadPngLogo
              accept=".png"
              name="logoBigOriginalPng"
              attention={!formik.values.logoBigOriginalPng && 'Не заполнено'}
              label="Большой оригинальный логотип PNG"
              formik={formik}
            />
            <UploadSvgLogo
              accept=".svg"
              name="logoBigInvertedSvg"
              pngName="logoBigInvertedPng"
              size="big"
              attention={!formik.values.logoBigInvertedSvg && 'Не заполнено'}
              label="Большой инвертированный логотип SVG"
              formik={formik}
            />
            <UploadPngLogo
              accept=".png"
              name="logoBigInvertedPng"
              attention={!formik.values.logoBigInvertedPng && 'Не заполнено'}
              label="Большой инвертированный логотип PNG"
              formik={formik}
            />
            <UploadSvgLogo
              accept=".svg"
              name="logoSmallOriginalSvg"
              pngName="logoSmallOriginalPng"
              size="small"
              attention={!formik.values.logoSmallOriginalSvg && 'Не заполнено'}
              label="Маленький оригинальный логотип SVG"
              formik={formik}
            />
            <UploadPngLogo
              accept=".png"
              name="logoSmallOriginalPng"
              attention={!formik.values.logoSmallOriginalPng && 'Не заполнено'}
              label="Маленький оригинальный логотип PNG"
              formik={formik}
            />
            <UploadSvgLogo
              accept=".svg"
              name="logoSmallInvertedSvg"
              pngName="logoSmallInvertedPng"
              size="small"
              attention={!formik.values.logoSmallInvertedSvg && 'Не заполнено'}
              label="Маленький инвертированный логотип SVG"
              formik={formik}
            />
            <UploadPngLogo
              accept=".png"
              name="logoSmallInvertedPng"
              attention={!formik.values.logoSmallInvertedPng && 'Не заполнено'}
              label="Маленький инвертированный логотип PNG"
              formik={formik}
            />
            <Colorpicker
              label="Цвет банка"
              name="color"
              attention={!formik.values.color && 'Не заполнено'}
              formik={formik}
            />
            <FieldArray
              label="Цвета банка"
              name="colors"
              attention={!formik.values.colors.length && 'Не заполнено'}
              addButtonText="Добавить цвет"
              formik={formik}
            >
              {({ name }) => <Colorpicker formik={formik} name={name} />}
            </FieldArray>
            <Colorpicker
              label="Цвет фона формы"
              name="formBackgroundColor"
              attention={!formik.values.formBackgroundColor && 'Не заполнено'}
              formik={formik}
            />
            <FieldArray
              label="Цвета фона формы для градинета"
              name="formBackgroundColors"
              attention={!formik.values.formBackgroundColors.length && 'Не заполнено'}
              addButtonText="Добавить цвет"
              formik={formik}
            >
              {({ name }) => <Colorpicker formik={formik} name={name} />}
            </FieldArray>
            <Colorpicker
              label="Цвет текста на форме"
              name="formTextColor"
              attention={!formik.values.formTextColor && 'Не заполнено'}
              formik={formik}
            />
            <Colorpicker
              label="Цвет границ полей в форме"
              name="formBorderColor"
              attention={!formik.values.formBorderColor && 'Не заполнено'}
              formik={formik}
            />
            <RadioButtons
              label="Фон формы"
              name="formBackgroundLightness"
              attention={!formik.values.formBackgroundLightness && 'Не заполнено'}
              formik={formik}
            >
              <RadioButton name="formBackgroundLightness" value="dark" label="Скорее тёмный" formik={formik} />
              <RadioButton name="formBackgroundLightness" value="light" label="Скорее светлый" formik={formik} />
            </RadioButtons>
            <RadioButtons
              label="Логотип на форме"
              name="formLogoScheme"
              attention={!formik.values.formLogoScheme && 'Не заполнено'}
              formik={formik}
            >
              <RadioButton name="formLogoScheme" value="original" label="Оригинальный" formik={formik} />
              <RadioButton name="formLogoScheme" value="inverted" label="Инвертированный" formik={formik} />
            </RadioButtons>
            <Input
              name="site"
              attention={!formik.values.site && 'Не заполнено'}
              label="Ссылка на сайт банка"
              formik={formik}
            />
            <Input
              name="phone"
              attention={!formik.values.phone && 'Не заполнено'}
              label="Телефон банка"
              formik={formik}
            />
            <Textarea
              name="namesInBins"
              attention={!formik.values.namesInBins && 'Не заполнено'}
              label="Названия банка в таблице с бинами"
              formik={formik}
            />
            <Textarea
              name="bins"
              label="Бины банка"
              formik={formik}
            />
            <Alert {...alertUpdateProps} />
            <Alert {...alertApproveProps} />
            <Alert {...alertActualizeProps} />
            <Buttons>
              <Button {...buttonUpdateProps}>Сохранить изменения</Button>
              {canApproveBanks(me) && bank.fulfilled && (
                <Button {...buttonApproveProps} onClick={() => approveFormik.handleSubmit()}>
                  Подтвердить
                </Button>
              )}
              {canActualizeBanks(me) && bank.fulfilled && (
                <Button {...buttonActualizeProps} onClick={() => actualizeFormik.handleSubmit()}>
                  Актуализировать
                </Button>
              )}
            </Buttons>
          </FormItems>
        </Form>
      </Segment>
      {canViewBanksLogs(me) && (
        <Segment title="Логи">
          <BanksLogs bankId={bank.id} />
        </Segment>
      )}
    </MainSegment>
  )
}

const GetBankWrapper = props => {
  const bankId = props.match.params.bankId
  const queryResult = useQuery('getBank', {
    variables: {
      input: {
        bankId,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ bank }) => !!bank}>
      {({ bank }) => <BankEditPage bank={bank} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = props => (
  <PageWrapper authorizedOnly checkAccess={({ me }) => canEditBanks(me)}>
    {({ me }) => <GetBankWrapper me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
