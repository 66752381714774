import React, { useRef, useEffect, useState } from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import Checkbox, { Checkboxes } from 'components/Checkbox'
import CountryRadioButton, { CountryRadioButtons } from 'components/CountryRadioButton'
import toMoney from 'lib/toMoney'
import toHumanCountry from 'lib/toHumanCountry'
import redirectPost from 'lib/redirectPost'
import _ from 'lodash'
import useMutation from 'lib/useMutation'
import binking from 'lib/binking'
import css from '../ReplenishBalance/styles.module.scss'
import cn from 'classnames'
import useQuery from 'lib/useQuery'
import { useLang } from 'lib/i18n'
import lang from './lang.js'
import PaymentMethod from '../PaymentMethod'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { useApolloClient } from '@apollo/react-hooks'
import { Deprecated } from 'components/Deprecated'

const ChooseCountry = ({ formik, me, archivePrices, usdPrice }) => {
  const i18n = useLang(lang)
  return (
    <CountryRadioButtons label={i18n('country')} name="country" direction="col" formik={formik}>
      {_.sortBy(archivePrices, [
        function (archivePrice) {
          if (archivePrice.country === 'all') return 'AAA'
          return toHumanCountry(archivePrice.country, i18n('lang'))
        },
      ]).map((archivePrice, i) => {
        const currentArchiveAccess = me.archivesAccess.find(
          (archiveAccess) => archiveAccess.country === archivePrice.country && archiveAccess.active
        )
        return (
          !currentArchiveAccess && (
            <CountryRadioButton
              key={i}
              country={archivePrice.country}
              name="country"
              value={archivePrice.country}
              label={`${toHumanCountry(archivePrice.country, i18n('lang'))} ${i18n('za')} ${toMoney({
                sumUsd: archivePrice.price,
                usdPrice,
                type: me.paymentMethod === 'cp' ? 'both' : 'usd',
              })}`}
              formik={formik}
            />
          )
        )
      })}
    </CountryRadioButtons>
  )
}

const BuyArchiveCP = ({ temp, me, archivePrices, country, setCountry }) => {
  const { usdPrice } = useQuery('getUsdPrice')
  const checkout = useRef()
  const i18n = useLang(lang)
  const formEl = useRef()
  const [buyArchive] = useMutation('buyArchive', {
    refetchQueries: ['getTxs'],
  })
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      // cardNumber: '4242424242424242',
      // month: '12',
      // year: '24',
      // code: '123',
      // name: 'Sergey Odin',
      cardNumber: '',
      month: '',
      year: '',
      code: '',
      name: '',
      saveCard: true,
      savedCard: (me.bankCards && me.bankCards.length && me.bankCards[0].hash) || 'new',
      country: country,
    },
    onSubmit: async ({ country, saveCard, name, savedCard }) => {
      let result
      if (savedCard === 'new') {
        const checkoutResult = checkout.current.createCryptogramPacket()
        if (!checkoutResult.success) {
          throw new Error(checkoutResult.messages[_.keys(checkoutResult.messages)[0]])
        }
        result = await buyArchive({
          variables: {
            input: {
              country,
              saveCard,
              cardCryptogramPacket: checkoutResult.packet,
              name,
            },
          },
        })
      } else {
        result = await buyArchive({
          variables: {
            input: {
              country,
              hash: savedCard,
            },
          },
        })
      }
      if (result.success) return !temp
      // if (result.success) {
      //   window.location.reload()
      //   return true
      // }
      if (result.acsUrl) {
        return new Promise(function () {
          redirectPost(result.acsUrl, {
            PaReq: result.paReq,
            MD: result.transactionId,
            TermUrl:
              process.env.REACT_APP_BACKEND_URI +
              '/cloudpayments/acs?redirectUrl=' +
              encodeURIComponent(window.location.href),
          })
        })
      }
      throw new Error('Something went wrong')
    },
    validate: (values) => {
      const errors = {}
      if (!values.country) {
        errors.country = i18n('chooseCountry')
      }
      if (values.savedCard === 'new') {
        if (!values.name) {
          errors.name = i18n('name')
        }
        const validationResult = binking.validate(values.cardNumber, values.month, values.year, values.code)
        for (const validationError of Object.values(validationResult.errors)) {
          errors[validationError.field] = validationError.message
        }
      }
      return errors
    },
    successMessage: i18n('successMessage'),
    resetOnSuccess: true,
  })
  useEffect(() => {
    if (formik.values.savedCard === 'new') {
      checkout.current =
        window.cp && new window.cp.Checkout(process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID, formEl.current)
    }
  }, [formik.values.savedCard])
  const [bkResult, setBkResult] = useState(binking.defaultResult)
  useEffect(() => {
    binking(formik.values.cardNumber, (result) => {
      setBkResult(result)
    })
  }, [formik.values.cardNumber])
  const [savedBankCardsBkResult, setSavedBankCardsBkResult] = useState({})
  useEffect(() => {
    let savedBankCardsBkResult1 = {}
    for (const bankCard of me.bankCards) {
      // eslint-disable-next-line no-loop-func
      binking(bankCard.firstSix, (result) => {
        savedBankCardsBkResult1 = { ...savedBankCardsBkResult1, [bankCard.hash]: result }
        setSavedBankCardsBkResult(savedBankCardsBkResult1)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(me.bankCards)])
  useEffect(() => {
    setCountry(formik.values.country)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country])
  return (
    <Form formik={formik}>
      <FormItems>
        <ChooseCountry formik={formik} me={me} archivePrices={archivePrices} usdPrice={usdPrice} />
        {!!formik.values.country && me.lang === 'ru' && <PaymentMethod me={me} />}
        {!!formik.values.country && !!me.bankCards.length && (
          <div className={css.savedCards}>
            {me.bankCards.map((bankCard, i) => (
              <div
                className={cn({ [css.savedCard]: true, [css.active]: formik.values.savedCard === bankCard.hash })}
                onClick={() => {
                  formik.setFieldValue('savedCard', bankCard.hash)
                }}
                key={i}
              >
                <img
                  alt=""
                  src={
                    savedBankCardsBkResult[bankCard.hash] &&
                    savedBankCardsBkResult[bankCard.hash].bankLogoSmallOriginalSvg
                  }
                  className={css.bankLogo}
                />
                <img
                  alt=""
                  src={
                    savedBankCardsBkResult[bankCard.hash] && savedBankCardsBkResult[bankCard.hash].brandLogoOriginalSvg
                  }
                  className={css.brandLogo}
                />
                <span className={css.lastFour}>... {bankCard.lastFour}</span>
                <span className={css.expDate}>{bankCard.expDate}</span>
              </div>
            ))}
            <div
              className={cn({
                [css.savedCard]: true,
                [css.newCard]: true,
                [css.active]: formik.values.savedCard === 'new',
              })}
              onClick={() => {
                formik.setFieldValue('savedCard', 'new')
              }}
            >
              <div className={css.plus}></div>
              <div className={css.text}>{i18n('newCard')}</div>
            </div>
          </div>
        )}
        {!!formik.values.country && formik.values.savedCard === 'new' && (
          <>
            <div className={css.card}>
              <div className={css.cardFront} style={{ backgroundColor: bkResult.formBackgroundColor }}>
                <div className={css.logoBox}>
                  {bkResult.formBankLogoBigSvg && <img src={bkResult.formBankLogoBigSvg} alt="logo" />}
                </div>
                <Input
                  name="cardNumber"
                  autocomplete="cc-number"
                  noName
                  formik={formik}
                  data-cp="cardNumber"
                  placeholder="0000 0000 0000 0000"
                  mask={bkResult.cardNumberMask}
                  style={{ borderColor: bkResult.formBorderColor }}
                  card
                />
                <div className={css.cardDate}>
                  <Input
                    name="month"
                    style={{ borderColor: bkResult.formBorderColor }}
                    noName
                    formik={formik}
                    data-cp="expDateMonth"
                    placeholder="MM"
                    autocomplete="cc-exp-month"
                    mask={'00'}
                    card
                  />
                  <Input
                    name="year"
                    style={{ borderColor: bkResult.formBorderColor }}
                    noName
                    mask={'00'}
                    formik={formik}
                    data-cp="expDateYear"
                    placeholder="YY"
                    autocomplete="cc-exp-year"
                    card
                  />
                </div>
                <div className={css.lastRow}>
                  <Input
                    name="name"
                    style={{ borderColor: bkResult.formBorderColor }}
                    noName
                    formik={formik}
                    data-cp="name"
                    placeholder={i18n('nameCard')}
                    card
                    className={css.nameField}
                  />
                  {bkResult.formBrandLogoSvg && (
                    <img className={css.brandLogo} src={bkResult.formBrandLogoSvg} alt="logo" />
                  )}
                </div>
              </div>
              <div className={css.cardBack}>
                <div className={css.inputPosition}>
                  <Input
                    name="code"
                    autocomplete="cc-csc"
                    type="password"
                    noName
                    card
                    formik={formik}
                    placeholder={bkResult.codeName}
                    data-cp="cvv"
                    mask={'0000'}
                  />
                </div>
              </div>
            </div>
            <Checkboxes name="saveCard" formik={formik}>
              <Checkbox name="saveCard" type="boolean" label={i18n('saveCard')} bold formik={formik} />
            </Checkboxes>
          </>
        )}
        <Alert {...alertProps} />
        <Button {...buttonProps}>
          {formik.values.country
            ? `${i18n('pay')} ${toMoney({
                sumUsd: archivePrices.find((archivePrice) => archivePrice.country === formik.values.country).price,
                type: me.paymentMethod === 'cp' ? 'both' : 'usd',
                usdPrice,
              })}`
            : i18n('pay')}
        </Button>
      </FormItems>
    </Form>
  )
}

const BuyArchivePP = ({ temp, me, archivePrices, country, setCountry }) => {
  const { usdPrice } = useQuery('getUsdPrice')
  const apolloClient = useApolloClient()
  const { formik } = useForm({
    initialValues: {
      country: country,
    },
  })
  useEffect(() => {
    setCountry(formik.values.country)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country])
  return (
    <FormItems>
      <ChooseCountry formik={formik} me={me} archivePrices={archivePrices} usdPrice={usdPrice} />
      {!!formik.values.country && me.lang === 'ru' && <PaymentMethod me={me} />}
      <PayPalScriptProvider options={{ 'client-id': process.env.REACT_APP_PP_CLIENT_ID }}>
        {!!formik.values.country && (
          <PayPalButtons
            forceReRender={[formik.values.country]}
            createOrder={(data, actions) => {
              return fetch(process.env.REACT_APP_BACKEND_URI + '/paypal-create-order', {
                method: 'post',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  type: 'archive',
                  country: formik.values.country,
                  userId: me.id,
                  redirect: window.location.href,
                }),
              })
                .then((response) => response.json())
                .then((order) => order.id)
            }}
            onApprove={(data, actions) => {
              return fetch(process.env.REACT_APP_BACKEND_URI + '/paypal-capture-payment', {
                method: 'post',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  orderId: data.orderID,
                }),
              })
                .then((response) => response.json())
                .then((orderData) => {
                  formik.setFieldValue('country', '')
                  return apolloClient.reFetchObservableQueries()
                })
            }}
          />
        )}
      </PayPalScriptProvider>
    </FormItems>
  )
}

const BuyArchive = ({ temp, me, archivePrices }) => {
  const [country, setCountry] = useState('')
  return (
    <>
      <Deprecated />
      {/* {me.paymentMethod === 'cp' ? (
        <BuyArchiveCP {...{ temp, me, archivePrices, country, setCountry }} />
      ) : (
        <BuyArchivePP {...{ temp, me, archivePrices, country, setCountry }} />
      )} */}
    </>
  )
}

export default BuyArchive
