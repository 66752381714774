const lang = {
  ru: {
    title: 'Пользователь',
    data: 'Дата',
    address: 'Адрес',
    activations: 'Активирован',
    yes: 'Да',
    no: 'Нет',
  },
  en: {
    title: 'User',
    data: 'Date',
    address: 'Address',
    activations: 'Activated',
    yes: 'Yes',
    no: 'No',
  }
}

export default lang
