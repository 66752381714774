import React from 'react'
import PagePadding from 'components/PagePadding'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Link from 'components/Link'
import Layout from 'components/Layout'
import Form, { FormItems, useForm } from 'components/Form'
import * as Yup from 'yup'
import useMutation from 'lib/useMutation'
import parseQueryString from 'lib/parseQueryString'
import cookieStore from 'lib/cookieStore'
import { useApolloClient } from '@apollo/react-hooks'
import PageWrapper from 'components/PageWrapper'
import { countryOptions } from 'lib/toHumanCountry'
import Select from 'components/Select'
import css from './styles.module.scss'
import { getDashboardRoute, getSignUpRoute, getSignInRoute } from 'lib/routes'
import Helmet from 'components/Helmet'
import { aliasUser, track } from 'lib/mixpanel'
import useGlobalState from 'lib/globalState'
import { useLang } from 'lib/i18n'
import lang from './lang.js'

const SignUpPage = ({ history }) => {
  const i18n = useLang(lang)
  const [globalState] = useGlobalState()
  const langName = globalState.lang
  const apolloClient = useApolloClient()
  const queryArray = parseQueryString()
  const [signUp] = useMutation('signUp', {
    errorCodes: {
      ERR_EMAIL_TAKEN: i18n('errorCodes'),
    },
  })
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      email: '',
      country: '',
      password: '',
      passwordAgain: '',
      referralCode: cookieStore.get('referralCode') || '',
    },
    onSubmit: ({ email, country, password, referralCode }) => {
      return signUp({
        variables: { input: { email, country, password, referralCode, lang: langName } },
        parser: [['token', 'signUp.token'], ['user', 'signUp.user']],
      }).then(({ token, user }) => {
        if (token) {
          aliasUser(user.id)
          track('signUp', { referralCode })
          cookieStore.set('token', token)
          return apolloClient.resetStore().then(() => {
            history.push(queryArray.then ? queryArray.then : getDashboardRoute())
            return false
          })
        }
      })
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18n('emailErr'))
        .required(i18n('required')),
      country: Yup.string().required(i18n('required')),
      password: Yup.string().required(i18n('required')),
      passwordAgain: Yup.string()
        .oneOf([Yup.ref('password'), null], i18n('passErr'))
        .required(i18n('required')),
    }),
    successMessage: false,
  })
  return (
    <div className={css.signInPage}>
      <Helmet title={i18n('title')} />
      <Layout perple={true} minimal={true}>
        <PagePadding>
          <PageWrapper redirerctAuthorized={queryArray.then ? queryArray.then : getDashboardRoute()}>
            <div className={css.modal}>
              <div className={css.menu}>
                <Link type="solid" className={css.link} currentClassName={css.current} nowrap to={getSignInRoute()}>
                  {i18n('login')}
                </Link>
                <Link type="solid" className={css.link} currentClassName={css.current} nowrap to={getSignUpRoute()}>
                  {i18n('regLink')}
                </Link>
              </div>
              <div className={css.content}>
                <Form formik={formik}>
                  <FormItems>
                    <Input label={i18n('email')} name="email" formik={formik} />
                    <Select
                      label={i18n('country')}
                      hint={i18n('countryHit')}
                      name="country"
                      options={[...countryOptions, { value: 'other', label: 'Other' }]}
                      formik={formik}
                    />
                    <Input label={i18n('pass')} name="password" type="password" formik={formik} />
                    <Input label={i18n('confirmPass')} name="passwordAgain" type="password" formik={formik} />
                    <Alert {...alertProps} />
                    <Button {...buttonProps}>{i18n('registrations')}</Button>
                  </FormItems>
                </Form>
              </div>
            </div>
          </PageWrapper>
        </PagePadding>
      </Layout>
    </div>
  )
}

export default SignUpPage
