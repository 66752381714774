import useGlobalState from 'lib/globalState'
import _ from 'lodash'

export const useLang = (obj, lang) => {
  const [globalState] = useGlobalState()
  const langHere = lang || globalState.lang
  return (key, ...arg) => {
    if (key === 'lang') {
      return langHere
    }
    const value = _.get(obj[langHere], key)
    return _.isFunction(value) ? value(...arg) : value || 'INVALID LANG KEY'
  }
}

export const switchLang = (lang, dispatchGlobalAction, location, history, changeMyLang, me) => {
  const domain = window.location.hostname
  const byDomainLangShouldBe = domain === 'binking.ru' || domain === '127.0.0.1' ? 'ru' : 'en'
  if (lang !== byDomainLangShouldBe) {
    return
  }
  dispatchGlobalAction({
    type: 'switchLang',
    input: {
      lang: lang,
    },
  })
  me &&
    changeMyLang({
      variables: {
        input: {
          lang: lang,
        },
      },
    })
  // const currentUrlLang = location.pathname.split('/')[1]
  // if (['ru', 'en'].includes(currentUrlLang)) {
  //   const newPath = location.pathname.replace(`/${currentUrlLang}/`, `/${lang}/`)
  //   history.replace(newPath)
  // }
}
