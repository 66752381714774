const lang = {
  ru: {
    newCard: 'Новая карта',
    validTrough: 'Действует до',
    code: 'Код<br>на&nbsp;обратной<br>стороне',
    saveCard: 'Сохранить карту для последующих платежей',
    pay: 'Оплатить',
    success: 'Оплата произведена успешно',
    back: 'Вернуться к форме оплаты',
    check: 'Проверьте корректность введённых данных',
    bank1: 'ru-sberbank',
    bank2: 'ru-rosbank',
    reject: (bankInfo) =>
      bankInfo && bankInfo.bankPhone
        ? 'Ваш банк отклонил операцию по указанной карте. Позвоните в ' +
          bankInfo.bankLocalName +
          ' по номеру ' +
          bankInfo.bankPhone +
          ', чтобы устранить причину.'
        : 'Ваш банк отклонил операцию по указанной карте.',
  },
  en: {
    newCard: 'New card',
    validTrough: 'Valid through',
    code: 'Security<br>code',
    saveCard: 'Save your card for future payments',
    pay: 'Pay',
    success: 'Payment successful',
    back: 'Back to payment form',
    check: 'Check your inputs',
    bank1: 'us-chase',
    bank2: 'de-deutsche',
    reject: (bankInfo) =>
      bankInfo && bankInfo.bankPhone
        ? 'Your bank has declined the transaction by the specified card. Call to ' +
          bankInfo.bankLocalName +
          ' by number ' +
          bankInfo.bankPhone +
          ', in order to remove the causes.'
        : 'Your bank has declined the transaction by the specified card.',
  },
}

export default lang
