const lang = {
  ru: {
    token: 'Ссылка для подтверждения адреса электронной почты не действительна',
    title: 'Подтверждение почты',
    success: 'Почта успешно подтверждена',
    home: 'На главную',
  },
  en: {
    token: 'Email verification link is invalid',
    title: 'Email confirmation',
    success: 'Email successfully confirmed',
    home: 'To home page',
  }
}

export default lang
