import React from 'react'
import css from './styles.module.scss'

const PagePadding = (props) => (
  <div className={css.pagePadding}>
    <div className={css.wrapper} {...props} />
  </div>
)

export default PagePadding
