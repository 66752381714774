import React from 'react'
import css from './styles.module.scss'
import QueryWrapper from 'components/QueryWrapper'
import Loader from 'components/Loader'
import useQuery from 'lib/useQuery'
import toHumanDate from 'lib/toHumanDate'
import InfiniteScroll from 'react-infinite-scroller'
import cn from 'classnames'
import { getBankEditRoute } from 'lib/routes'
import { useHistory } from 'react-router-dom'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const BanksLogs = ({ bankId, ...restProps }) => {
  const i18n = useLang(lang)
  const history = useHistory()
  const queryVariables = !bankId
    ? {}
    : {
        bankId,
      }
  const queryResult = useQuery('getBanksLogs', {
    variables: { input: queryVariables },
  })
  const { banksLogs, pageInfo, fetchMore } = queryResult
  return (
    <QueryWrapper queryResult={queryResult}>
      {() => (
        <table className={css.banksLogs}>
          <thead>
            <tr>
              <th>{i18n('code')}</th>
              <th>{i18n('date')}</th>
              <th>{i18n('user')}</th>
              <th>{i18n('bank')}</th>
            </tr>
          </thead>
          <InfiniteScroll
            threshold={250}
            element="tbody"
            loadMore={async () => {
              fetchMore({
                variables: {
                  input: {
                    ...queryVariables,
                    cursor: pageInfo.cursor,
                    limit: 30,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  return {
                    getBanksLogs: {
                      ...fetchMoreResult.getBanksLogs,
                      banksLogs: [
                        ...prev.getBanksLogs.banksLogs,
                        ...fetchMoreResult.getBanksLogs.banksLogs,
                      ],
                    },
                  }
                },
              })
            }}
            hasMore={pageInfo.hasNextPage}
            loader={
              <tr key="loader">
                <td colSpan="4">
                  <Loader type="section" />
                </td>
              </tr>
            }
          >
            {banksLogs.map((bankLog, i) => {
              const onClick =
                bankId || !bankLog.bank
                  ? undefined
                  : () => history.push(getBankEditRoute(bankLog.bank.id))
              return (
                <React.Fragment key={i}>
                  <tr
                    className={cn(css.main, !bankId && css.clickable)}
                    onClick={onClick}
                  >
                    <td className={css.code}>{bankLog.code}</td>
                    <td>
                      {toHumanDate(bankLog.createdAt, 'DD.MM.YYYY HH:mm')}
                    </td>
                    <td>{bankLog.user.email}</td>
                    <td>{bankLog.bank && bankLog.bank.alias}</td>
                  </tr>
                  {!!bankLog.extra && (
                    <tr
                      className={cn(css.extra, !bankId && css.clickable)}
                      onClick={onClick}
                    >
                      <td colSpan="4">{bankLog.extra}</td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </InfiniteScroll>
        </table>
      )}
    </QueryWrapper>
  )
}

export default BanksLogs
