import React from 'react'
import PagePadding from 'components/PagePadding'
import MainSegment from 'components/MainSegment'
import Layout from 'components/Layout'
import Helmet from 'components/Helmet'
import Link from 'components/Link'
import RichText from 'components/RichText'
import { github } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import SyntaxHighlighter from 'react-syntax-highlighter'
import useGlobalState from 'lib/globalState'

const GuidePage = () => {
  const [globalState] = useGlobalState()
  const langName = globalState.lang
  return langName === 'ru' ? (
    <Layout perple={true} minimal={true}>
      <Helmet title="Создаём королевскую форму для приёма банковских карт" />
      <PagePadding>
        <MainSegment title="Создаём королевскую форму для приёма банковских карт">
          <RichText>
            <p>
              В этой статье я дам рекомендации по созданию платёжных форм, которые будут выгодно отличаться от форм
              ваших конкурентов. Каждый пункт рекомендаций будет сопровождаться примером кода. Полный пример кода,
              включающий адаптивную вёрстку, реализацию валидационных тултипов, и прочих мелочей опущенных для краткости
              в самой статье вы можете <Link href="http://jsfiddle.net/iserdmi/9sj53x01/">посмотреть здесь.</Link>
            </p>
            <p>
              В рамках этой статьи мы не рассматриваем привязку формы к какому-то конкретному мерчант, мы лишь делаем её
              более отзывчивой.
            </p>
            <p>Для создания формы мы будем использовать следующие инструменты:</p>
            <ul>
              <li>Нативный JS</li>
              <li>
                BinKing — вспомогательный сервис для создания платёжных форм:{' '}
                <Link href="https://github.com/sdandteam/binking">https://github.com/sdandteam/binking</Link>
              </li>
              <li>
                IMask — инструмент для создания масок полей ввода:{' '}
                <Link href="https://imask.js.org/">https://imask.js.org</Link>
              </li>
              <li>
                Tippy — инструмент для создания тултипов:{' '}
                <Link href="https://atomiks.github.io/tippyjs/">https://atomiks.github.io/tippyjs</Link>
              </li>
            </ul>
            <h2>Определение логотипа банка</h2>
            <p>
              Вы наверное замечали, что существуют такие формы для приёма банковских карт, в которых, по мере ввода
              номера карты, появляется логотип банка, которому принадлежит банковская карта?
            </p>
            <p>Такое поведение помогает реализовать JS плагин BinKing:</p>
            <SyntaxHighlighter style={github} language="javascript">{`      function initBinking () {
        binking.setDefaultOptions({
          strategy: 'api',
          apiKey: 'cbc67c2bdcead308498918a694bb8d77' // Replace it with your API key
        })
      }

      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          // …
          if (result.formBankLogoBigSvg) {
            $bankLogo.src = result.formBankLogoBigSvg
            $bankLogo.classList.remove('binking__hide')
          } else {
            $bankLogo.classList.add('binking__hide')
          }
          // …
        })
      }`}</SyntaxHighlighter>

            <h2>Определение цветов банка</h2>
            <p>
              Для красоты картины предлагаю вам также перекрашивать саму форму в цвета банка. Разумеется важно также не
              забыть и перекрасить цвет текста. Здесь нам опять же поможет BinKing.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          // …
          $frontPanel.style.background = result.formBackgroundColor
          $frontPanel.style.color = result.formTextColor
          // …
        })
      }`}</SyntaxHighlighter>
            <h2>Определение логотипа платёжной системы</h2>
            <p>
              Традиционно формы оплаты отображают логотип платёжной системы выпустившей карту. Для этого опять же
              используем функционал BinKing. BinKing, в отличие от других плагинов для определения платёжной системы,
              предоставляет и сами логотипы.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          // …
          if (result.formBrandLogoSvg) {
            $brandLogo.src = result.formBrandLogoSvg
            $brandLogo.classList.remove('binking__hide')
          } else {
            $brandLogo.classList.add('binking__hide')
          }
          // …
        })
      }`}</SyntaxHighlighter>
            <h2>Определение банка привязанных карт</h2>
            <p>
              При вводе данных новой карты, записывайте себе в базу данных кроме токена карты ещё и alias банка в
              системе BinKing. Тогда при выводе привязанных карт вы сможете вывести кроме последних 4 цифр и логотипа
              платёжной системы ещё и логотип банка, что сильно упростит жизнь пользователю. Причём BinKing выдаёт как
              полноразмерные логотипы банков, так и эмблемы банков отдельно.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function showSavedCards () {
        if (savedCards.length) {
          var banksAliases = savedCards.map(function (card) {
            return card.bankAlias
          })
          binking.getBanks(banksAliases, function (result) {
            savedCardsBanks = result
            var savedCardsListHtml = savedCards.reduce(function (acc, card, i) {
              if (result[i]) {
                return acc += '<div class="binking__card" data-index="' + i + '">' +
                '<img class="binking__card-bank-logo" src="' + result[i].bankLogoSmallOriginalSvg + '" />' +
                '<img class="binking__card-brand-logo" src="' + binking.getBrandLogo(card.brandAlias) + '" />' +
                '<div class="binking__card-last4">...' + card.last4 + '</div>' +
                '<div class="binking__card-exp">' + card.expMonth + '/' + card.expYear + '</div>' +
                '</div>'
              }
              return acc += '<div class="binking__card" data-index="' + i + '">' +
                '<img class="binking__card-brand-logo" src="' + binking.getBrandLogo(card.brandAlias) + '" />' +
                '<div class="binking__card-last4">... ' + card.last4 + '</div>' +
                '<div class="binking__card-exp">' + card.expMonth + '/' + card.expYear + '</div>' +
                '</div>'
            }, '') // вывод карты, для которой не был найден банк
            $сardsList.innerHTML = savedCardsListHtml + $сardsList.innerHTML
          })
        }
      }`}</SyntaxHighlighter>
            <h2>Автоматический фокус первого поля</h2>
            <p>
              Удобно, когда курсор уже установлен в первое поле, то есть в поле для ввода банковской карты. Это легко,
              достаточно пары строк кода:
            </p>
            <SyntaxHighlighter
              style={github}
              language="javascript"
            >{`      var $cardNumberField = document.querySelector('.binking__number-field')
      $cardNumberField.focus()`}</SyntaxHighlighter>
            <h2>Автоматически перевод курсора</h2>
            <p>
              Пользователю удобно, когда курсор автоматически перемещается между полями по мере ввода данных. Самая
              большая хитрость состоит в том, чтобы своевременно перевести курсор из поля для ввода карты. Беда в том,
              что не все номера карт состоят из 16 цифр. Переводить курсор следует тогда и только тогда, когда введено
              символов не меньше минимальной длины карты, и когда в номере карты нету ошибок согласно алгоритму Луна
              (алгоритм позволяющий определить содержатся ли в номере карты опечатки).
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          // …
          var validationResult = validate()
          var isFulfilled = result.cardNumberNormalized.length >= result.cardNumberMinLength
          var isChanged = prevNumberValue !== $cardNumberField.value
          if (isChanged && isFulfilled) {
            if (validationResult.errors.cardNumber) {
              cardNumberTouched = true
              validate()
            } else {
              $monthField.focus()
            }
          }
          prevNumberValue = $cardNumberField.value
        })
      }

      function monthChangeHandler () {
        var validationResult = validate()
        if (prevMonthValue !== $monthField.value && $monthField.value.length >= 2) {
          if (validationResult.errors.month) {
            monthTouched = true
            validate()
          } else {
            $yearField.focus()
          }
        }
        prevMonthValue = $monthField.value
      }

      function yearChangeHandler () {
        var validationResult = validate()
        if (prevYearValue !== $yearField.value && $yearField.value.length >= 2) {
          if (validationResult.errors.year) {
            yearTouched = true
            validate()
          } else {
            $codeField.focus()
          }
        }
        prevYearValue = $yearField.value
      }`}</SyntaxHighlighter>
            <h2>Валидация полей формы</h2>
            <p>
              Для валидация полей формы мы используем метод validate от BinKing. Валидатор позаботится о том, чтобы в
              номере карты не было опечаток, чтобы дата срока истечения карты была в будущем, а не в прошлом, проверит
              заполненность полей и прочее:{' '}
              <Link to="https://github.com/sdandteam/binking#validation">
                https://github.com/sdandteam/binking#validation
              </Link>
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function validate () {
        var validationResult = binking.validate($cardNumberField.value, $monthField.value, $yearField.value, $codeField.value)
        if (validationResult.errors.cardNumber && cardNumberTouched) {
          cardNumberTip.setContent(validationResult.errors.cardNumber.message)
          cardNumberTip.show()
        } else {
          cardNumberTip.hide()
        }
        var monthHasError = validationResult.errors.month && monthTouched
        if (monthHasError) {
          monthTip.setContent(validationResult.errors.month.message)
          monthTip.show()
        } else {
          monthTip.hide()
        }
        if (!monthHasError && validationResult.errors.year && yearTouched) {
          yearTip.setContent(validationResult.errors.year.message)
          yearTip.show()
        } else {
          yearTip.hide()
        }
        if (validationResult.errors.code && codeTouched) {
          codeTip.setContent(validationResult.errors.code.message)
          codeTip.show()
        } else {
          codeTip.hide()
        }
        return validationResult
      }`}</SyntaxHighlighter>
            <h2>Маски полей формы</h2>
            <p>
              Давайте сделаем так, чтобы в наши поля можно было вводить только цифры, номер карты аккуратно разделялся
              пробелами, а в поле месяца нельзя было ввести число большее 12.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function initMasks () {
        cardNumberMask = IMask($cardNumberField, {
          mask: binking.defaultResult.cardNumberMask
        })
        monthMask = IMask($monthField, {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
          autofix: true
        })
        yearMask = IMask($yearField, {
          mask: '00'
        })
        codeMask = IMask($codeField, {
          mask: '0000'
        })
      }`}</SyntaxHighlighter>
            <h2>Показ телефона банка в случае отклонения платежа</h2>
            <p>
              Если платёж отклоняется банком, то есть ошибка перевода не наш вашей стороне, то с целью снижения нагрузки
              на ваш отдел поддержки, покажите пользователю понятно сообщение, указав название банка и номер телефона
              банка. Всё это опять же можно сделать благодаря BinKing.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          newCardInfo = result
          // …
        })
      }

      function formSubmitHandler (e) {
        // …
        var bankInfo = selectedCardIndex !== null ? savedCardsBanks[selectedCardIndex] : newCardInfo || null
        $error.innerHTML = bankInfo && bankInfo.bankPhone
          ? 'Ваш банк отклонил операцию по указанной карте. Позвоните в ' + bankInfo.bankLocalName + ' по номеру ' + bankInfo.bankPhone + ', чтобы устранить причину.'
          : 'Ваш банк отклонил операцию по указанной карте.'
        // …`}</SyntaxHighlighter>
            <h2>Логотипы вызывающие доверие</h2>
            <p>
              Принято размещать рядом с формой логотипы вызывающие доверия. Чтобы вам самостоятельно не пришлось их
              искать, вот вам эти логотипы в формате svg.
            </p>
            <SyntaxHighlighter style={github} language="html">{`      <div class="binking__trust-logos">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/secure-connection.svg" alt="">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/mastercard.svg" alt="">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/mir.svg" alt="">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/visa.svg" alt="">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/pci-dss.svg" alt="">
      </div>`}</SyntaxHighlighter>
            <h2>Правильная раскладка клавиатуры</h2>
            <p>
              На мобильных телефонах возможно указать то, какой будет отображаемая клавиатура при фокусе на том или ином
              поле. Давайте сделаем так, чтобы выпадала клавиатура для ввода чисел. Для этого необходимо указать
              атрибуты <code>inputmode="numeric" pattern="[0-9]*"</code>
            </p>
            <h2>Распознавание полей для ввода карты</h2>
            <p>
              У некоторых пользователей сохранены данные платёжных карт в браузере. Чтобы в вашей форме работало
              автоматическое распознавание полей необходимо указать правильные атрибуты <code>name</code> и{' '}
              <code>autocomplete</code>
            </p>
            <SyntaxHighlighter
              style={github}
              language="html"
            >{`          <div class="binking__panel binking__front-panel">
            <img class="binking__form-bank-logo binking__hide">
            <img class="binking__form-brand-logo binking__hide">
            <div class="binking__front-fields">
              <input name="cardnumber" autocomplete="cc-number" inputmode="numeric" pattern="[0-9 ]*" class="binking__field binking__number-field" type="text" placeholder="0000 0000 0000 0000">
              <label class="binking__label binking__date-label">Действует до</label>
              <input name="ccmonth" autocomplete="cc-exp-month" inputmode="numeric" pattern="[0-9]*" class="binking__field binking__month-field binking__date-field" type="text" placeholder="MM">
              <input name="ccyear" autocomplete="cc-exp-year" inputmode="numeric" pattern="[0-9]*" class="binking__field binking__year-field binking__date-field" type="text" placeholder="YY">
            </div>
          </div>
          <div class="binking__panel binking__back-panel">
            <input name="cvc" autocomplete="cc-csc" inputmode="numeric" pattern="[0-9]*" class="binking__field binking__code-field" type="password">
            <label class="binking__label binking__code-label">Код<br>на&nbsp;обратной<br>стороне</label>
          </div>`}</SyntaxHighlighter>
          </RichText>
        </MainSegment>
      </PagePadding>
    </Layout>
  ) : (
    <Layout perple={true} minimal={true}>
      <Helmet title="How to make a custom bank card data fill-in form" />
      <PagePadding>
        <MainSegment title="How to make a custom bank card data fill-in form">
          <RichText>
            <p>
              In this article, I provide some tips on how to make payment forms that would look better than your competitors’ analogues. Every tip is provided with code samples. The full code sample, including validation tooltips and other peculiarities is <Link href="http://jsfiddle.net/iserdmi/9sj53x01/">here.</Link>
            </p>
            <p>
              We are not going to talk about binding the form to some definite merchant product; we just focus on how to make the form more responsive.
            </p>
            <p>To create forms, we are going to use the next tools:</p>
            <ul>
              <li>Native JS</li>
              <li>
                BinKing — a payment forms creation supservice:{' '}
                <Link href="https://github.com/sdandteam/binking">https://github.com/sdandteam/binking</Link>
              </li>
              <li>
                IMask — an input mask creation tool:{' '}
                <Link href="https://imask.js.org/">https://imask.js.org</Link>
              </li>
              <li>
                Tippy — a tooltip creation tool:{' '}
                <Link href="https://atomiks.github.io/tippyjs/">https://atomiks.github.io/tippyjs</Link>
              </li>
            </ul>
            <h2>Bank logo output</h2>
            <p>
              Probably you’ve noticed that there are some banking card information fulfillment forms that display a bank’s logo after filling in a card number.
            </p>
            <p>This conditions are executed via JS plugin BinKing:</p>
            <SyntaxHighlighter style={github} language="javascript">{`      function initBinking () {
        binking.setDefaultOptions({
          strategy: 'api',
          apiKey: 'cbc67c2bdcead308498918a694bb8d77' // Replace it with your API key
        })
      }

      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          // …
          if (result.formBankLogoBigSvg) {
            $bankLogo.src = result.formBankLogoBigSvg
            $bankLogo.classList.remove('binking__hide')
          } else {
            $bankLogo.classList.add('binking__hide')
          }
          // …
        })
      }`}</SyntaxHighlighter>

            <h2>Bank colors output</h2>
            <p>
              To make everything looks nice, the form is better to be painted with a bank’s brand color. It’s important to change the text colorization as well. To solve these tasks we use BinKing again.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          // …
          $frontPanel.style.background = result.formBackgroundColor
          $frontPanel.style.color = result.formTextColor
          // …
        })
      }`}</SyntaxHighlighter>
            <h2>Payment system logo output</h2>
            <p>
              Usually, a logo of a bank card emitter payment system is displayed on a payment form. To accomplish this, we use BinKing. Unlike other plugins, BinKing does not only recognize a payment systems, but displays payment system logos as well.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          // …
          if (result.formBrandLogoSvg) {
            $brandLogo.src = result.formBrandLogoSvg
            $brandLogo.classList.remove('binking__hide')
          } else {
            $brandLogo.classList.add('binking__hide')
          }
          // …
        })
      }`}</SyntaxHighlighter>
            <h2>Recognition of references between cards and banks</h2>
            <p>
              During a new card entry, record to your database both token and BinKing system alias. If you do so, you’ll be able to output not only 4 last digits and a payment system logo, but a bank logo as well. This would improve and simplify user experience a lot. BinKing is capable to show the full-sized bank logos or just separate bank logo picks.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function showSavedCards () {
        if (savedCards.length) {
          var banksAliases = savedCards.map(function (card) {
            return card.bankAlias
          })
          binking.getBanks(banksAliases, function (result) {
            savedCardsBanks = result
            var savedCardsListHtml = savedCards.reduce(function (acc, card, i) {
              if (result[i]) {
                return acc += '<div class="binking__card" data-index="' + i + '">' +
                '<img class="binking__card-bank-logo" src="' + result[i].bankLogoSmallOriginalSvg + '" />' +
                '<img class="binking__card-brand-logo" src="' + binking.getBrandLogo(card.brandAlias) + '" />' +
                '<div class="binking__card-last4">...' + card.last4 + '</div>' +
                '<div class="binking__card-exp">' + card.expMonth + '/' + card.expYear + '</div>' +
                '</div>'
              }
              return acc += '<div class="binking__card" data-index="' + i + '">' +
                '<img class="binking__card-brand-logo" src="' + binking.getBrandLogo(card.brandAlias) + '" />' +
                '<div class="binking__card-last4">... ' + card.last4 + '</div>' +
                '<div class="binking__card-exp">' + card.expMonth + '/' + card.expYear + '</div>' +
                '</div>'
            }, '') // cards with undected bank
            $сardsList.innerHTML = savedCardsListHtml + $сardsList.innerHTML
          })
        }
      }`}</SyntaxHighlighter>
            <h2>First field auto-focus</h2>
            <p>
              It’s quite convenient when the cursor is set on the first field already (this is a card number field). It’s easy, just two lines of code:
            </p>
            <SyntaxHighlighter
              style={github}
              language="javascript"
            >{`      var $cardNumberField = document.querySelector('.binking__number-field')
      $cardNumberField.focus()`}</SyntaxHighlighter>
            <h2>Automatic cursor move</h2>
            <p>
              It’s easier for a user when the cursor moves from the completed field to the next one. The point here is to move the cursor right in time. The problem here is that some cards numbers consist of more (or less) then 16 digits. The cursor should be moved only when the least number of digits is reached, and when the card number is entered correctly according to Luna algorithm (this algorithm helps to check the card number entry on typos).
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          // …
          var validationResult = validate()
          var isFulfilled = result.cardNumberNormalized.length >= result.cardNumberMinLength
          var isChanged = prevNumberValue !== $cardNumberField.value
          if (isChanged && isFulfilled) {
            if (validationResult.errors.cardNumber) {
              cardNumberTouched = true
              validate()
            } else {
              $monthField.focus()
            }
          }
          prevNumberValue = $cardNumberField.value
        })
      }

      function monthChangeHandler () {
        var validationResult = validate()
        if (prevMonthValue !== $monthField.value && $monthField.value.length >= 2) {
          if (validationResult.errors.month) {
            monthTouched = true
            validate()
          } else {
            $yearField.focus()
          }
        }
        prevMonthValue = $monthField.value
      }

      function yearChangeHandler () {
        var validationResult = validate()
        if (prevYearValue !== $yearField.value && $yearField.value.length >= 2) {
          if (validationResult.errors.year) {
            yearTouched = true
            validate()
          } else {
            $codeField.focus()
          }
        }
        prevYearValue = $yearField.value
      }`}</SyntaxHighlighter>
            <h2>Input fields validations</h2>
            <p>
              To validate fields, we use BinKing validate method. Validator helps to avoid typos in a card numbed, it checks a card expire data, checks the fulfillment of every field, etc.:{' '}
              <Link to="https://github.com/sdandteam/binking#validation">
                https://github.com/sdandteam/binking#validation
              </Link>
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function validate () {
        var validationResult = binking.validate($cardNumberField.value, $monthField.value, $yearField.value, $codeField.value)
        if (validationResult.errors.cardNumber && cardNumberTouched) {
          cardNumberTip.setContent(validationResult.errors.cardNumber.message)
          cardNumberTip.show()
        } else {
          cardNumberTip.hide()
        }
        var monthHasError = validationResult.errors.month && monthTouched
        if (monthHasError) {
          monthTip.setContent(validationResult.errors.month.message)
          monthTip.show()
        } else {
          monthTip.hide()
        }
        if (!monthHasError && validationResult.errors.year && yearTouched) {
          yearTip.setContent(validationResult.errors.year.message)
          yearTip.show()
        } else {
          yearTip.hide()
        }
        if (validationResult.errors.code && codeTouched) {
          codeTip.setContent(validationResult.errors.code.message)
          codeTip.show()
        } else {
          codeTip.hide()
        }
        return validationResult
      }`}</SyntaxHighlighter>
            <h2>Input form masks</h2>
            <p>
              Let’s set digits only for a card number field, let’s divide the card number with spaces and let’s limit the month field with maximum value of 12.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function initMasks () {
        cardNumberMask = IMask($cardNumberField, {
          mask: binking.defaultResult.cardNumberMask
        })
        monthMask = IMask($monthField, {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
          autofix: true
        })
        yearMask = IMask($yearField, {
          mask: '00'
        })
        codeMask = IMask($codeField, {
          mask: '0000'
        })
      }`}</SyntaxHighlighter>
            <h2>Bank phone number display in case of payment rejection</h2>
            <p>
              If the payment is rejected by the bank (which means that this rejection didn’t happen due to user’s misactions), then, to reduce the stress on your support service, you can display a message with a name of bank and its phone number, so users can contact the bank by themselves. You can provide this function with BinKing too.
            </p>
            <SyntaxHighlighter style={github} language="javascript">{`      function cardNumberChangeHandler () {
        binking($cardNumberField.value, function (result) {
          newCardInfo = result
          // …
        })
      }

      function formSubmitHandler (e) {
        // …
        var bankInfo = selectedCardIndex !== null ? savedCardsBanks[selectedCardIndex] : newCardInfo || null
        $error.innerHTML = bankInfo && bankInfo.bankPhone
          ? 'Your bank has declined the transaction by the specified card. Call to ' + bankInfo.bankLocalName + ' by number ' + bankInfo.bankPhone + ', in order to remove the causes.'
          : 'Your bank has declined the transaction by the specified card.'
        // …`}</SyntaxHighlighter>
            <h2>Reliable logos</h2>
            <p>
              Usually reliable logos stands next to a form. Here we provide them in svg format, so there’s no need for you to search these logos by yourself.
            </p>
            <SyntaxHighlighter style={github} language="html">{`      <div class="binking__trust-logos">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/secure-connection.svg" alt="">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/mastercard.svg" alt="">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/mir.svg" alt="">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/visa.svg" alt="">
        <img class="binking__trust-logo" src="https://static.binking.io/trust-logos/pci-dss.svg" alt="">
      </div>`}</SyntaxHighlighter>
            <h2>Correct keyboard layout</h2>
            <p>
              On mobile phones you can choose the keyboard layout for every input field. Let’s set the numeric keypad. To make it, we should set the next attributes: <code>inputmode="numeric" pattern="[0-9]*"</code>
            </p>
            <h2>Card fields recognition</h2>
            <p>
              Some users have card data saved on browsers. To make your form automatic recognition works correctly, you should set the appropriate <code>name</code> and{' '}
              <code>autocomplete</code>
            </p>
            <SyntaxHighlighter
              style={github}
              language="html"
            >{`          <div class="binking__panel binking__front-panel">
            <img class="binking__form-bank-logo binking__hide">
            <img class="binking__form-brand-logo binking__hide">
            <div class="binking__front-fields">
              <input name="cardnumber" autocomplete="cc-number" inputmode="numeric" pattern="[0-9 ]*" class="binking__field binking__number-field" type="text" placeholder="0000 0000 0000 0000">
              <label class="binking__label binking__date-label">Valid through</label>
              <input name="ccmonth" autocomplete="cc-exp-month" inputmode="numeric" pattern="[0-9]*" class="binking__field binking__month-field binking__date-field" type="text" placeholder="MM">
              <input name="ccyear" autocomplete="cc-exp-year" inputmode="numeric" pattern="[0-9]*" class="binking__field binking__year-field binking__date-field" type="text" placeholder="YY">
            </div>
          </div>
          <div class="binking__panel binking__back-panel">
            <input name="cvc" autocomplete="cc-csc" inputmode="numeric" pattern="[0-9]*" class="binking__field binking__code-field" type="password">
            <label class="binking__label binking__code-label">Security<br>code</label>
          </div>`}</SyntaxHighlighter>
          </RichText>
        </MainSegment>
      </PagePadding>
    </Layout>
  )
}

export default GuidePage
