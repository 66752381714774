import { useApolloClient } from '@apollo/react-hooks'
import cookieStore from 'lib/cookieStore'
import { useHistory } from 'react-router-dom'
import { getSignInRoute } from 'lib/routes'

const useLogout = () => {
  const apolloClient = useApolloClient()
  const history = useHistory()
  return () => {
    cookieStore.remove('token')
    return apolloClient.resetStore().then(() => {
      history.push(getSignInRoute())
    })
  }
}

export default useLogout
