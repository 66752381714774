import React, { useEffect, useState } from 'react'
import { useForm } from 'components/Form'
import useMutation from 'lib/useMutation'
import binking from 'lib/binking'
import css from './styles.module.scss'

const BankCard = ({ bankCard, setAlertProps }) => {
  const [removeBankCard] = useMutation('removeBankCard')
  const { formik } = useForm({
    initialValues: {},
    onSubmit: values => {
      return removeBankCard({
        variables: {
          input: {
            hash: bankCard.hash,
          },
        },
      }).then(() => false)
    },
    successMessage: false,
  })
  const [bkResult, setBkResult] = useState(null)
  useEffect(() => {
    binking(bankCard.firstSix, result => {
      setBkResult(result)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={css.bankCard}>
      <span
        className={css.remove}
        onClick={e => {
          e.preventDefault()
          formik.handleSubmit()
        }}
      ></span>
      <img alt="" src={bkResult && bkResult.bankLogoSmallOriginalSvg} className={css.bankLogo} />
      <img alt="" src={bkResult && bkResult.brandLogoOriginalSvg} className={css.brandLogo} />
      <span className={css.lastFour}>... {bankCard.lastFour}</span>
      <span className={css.expDate}>{bankCard.expDate}</span>
    </div>
  )
}

const ManageBankCards = ({ me }) => {
  return (
    <div className={css.bankCards}>
      {me.bankCards.map((bankCard, i) => (
        <BankCard key={i} bankCard={bankCard} />
      ))}
    </div>
  )
}

export default ManageBankCards
