import React from 'react'
import 'styles/global.scss'
import ApolloProvider from 'components/ApolloProvider'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomePage from 'pages/HomePage'
import MakeArchivePriceVisible from 'components/MakeArchivePriceVisible'
import UserMixpanel from 'components/UserMixpanel'
import LangNormalizer from 'components/LangNormalizer'
import ReferralLink from 'components/ReferralLink'
// import ServicePage from 'pages/ServicePage'
import GuidePage from 'pages/GuidePage'
import CompanyPage from 'pages/CompanyPage'
import BankJSONPage from 'pages/BankJSONPage'
import PricePage from 'pages/PricePage'
import DocsIntroPage from 'pages/DocsIntroPage'
import DocsApiPage from 'pages/DocsApiPage'
import DocsArchivePage from 'pages/DocsArchivePage'
import DocsBanksPage from 'pages/DocsBanksPage'
import YandexMetrika from 'components/YandexMetrika'
// import DocsMerchantsPage from 'pages/DocsMerchantsPage'
import SignInPage from 'pages/SignInPage'
import SignUpPage from 'pages/SignUpPage'
// import DashboardPage from 'pages/DashboardPage'
import ConfirmEmailPage from 'pages/ConfirmEmailPage'
import ConfirmNewEmailPage from 'pages/ConfirmNewEmailPage'
import RestorePasswordPage from 'pages/RestorePasswordPage'
import ResetPasswordPage from 'pages/ResetPasswordPage'
import BanksPage from 'pages/BanksPage'
import BanksLogsPage from 'pages/BanksLogsPage'
import UsersPage from 'pages/UsersPage'
import UserPage from 'pages/UserPage'
import ReferersPage from 'pages/ReferersPage'
import BankNewPage from 'pages/BankNewPage'
import BankEditPage from 'pages/BankEditPage'
import BanksStatPage from 'pages/BanksStatPage'
import BinBanksPage from 'pages/BinBanksPage'
import ServiceStatPage from 'pages/ServiceStatPage'
import ApiSettingsPage from 'pages/ApiSettingsPage'
import ProfileSettingsPage from 'pages/ProfileSettingsPage'
import ArchiveSettingsPage from 'pages/ArchiveSettingsPage'
import PaymentsSettingsPage from 'pages/PaymentsSettingsPage'
import UnknownRequestedBinsPage from 'pages/UnknownRequestedBinsPage'
import NotFoundPage from 'pages/NotFoundPage'
import DashboardLayout from 'components/DashboardLayout'
import DocsLayout from 'components/DocsLayout'
import PagePadding from 'components/PagePadding'
import PaymentResultModal from 'components/PaymentResultModal'
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage'
import TermsOfUse from 'pages/TermsOfUse'
import * as routes from 'lib/routes'
import ScrollTopRouter from 'components/ScrollTopRouter'
import { GlobalStateProvider } from 'lib/globalState'
import * as store from 'lib/store'

function App() {
  React.useEffect(() => {
    const now = new Date()
    const firstOctober2024 = new Date('2024-10-01')
    if (now > firstOctober2024) {
      window.location.href = 'https://github.com/svagcrew/binking'
    }
  }, [])
  return (
    <GlobalStateProvider initialState={store.initialState} reducer={store.reducer}>
      <ApolloProvider>
        <Router>
          <ReferralLink />
          <LangNormalizer />
          <UserMixpanel />
          <MakeArchivePriceVisible />
          <Switch>
            <Route exact path={[routes.getHomeRoute(':lang'), '/']} component={HomePage} />
            {/*}<Route
            exact
            path={routes.getServiceRoute()}
            component={ServicePage}
          />*/}
            <Route exact path={routes.getGuideRoute(':lang')} component={GuidePage} />
            <Route exact path={routes.getPriceRoute(':lang')} component={PricePage} />
            <Route exact path={routes.getPrivacyPolicyRoute(':lang')} component={PrivacyPolicyPage} />
            <Route exact path={routes.getTermsOfUseRoute(':lang')} component={TermsOfUse} />
            <Route exact path={routes.getCompanyRoute(':lang')} component={CompanyPage} />
            <Route exact path={routes.getBankJSONRoute(':lang')} component={BankJSONPage} />
            <Route exact path={routes.getSignInRoute(':lang')} component={SignInPage} />
            <Route exact path={routes.getSignUpRoute(null, ':lang')} component={SignUpPage} />
            <Route
              exact
              path={routes.getConfirmEmailRoute(':confirmEmailToken', ':lang')}
              component={ConfirmEmailPage}
            />
            <Route
              exact
              path={routes.getConfirmNewEmailRoute(':confirmNewEmailToken', ':lang')}
              component={ConfirmNewEmailPage}
            />
            <Route exact path={routes.getRestorePasswordRoute(':lang')} component={RestorePasswordPage} />
            <Route
              exact
              path={routes.getResetPasswordRoute(':passwordResetToken', ':lang')}
              component={ResetPasswordPage}
            />
            <Route
              path={routes.getDocsRouteGroup(':lang')}
              render={() => (
                <DocsLayout>
                  <Switch>
                    <Route exact path={routes.getDocsIntroRoute(':lang')} component={DocsIntroPage} />
                    <Route exact path={routes.getDocsApiRoute(':lang')} component={DocsApiPage} />
                    <Route exact path={routes.getDocsArchiveRoute(':lang')} component={DocsArchivePage} />
                    <Route exact path={routes.getDocsBanksRoute(':lang')} component={DocsBanksPage} />
                    {/*<Route
                    exact
                    path={routes.getDocsMerchantsRoute()}
                    component={DocsMerchantsPage}
                  />*/}
                    <Route component={NotFoundPage} />
                  </Switch>
                </DocsLayout>
              )}
            />
            <Route
              path={routes.getDashboardRouteGroup(':lang')}
              render={() => (
                <DashboardLayout>
                  <Switch>
                    <Route exact path={routes.getApiSettingsRoute(':lang')} component={ApiSettingsPage} />
                    <Route exact path={routes.getProfileSettingsRoute(':lang')} component={ProfileSettingsPage} />
                    <Route exact path={routes.getArchiveSettingsRoute(':lang')} component={ArchiveSettingsPage} />
                    <Route exact path={routes.getPaymentsSettingsRoute(':lang')} component={PaymentsSettingsPage} />
                    <Route exact path={routes.getBankEditRoute(':bankId', ':lang')} component={BankEditPage} />
                    <Route exact path={routes.getBankNewRoute(':lang')} component={BankNewPage} />
                    <Route exact path={routes.getBanksRoute(':lang')} component={BanksPage} />
                    <Route exact path={routes.getUsersRoute(':lang')} component={UsersPage} />
                    <Route exact path={routes.getUserRoute(':userId', ':lang')} component={UserPage} />
                    <Route exact path={routes.getBanksLogsRoute(':lang')} component={BanksLogsPage} />
                    <Route exact path={routes.getReferersRoute(':lang')} component={ReferersPage} />
                    <Route exact path={routes.getBanksStatRoute(':lang')} component={BanksStatPage} />
                    <Route exact path={routes.getBinBanksRoute(':lang')} component={BinBanksPage} />
                    <Route exact path={routes.getServiceStatRoute(':lang')} component={ServiceStatPage} />
                    <Route
                      exact
                      path={routes.getUnknownRequestedBinsPageRoute(':lang')}
                      component={UnknownRequestedBinsPage}
                    />
                    <Route component={NotFoundPage} />
                  </Switch>
                </DashboardLayout>
              )}
            />
            <Route
              render={() => (
                <PagePadding>
                  <NotFoundPage />
                </PagePadding>
              )}
            />
          </Switch>
          <PaymentResultModal />
          <ScrollTopRouter />
          <YandexMetrika />
        </Router>
      </ApolloProvider>
    </GlobalStateProvider>
  )
}

export default App
