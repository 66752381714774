import React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'
const Helmet = ({ title, description }) => {
  return (
    <ReactHelmet>
      <title>{title ? `${title} — BinKing` : 'BinKing'}</title>
      {description && <meta name="description" content={description} />}
    </ReactHelmet>
  )
}

export default Helmet
