const lang = {
  ru: {
    apiOption: 'Настройки API',
    active: 'Активация',
    getKey: (sum, requests) => `Чтобы получить ключ API, привяжите банковскую карту. Привязав
    карту, вы получите подарочные ${sum} (${requests} запросов) на счёт и сможете начать
    использование API. После привязки карты, вы сразу же сможете
    настроить или отключить автоматическое пополнение баланса на
    этой же странице.`,
    getKey1: (
      sum,
      requests
    ) => `Чтобы получить ключ API, пополните баланс на любую сумму. Впервые пополнив баланс, вы дополнительно получите подарочные ${sum} (${requests} запросов) на счёт и сможете начать
    использование API.`,
    apiKey: 'Ваш API ключ:',
    balance: 'Ваш баланс:',
    requests: 'запросов',
    allQueryMonth: 'Количество вызовов за этот месяц:',
    allPriceMonth: 'Всего потрачено за этот месяц:',
    unavailable: 'API сейчас недоступно. Чтобы возобновить работу API пополните баланс',
    autoBalance: 'Автоматическое пополнение баланса',
    autoChangeText: `Чтобы автоматически пополнять баланс, когда он подходит к
    нулю, привяжите банковскую карту к своему аккаунту.`,
    autoText: `При включённом автоматическом пополнении, с вашей
    привязанной карты будет автоматически списываться
    указанное количество средств, при достижении указанного
    минимального порога, но не более указанной суммы в месяц.`,
    allUrl: 'Все URL ваших сайтов',
    urlText: `Укажите URL адреса ваших сайтов, на которых вы собирайтесь
    использовать API. Если оставить это поле пустым, то запрос к
    API можно будет отправить с любого сайта.`,
    changeApiKey: 'Изменение ключа API',
    yesText: `Если вы по каким-то причинам хотите обновить API ключ,
    введите слово "YES" в поле для подтверждения. Учтите, что
    старый ключ перестанет работать, и вам нужно будет
    установить новый ключ везде, где вы используете API.
    Изменяйте API ключ только, если вам это необходимо.`,
    statsMonths: 'Статистика использования по месяцам',
    statsDay: 'Статистика использования по дням',
  },
  en: {
    apiOption: 'API settings',
    active: 'Activation',
    getKey: (sum, requests) =>
      `In order to get an API key, connect your bank card. By connecting the card, you will receive a gift ${sum} (${requests} requests) to your account and you can start using the API. After connecting the card, you can immediately configure or disable automatic balance replenishment on the same page.`,
    getKey1: (sum, requests) =>
      `In order to get an API key, replenish the balance for any amount. When you top up your balance for the first time, you will additionally receive gift ${sum} (${requests} requests) to your account and you will be able to start API usage.`,
    apiKey: 'Your API key:',
    balance: 'Your balance:',
    requests: 'requests',
    allQueryMonth: 'Number of queries in this month:',
    allPriceMonth: 'Overall spent in this month:',
    unavailable: 'API is currently unavailable. To resume the operation of API top up your balance',
    autoBalance: 'Automatic top up of balance',
    autoChangeText: `To automatically top up the balance when it comes to
     zero, link a bank card to your account.`,
    autoText: `With automatic top-up enabled from your   linked card will be automatically debited   the specified amount of money upon reaching the specified minimum limit, but not more than the specified amount per month.`,
    allUrl: 'All URLs of your websites',
    urlText: `Specify the URL addresses of your websites where you intend to use the API. If you leave this field blank, then the request to the API can be sent from any website.`,
    changeApiKey: 'Change of API key',
    yesText: `If for some reason you want to update your API key, enter the word "YES" in the confirmation field. Please note that   the old key will stop working and you will need to    set up a new key wherever you use the API.   Change the API key only by peculiar necessity..`,
    statsMonths: 'Usage statistics by months',
    statsDay: 'Usage statistics by days',
  },
}

export default lang
