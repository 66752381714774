const lang = {
  ru: {
    archive: 'Архив с базой данных',
    saveArchive: 'Скачать архив',
    for: 'за',
    subscriptionEnds: 'Подписка на обновления заканчивается сегодня',
    stayed: ['остался', 'осталось', 'осталось'],
    dateEnds: 'До окончания подписки на обновления ',
    days: ['день', 'дня', 'дней'],
    archiveAcquisition: 'Приобретение архива',
    archiveText: `Вы можете купить архив со всеми данными о банках, включая файлы с логотипами. Структура архива и
    рекомандации по использованию описаны в документации.`,
    archiveText2: 'Можно купить архив с данными всех стран или только нужных по отдельности.',
    archiveText3: `Ссылка для скачивания архива с актуальной базой данных будет доступна в течение 1 года с момента
    оплаты. Ссылка на архив с обновлёнными даннными выкладывается первого числа каждого месяца.`,
    sandUs: 'Напишите нам в чат,',
    sandUsText: `чтобы узнать
    стомиость доступа к архива с базой данных и получит возможность его приобрести`,
    queryPrice: 'Запросить стоимость',
  },
  en: {
    archive: 'Download database archive',
    saveArchive: 'Download archive',
    for: 'for',
    subscriptionEnds: 'Your updates subscription ends today',
    stayed: ['remained', 'remained', 'remained'],
    dateEnds: ['days', 'days', 'days'],
    days:  `left until the end of your updates subscription`,
    archiveAcquisition: 'Acquisition of archive',
    archiveText: `You can buy an archive with all data about banks, including files with logos. Documentation have the archive structure and recommendations for use. `,
    archiveText2: 'You can buy an archive with data with all countries or just the ones you need separately.',
    archiveText3: `The link for downloading the archive with the current database will be available within 1 year from the date of payment. A link to the archive with updated data is posted on the first day of each month.`,
    sandUs: 'Write us in the chat,',
    sandUsText: `to find out the cost of access to the archive with the database and get the opportunity to purchase it`,
    queryPrice: 'Request a price',
  }
}

export default lang
