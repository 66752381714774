const lang = {
  ru: {
    token: 'Ссылка для подтверждения смены адреса электронной почты не действительна',
    title: 'Подтверждение смены почты',
    success: 'Смена почты успешно подтверждена',
    home: 'На главную',
  },
  en: {
    token: 'The link for confirmation of change of an email address is invalid',
    title: 'Confirmation of change of an email',
    success: 'Change of email successfully confirmed',
    home: 'To home page',
  }
}

export default lang
