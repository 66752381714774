const lang = {
  ru: {
    link: 'Ссылка для восстановления пароля отправлена вам на почту',
    required: 'Обязательное поле',
    error: 'Некорректный адрес электронной почты',
    new: 'Новый пароль установлен, теперь вы можете',
    enter: 'Войти',
    enter1: 'Вход',
    help: 'с помощью него',
    setNewPasswoed: 'Восстановление пароля',
    mail: 'Адрес электронной почты',
  },
  en: {
    link: 'A link to reset your password has been sent to your email',
    required: 'Required field',
    error: 'Incorrect email address',
    new: 'The new password has been set uo, now you can',
    enter: 'Login',
    enter1: 'Login',
    help: 'with it',
    setNewPasswoed: 'Password recovery',
    mail: 'E-mail address',
  },
}

export default lang
