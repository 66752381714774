import React from 'react'
import MainSegment from 'components/MainSegment'
import PagePadding from 'components/PagePadding'
import PageWrapper from 'components/PageWrapper'
import Input from 'components/Input'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import Link from 'components/Link'
import Form, { FormItems, useForm } from 'components/Form'
import * as Yup from 'yup'
import useMutation from 'lib/useMutation'
import { getSignInRoute } from 'lib/routes'
import Helmet from 'components/Helmet'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'


const RestorePasswordPage = ({ history }) => {
  const i18n = useLang(lang)
  const [requestPasswordReset] = useMutation('requestPasswordReset')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      email: '',
    },
    onSubmit: values => {
      return requestPasswordReset({
        variables: { input: values },
      })
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18n('error'))
        .required(i18n('required')),
    }),
    successMessage: i18n('link'),
    resetOnSuccess: true,
  })
  return (
    <PagePadding>
      <Helmet title={i18n('setNewPasswoed')} />
      <PageWrapper redirerctAuthorized>
        <MainSegment title={i18n('setNewPasswoed')}>
          <Form formik={formik}>
            <FormItems>
              <Input label={i18n('mail')} name="email" formik={formik} />
              <Alert {...alertProps} />
              <Buttons withLinks>
                <Button {...buttonProps}>{i18n('setNewPasswoed')}</Button>
                <Link type="solid" nowrap to={getSignInRoute()}>
                  {i18n('enter1')}
                </Link>
              </Buttons>
            </FormItems>
          </Form>
        </MainSegment>
      </PageWrapper>
    </PagePadding>
  )
}

export default RestorePasswordPage
