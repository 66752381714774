const lang = {
  ru: {
    error: 'Ошибка в ',
    url: 'должно быть валидным URL',
    successMessage: 'Список URL ваших сайтов успешно обновлён',
    newUrl: 'Каждый URL на новой строке',
    urlList: 'Список URL',
    save: 'Сохранить изменения',
  },
  en: {
    error: 'Error in ',
    url: ' URL must be valid',
    successMessage: 'The URL list of your websites successfully updated',
    newUrl: 'Each URL on a new line',
    urlList: ' URL List',
    save: 'Save changes',
  }
}

export default lang
