import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import css from './styles.module.scss'
import { ReactComponent as GitIcon } from 'assets/Kisa.svg'

const Button = ({
  to,
  buttonStyle = 'yellow',
  href,
  square,
  loading = false,
  disabled,
  children,
  fullWidth,
  asSpan,
  mobile,
  className,
  ...restProps
}) => {
  const classNameHere = cn({
    [css.button]: true,
    [css.loading]: loading,
    [css.disabled]: disabled,
    [css[buttonStyle]]: !!buttonStyle,
    [css.square]: !!square,
    [css.fullWidth]: !!fullWidth,
    [className]: !!className,
  })
  const childrenHere = <span className={css.content}>{children}</span>
  return to ? (
    <Link to={to} className={classNameHere} {...restProps}>
      {buttonStyle === 'git' && <GitIcon />}
      {childrenHere}
    </Link>
  ) : href ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={href} className={classNameHere} {...restProps}>
      {buttonStyle === 'git' && <GitIcon />}
      {childrenHere}
    </a>
  ) : asSpan ? (
    <span className={classNameHere} {...restProps}>
      {(buttonStyle === 'git' && <GitIcon />, childrenHere)}{' '}
    </span>
  ) : mobile ? (
    <button className={css.mobileButton} type="button" {...restProps}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  ) : (
    <button disabled={disabled} className={classNameHere} {...restProps}>
      {' '}
      {buttonStyle === 'git' && <GitIcon />}
      {childrenHere}
    </button>
  )
}

export const Buttons = ({ children, withLinks, start, className, ...rerstProps }) => (
  <div
    className={cn({
      [css.buttonsPlace]: true,
      [css.withLinks]: !!withLinks,
      [css.start]: !!start,
      [className]: !!className,
    })}
    {...rerstProps}
  >
    <div className={css.buttons}>{children}</div>
  </div>
)

export default Button
