import React from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import * as Yup from 'yup'
import useMutation from 'lib/useMutation'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const ChangeEmail = ({ history }) => {
  const i18n = useLang(lang)
  const [requestEmailChanging] = useMutation('requestEmailChanging')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      email: '',
    },
    onSubmit: ({ email }) => {
      return requestEmailChanging({
        variables: { input: { newEmail: email } },
      })
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18n('error'))
        .required(i18n('required')),
    }),
    successMessage:
      i18n('successMessage'),
    resetOnSuccess: true,
  })
  return (
    <Form formik={formik}>
      <FormItems>
        <Input
          label={i18n('email')}
          name="email"
          type="email"
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button {...buttonProps}>{i18n('changeEmail')}</Button>
      </FormItems>
    </Form>
  )
}

export default ChangeEmail
