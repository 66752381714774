import React from 'react'
import MainSegment from 'components/MainSegment'
import Helmet from 'components/Helmet'
import Button, { Buttons } from 'components/Button'
import { getDashboardRoute, getHomeRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'
import { useLang  } from 'lib/i18n'
import lang from './lang.js'

const NotFoundPage = ({ title, text }) => {
  const i18n = useLang(lang)
  const { me } = useQuery('getMe')
  return (
    <MainSegment title={title ? title : i18n('title')}>
      <Helmet title={title ? title : i18n('title')} />
      <p>{text ? text : i18n('text')}</p>
      <Buttons start style={{ marginTop: 15 }}>
        <Button to={me ? getDashboardRoute() : getHomeRoute()}>{i18n('btn')}</Button>
      </Buttons>
    </MainSegment>
  )
}

export default NotFoundPage
