const lang = {
  ru: {
    settings: 'Настройки профиля',
    mailManagement: 'Управление почтой',
    address: 'Ваш текущий адрес:',
    form: 'Если вы хотите сменить адрес почты, воспользуйтесь формой ниже.',
    ChangePassword: 'Изменение пароля',
  },
  en: {
    settings: 'Profile settings',
    mailManagement: 'Mail management',
    address: 'Your current address:',
    form: 'If you want to change your email address, please use the form below.',
    ChangePassword: 'Change password',
  }
}

export default lang
